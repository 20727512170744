import "./hubs-page.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EditButtonComponent, SyncButtonComponent } from "../../components/button-component";
import PlacerholderComponent from "../../components/placerholder-component";
import useMyHubs from "../../services/hub/useMyHubs";
import { RequestState } from "../../services/core/request-state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HubHelpers } from "../../services/hub/hub-helper";
import MemberService from "../../services/member/member-service";
import StringHelpers from "../../services/core/string-helpers";
import Member from "../../services/member/member";
import DateHelpers from "../../services/core/date-helpers";

type Props = {
  member: Member;
};

export default function HubsPage(props: Props) {
  const { member } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const { hubs, requestState, refresh } = useMyHubs({ onErrorNotificationMessage: t("hubs-page.error-refresh") });

  return (
    <div className="container page hubs-page pt-5">
      <h1>{t("hubs-page.title")}</h1>
      <SyncButtonComponent
        className="ms-3 mb-3"
        disabled={requestState !== RequestState.Success}
        onClick={() => refresh()}
      ></SyncButtonComponent>

      {requestState === RequestState.Failure && <div>{t("hubs-page.error-refresh")}</div>}

      <div className="row mt-3">
        <div className="col">
          <div className="customer card">
            <div className="card-body">
              <PlacerholderComponent loading={requestState === RequestState.Loading} rows={5}>
                <table className="table table-sm table-hover row-clikable">
                  <thead>
                    <tr>
                      <th>{t("hubs-page.name")}</th>
                      <th>{t("hubs-page.state")}</th>
                      {MemberService.isLocketgoHubAdmin(member) && <th>{t("hubs-page.version")}</th>}
                      <th></th>

                      {/* Empty column header for actions */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {requestState === RequestState.Success && hubs.length === 0 && (
                      <tr>
                        <td colSpan={3}>{t("hubs-page.no-hubs")}</td>
                      </tr>
                    )}

                    {hubs.map((hub) => {
                      const url = `/hubs/${hub.id}`;
                      const online = hub.state === "Online";
                      const title = t(online ? "components.location-online" : "components.location-offline");
                      if (!hub.active) {
                        return <></>;
                      }
                      return (
                        <tr key={hub.id} onClick={() => history.push(url)}>
                          <td>
                            {hub.name}
                            {hub.private && <FontAwesomeIcon className="ms-2" icon={["fas", "user-lock"]} />}
                          </td>
                          <td>
                            <span
                              className={`ms-2 text-${online ? "success" : "danger"}`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={title}
                            >
                              <FontAwesomeIcon icon={["fas", "circle"]} />
                            </span>
                          </td>
                          {MemberService.isLocketgoHubAdmin(member) && (
                            <td>
                              {hub.version &&
                                DateHelpers.formatDateTime(new Date(hub.version.substring(0, 19) + "+00:00"), DateHelpers.UserTimeZone)}
                            </td>
                          )}
                          <td>{t("hubs-page.compartments", { count: HubHelpers.GetCompartmentCount(hub) })}</td>
                          <td>
                            <EditButtonComponent url={url}></EditButtonComponent>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </PlacerholderComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
