import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  setValue: Function;
  msgError: string | undefined;
  onMsgErrorChanged: Function;
  disabled?: boolean | undefined;
};

export function LastNameComponent(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <label className="form-label">{t("member-profile.lastName")}</label>
      <input className="form-control" value={props.value} onChange={(ev) => validate(ev.target.value)} disabled={props.disabled} maxLength={50}/>
      {props.msgError && <label className="error-msg">{props.msgError}</label>}
    </>
  );
  function validate(value : string)
  {
    value = value.substring(0,50);
    props.setValue(value);

    props.onMsgErrorChanged("");
    if(value.length > 50)
    {
      props.onMsgErrorChanged(t("input-component.lastname-error"));
    }
  }
}

