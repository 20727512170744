import React from "react";
import Member from "../../services/member/member";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "../customers/customers-page.scss";
import ToastHelpers from "../../services/core/toast-helpers";
import Organization from "../../services/organization/organization";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import LoadingComponent from "../../components/loading-component";
import languageService from "../../services/core/language-service";
import memberService from "../../services/member/member-service";
import organizationService from "../../services/member/organization-service";
import { Hub } from "../../services/hub/hub";
import "./hub-permission-table.scss";

interface Iprops {
  member: Member | undefined;
  organizationId: string;
  setMember: Function;
}

export default function HubPermissionTable(props: Iprops) {
  const { t } = useTranslation();

  const { member, organizationId, setMember } = props;
  const [organization, setOrganization] = useState<Organization>();
  const [loading, setLoading] = useState<boolean>(true);
  const columnsTemp = [
    {
      Header: "Hub Id",
      accessor: "hubId",
    },
    {
      Header: "User permission over Hub",
      accessor: "permission",
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      columns: useMemo(() => columnsTemp, [languageService.language]),
      data: useMemo(() => updateHubsRoleData(organization, member), [organization, member, loading]),
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    if (organizationId) {
      setPageSize(10);
      refresh();
    }
  }, [organizationId]);

  function refresh() {
    const orgId = organizationId;
    if (orgId) {
      setLoading(true);

      memberService.getMembers(orgId);
      organizationService
        .getOrganizationById(organizationId)
        .then((org) => {
          if (org) {
            setOrganization(org);
          }
        })
        .catch(() => {
          ToastHelpers.error(t("orders-page.error-refresh"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function changeMemberPermissions(permission: string, currentPermission: string, hubId: string) {
    // Push updated user to DB
    if (member?.email) {
      setLoading(true);

      permission = permission === "None" ? "" : permission;
      var mem = member;

      if (currentPermission === "None") {
        await memberService
          .addMemberRole(member.email, hubId, permission, "Hub")
          .then((res) => {
            var hub = new Hub();
            hub.id = hubId;

            mem.associatedHubs.push({
              entity: hub,
              roles: [permission],
            });

            setMember(mem);
          })
          .catch((err) => {
            ToastHelpers.error("Error creating member role");
          });
      } else {
        await memberService
          .updateMemberRole(member.email, hubId, permission, "Hub", "Active")
          .then((res) => {
            mem.associatedHubs.forEach((element) => {
              if (element.entity?.id === hubId) {
                element.roles = permission === "" ? ["None"] : [permission];
              }
            });
            setMember(mem);
          })
          .catch((err) => {
            ToastHelpers.error("Error changing member role");
          });
      }
    }
    setLoading(false);
  }

  return (
    <div className="mt 10">
      <table {...getTableProps()} className="table  table-sm table-hover row-clikable">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return <th {...column.getHeaderProps()}>{column.render("Header")}</th>;
              })}

              {/* Empty column header for actions */}
              <td></td>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading && (
            <tr>
              <td colSpan={7} className="text-center">
                <LoadingComponent loading={loading} />
              </td>
            </tr>
          )}

          {!loading &&
            page.map((row, i) => {
              prepareRow(row);

              let hub = row.original as any;

              return (
                <tr {...row.getRowProps()}>
                  <td {...row.cells[0].getCellProps()} id={member?.country}>
                    {hub.hubId}
                  </td>
                  <td {...row.cells[1].getCellProps()}>
                    <div className="permission-radio-group">
                      <input
                        type="radio"
                        value="Admin"
                        name={`role-${hub.hubId}`}
                        checked={hub.permission === "Admin" || hub.permission === "admin"}
                        onChange={() => changeMemberPermissions("Admin", hub.permission, hub.hubId)}
                      />{" "}
                      Admin
                      <input
                        type="radio"
                        value="Contributor"
                        name={`role-${hub.hubId}`}
                        checked={hub.permission === "Contributor"}
                        onChange={() => changeMemberPermissions("Contributor", hub.permission, hub.hubId)}
                      />{" "}
                      Contributor
                      <input
                        type="radio"
                        value="Concierge"
                        name={`role-${hub.hubId}`}
                        checked={hub.permission === "Concierge"}
                        onChange={() => changeMemberPermissions("Concierge", hub.permission, hub.hubId)}
                      />{" "}
                      Concierge
                      <input
                        type="radio"
                        value="None"
                        name={`role-${hub.hubId}`}
                        checked={hub.permission === "None"}
                        onChange={() => changeMemberPermissions("None", hub.permission, hub.hubId)}
                      />{" "}
                      None
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

function updateHubsRoleData(organization: Organization | undefined, member: Member | undefined): Array<any> {
  const tableData: Array<any> = [];

  organization?.associatedHubs.forEach((hub) => {
    var permission = "None";

    if (member?.associatedHubs) {
      member.associatedHubs.forEach((element) => {
        if (element.entity?.id == hub.hubId) {
          permission = element.roles[0];
        }
      });
    }

    if (hub) {
      if (hub.state === "Approved") {
        tableData.push({
          hubId: hub.hubId,
          permission: permission,
        });
      }
    }
  });

  return tableData;
}
