import { Dropdown } from "bootstrap";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

enum Side {
    Front = "front",
    Back = "back",
}
  
interface IDropdownSideProps {
    onChange: (side: Side) => void;
}

function DropdownSide(props: IDropdownSideProps) {
    const { onChange } = props;
  
    const [state, setState] = useState<Side>(Side.Front);
    const [dropdown, setDropdown] = useState<Dropdown | null>(null);
    const ddRef = useRef(null);
  
    const { t } = useTranslation();
  
    useEffect(() => {
      if (!dropdown) {
        setDropdown(new Dropdown(ddRef.current || "", {}));
      }
    }, [dropdown]);
  
    const onDropdownClick = () => {
      dropdown?.show();
    };
  
    const onItemClick = (newSide: Side) => {
      if (state !== newSide) {
        setState(newSide);
        onChange(newSide);
      }
  
      dropdown?.hide();
    };
  
    return (
      <div className="dropdown ms-2">
        <button
          className="btn btn-outline-primary dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          ref={ddRef}
          aria-expanded="false"
          onClick={onDropdownClick}
        >
          {t(`hubs-page.dropdown-side-${state.toString()}`)}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdown1">
          {Object.values(Side).map((state: Side) => {
            return (
              <li key={state}>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    onItemClick(state);
                  }}
                >
                  {t(`hubs-page.dropdown-side-${state}`)}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  export default DropdownSide;