import axios from "axios";
import authService from "./auth/auth-service";

export default class ServicesApiBaseService {
  apiUrl: string | undefined = process.env.REACT_APP_SERVICESAPI_URL;

  async getFile(url: string): Promise<Blob | undefined> {
    const response = await axios.get<Blob>(url, { ...(await this.getHttpConfig()), responseType: "blob" }).catch((error) => {
      return this.handleError("GET", url, error);
    });
    return response?.data;
  }

  async getObject<T>(url: string): Promise<T | undefined> {
    const response = await axios.get<T>(url, await this.getHttpConfig()).catch((error) => {
      return this.handleError("GET", url, error);
    });
    return response?.data;
  }

  async postObject<Tin, Tout>(url: string, data: Tin): Promise<Tout | undefined> {
    const response = await axios.post<Tout>(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("POST", url, error);
    });
    return response?.data;
  }

  async deleteObject<Tout>(url: string): Promise<Tout | undefined> {
    const response = await axios.delete<Tout>(url, await this.getHttpConfig()).catch((error) => {
      return this.handleError("DELETE", url, error);
    });
    return response?.data;
  }

  async putObject<Tin>(url: string, data: Tin): Promise<void> {
    await axios.put<Tin>(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("PUT", url, error);
    });
  }

  protected async getHttpConfig() {
    return {
      headers: { Authorization: `Bearer ${await authService.getAccessToken()}` },
    };
  }

  private handleError(method: string, url: string, error: any) {
    if (error.response) {
      if (error.response.status === 404) {
        return undefined;
      }

      console.error(`${method} ${url} error ${error.response.status} : '${error.response.statusText}'`);
    } else if (error.message) {
      console.error(`${method} ${url} error ${error.message}`);
    } else {
      console.error(`${method} ${url} unkown error`, error);
    }

    throw error;
  }
}
