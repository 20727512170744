import { Reservation } from "../order/reservation";
import { Compartment, CompartmentStates } from "./compartment";
import { Group } from "./group";
import { Hub, MyHub } from "./hub";

export class HubHelpers {
  static findGroup(hub: Hub, groupId: string): Group | undefined {
    let group = undefined;
    hub?.groups?.forEach((element: Group) => {
      if (element.id === groupId) {
        group = element;
      }
    });
    return group;
  }

  static findCompartment(hub: Hub, reservation: Reservation): Compartment | undefined {
    return HubHelpers.findGroup(hub, reservation.groupId)?.compartments.find((c) => c.number === reservation?.compartmentNumber);
  }

  static GetCompartmentCount(hub: Hub): number {
    let nbCompartements = 0;
    for (let i = 0; i < hub.groups.length; i++) {
      nbCompartements += hub.groups[i].compartments.length;
    }
    return nbCompartements;
  }

  static getPackagingOptiponIds(hub: Hub): Set<string> {
    let ids: Set<string> = new Set<string>();

    hub.groups?.forEach((group: Group) => {
      group.compartments?.forEach((compartment: Compartment) => {
        compartment.packagingOptionsIds?.forEach((packagingOptionId: string) => {
          ids.add(packagingOptionId);
        });
      });
    });

    return ids;
  }

  static isHubOnline(hubState: string | undefined) {
    return hubState === "Online";
  }

  static isOneGroupsOnline(hub: Hub): boolean {
    if (!this.isHubOnline(hub.state)) return false;

    let group = hub.groups.find((group) => group.state === "Online");
    return group !== null || group !== undefined;
  }

  static isGroupOnline(hub: Hub, groupId: string): boolean {
    if (!this.isHubOnline(hub.state)) return false;

    let group: Group | undefined = hub?.groups.find((group: Group) => group.id === groupId);

    if (!group || !group?.state) return false;

    return group.state === "Online";
  }

  static isCompartmentOnline(hub: Hub, groupId: string, compartmentNumber: string): boolean {
    if (!this.isHubOnline(hub.state)) return false;

    let group: Group | undefined = hub?.groups.find((group: Group) => group.id === groupId);

    if (!group || !group?.state) return false;

    let compartment: Compartment | undefined = group?.compartments.find(
      (compartment: Compartment) => compartment.number === compartmentNumber
    );

    if (!compartment || !compartment?.state) return false;

    return compartment.state !== "Unknown";
  }

  static getAddress(hub: Hub): string {
    if (!hub || !hub.address) {
      return "";
    }

    let text = "";

    if (hub.address.streetNumber) {
      text = hub.address.streetNumber;
    }
    if (hub.address.street) {
      text += (text.length > 0 ? " " : "") + hub.address.street;
    }

    if (hub.address.city) {
      text += (text.length > 0 ? ", " : "") + hub.address.city;
    }
    if (hub.address.state) {
      text += (text.length > 0 ? ", " : "") + hub.address.state;
    }
    if (hub.address.postalCode) {
      text += (text.length > 0 ? ", " : "") + hub.address.postalCode;
    }

    return text;
  }

  static getCompartmentSizeIds(hub: Hub): Set<string> {
    let sizeIds = new Set<string>();

    if (hub.id && hub.groups.length > 0) {
      hub.groups.forEach((group) => {
        if (group.compartments.length > 0) {
          group.compartments.forEach((compartment) => {
            if (!sizeIds.has(compartment.sizeId)) {
              sizeIds.add(compartment.sizeId);
            }
          });
        }
      });
    }

    return sizeIds;
  }

  static getAvailableCompartmentSizeIds(hub: MyHub): Array<string> {
    let sizeIds = Array<string>();

    for (let i = 0; i < hub.groups.length; i++) {
      let group = hub.groups[i];
      for (let j = 0; j < group.compartments.length; j++) {
        let compartment = group.compartments[j];
        const compartmentHasReservation = hub.reservations.find(
          (r) => r.groupId === group.id && r.compartmentNumber === compartment.number
        );
        const isOpened = compartment.state === CompartmentStates.Opened;
        const isDeactivated = hub?.reservationDenies?.some(
          (r) => r.compartmentNumber === compartment.number && r.groupId === group.id && r.hubId === hub.id
        );

        if (!sizeIds.includes(compartment.sizeId) && !compartmentHasReservation && !isOpened && !isDeactivated) {
          sizeIds.push(compartment.sizeId);
        }
      }
    }

    return sizeIds;
  }
}
