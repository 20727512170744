import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Hub } from "../services/hub/hub";

interface HubLocationProps {
  hub: Hub | null;
  formatToFit?: number | undefined;
}

export default function HubLocationComponent(props: HubLocationProps) {
  const { hub, formatToFit } = props;
  const { t } = useTranslation();

  if (!hub) {
    return null;
  }

  const online = hub.state === "Online";
  const locationStyle = `me-2 text-${online ? "success" : "danger"}`;
  const title = t(online ? "components.location-online" : "components.location-offline");

  return (
    <>
      <span className={locationStyle} data-bs-toggle="tooltip" data-bs-placement="top" title={title}>
        <FontAwesomeIcon icon={["fas", "circle"]} />
      </span>
      {formatToFit && formatStringToFit(hub.name, formatToFit)}
      {!formatToFit && hub.name}
    </>
  );
}

function formatStringToFit(value: String, minWidth: number) {
  value = value.trim();
  let nbExtraLetterCanFit = Math.floor((window.innerWidth - minWidth) / 16);
  let length = 3 + (nbExtraLetterCanFit > 0 ? nbExtraLetterCanFit : 0);
  let resString = value.substr(0, length);

  if (resString === value) return value;

  return value.substr(0, length).trimEnd() + "...";
}
