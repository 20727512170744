import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Hub } from "../services/hub/hub";
import { HubHelpers } from "../services/hub/hub-helper";
import { Order, OrderFlowStringValue } from "../services/order/order";
import { OrderHelpers } from "../services/order/order-helpers";
import { Reservation } from "../services/order/reservation";
import { OpenDoorComponent, DoorOpenedComponent } from "./order-page-components";
import { StateBadgeComponent } from "./state-badge-component";
import TimerComponent from "./timerComponent";

interface LockerMultipleReservationsMessageProps {
  askOpenDoor: (order: Order, reservation: Reservation) => Promise<void>;
  order: Order | undefined;
  reservation: Reservation;
  hub: Hub | undefined;
  reservationInOpening: null;
  reservationIsActive: boolean;
  compartmentSizeName?: string;
  helpLink: string;
  hubName?: string;
}

export default function LockerMultipleReservationsMessageComponent({
  askOpenDoor,
  order,
  reservation,
  hub,
  reservationInOpening,
  reservationIsActive,
  compartmentSizeName,
  helpLink,
  hubName,
}: LockerMultipleReservationsMessageProps) {
  const { t } = useTranslation();
  const groupId = reservation.groupId;
  const opened = OrderHelpers.inOpenedState(reservation.state);
  const inOpening = reservationInOpening !== null;
  const isPersonalStorage = order?.flow?.toString() === OrderFlowStringValue.PersonalStorage;

  const reservationTimeLeft = useMemo(() => {
    const dateNow = new Date();
    const until = new Date(reservation.end);
    const milisecondsLeft = until.getTime() - dateNow.getTime();
    return milisecondsLeft / 1000;
  }, [reservation.end]);

  return (
    <tr key={reservation.id}>
      <td>
        {hubName ?? /^\d+$/.test(reservation.compartmentNumber)
          ? reservation.compartmentNumber
          : reservation.groupId + reservation.compartmentNumber}
      </td>
      <td>
        <TimerComponent timeInSeconds={reservationTimeLeft} message="" onTimerEnds={() => null} className={"text-dark"} />
      </td>
      {compartmentSizeName && <td>{compartmentSizeName}</td>}
      <td>
        <StateBadgeComponent state={reservation.state} isPersonalStorage={isPersonalStorage}></StateBadgeComponent>
      </td>
      <td>
        {hub && HubHelpers.isGroupOnline(hub, groupId) ? (
          <>
            <OpenDoorComponent
              onClick={() => {
                if (order) askOpenDoor(order, reservation);
              }}
              disabled={opened || inOpening || !reservationIsActive}
            ></OpenDoorComponent>
            {!inOpening && opened && reservationIsActive && <DoorOpenedComponent />}
          </>
        ) : (
          <>
            <h4 className="text-center fw-bold mt-0 pt-0">
              {t("my-reservations-page.hub-or-group-offline")}
              <br />
              <a className="h4 text-center mt-0 pt-0" href={helpLink}>
                {helpLink}
              </a>
            </h4>
            <br />
          </>
        )}
      </td>
    </tr>
  );
}
