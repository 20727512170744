import { useEffect, useState, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./orders-page.scss";
import { Order } from "../../services/order/order";
import { Hub } from "../../services/hub/hub";
import { useTranslation } from "react-i18next";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import hubService from "../../services/hub/hub-service";
import orderService, { OrderState } from "../../services/order/order-service";
import Dropdown from "bootstrap/js/dist/dropdown";
import StringHelpers from "../../services/core/string-helpers";
import { OrderHelpers } from "../../services/order/order-helpers";
import Member from "../../services/member/member";
import Organization from "../../services/organization/organization";
import PagingComponent from "../../components/paging-component";
import ToastHelpers from "../../services/core/toast-helpers";
import { AddButtonComponent, SyncButtonComponent } from "../../components/button-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import languageService from "../../services/core/language-service";
import DateRangeComponent, { DateRangeOptions } from "../../components/dateRangeComponent";
import { GlobalFilter } from "../../components/globalfilter-component";
import { OrdersTableComponent } from "../../components/orders-page-components/orders-table-component";
import DateHelpers from "../../services/core/date-helpers";

interface IDropdownStateProps {
  onChange: any;
}

function DropdownState(props: IDropdownStateProps) {
  const { onChange } = props;

  const [state, setState] = useState<OrderState>(OrderState.Active);
  const [dropdown, setDropdown] = useState<Dropdown | null>(null);
  const ddRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!dropdown) {
      setDropdown(new Dropdown(ddRef.current || "", {}));
    }
  }, [dropdown]);

  const onDropdownClick = () => {
    dropdown?.show();
  };

  const onItemClick = (newState: OrderState) => {
    if (state !== newState) {
      setState(newState);
      onChange(newState);
    }

    dropdown?.hide();
  };

  return (
    <div className="dropdown ms-2">
      <button
        className="btn btn-outline-primary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        ref={ddRef}
        aria-expanded="false"
        onClick={onDropdownClick}
      >
        {t(`orders-page.dropdown-state-${state.toString()}`)}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdown1">
        {Object.values(OrderState).map((state: OrderState) => {
          return (
            <li key={state}>
              <button
                className="dropdown-item"
                onClick={() => {
                  onItemClick(state);
                }}
              >
                {t(`orders-page.dropdown-state-${state}`)}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface onDateProps {
  currentStart: string;
  currentEnd: string;
}

interface OrdersPageProps {
  organization: Organization | undefined;
  member: Member;
}

export default function OrdersPage(props: OrdersPageProps) {
  const { organization } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<Array<Order>>([]);
  const [hubs, setHubs] = useState<Array<Hub> | null>(null);
  const history = useHistory();

  let now = new Date();
  let roundAt5 = 5 - (now.getMinutes() % 5);
  now.setMinutes(now.getMinutes() + roundAt5);

  let startDate = now;
  let endDate = new Date(now);
  endDate.setDate(endDate.getDate() + 1);
  startDate.setMonth(startDate.getMonth() - 1);
  startDate.setDate(startDate.getDate() + 1);
  let startInit = DateHelpers.toDateString(startDate, DateHelpers.UserTimeZone);
  let endInit = DateHelpers.toDateString(endDate, DateHelpers.UserTimeZone);

  const [currentStartEnd, setCurrentStartEnd] = useState<onDateProps>({
    currentStart: startInit,
    currentEnd: endInit,
  });

  const [currentState, setCurrentState] = useState<OrderState>(OrderState.Active);

  const { t } = useTranslation();

  useEffect(() => {
    if (organization) {
      setPageSize(10);

      hubService
        .getHubs()
        .then((hubs) => {
          setHubs(hubs);
        })
        .catch(() => {
          ToastHelpers.error(t("orders-page.error-refresh"));
        });
    }
  }, [organization, t]);

  useMemo(async () => {
    if (currentStartEnd) {
      await refreshOrders(currentStartEnd.currentStart, currentStartEnd.currentEnd);
    }
  }, [currentState, currentStartEnd]);

  const onStateChange = (newState: OrderState) => {
    setCurrentState(newState);
  };

  //Prepare Table Data
  let tableDataCSV: Array<any> = useMemo(() => [], [orders, hubs]);

  //Table configuration
  const columnsTemp = [
    {
      Header: t("orders-page-table-header.OrderId"),
      accessor: "orderId",
    },
    {
      Header: t("orders-page-table-header.State"),
      accessor: "state",
    },
    {
      Header: t("orders-page-table-header.Customer"),
      accessor: "customer",
    },
    {
      Header: t("customers-page-table-header.Email"),
      accessor: "email",
    },
    {
      Header: t("customers-page-table-header.Phone"),
      accessor: "phone",
    },
    {
      Header: t("orders-page-table-header.Location"),
      accessor: "hubId",
    },
    {
      Header: t("orders-page-table-header.Lockers"),
      accessor: "lockers",
    },
    {
      Header: t("orders-page-table-header.CreationDate"),
      accessor: "creationDate",
    },
    {
      Header: t("orders-page-table-header.reservation-Block-Name"),
      accessor: "reservationBlockName",
    },
    {
      Header: t("order-page.reference-number"),
      accessor: "referenceNumber",
    },
    {
      Header: t("common.tracking-numbers"),
      accessor: "trackingNumbers",
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line
      columns: useMemo(() => columnsTemp, [languageService.language]),
      // eslint-disable-next-line
      data: useMemo(() => updateTableOrdersData(orders, hubs, tableDataCSV), [orders, hubs]),
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(state.globalFilter);
  }, [orders, hubs]);

  return (
    <div className="container page orders-page">
      <h1>{t("orders-page.locker-orders")}</h1>

      <div className="toolbar">
        <AddButtonComponent title={t("orders-page.btn-create-order")} onClick={() => history.push(`/create-order`)}></AddButtonComponent>

        <AddButtonComponent
          className="btn btn-outline-primary ms-2"
          title={t("orders-page.btn-create-return")}
          onClick={() => history.push(`/create-return`)}
        ></AddButtonComponent>

        <SyncButtonComponent
          className="ms-2"
          onClick={async () => await refreshOrders(currentStartEnd?.currentStart, currentStartEnd?.currentEnd)}
        ></SyncButtonComponent>

        <DropdownState onChange={onStateChange} />

        <div className="ms-1">
          <DateRangeComponent
            id={"ordersDatesFilter"}
            default={DateRangeOptions.Month}
            onDateChange={onDateChanged}
            start={currentStartEnd?.currentStart}
            end={currentStartEnd?.currentEnd}
          />
        </div>

        <div className="line2 ms-2">
          <CSVLink className="btn btn-outline-primary" data={tableDataCSV} enclosingCharacter={`'`} filename="ExportOrders.csv">
            <FontAwesomeIcon icon="file-export"></FontAwesomeIcon> {t("common.export")}
          </CSVLink>

          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
        </div>
      </div>

      <div>
        <OrdersTableComponent
          hubs={hubs}
          isLoading={loading}
          tableProps={getTableProps()}
          tableBodyProps={getTableBodyProps()}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
        />
        <PagingComponent
          countTextResource={"orders-page.item-count"}
          count={rows.length}
          pageLength={pageOptions.length}
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );

  async function refreshOrders(start: string | undefined, end: string | undefined) {
    const orgId = organization?.id;
    if (orgId) {
      setLoading(true);
      if (start && end) {
        await orderService
          .getOrders(orgId, currentState, start, end)
          .then((data) => {
            setOrders(data);
          })
          .catch(() => {
            ToastHelpers.error(t("orders-page.error-refresh"));
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        await orderService
          .getOrders(orgId, currentState)
          .then((data) => {
            setOrders(data);
          })
          .catch(() => {
            ToastHelpers.error(t("orders-page.error-refresh"));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }

  function onDateChanged(start: string, end: string) {
    setCurrentStartEnd({ currentStart: start, currentEnd: end });
  }
}

//Prepare Table Data
function updateTableOrdersData(orders: Array<Order>, hubs: Array<Hub> | null, tableDataCSV: Array<any>): Array<any> {
  const tableData: Array<any> = [];
  if (orders && hubs) {
    orders.forEach((order: Order) => {
      const orderLockers = OrderHelpers.getOrderLockers(order).toString();
      const trackingNumbers = order?.items?.flatMap((item) => item.reservation.trackingNumbers ?? []);
      tableData.push({
        orderId: order.id.toString(),
        state: order.state.toString(),
        customer: `${order.customer?.lastname?.toString()} ${order.customer?.firstname?.toString()}`,
        email: order.customer?.email?.toString(),
        phone: order.customer?.phone?.toString(),
        hubId: order.hubId,
        lockers: orderLockers,
        creationDate: order.orderDate.toString(),
        notifyLevel: order.notifyLevel,
        reservationBlockName: order?.items[0]?.reservation?.reservationBlockName,
        referenceNumber: order.referenceNumber,
        trackingNumbers: trackingNumbers,
      });

      const tmp = {
        HubName: order.hubId,
        OrderId: order.id.toString(),
        ReferenceNumber: order.referenceNumber,
        CreationDate: order.orderDate.toString(),
        State: order.state.toString(),
        FirstName: order.customer?.firstname,
        LastName: order.customer?.lastname,
        Email: order.customer?.email,
        Phone: order.customer?.phone,
        SiteId: (order?.items && order.items.length > 0 && order.items[0] && order?.items[0]?.reservation?.micrositeId) || "",
        reservationBlockName:
          (order.items && order.items.length > 0 && order.items[0] && order?.items[0]?.reservation?.reservationBlockName) || "",
        Locker: orderLockers,
      };
      tableDataCSV.push(tmp);
    });
  }
  return tableData;
}
