interface NumberDropdownProps {
  id: string | undefined;
  min: number;
  max: number;
  current: number;
  disabled: boolean;
  onchange: Function;
}
export function NumberDropDown(props: NumberDropdownProps) {
  return (
    <select
      id={props.id}
      value={props.current}
      style={{ width: "auto" }}
      className="form-select d-inline"
      disabled={props.disabled}
      onChange={() => props.onchange(props.id)}
    >
      {Array.from(new Array(props.max), (x, i) => i + props.min).map((i) => {
        return (
          <option key={"dropDownOpt" + i} id={"dropDownOpt" + i} value={i}>
            {i}
          </option>
        );
      })}
    </select>
  );
}
