import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InputComponent } from "../../../components/input";
import { ActionButtonComponent, DeleteButtonComponent } from "../../../components/button-component";
import micrositeService from "../../../services/micro-site/microsite-service";
import LoadingComponent from "../../../components/loading-component";
import adminService from "../../../services/admin/admin-service";
import HubInclusionTable from "./hub-inclusion-table";
import Organization from "../../../services/organization/organization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IParamTypes {
  organizationId: string;
  micrositeId: string;
}

interface Props {
  chosenOrg: Organization;
}

export default function MicrositeAdminPage(props: Props) {
  const { organizationId, micrositeId } = useParams<IParamTypes>();
  const { chosenOrg } = props;

  const [nameFr, setNameFr] = useState<string | undefined>(undefined);
  const [nameEn, setNameEn] = useState<string | undefined>(undefined);
  const [hubIds, setHubsIds] = useState<Array<string> | undefined>(undefined);
  const [micrositeIdState, setMicrositeIdState] = useState<string | undefined>(undefined);
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
  const [countryCode, setCountryCode] = useState<string | undefined>("");
  const [currency, setCurrency] = useState<string | undefined>("");
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  useEffect(() => {
    async function fetchMicrosite() {
      if (micrositeId === "NEWMICROSITE") return;
      setLoading(true);
      await micrositeService
        .getMicrosite(micrositeId)
        .then((micrositeData) => {
          var nameMap: Map<string, string> = micrositeData?.name;
          setNameFr(nameMap.get("fr"));
          setNameEn(nameMap.get("en"));
          setMicrositeIdState(micrositeData?.id);
          setLogoUrl(micrositeData?.logoUrl);
          setCountryCode(micrositeData?.countryCode);
          setCurrency(micrositeData?.currency);
          setHubsIds(micrositeData?.hubIds);
        })
        .catch((error) => {
          console.error("Failed to fetch microsite data", error);
        });
      setLoading(false);
    }
    fetchMicrosite();
  }, [micrositeId]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (micrositeId === "NEWMICROSITE") {
        await adminService.createMicrosite(
          nameFr,
          nameEn,
          micrositeIdState,
          logoUrl,
          countryCode ?? "",
          currency ?? "",
          organizationId,
          hubIds ?? []
        );
      } else {
        await adminService.updateMicrosite(
          micrositeId,
          nameFr,
          nameEn,
          micrositeIdState,
          logoUrl,
          countryCode ?? "",
          currency ?? "",
          organizationId,
          hubIds ?? []
        );
      }
      history.push(`/organizations/${organizationId}/microsites`);
    } catch (error) {
      console.error("Failed to submit microsite data", error);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await adminService.deleteMicrosite(micrositeId);
      history.push(`/organizations/${organizationId}/microsites`);
    } catch (error) {
      console.error("Failed to delete", error);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingComponent loading={loading} />;
  }

  return (
    <div className="container page admin-page pt-5">
      <div className="col-12 offset-lg-2 col-lg-8">
        <h2 className="mb-3">Microsite Database Admin</h2>
        <div className="card">
          <div className="card-body">
            <form className="row g-3" onSubmit={(ev) => ev.preventDefault()}>
              <div className="col-md-6">
                <InputComponent
                  labelText={"Microsite Id (only lowercase and - )"}
                  setValue={(value: string) => setMicrositeIdState(value)}
                  value={micrositeIdState ? micrositeIdState : ""}
                  disabled={false}
                  regExp={/^[a-z0-9-]+$/}
                />
              </div>
              <div className="col-md-6">
                <InputComponent
                  labelText={"Country Code (CA or US)"}
                  setValue={(value: string) => setCountryCode(value)}
                  value={countryCode ?? ""}
                  disabled={false}
                  regExp={/^(CA|US)$/}
                />
              </div>
              <div className="col-md-6">
                <InputComponent
                  labelText={"NameEn"}
                  setValue={(value: string) => setNameEn(value)}
                  value={nameEn ? nameEn : ""}
                  disabled={false}
                />
              </div>
              <div className="col-md-6">
                <InputComponent
                  labelText={"Currency (CAD or USD)"}
                  setValue={(value: string) => setCurrency(value)}
                  value={currency ?? ""}
                  disabled={false}
                  regExp={/^(CAD|USD)$/}
                />
              </div>
              <div className="col-md-6">
                <InputComponent
                  labelText={"NameFr"}
                  setValue={(value: string) => setNameFr(value)}
                  value={nameFr ? nameFr : ""}
                  disabled={false}
                />
              </div>
              <div className="col-md-6">
                <InputComponent
                  labelText={"Logo URL"}
                  setValue={(value: string) => setLogoUrl(value)}
                  value={logoUrl ? logoUrl : ""}
                  disabled={false}
                />
              </div>
              <HubInclusionTable hubs={chosenOrg.associatedHubs} setHubsIds={setHubsIds} currentHubs={hubIds} />
              <div className="col-md-6">
                <ActionButtonComponent
                  type="submit"
                  title={micrositeId === "NEWMICROSITE" ? "Create" : "Update"}
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="btn btn-primary"
                  disabled={false}
                />

                <button
                  className="btn btn-md btn-primary-outline"
                  type="submit"
                  style={{ marginLeft: 20 }}
                  onClick={() => history.push(`/organizations/${organizationId}/microsites`)}
                >
                  {"Cancel"}
                </button>
                {micrositeId !== "NEWMICROSITE" && (
                  <button
                    className={`btn btn-danger`}
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "trash-alt"]} /> {"Delete"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
