import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./dashboard-page.scss";
import analyticService from "../../services/analytic/analytic-service";
import { Dashboard } from "../../services/analytic/dashboard";
import ToastHelpers from "../../services/core/toast-helpers";
import hubService from "../../services/hub/hub-service";
import { Hub } from "../../services/hub/hub";
import Member from "../../services/member/member";
import HubStatsComponent from "./hub-stats-component";
import memberService from "../../services/member/member-service";
import { SyncButtonComponent } from "../../components/button-component";
import ChartLine from "../../components/chart-line";
import StringHelpers from "../../services/core/string-helpers";
import OrderStatsTable from "./order-stats-component";
import DateRangeComponent, { DateRangeOptions } from "../../components/dateRangeComponent";
import EventStatsTable from "./event-stats-component";
import LoadingComponent from "../../components/loading-component";
import DateHelpers from "../../services/core/date-helpers";

type Props = {
  member: Member;
};

interface onDateProps {
  currentStart: string;
  currentEnd: string;
}

export default function DashboardPage(props: Props) {
  const { member } = props;

  const [loading, setLoading] = useState<boolean>(true);

  const [model, setModel] = useState<Dashboard | undefined>(undefined);
  const [hubs, setHubs] = useState<Array<Hub> | null>(null);
  const isHubContributor = memberService.isHubContributor(member);
  const isOrganizationContributor = memberService.isOrganizationContributor(member);
  const isLocketgoAdmin = memberService.isLocketgoAdmin(member);
  const refreshOn = isLocketgoAdmin ? 60000 : 300000;
  const { t } = useTranslation();

  let now = new Date(Date.now());
  let roundAt5 = 5 - (now.getMinutes() % 5);
  now.setMinutes(now.getMinutes() + roundAt5);

  let startDate = now;
  let endDate = new Date(now);
  endDate.setDate(endDate.getDate() + 1);
  startDate.setDate(startDate.getDate() - 6);
  let startInit = DateHelpers.toDateString(startDate, DateHelpers.UserTimeZone);
  let endInit = DateHelpers.toDateString(endDate, DateHelpers.UserTimeZone);

  const [currentStartEnd, setCurrentStartEnd] = useState<onDateProps>({
    currentStart: startInit,
    currentEnd: endInit,
  });
  const [canApply, setCanApply] = useState<boolean>(true);

  useMemo(async () => {
    await hubService
      .getHubs()
      .then((hubs) => {
        setHubs(hubs);
      })
      .catch(() => {
        ToastHelpers.error(t("analytic.error"));
      })
      .finally(() => setLoading(false));
  }, []);

  useMemo(async () => {
    if (currentStartEnd) {
      await refresh(currentStartEnd.currentStart, currentStartEnd.currentEnd);
    }
  }, [currentStartEnd]);

  useEffect(() => {
    const timeoutId = setInterval(async () => {
      if (currentStartEnd) {
        await refresh(currentStartEnd.currentStart, currentStartEnd.currentEnd);
      }
    }, refreshOn);
    return () => clearInterval(timeoutId);
  }, [currentStartEnd]);

  let dateStringExport = `-${DateHelpers.formatDateEightChar(
    new Date(currentStartEnd.currentStart),
    DateHelpers.UserTimeZone
  )}-${DateHelpers.formatDateEightChar(new Date(currentStartEnd.currentEnd), DateHelpers.UserTimeZone)}`;

  if (loading) {
    return <LoadingComponent loading={loading} />;
  }

  const hasEvents = model && model.events?.values?.length > 0;
  const hasOrgs = model && model.organizations?.values?.length > 0;
  const hasHubs = model && model.hubs?.values?.length > 0;

  return (
    <div className="page dashboard-page">
      {model && (
        <div className="container">
          <h5>{t("analytic.reservation-title")}</h5>
          <SyncButtonComponent
            className="ms-2 mb-1"
            onClick={async () => await refresh(currentStartEnd?.currentStart, currentStartEnd?.currentEnd)}
          />
          {isOrganizationContributor && model.organizations && (
            <>
              <div className="section-reservation-detail bg-light shadow rounded-3 border p-3">
                <div className="float-end">
                  <DateRangeComponent
                    id={"dateRangeSelection"}
                    default={DateRangeOptions.Week}
                    onDateChange={onDateChanged}
                    start={currentStartEnd.currentStart}
                    end={currentStartEnd.currentEnd}
                  />
                </div>

                <ChartLine
                  labels={model.organizations.timeline.map((timeline) => timeline.text)}
                  data={model.organizations.timeline.map((timeline) => timeline.count)}
                />
                {(hasOrgs || hasEvents || hasHubs) && (
                  <div className="row justify-content-between mt-4">
                    <nav>
                      <ul className="nav nav-tabs" id="nav-tab" role="tablist">
                        {hasEvents && (
                          <li className="nav-item ">
                            <button
                              className="nav-link active"
                              id="nav-events-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-events"
                              type="button"
                              role="tab"
                              aria-controls="nav-events"
                              aria-selected="true"
                            >
                              {t("analytic.events")}
                            </button>
                          </li>
                        )}
                        {hasOrgs && (
                          <li className="nav-item ">
                            <button
                              className={`nav-link  ${!hasEvents ? "active" : ""}`}
                              id="nav-organizations-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-organizations"
                              type="button"
                              role="tab"
                              aria-controls="nav-organizations"
                              aria-selected={!hasEvents}
                            >
                              {t("common.organizations")}
                            </button>
                          </li>
                        )}
                        {hasHubs && (
                          <li className="nav-item ">
                            <button
                              className={`nav-link  ${!hasEvents && !hasOrgs ? "active" : ""}`}
                              id="nav-hubs-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-hubs"
                              type="button"
                              role="tab"
                              aria-controls="nav-hubs"
                              aria-selected={!hasEvents && !hasOrgs}
                            >
                              {t("analytic.hubs")}
                            </button>
                          </li>
                        )}
                      </ul>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      {hasEvents && (
                        <div className="tab-pane fade show shadow active" id="nav-events" role="tabpanel" aria-labelledby="nav-events-tab">
                          <EventStatsTable
                            title={t("analytic.events")}
                            isLocketgoAdmin={isLocketgoAdmin}
                            model={model.events}
                            microsites={model.microsites}
                            exportFileName={`ExportEvents${dateStringExport}.csv`}
                          ></EventStatsTable>
                        </div>
                      )}
                      {hasOrgs && (
                        <div
                          className={`tab-pane fade shadow ${!hasEvents ? "show active" : ""}`}
                          id="nav-organizations"
                          role="tabpanel"
                          aria-labelledby="nav-organizations-tab"
                        >
                          <OrderStatsTable
                            title={t("common.organizations")}
                            isLocketgoAdmin={isLocketgoAdmin}
                            model={model.organizations}
                            exportFileName={`ExportOrganizations${dateStringExport}`}
                          ></OrderStatsTable>
                        </div>
                      )}
                      {hasHubs && (
                        <div
                          className={`tab-pane fade shadow ${!hasEvents && !hasOrgs ? "show active" : ""}`}
                          id="nav-hubs"
                          role="tabpanel"
                          aria-labelledby="nav-hubs-tab"
                        >
                          <OrderStatsTable
                            title={t("analytic.hubs")}
                            isLocketgoAdmin={isLocketgoAdmin}
                            model={model.hubs}
                            exportFileName={`ExportHubs${dateStringExport}`}
                          ></OrderStatsTable>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {isHubContributor && <HubStatsComponent hubs={hubs || undefined} hubUsages={model.hubUsages} />}
        </div>
      )}
    </div>
  );

  async function refresh(startDate: string, endDate: string) {
    await analyticService
      .getDashboard(startDate, endDate)
      .then((dashboard) => {
        if (dashboard) {
          setModel(dashboard);
        }
      })
      .catch(() => {
        ToastHelpers.error(t("analytic.error"));
      });
  }

  function onDateChanged(start: string, end: string) {
    if (currentStartEnd.currentStart !== start || currentStartEnd.currentEnd !== end) {
      setCurrentStartEnd({ currentStart: start, currentEnd: end });
    }
  }
}
