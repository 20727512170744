import ServicesApiBaseService from "../services-api-base-service";
import { PackagingOption } from "./packgingOption";

class PackagingOptionService extends ServicesApiBaseService {
  async getPackagingOptions(): Promise<Array<PackagingOption>> {
    const packagingOptions = await this.getObject<Array<PackagingOption>>(`${this.apiUrl}/packagingOptions`);
    if (packagingOptions) {
      let cs = packagingOptions.map((packagingOption) => {
        if (packagingOption?.description) {
          packagingOption.description = new Map(Object.entries(packagingOption?.description));
        }
        return packagingOption;
      });
      return cs;
    }

    return packagingOptions || [];
  }
}

const packagingOptionService = new PackagingOptionService();
export default packagingOptionService;
