import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionButtonComponent } from "../../../components/button-component";
import { InputComponent } from "../../../components/input";
import organizationService from "../../../services/member/organization-service";
import { Order } from "../../../services/order/order";
import Organization from "../../../services/organization/organization";

export interface OrderRequestOrganizationAddressModalProps {
  isAdmin: boolean;
  organization: Organization;
  order: Order;
  onRequiredAddressMessageChange: (isAddressMessageRequired: boolean) => void;
  requestCarrierPickup: (order: Order, organization: Organization) => void;
}
function OrderRequestOrganizationAddressModal(props: OrderRequestOrganizationAddressModalProps) {
  const { isAdmin, organization, order, onRequiredAddressMessageChange, requestCarrierPickup } = props;
  const { t } = useTranslation();

  const [streetNumber, setStreetNumber] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const state = "QC";
  const country = "Canada";

  if (!isAdmin) {
    return (
      <div className="container page">
        <div className="col-12 col-xl-6 offset-xl-3">
          <div className="card">
            <div className="card-header align-items-center">
              <div className="d-inline-block">
                <h2>{t("order-page.msg-admin-requires-address")}</h2>{" "}
              </div>
            </div>
            <div className="card-body">
              <div className="align-items-center">
                <button className="btn btn-primary" onClick={() => onRequiredAddressMessageChange(false)}>
                  {t("common.return")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container page">
      <div className="col-12 col-xl-6 offset-xl-3">
        <div className="card">
          <div className="card-header align-items-center">
            <div className="d-inline-block">
              <h2>{t("order-page.msg-requires-address")}</h2>
              <div>{t("order-page.msg-address-precision")}</div>
            </div>
          </div>
          <div className="card-body">
            <form className="row g-3" onSubmit={(ev) => ev.preventDefault()}>
              <div className="col-md-3">
                <InputComponent
                  labelText={t("member-profile.streetNumber") + "*"}
                  setValue={(value: string) => setStreetNumber(value)}
                  value={streetNumber}
                />
              </div>
              <div className="col-md-9">
                <InputComponent
                  labelText={t("member-profile.street") + "*"}
                  setValue={(value: string) => setStreet(value)}
                  value={street}
                />
              </div>
              <div className="col-md-8">
                <InputComponent labelText={t("member-profile.city") + "*"} setValue={(value: string) => setCity(value)} value={city} />
              </div>
              <div className="col-md-4">
                <InputComponent
                  labelText={t("member-profile.postalCode") + "*"}
                  setValue={(value: string) => setPostalCode(value)}
                  value={postalCode}
                />
              </div>
              <div className="col-md-6">
                <InputComponent labelText={t("member-profile.state") + "*"} setValue={(value: string) => {}} value={state} disabled />
              </div>
              <div className="col-md-6">
                <InputComponent labelText={t("member-profile.country") + "*"} setValue={(value: string) => {}} value={country} disabled />
              </div>
              <div className="col-6">
                <ActionButtonComponent
                  type="submit"
                  title={t("order-page.request-carrier-pickup")}
                  onClick={() => saveAddressAndSend(order)}
                  disabled={saveDisabled()}
                  className="btn btn-primary me-2"
                  icon={["fas", "paper-plane"]}
                />
                <ActionButtonComponent
                  title={t("common.cancel")}
                  onClick={() => onRequiredAddressMessageChange(false)}
                  className="btn btn-outline-secondary me-2"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  function saveDisabled(): boolean {
    return !street || !streetNumber || !city || !postalCode;
  }

  function saveAddressAndSend(order: Order) {
    if (street && streetNumber && city && postalCode && organization) {
      organizationService.updateOrganization(organization.id, streetNumber, street, city, postalCode, state, country).then(() => {
        onRequiredAddressMessageChange(false);

        organization.street = street;
        organization.streetNumber = streetNumber;
        organization.city = city;
        organization.postalCode = postalCode;
        organization.state = state;
        organization.country = country;

        requestCarrierPickup(order, organization);
      });
    }

    onRequiredAddressMessageChange(false);
  }
}

export default OrderRequestOrganizationAddressModal;
