import React from "react";
import StringHelpers from "../../services/core/string-helpers";
import { EditButtonComponent } from "../button-component";
import HubLocationComponent from "../hub-location-component";
import LoadingComponent from "../loading-component";
import { NotifyLevelComponent } from "../notify-level-icon-component";
import { StateBadgeComponent } from "../state-badge-component";
import { useHistory } from "react-router-dom";
import { HeaderGroup, Row, TableBodyProps, TableProps } from "react-table";
import { Hub } from "../../services/hub/hub";
import DateHelpers from "../../services/core/date-helpers";

interface OrdersTableComponentProps {
  hubs: Hub[] | null;
  isLoading: boolean;
  tableProps: TableProps;
  tableBodyProps: TableBodyProps;
  headerGroups: HeaderGroup<object>[];
  page: Row<Object>[];
  prepareRow: (row: Row<object>) => void;
}

const OrderIdColumnIndex: number = 0;
const BadgeColumnIndex: number = 1;
const CustomerInfoColumIndex: number = 2;
const CustomerEmailColumnIndex: number = 3;
const CustomerPhoneColumnIndex: number = 4;
const HubColumnIndex: number = 5;
const LockersColumnIndex: number = 6;
const CreationDateColumnIndex: number = 7;
const ReservationBlockNameColumnIndex: number = 8;
const ReferenceNumberColumnIndex: number = 9;
const TrackingNumbersColumnIndex: number = 10;

export function OrdersTableComponent(props: OrdersTableComponentProps) {
  const { hubs, isLoading, tableProps, tableBodyProps, headerGroups, page, prepareRow } = props;

  const history = useHistory();

  function findHub(hubId: string | null): Hub | undefined {
    if (!hubId) {
      return undefined;
    }
    return hubs?.find((h) => h.id === hubId) || undefined;
  }

  return (
    <div className="row mt-3">
      <div className="col">
        <div className="customer card">
          <div className="card-body">
            <table {...tableProps} className="table table-sm table-hover row-clikable">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th></th>

                    {headerGroup.headers.map((column) => {
                      const { getHeaderProps, render } = column;
                      const key = getHeaderProps().key;
                      if (key !== "header_email" && key !== "header_phone") {
                        return <th {...getHeaderProps()}>{render("Header")}</th>;
                      }
                    })}
                    <th></th>
                  </tr>
                ))}
              </thead>
              <tbody {...tableBodyProps}>
                {isLoading && (
                  <tr>
                    <td colSpan={10} className="text-center">
                      <LoadingComponent loading={isLoading} />
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  page.map((row, i) => {
                    prepareRow(row);
                    const hub = row.cells[OrderIdColumnIndex].value && findHub(row.cells[HubColumnIndex].value);
                    const url = `/orders/${row.cells[OrderIdColumnIndex].value}`;
                    const order = row.original as any;

                    let customerInfo = "";
                    if (!stringIsNullOrEmpty(row.cells[CustomerInfoColumIndex].value)) {
                      customerInfo = row.cells[CustomerInfoColumIndex].value;
                    } else if (!stringIsNullOrEmpty(row.cells[CustomerEmailColumnIndex].value)) {
                      customerInfo = row.cells[CustomerEmailColumnIndex].value;
                    } else if (!stringIsNullOrEmpty(row.cells[CustomerPhoneColumnIndex].value)) {
                      customerInfo = row.cells[CustomerPhoneColumnIndex].value;
                    } else if (!stringIsNullOrEmpty(row.cells[ReferenceNumberColumnIndex].value)) {
                      customerInfo = row.cells[ReferenceNumberColumnIndex].value;
                    }

                    const isPersonalStorage = !stringIsNullOrEmpty(row.cells[8].value);

                    return (
                      <tr {...row.getRowProps()} onClick={() => history.push(url)}>
                        <td className="text-center">
                          <NotifyLevelComponent notifyLevel={order.notifyLevel}></NotifyLevelComponent>
                        </td>

                        {/* OrderID */}
                        <td {...row.cells[OrderIdColumnIndex].getCellProps()}>{row.cells[OrderIdColumnIndex].render("Cell")}</td>

                        {/* Badge */}
                        <td {...row.cells[BadgeColumnIndex].getCellProps()}>
                          <StateBadgeComponent
                            state={row.cells[BadgeColumnIndex].value}
                            isPersonalStorage={isPersonalStorage}
                          ></StateBadgeComponent>
                        </td>

                        {/* Customer info */}
                        <td {...row.cells[CustomerInfoColumIndex].getCellProps()}>{customerInfo}</td>

                        {/* Location */}
                        <td {...row.cells[HubColumnIndex].getCellProps()}>
                          <HubLocationComponent hub={hub} formatToFit={376} />
                        </td>

                        {/* Lockers */}
                        <td {...row.cells[LockersColumnIndex].getCellProps()}>{row.cells[LockersColumnIndex].render("Cell")}</td>

                        {/* CreationDate */}
                        <td {...row.cells[CreationDateColumnIndex].getCellProps()}>
                          {DateHelpers.formatDateTime(new Date(row.cells[CreationDateColumnIndex].value), DateHelpers.UserTimeZone)}
                        </td>

                        {/* ReservationBlockName */}
                        <td {...row.cells[ReservationBlockNameColumnIndex].getCellProps()} className="overflow-ellipsis">
                          {row.cells[ReservationBlockNameColumnIndex].render("Cell")}
                        </td>

                        {/* ReferenceNumber */}
                        <td {...row.cells[ReferenceNumberColumnIndex].getCellProps()} className="overflow-ellipsis">
                          {row.cells[ReferenceNumberColumnIndex].render("Cell")}
                        </td>
                        {/*TrackingNumbers */}
                        <td {...row.cells[TrackingNumbersColumnIndex].getCellProps()} className="overflow-ellipsis">
                          {row.cells[TrackingNumbersColumnIndex].value.map((trackingNumber: string, index: number) => (
                            <React.Fragment key={index}>
                              {trackingNumber}
                              <br />
                            </React.Fragment>
                          ))}
                        </td>

                        <td>
                          <EditButtonComponent url={url}></EditButtonComponent>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function stringIsNullOrEmpty(value: String) {
  return value === null || value === undefined || value === "" || value === " " || value.includes("undefined");
}
