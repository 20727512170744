import { useTranslation } from "react-i18next";
const RegExPhone1 = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
const RegExPhone2 = /^\d{10}$/;

type Props = {
  value: string;
  setValue: Function;
  msgError: string | undefined;
  onMsgErrorChanged: Function;
  label: string;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
  autofocus?: boolean | undefined;
};

export function PhoneFormComponent(props: Props) {
  const { t } = useTranslation();
  const error = t("input-component.error-phone");
  const labelText = props.label || t("common.phone");

  let disable = props.disabled && props?.disabled === true;

  return (
    <>
      <div className="mb-3">
        <label className="form-label">{labelText}</label>
        <input
          className="form-control"
          type="tel"
          value={props.value}
          placeholder="000-000-0000"
          onChange={(ev) => validate(ev.target.value)}
          disabled={disable}
          autoFocus={props.autofocus}
        />
        {props.msgError && <label className="error-msg">{props.msgError}</label>}
      </div>
    </>
  );

  function validate(value: string) {
    props.setValue(value);

    if (isValid(value)) {
      props.msgError && props.onMsgErrorChanged(undefined);
    } else {
      !props.msgError && props.onMsgErrorChanged(error);
    }
  }

  function isValid(value: string): boolean {
    let valid: boolean = RegExPhone1.test(value) || RegExPhone2.test(value);

    if (props.required && valid) return true;

    if (!props.required && (valid || value.length === 0)) return true;

    return false;
  }
}
