import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  notifyLevel: string;
};

export function NotifyLevelComponent(props: Props) {
  if (props.notifyLevel === "High") {
    return <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} size="2x" className="text-danger" />;
  }

  if (props.notifyLevel === "Medium") {
    return <FontAwesomeIcon icon={["fas", "info"]} size="2x" className="text-warning" />;
  }

  return null;
}
