import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { ActionButtonComponent, AddButtonComponent, EditButtonComponent } from "../../components/button-component";
import languageService from "../../services/core/language-service";
import ToastHelpers from "../../services/core/toast-helpers";
import Member from "../../services/member/member";
import MemberRoleOrganization from "../../services/member/member-role-organization";
import memberService from "../../services/member/member-service";
import organizationService from "../../services/member/organization-service";
import Microsite from "../../services/micro-site/microsite";

interface Props {
  member: Member | undefined;
}

interface IParamTypes {
  organizationId: string;
}

export default function MicrositesPage(props: Props) {
  const { t } = useTranslation();
  const { member } = props;
  const language: string = languageService.getLanguage();
  const { organizationId } = useParams<IParamTypes>();

  const [microsites, setMicrosites] = useState<Array<Microsite>>([]);
  const [currentOrganization, setCurrentOrganization] = useState<MemberRoleOrganization | undefined>(undefined);

  const history = useHistory();

  useEffect(
    () => {
      if (member) {
        let organization = member.associatedOrganizations.find((organization) => organization.entity?.id == organizationId);
        if (member && organization && memberService.isOrganizationContributor(member, organizationId)) {
          setCurrentOrganization(organization);
          organizationService
            .getAllMicrositesOfOrganization(organizationId)
            .then((microsites) => {
              setMicrosites(microsites);
              if (!microsites.length && !memberService.isLocketgoAdmin(member)) {
                history.push(`/organizations`);
              }
            })
            .catch(() => {
              ToastHelpers.error(t("micro-site-page.microsites-error"));
            });
        }
      }
    },
    // eslint-disable-next-line
    [currentOrganization]
  );

  return (
    <>
      {member && currentOrganization && memberService.isOrganizationContributor(member, organizationId) && (
        <div className="container pt-3 page">
          <div className="row">
            <div className="col-12 offset-lg-2 col-lg-8">
              <div className="page-breadcrumb">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to={`/organizations`}>{t("common.organizations")}</Link>
                    {" / "}
                    <Link to={`/organizations/${organizationId}`}>{currentOrganization?.entity?.name}</Link>
                    {` / ${t("microsites-page.name")}`}
                  </li>
                </ul>
              </div>
              <div className="header">
                <h1>{`${t("microsites-page.title")}`}</h1>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="col-12">
                    <table className="table table-hover row-clikable table-order">
                      <thead>
                        <tr>
                          <th>{t("common.name")}</th>
                          <th>
                            {memberService.isLocketgoAdmin(member) && (
                              <AddButtonComponent
                                title={"Create Microsite"}
                                onClick={() => history.push(`/database-admin/microsite/${organizationId}/NEWMICROSITE`)}
                              ></AddButtonComponent>
                            )}
                          </th>
                          {memberService.isLocketgoAdmin(member) && <th>LOCKETGO ADMIN EDIT</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {microsites?.map((microsite) => {
                          const isLocketgoAdmin = memberService.isLocketgoAdmin(member);
                          const url = `/organizations/${organizationId}/microsites/${microsite.id}/reservationBlocks`;
                          return (
                            <tr
                              key={microsite.id}
                              onClick={() => {
                                isLocketgoAdmin ?? history.push(url);
                              }}
                            >
                              <td>{microsite.name.get(language)}</td>
                              <td>
                                <ActionButtonComponent
                                  icon={["fas", "pen"]}
                                  title={t("events-page.events")}
                                  onClick={() => {
                                    history.push(url);
                                  }}
                                  className="btn btn-primary"
                                  disabled={false}
                                />
                              </td>
                              {isLocketgoAdmin && (
                                <td>
                                  <ActionButtonComponent
                                    icon={["fas", "pen"]}
                                    title={"Microsite"}
                                    onClick={() => {
                                      history.push(`/database-admin/microsite/${organizationId}/${microsite.id}`);
                                    }}
                                    className="btn btn-primary"
                                    disabled={false}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
