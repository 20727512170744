import { useTranslation } from "react-i18next";

interface InputProps {
  value: string;
  setValue: Function;
  msgError?: string;
  onMsgErrorChanged?: Function;
  regExp?: RegExp;
  type?: string;
  labelText: string;
  required?: boolean;
  disabled?: boolean;
  autofocus?: boolean | undefined;
}

export function InputComponent(props: InputProps) {
  const { t } = useTranslation();

  return (
    <>
      <label className="form-label">{props.labelText}</label>
      <input
        className="form-control"
        type={props.type}
        value={props.value}
        placeholder={getPlaceHolder(props.type)}
        onChange={(ev) => validate(ev.target.value)}
        required={props.required}
        disabled={props.disabled}
        autoFocus={props.autofocus}
      />
      {props.msgError && <label className="error-msg">{props.msgError}</label>}
    </>
  );

  function getPlaceHolder(type: string | undefined): string {
    switch (type) {
      case "tel":
        return "000-000-0000";
      case "email":
        return t("input-component.placeholder-email");
      case "code-6digits":
        return "XXXXXX";
      case "code-sl5c": //TODO: get the code format for each Hub Type.
        return "XXXXXX";
      default:
        return "";
    }
  }

  function getMsgError(type: string | undefined) {
    switch (type) {
      case "tel":
        return t("input-component.error-phone");
      case "email":
        return t("input-component.error-email");
      case "code-6digits":
        return t("input-component.error-code-6digits");
      case "code-sl5c":
        return t("input-component.error-code-sl5c");
      case "extension":
        return t("input-component.error-extension");
      default:
        return undefined;
    }
  }

  function validate(value: string) {
    props.setValue(value);
    if (props.regExp?.test(value)) {
      props.onMsgErrorChanged?.(undefined);
    } else {
      props.onMsgErrorChanged?.(getMsgError(props.type));
    }
  }
}
