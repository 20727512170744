import { useTranslation } from "react-i18next";
import { Hub } from "../services/hub/hub";
import { Order } from "../services/order/order";
import { OrderHelpers } from "../services/order/order-helpers";
import { Reservation } from "../services/order/reservation";
import { ReservationState } from "../services/order/ReservationState";
import LockComponent from "./lock-component";
import TimerComponent from "./timerComponent";

interface LockerSingleReservationMessageProps {
  firstOrder: Order;
  firstReservation: Reservation;
  firstHub: Hub;
  askOpenDoor: (order: Order, reservation: Reservation) => Promise<void>;
  disabled: boolean;
  dateNow: Date;
}

export default function LockerSingleReservationMessageComponent({
  firstOrder,
  firstReservation,
  firstHub,
  askOpenDoor,
  disabled,
  dateNow,
}: LockerSingleReservationMessageProps) {
  const { t } = useTranslation();
  const startTime = new Date(firstReservation.start);
  const endTime = new Date(firstReservation.end);
  const isCompleted = firstReservation?.state === ReservationState.Completed || dateNow > endTime;
  const isNotStarted = dateNow < startTime;
  const reservationTimeLeftBeforeStart = (startTime.getTime() - dateNow.getTime()) / 1000;
  //Checking if compartment number is a number, if it is, only show the compartment numb, if not show the col name as well
  const lockernumb = /^\d+$/.test(firstReservation.compartmentNumber)
    ? firstReservation.compartmentNumber
    : firstReservation.groupId + firstReservation.compartmentNumber;
  return (
    <>
      {isNotStarted && (
        <div className="text-center mb-3 d-block">
          <div className="fs-5 start-reservation">
            <TimerComponent
              timeInSeconds={reservationTimeLeftBeforeStart}
              message="my-reservations-page.start-of-reservation"
              onTimerEnds={() => null}
            />
          </div>
        </div>
      )}

      {firstOrder.flow.toString() === "Deliver" && <div className="spacer"></div>}

      <h1 className="text-center fw-bold mt-0 pt-0 pb-1 mb-3 text-dark">
        {firstHub.zoneName} - {t("common.locker")} {lockernumb}
      </h1>
      <h2 className="text-center fw-bold mt-0 pt-0 pb-1 mb-3 text-dark">
        {firstReservation.micrositeId == "yano" || firstReservation.micrositeId == "shabono"
          ? t("my-reservations-page.locker-code") + firstReservation.trackingNumbers[0]
          : ""}
      </h2>
      <div className="row justify-content-center mb-3">
        <button
          className="btn col-11 btn-primary rounded-3 shadow-lg pushable"
          onClick={async () => await askOpenDoor(firstOrder, firstReservation)}
          disabled={disabled || isCompleted || isNotStarted}
        >
          <span className="front rounded-3">
            <span className="me-2">
              <LockComponent opened={OrderHelpers.inOpenedState(firstReservation?.state)} />
            </span>
            <span>{t("my-reservations-page.open-locker").toUpperCase()}</span>
          </span>
        </button>
      </div>
    </>
  );
}
