import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SyncButtonComponent } from "../../components/button-component";
import { DoorOpenedComponent, DoorOpeningGifComponent, OpenDoorComponent } from "../../components/order-page-components";
import ToastHelpers from "../../services/core/toast-helpers";
import { CompartmentStates } from "../../services/hub/compartment";
import { MyHub } from "../../services/hub/hub";
import hubService from "../../services/hub/hub-service";
import Member from "../../services/member/member";
import memberService from "../../services/member/member-service";

interface IPropCompartmentDetail {
  hub: MyHub | null;
  orderId?: string;
  paymentId?: string;
  groupId: string;
  compartmentNumber: string;
  side: string;
  member: Member;
  onClose: any;
  refresh: any;
}

export default function HubCompartmentDetail(props: IPropCompartmentDetail) {
  const { hub, orderId, paymentId, groupId, compartmentNumber, side, member, onClose, refresh } = props;
  const { t } = useTranslation();

  const [inOpening, setInOpening] = useState<boolean>(false);
  const [isLocketgoHubAdmin, setIsLocketgoHubAdmin] = useState<boolean>(false);
  const [isHubAdmin, setIsHubAdmin] = useState<boolean>(false);

  const group = hub?.groups.find((g) => g.id === groupId);
  const compartment = group?.compartments.find((c) => c.number === compartmentNumber);
  const isOpened = compartment?.state === CompartmentStates.Opened;
  const isDeactivated = hub?.reservationDenies?.some(
    (r) => r.compartmentNumber === compartmentNumber && r.groupId === groupId && r.hubId === hub.id
  );
  var doornumber = "";
  if(compartment) {
     doornumber = /^\d+$/.test(compartment.number) ?  compartment.number : group?.id + compartment.number;
  }

  useEffect(() => {
    if (hub) {
      setIsLocketgoHubAdmin(memberService.isLocketgoHubAdmin(member));
      setIsHubAdmin(memberService.isHubContributor(member, hub.id));
    } else {
      setIsLocketgoHubAdmin(false);
      setIsHubAdmin(false);
    }
  }, [hub, member]);

  if (!compartment) {
    return <div>{t("hub-compartment-detail.error-loading-compartment")}</div>;
  }

  return (
    <div className="panel-popup panel-centered">
      <div className="card">
        <div className="card-header align-items-center">
          <div className="d-inline-block">
            <h2>
              {doornumber ? doornumber : group?.id + compartment.number}
              <SyncButtonComponent
                onClick={() => {
                  refresh();
                  setInOpening(false);
                }}
                className={"ms-2"}
              />
            </h2>
          </div>
          <button className="btn-close float-end" onClick={() => onClose()}></button>
        </div>

        <div className="card-body">
          {/* Order */}
          {orderId && (
            <div className="mb-3">
              <div>
                <div className="lead">{t("hub-page.detail-order-title")}</div>
                <Link className="ps-0" to={`/orders/${orderId}`}>
                  {orderId}
                </Link>
              </div>

              {paymentId && (
                <div>
                  {t("hub-compartment-detail.with-payment")}
                  <FontAwesomeIcon className="ms-2" icon={["fas", "credit-card"]} />
                </div>
              )}
              <hr />
            </div>
          )}

          <div className={orderId ? "mt-3" : ""}>
            <div className="lead mb-2">{t("hub-page.detail-door-management")}</div>

            {/* Open  */}
            <div /*className={"item-lock" + (isOpened ? " opened" : " closed")}*/>
              {/*isOpened && <DoorOpenedComponent />*/}
              <span /*className={isOpened ? "ms-3" : ""}*/>
                {inOpening ? (
                  <DoorOpeningGifComponent />
                ) : (
                  <OpenDoorComponent displayIcon={true /*!isOpened*/} onClick={openDoor} disabled={inOpening /* || isOpened*/} />
                )}
              </span>
            </div>

            {/* Toggle */}
            {isLocketgoHubAdmin && (
              <div className="mt-2">
                <button className="btn btn-outline-primary" onClick={toggleDoor}>
                  {t("hub-page.toggle-door")}
                </button>
              </div>
            )}
          </div>

          {/* Activate/Deactivate */}
          <div className="mt-3">
            <hr />

            <div className="lead mb-2">{t("hub-page.reservationDenies-label-title")}</div>

            {isDeactivated && <label className="disabled me-2">{t("hub-page.reservationDenies-label-deactivated")}</label>}
            {!isDeactivated && <label className="me-2">{t("hub-page.reservationDenies-label-activated")}</label>}

            {isHubAdmin && (
              <>
                {isDeactivated ? (
                  <button className="btn btn-outline-primary mb-2" onClick={onActivation}>
                    {t("hub-page.reservationDenies-activate")}
                  </button>
                ) : (
                  <button className="btn btn-outline-primary mb-2" onClick={onDeactivation}>
                    {t("hub-page.reservationDenies-deactivate")}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  async function openDoor(): Promise<void> {
    if (!hub) {
      return;
    }

    setInOpening(true);
    hubService
      .openDoor(hub.id, groupId, compartmentNumber, side)
      .then(async (success) => {
        await refresh();
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("order-page.error-opendoor"));
        } else if (error?.response?.status === 424) {
          ToastHelpers.error(t("order-page.error-opendoor-424"));
        } else {
          ToastHelpers.error(t("common.error-generic"));
        }
      })
      .finally(() => {
        setInOpening(false);
      });
  }

  async function toggleDoor(): Promise<void> {
    if (!hub) {
      return;
    }

    hubService
      .toggleDoor(hub.id, groupId, compartmentNumber)
      .then(async (success) => {
        ToastHelpers.success(t("hub-page.toggle-message", { state: success ? "done" : "error" }));
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("order-page.error-opendoor"));
        } else if (error?.response?.status === 424) {
          ToastHelpers.error(t("order-page.error-opendoor-424"));
        } else {
          ToastHelpers.error(t("common.error-generic"));
        }
      });
  }

  function onDeactivation() {
    if (compartment?.state && hub) {
      hubService
        .deactivateLockerReservation(hub.id, groupId, compartmentNumber)
        .then(async (success) => {
          await refresh();
        })
        .catch((error) => {
          ToastHelpers.error(t("common.error-generic"));
        });
    }
  }

  function onActivation() {
    if (compartment?.state && hub) {
      hubService
        .activateLockerReservation(hub.id, groupId, compartmentNumber)
        .then(async (success) => {
          await refresh();
        })
        .catch((error) => {
          ToastHelpers.error(t("common.error-generic"));
        });
    }
  }
}
