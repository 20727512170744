import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useTranslation } from "react-i18next";
import { AssetColors } from "../../assets/assets-colors";
import { HeaderIcon } from "../../components/header";
import authService from "../../services/auth/auth-service";
import EmailLink from "../../components/email-link-component";
import { useEffect, useState } from "react";
import memberService from "../../services/member/member-service";
import Member from "../../services/member/member";
import ToastHelpers from "../../services/core/toast-helpers";
import LoadingComponent from "../../components/loading-component";

interface HomeNotRegisteredPageProps {
  member: Member;
  changeLanguage: any;
  changeMember: any;
}

export default function HomeNotRegisteredPage(props: HomeNotRegisteredPageProps) {
  const { member, changeLanguage, changeMember } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  function reload() {
    window.location.reload();
  }

  async function logout(): Promise<void> {
    await authService.logout();
    changeMember(null);
  }

  useEffect(() => {
    memberService
      .createMember(member)
      .then(() => {
        changeMember(member);
      })
      .catch(() => {
        ToastHelpers.error(t("common.error-generic"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [member]);

  if (loading) {
    return <LoadingComponent loading />;
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
              <nav className="navbar reset">
                <ul className="navbar-nav align-items-center ms-auto">
                  <li className="nav-item">
                    <button className="nav-link" onClick={changeLanguage}>
                      <FontAwesomeIcon className="me-2" icon={["fas", "globe"]} />
                      {t("language.display-long")}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div className="container page sign-up-page pt-5">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <div className="card card-full">
              <div className="logo">
                <HeaderIcon logo={AssetColors.Blue} />
              </div>

              <div className="card-left">
                <div className="card-body">
                  <h5>{t("home-not-registered.oups")}</h5>
                  <h6>{t("home-not-registered.title")}</h6>
                  <h6 className="mb-0">
                    <Trans
                      i18nKey="home-not-registered.contact-support"
                      components={{
                        EmailLink: <EmailLink address="support@locketgo.com" subject={t("home-not-registered.contact-support-title")} />,
                      }}
                    />
                  </h6>
                  <div className="sub-info mb-5">{t("home-not-registered.info")}</div>

                  <button className="mt-2 me-4 btn btn-lg btn-primary" onClick={async () => reload()}>
                    {t("home-not-registered.retry")}
                  </button>

                  <button className="mt-2 btn btn-lg btn-danger" onClick={async () => await logout()}>
                    {t("home-not-registered.logout")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
