import { useTranslation } from "react-i18next";
import "./dashboard-page.scss";
import HubLocationComponent from "../../components/hub-location-component";
import { Hub } from "../../services/hub/hub";
import StringHelpers from "../../services/core/string-helpers";
import { HubUsage } from "../../services/analytic/hubUsage";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  hubs: Array<Hub> | undefined;
  hubUsages: Array<HubUsage> | undefined;
};

export default function HubStatsComponent(props: Props) {
  const { hubs, hubUsages } = props;
  const history = useHistory();

  const { t } = useTranslation();

  const hubCount = (hubs && hubs.length) || 0;
  const groupCount = (hubs && hubs.map((item) => item.groups.length).reduce((prev, next) => prev + next)) || 0;
  const used = (hubUsages && hubUsages.length && hubUsages?.map((item) => item.used).reduce((prev, next) => prev + next)) || 0;
  const total = (hubUsages && hubUsages.length && hubUsages?.map((item) => item.total).reduce((prev, next) => prev + next)) || 0;

  return (
    <>
      {/* Hub Capacity */}
      {hubUsages && hubUsages.length > 0 && (
        <div className="hubs-component">
          <h5>{t("analytic.hub-usage-title")}</h5>
          <div className="hubs-usage shadow rounded-3 mt-2 border p-3">
            <div className="col-12">
              <table className="table table-sm table-hover row-clikable">
                <thead>
                  <tr>
                    <th>{t("analytic.hub")}</th>
                    <th>{t("analytic.hub-usage")}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {hubUsages.map((hubUsage) => {
                    const percentage = hubUsage.total > 0 ? (hubUsage.used * 100) / hubUsage.total : 0;
                    const progressStyle = { width: percentage + "%" };
                    const hub = hubs?.find((h) => h.id === hubUsage.id) || null;
                    const url = hub ? `/hubs/${hub.id}` : "/";

                    return (
                      <tr key={hubUsage.id} onClick={() => history.push(url)}>
                        <td className="pt-2 pb-2">
                          <HubLocationComponent hub={hub} />
                        </td>
                        <td className="pt-2 pb-2">{t("analytic.hub-usage-text", { used: hubUsage.used, total: hubUsage.total })}</td>
                        <td className="pt-2 pb-2">
                          <div className="progress">
                            <div
                              className="progress-bar progress-bar-striped"
                              role="progressbar"
                              style={progressStyle}
                              aria-valuenow={hubUsage.used}
                              aria-valuemin={0}
                              aria-valuemax={hubUsage.total}
                            ></div>
                          </div>
                        </td>
                        <td className="pt-2 pb-2">{StringHelpers.formatNumber(percentage, 0)}%</td>
                        <td className="pt-2 pb-2">
                          <FontAwesomeIcon icon={["fas", "angle-right"]} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div>
              <span>{t("analytic.hub-summary-text1", { hubCount, groupCount })}</span>
              <span className="float-end">{t("analytic.hub-summary-text2", { used, total })}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
