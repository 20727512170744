import { useTranslation } from "react-i18next";

interface Props {
  headerMsg: string;
  onYesClick: Function;
  onNoClick: Function;
}

export function CancelReservationMsgomponent(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="container page">
        <div className="col-12 col-lg-8 offset-lg-2">
          <div className="card">
            <div className="card-header align-items-center">
              <div className="d-inline-block">
                <h2>{props.headerMsg}</h2>{" "}
              </div>
            </div>
            <div className="card-body">
              <div className="align-items-center">
                <button className="btn btn-outline-primary me-2" onClick={() => props.onYesClick()}>
                  {t("order-page.cancel-reservation.btn-yes")}
                </button>

                <button className="btn btn-outline-primary me-2" onClick={() => props.onNoClick(false)}>
                  {t("order-page.cancel-reservation.btn-no")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
