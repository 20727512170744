import whiteLogo from "./LOCKETGO_LOGO_RVB-Blanc.svg";
import blackLogo from "./LOCKETGO_LOGO_RVB-Noir.svg";
import blueLogo from "./LOCKETGO_LOGO_RVB-Bleu.svg";

export enum AssetColors {
  Blue,
  Black,
  White,
}

export default function GetLocketgoLogoSource(assetColor: AssetColors): string | undefined {
  let color = undefined;
  switch (assetColor) {
    case AssetColors.Black:
      color = blackLogo;
      break;
    case AssetColors.White:
      color = whiteLogo;
      break;
    case AssetColors.Blue:
      color = blueLogo;
      break;
  }
  return color;
}
