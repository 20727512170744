import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface DeleteButtonProps {
  title: string | undefined;
  onClick: Function;
  disabled: boolean;
}

export function DeleteButtonComponent(props: DeleteButtonProps) {
  return (
    <>
      <button className={`btn btn-outline-primary`} onClick={() => props.onClick()} disabled={props.disabled}>
        <FontAwesomeIcon icon={["fas", "trash-alt"]} /> {props.title && " " + props.title}
      </button>
    </>
  );
}

interface AddButtonProps {
  title?: string | undefined;
  className?: string | undefined;
  onClick: Function;
}

export function AddButtonComponent(props: AddButtonProps) {
  return (
    <button className={props.className ? props.className : "btn btn-primary"} onClick={() => props.onClick()}>
      <FontAwesomeIcon className={props.title && "me-2"} icon={["fas", "plus"]} />
      {props.title && " " + props.title}
    </button>
  );
}

interface SyncButtonProps {
  onClick: Function;
  disabled: boolean;
  className?: string;
}
SyncButtonComponent.defaultProps = {
  disabled: false,
};
export function SyncButtonComponent(props: SyncButtonProps) {
  return (
    <button className={props.className + " btn btn-outline-primary "} onClick={() => props.onClick()} disabled={props.disabled}>
      <FontAwesomeIcon icon="sync"></FontAwesomeIcon>
    </button>
  );
}

interface EditButtonProps {
  url: string;
}

export function EditButtonComponent(props: EditButtonProps) {
  const history = useHistory();

  return (
    <button className="btn btn-outline-primary" onClick={() => history.push(props.url)}>
      <FontAwesomeIcon icon={["fas", "pen"]} />
    </button>
  );
}

interface ActionButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  className?: string | undefined;
  onClick: Function;
  title?: string | undefined;
  icon?: IconProp | undefined;
  disabled?: boolean;
}

export function ActionButtonComponent(props: ActionButtonProps) {
  return (
    <button type={props.type} className={props.className} onClick={() => props.onClick()} disabled={props.disabled}>
      {props.icon && <FontAwesomeIcon className="me-1" icon={props.icon} />} {props.title && props.title}
    </button>
  );
}

interface InfoCircleProps {
  id: string;
  title: string;
  textInfo: string;
}
export function InfoCirleButton({ id, title, textInfo }: InfoCircleProps) {
  const { t } = useTranslation();

  return (
    <>
      <button className="icon-info-circle primary" data-bs-target={`#${id}`} data-bs-toggle="modal">
        <FontAwesomeIcon className="blue-icon" icon={["fas", "info-circle"]} />
      </button>
      <div className="modal" tabIndex={-1} id={id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">{textInfo}</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
                {t("common.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface ExportButtonProps {
  title?: string;
  data: string | Array<any>;
  fileName: string;
}

export function ExportButton(props: ExportButtonProps) {
  const { t } = useTranslation();
  let title = props.title ? props.title : t("common.export");

  return (
    <CSVLink
      className={"btn btn-outline-primary"}
      title={title}
      data={props.data}
      enclosingCharacter={`"`}
      filename={props.fileName + ".csv"}
    >
      <FontAwesomeIcon icon="file-export"></FontAwesomeIcon>
    </CSVLink>
  );
}
