import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AssetColors } from "../../assets/assets-colors";
import { HeaderIcon } from "../../components/header";
import authService from "../../services/auth/auth-service";
import "./signup-page.scss";

type Props = {
  changeMember: any;
  changeLanguage: any;
};

export default function SignUpPage(props: Props) {
  const { changeMember, changeLanguage } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <header>
        <div className="container page sign-up-page">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
              <nav className="navbar reset">
                <ul className="navbar-nav align-items-center ms-auto">
                  <li className="nav-item">
                    <button className="nav-link" onClick={changeLanguage}>
                      <FontAwesomeIcon className="me-2" icon={["fas", "globe"]} />
                      {t("language.display-long")}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div className="container page sign-up-page">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <div className="card card-rounded card-full">
              <div className="row">
                <div className="col col-xl-8">
                  <div className="logo">
                    <HeaderIcon logo={AssetColors.Blue} />
                  </div>

                  <div className="card-left">
                    <div className="card-body">
                      <h5>{t("home-not-signed.title")}</h5>
                      <h6>{t("home-not-signed.subtitle")}</h6>

                      <div>
                        <button id="sign-up-btn" className="btn btn-lg btn-primary" onClick={async () => await login()}>
                          {t("home-not-signed.sign-up")}
                        </button>
                      </div>

                      <div className="section-login">
                        <label>{t("home-not-signed.already-have-account")}</label>
                        <button className="nav-link btn-login" onClick={async () => await login()}>
                          {t("home-not-signed.login")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-right d-none d-xl-inline-block col-4 gx-0">
                  <img className="img-fluid" src="/images/sign-up.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  async function login(): Promise<void> {
    const loginResponse = await authService.login();

    let response = await changeMember(loginResponse.member);
    if (response) {
      history.push("/");
    }
  }
}
