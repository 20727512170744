import { useTranslation } from "react-i18next";
import LoadingComponent from "../../components/loading-component";
import useHubs from "../../components/useEffect/useHubs";
import Microsite from "../../services/micro-site/microsite";

interface ZoneProps {
  microsite: Microsite | undefined;
  isLoading: boolean;
}

function MicrositeZones(props: ZoneProps) {
  const { t } = useTranslation();
  const { hubs } = useHubs();

  if (!props.microsite || props.microsite.hubIds.length === 0) {
    return null;
  }

  const matchingHubs = hubs?.filter((h) => {
    return props.microsite?.hubIds.includes(h.id) && !h.name;
  });

  if (!matchingHubs || matchingHubs.length === 0) {
    return null;
  }

  return (
    <div className="card col-4">
      <div className="card-header align-items-center">
        <h2>{t("events-page.zones")}</h2>
      </div>

      <div className="card-body">
        <div className="col-12">
          {props.isLoading && <LoadingComponent loading={props.isLoading} />}
          {!props.isLoading && (
            <ul className="">
              {matchingHubs.map((hub) => {
                return <li className="">{hub?.zoneName}</li>;
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default MicrositeZones;
