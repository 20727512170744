import ServicesApiBaseService from "../services-api-base-service";
import Organization from "../organization/organization";
import Microsite from "../micro-site/microsite";

class OrganizationService extends ServicesApiBaseService {
  async createOrganization(organization: Organization): Promise<Organization | undefined> {
    return await this.postObject<Organization, Organization>(`${this.apiUrl}/organizations`, organization);
  }

  async updateOrganization(
    organizationId: string,
    streetNumber: string,
    street: string,
    city: string,
    postalCode: string,
    state: string,
    country: string,
    active?: boolean
  ) {
    await this.putObject<any>(`${this.apiUrl}/organizations/${organizationId}`, {
      organizationId,
      streetNumber,
      street,
      city,
      postalCode,
      state,
      country,
      active: active ?? true,
    });
  }
  async getOrganizationById(organizationId: string): Promise<Organization | null> {
    const Organization = await this.getObject<Organization>(`${this.apiUrl}/organizations/${organizationId}`);
    return Organization || null;
  }

  async getAllMicrositesOfOrganization(organizationId: string): Promise<Array<Microsite>> {
    var microsites = await this.getObject<Array<Microsite>>(`${this.apiUrl}/organizations/${organizationId}/microsites`);
    if (microsites) {
      microsites = microsites.filter(function (microsite) {
        return microsite.active;
      });
      microsites.forEach((microsite) => {
        if (microsite) {
          if (microsite.name) {
            microsite.name = new Map(Object.entries(microsite?.name));
          }
        }
      });
    }

    return microsites || [];
  }
}

const organizationService = new OrganizationService();
export default organizationService;
