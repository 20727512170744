import memberService from "../member/member-service";
import Member from "../member/member";
import msalService from "./msal-service";
import LoginResponse from "./login-response";
import StringHelpers from "../core/string-helpers";

class AuthService {
  token: string | null = null;
  tokenExpiresOn: Date | null = null;

  public registerCallback(callback: any) {
    msalService.registerCallback(callback);
  }

  public async autoLogin(): Promise<LoginResponse> {
    var response = new LoginResponse();

    const msalAccount = msalService.getAccount();
    if (!msalAccount) {
      console.log("autoLogin() --> No MSAL Account");
      return response;
    }
    // console.log("AuthService.autoLogin msalAccount", msalAccount);

    const tokenResult = await msalService.getToken();
    // console.log("tokenResult", tokenResult);
    if (!tokenResult || !tokenResult.accessToken) {
      console.log("AuthService.autoLogin() --> No token");
      return response;
    }

    try {
      let account = await memberService.getMemberMe();
      //console.log("AuthService.autoLogin() --> Account", account);
      if (!account) {
        account = new Member();
        account.email = msalAccount.username;
        account = await this.createMember(msalAccount.username);
      }

      if (account) {
        account.externalId = msalAccount.localAccountId;
        console.log(
          `AuthService.autoLogin() --> ${account.email}/${account.externalId} [${StringHelpers.getFullName(
            account.firstname,
            account.lastname
          )}]}`
        );
      }

      response.member = account || null;
    } catch (error) {
      console.error("autoLogin error", error);
      response.error = true;
    }

    return response;
  }

  public async login(): Promise<LoginResponse> {
    await msalService.login(process.env.REACT_APP_ADB2C_LOGIN_TYPE || "redirect");

    return await this.autoLogin();
  }

  public async logout(): Promise<void> {
    await msalService.logout(process.env.REACT_APP_ADB2C_LOGIN_TYPE || "redirect");
  }

  public async getAccessToken(): Promise<string | null> {
    if (!this.tokenExpiresOn || this.tokenExpiresOn.getTime() < Date.now()) {
      const tokenResult = await msalService.getToken();
      // console.info("tokenResult", tokenResult);
      if (!tokenResult || !tokenResult.accessToken) {
        return null;
      }

      this.token = tokenResult.accessToken;
      this.tokenExpiresOn = tokenResult.expiresOn;
    }

    return this.token;
  }

  private async createMember(email: string): Promise<Member | undefined> {
    let accountToCreate = new Member();
    accountToCreate.email = email;

    try {
      const account = await memberService.createMember(accountToCreate);
      if (!account) {
        console.error("AuthService.Unable to create the Member Account", email);
        return undefined;
      }

      console.log("AuthService.Account(Me) created.", account);
      return account;
    } catch (error) {
      return undefined;
    }
  }
}

const authService = new AuthService();
export default authService;
