import "./hub-activities-page.scss";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import languageService from "../../services/core/language-service";
import StringHelpers from "../../services/core/string-helpers";
import useMyHub from "../../services/hub/useMyHub";
import Member from "../../services/member/member";
import LoadingComponent from "../../components/loading-component";
import { useEffect, useMemo, useState } from "react";
import hubService from "../../services/hub/hub-service";
import { HubActivity } from "../../services/hub/hub-activity";
import ToastHelpers from "../../services/core/toast-helpers";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import PagingComponent from "../../components/paging-component";
import { SyncButtonComponent } from "../../components/button-component";
import { OrderHelpers } from "../../services/order/order-helpers";
import { GlobalFilter } from "../../components/globalfilter-component";
import DateHelpers from "../../services/core/date-helpers";

interface IParamTypes {
  hubId: string;
}

interface IHubActivitiesPageProps {
  member: Member;
}

export function HubActivitiesPage(props: IHubActivitiesPageProps) {
  const { member } = props;
  const { hubId } = useParams<IParamTypes>();
  const { t } = useTranslation();

  const { hub } = useMyHub({
    hubId: hubId,
    onErrorNotificationMessage: t("hub-page.error-refresh"),
  });

  const [activities, setActivities] = useState<Array<HubActivity>>([]);
  const [activitiesMultiplier, setActivitiesMutiplier] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagingIndex, setPagingIndex] = useState<number>(0);

  const history = useHistory();

  const columnsTemp = [
    {
      Header: t("hub-history-page.table-header.date"),
      accessor: "createdOn",
    },
    {
      Header: t("hub-history-page.table-header.operation"),
      accessor: "description",
    },
    {
      Header: t("hub-history-page.table-header.author"),
      accessor: "createdBy",
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      columns: useMemo(() => columnsTemp, [languageService.language]),
      data: useMemo(() => updateTableActivitiesData(activities, member), [activities, languageService.language]),
      initialState: { pageIndex: pagingIndex },
    },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    refresh(pageIndex);
  }, [pageIndex + 1 == pageOptions.length && pageOptions.length % 10 == 0]);

  useEffect(() => {
    setGlobalFilter(state.globalFilter);
  }, [activities, languageService.language]);

  return (
    <div className="container page hub-activities-page">
      <div className="page-breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link className="" to={`/hubs`}>
              {t("hub-page.hublink")}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Link className="" to={`/hubs/${hubId}`}>
              {hub ? hub.name : hubId}
            </Link>
          </li>
          <li className="breadcrumb-item active">{t("hub-history-page.history-link")}</li>
        </ul>
      </div>

      <h1>{t("hub-history-page.history")}</h1>

      <div className="toolbar">
        <SyncButtonComponent onClick={() => refresh(0)}></SyncButtonComponent>

        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </div>

      <div>
        <div className="row mt-3">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <table {...getTableProps()} className="table table-sm table-hover row-clikable">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return <th {...column.getHeaderProps()}>{column.render("Header")}</th>;
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {loading && (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <LoadingComponent loading={loading} />
                        </td>
                      </tr>
                    )}

                    {!loading &&
                      page.map((row, i) => {
                        prepareRow(row);

                        let hubActivity = row.original as any;
                        let url = `/hubs/${hubId}`;

                        return (
                          <tr {...row.getRowProps()} onClick={() => history.push(url)}>
                            <td {...row.cells[0].getCellProps()}>{hubActivity.createdOn}</td>
                            <td {...row.cells[1].getCellProps()}>{hubActivity.description}</td>
                            <td {...row.cells[2].getCellProps()}>{hubActivity.createdBy}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <PagingComponent
          countTextResource={"hub-history-page.item-count"}
          count={rows.length}
          pageLength={pageOptions.length}
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );
  function refresh(pageIndex: number) {
    let multiplier = activitiesMultiplier;
    pageIndex++;

    if (pageIndex == pageOptions.length) {
      setPagingIndex(multiplier * 10 - 1);
      setActivitiesMutiplier(activitiesMultiplier + 1);
      multiplier++;
    } else if (pageIndex == 0) {
      multiplier = 1;
      setPagingIndex(0);
      setActivitiesMutiplier(1);
    }

    hubService
      .getHubActivities(hubId, multiplier * 100)
      .then((activities) => {
        setActivities(activities);
      })
      .catch((e) => {
        ToastHelpers.error(t("hub-history-page.history-error-refresh"));
      })
      .finally(() => {
        setLoading(false);
      });
  }
}

function updateTableActivitiesData(activities: HubActivity[], member: Member): Array<any> {
  const tableData: Array<any> = [];
  if (activities) {
    activities.forEach((hubActivity: HubActivity) => {
      if (hubActivity.id) {
        tableData.push({
          id: hubActivity.id,
          createdOn: DateHelpers.formatDateTime(new Date(hubActivity.createdOn), DateHelpers.UserTimeZone) ?? "",
          description: OrderHelpers.getActivityDescription(hubActivity, languageService.language),
          createdBy: hubActivity.createBy?.toString() || "",
        });
      }
    });
  }
  return tableData;
}
