import Member from "../../services/member/member";
import { useTranslation } from "react-i18next";
import "../account/member-page.scss";
import MemberRoleTable from "./memberRoleTable";
import Organization from "../../services/organization/organization";
import { useEffect, useState } from "react";
import EditMember from "./editMember";
import organizationService from "../../services/member/organization-service";
import ToastHelpers from "../../services/core/toast-helpers";
import AutocompleteSearch from "../../components/autocompleteSearch";

type Props = {
  member: Member;
};
type Item = {
  id: number;
  name: string;
  objId: string;
};
export default function AdminPage(props: Props) {
  const { member } = props;

  const { t } = useTranslation();
  const [editingMember, setEditingMember] = useState<boolean>(false);
  const [memberEdited, setMemberEdited] = useState<Member>();
  const [orgsItems, setOrgsItems] = useState<Array<Item>>([]);
  const [chosenOrgId, setChosenOrgId] = useState<String>("");
  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  useEffect(() => {
    var orgsStringList: Array<Item> = [];
    var count = 0;
    member.associatedOrganizations.forEach((org) => {
      if (org.entity) {
        orgsStringList.push({
          id: count,
          name: org.entity.name,
          objId: org.entity.id,
        });
        count++;
      }
    });

    setOrgsItems(orgsStringList);
  }, []);

  return (
    <div className="container page admin-page pt-5">
      <h2> DATABASE MEMBER MANAGEMENT </h2>

      <h5 className="mt-4"> {chosenOrgId == "" && "Enter the Organisation Name of members you want to modify"}</h5>
      {chosenOrgId != "" ? (
        editingMember ? (
          <EditMember
            editedMember={memberEdited}
            setEditingMember={setEditingMember}
            chosenOrgId={chosenOrgId}
            isNewUser={isNewUser}
            setMemberEdited={setMemberEdited}
          />
        ) : (
          <MemberRoleTable
            organizationId={chosenOrgId}
            setEditMember={setEditingMember}
            setMemberEdited={setMemberEdited}
            setIsNewUser={setIsNewUser}
          />
        )
      ) : (
        <AutocompleteSearch items={orgsItems} setter={setChosenOrgId} />
      )}

      <div className="row mt-5">
        {
          // <h2>Microsites TBD TODO</h2>
        }

        <br></br>
      </div>
    </div>
  );
}
