import OrganizationHubAssociation from "./organization-hub-association";

export default class Organization {
  id: string = "";
  name: string = "";

  streetNumber: string = "";
  street: string = "";
  city: string = "";
  state: string = "";
  country: string = "";
  postalCode: string = "";
  lat: number | null = null;
  lng: number | null = null;

  active: boolean = true;
  associatedHubs: Array<OrganizationHubAssociation> = [];
}
