import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import { InputComponent } from "../../components/input";
import LoadingComponent from "../../components/loading-component";
import StringHelpers from "../../services/core/string-helpers";
import ToastHelpers from "../../services/core/toast-helpers";
import micrositeService from "../../services/micro-site/microsite-service";
import { CodeType, ResetTime } from "../../assets/meta-table-keys";
import DateHelpers from "../../services/core/date-helpers";

interface IParamTypes {
  micrositeId: string;
  reservationBlockId: string;
}

export default function ReserveLockerSignInPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { micrositeId, reservationBlockId } = useParams<IParamTypes>();
  const [isEmail, setIsEmail] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<string | undefined>(undefined);

  const [orderId, setOrderId] = useState<string>("");
  const [linkSent, setLinkSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const resendNotificationTimeout = 60;
  const [counter, setCounter] = useState(resendNotificationTimeout);

  const [reservationBlock, setReservationBlock] = useState<any>({});
  const showSms = true;

  useEffect(() => {
    setLoading(true);

    micrositeService
      .getReservationBlockById(micrositeId, reservationBlockId)
      .then((value) => {
        setReservationBlock(value);
      })
      .catch((error) => {
        ToastHelpers.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reservationBlockId, micrositeId]);

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter((prev) => prev - 1), 1000);
      return () => clearInterval(timer); // Cleanup on unmount or before re-running effect
    }
  }, [counter]); // Depend on counter

  if (loading) {
    return <LoadingComponent loading={loading} />;
  }

  const meta = reservationBlock?.meta;
  const codeType = meta?.[CodeType] ? reservationBlock.meta[CodeType] : "none";

  return (
    <div className="container page orders-page">
      <div className="col-12 offset-lg-2 col-lg-8">
        <div className="card">
          <div className="card-header align-items-center">
            <div className="d-inline-block">
              <h2>{t("micro-site-reserve-locker.title")}</h2>
            </div>
            <button className="btn-close float-end" onClick={() => history.push(`/sites/${micrositeId}`)}></button>
          </div>
          <div className="card-body">
            {linkSent && (
              <div className="mb-3">
                {isEmail && <div>{t("micro-site-reserve-locker.verification-sent-email")}</div>}
                {!isEmail && <div>{t("micro-site-reserve-locker.verification-sent-phone")}</div>}
                <div>{t("micro-site-reserve-locker.please-finalise")}</div>
                <br></br>

                {isEmail && (
                  <div>
                    {t("micro-site-reserve-locker.verify-sent-email")}: <b>{email}</b>
                  </div>
                )}
                {!isEmail && (
                  <div>
                    {t("micro-site-reserve-locker.verify-sent-phone")}: <b>{phone}</b>
                  </div>
                )}
                <button className="btn btn-primary" onClick={() => resendNotification()} disabled={counter > 0}>
                  {t("micro-site-reserve-locker.resend-notification")} {counter > 0 && "(" + counter + ")"}
                </button>
              </div>
            )}
            {!linkSent && (
              <>
                {showSms && (
                  <>
                    <div className="mb-3">{t("micro-site-reserve-locker.signup")}</div>
                    <div className="mb-3">
                      <input type="radio" id="byEmail" name="email" value="email" checked={isEmail} onClick={() => setIsEmail(true)} />
                      <label htmlFor="byEmail" className="ms-2" onClick={() => setIsEmail(true)}>
                        {t("micro-site-reserve-locker.enter-email")}
                      </label>
                    </div>

                    <div className="mb-3">
                      <input type="radio" id="byPhone" name="phone" value="phone" checked={!isEmail} onClick={() => setIsEmail(false)} />
                      <label htmlFor="byPhone" className="ms-2" onClick={() => setIsEmail(false)}>
                        {t("micro-site-reserve-locker.enter-phone")}
                      </label>
                    </div>
                  </>
                )}
                {!showSms && <div className="mb-3">{t("micro-site-reserve-locker.signup-email-only")}</div>}
                <form>
                  {!showSms && (
                    <InputComponent
                      value={email}
                      setValue={setEmail}
                      regExp={StringHelpers.getEmailRegExp()}
                      msgError={emailError}
                      onMsgErrorChanged={setEmailError}
                      type={"email"}
                      labelText={t("common.email") + "*"}
                      required={true}
                    />
                  )}
                  {showSms &&
                    (isEmail ? (
                      <div className="mb-3">
                        <InputComponent
                          value={email}
                          setValue={setEmail}
                          regExp={StringHelpers.getEmailRegExp()}
                          msgError={emailError}
                          onMsgErrorChanged={setEmailError}
                          type={"email"}
                          labelText={t("common.email") + "*"}
                          required={true}
                        />
                      </div>
                    ) : (
                      <PhoneFormComponent
                        value={phone}
                        setValue={setPhone}
                        onMsgErrorChanged={setPhoneError}
                        msgError={phoneError}
                        label={t("common.phone") + "*"}
                      />
                    ))}

                  {codeType === "6-digit" && (
                    <InputComponent
                      labelText={t("input-component.code-label-6digits")}
                      type="code-6digits"
                      value={code}
                      setValue={setCode}
                      msgError={codeError}
                      onMsgErrorChanged={setCodeError}
                      required={true}
                      regExp={/^[0-9]{6}$/}
                    />
                  )}
                  {codeType === "1-to-4" && (
                    <InputComponent
                      labelText={t("input-component.code-label")}
                      type="code-sl5c"
                      value={code}
                      setValue={setCode}
                      msgError={codeError}
                      onMsgErrorChanged={setCodeError}
                      required={true}
                      regExp={/^[1-4]{6}$/}
                    />
                  )}
                  <div className="mb-4 mt-3">
                    <button type="submit" className="btn btn-primary" onClick={() => validate()} disabled={isBtnDisabled()}>
                      {t("micro-site-reserve-locker.validate-button")}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function isBtnDisabled(): boolean {
    if (isEmail)
      return emailError !== undefined || loading || codeError !== undefined || !email || (!code && reservationBlock.codeSpecified);
    return phoneError !== undefined || loading || codeError !== undefined || !phone || (!code && reservationBlock.codeSpecified);
  }
  async function validate() {
    let newEmail = email;
    let newPhone = phone;
    if (isEmail) {
      newPhone = "";
      if (email && emailError) {
        return;
      }
    } else {
      newEmail = "";
      if (phone && phoneError) {
        return;
      }
    }

    createOrder(newPhone, newEmail);
  }

  function resendNotification() {
    setLoading(true);
    micrositeService
      .resendNotification(micrositeId, reservationBlockId, orderId, phone, email, undefined)
      .then((res) => {
        if (!res) {
          ToastHelpers.error(t("create-order-page.error-no-availability"));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("create-order-page.error-conflict"));
        } else {
          if (reservationBlock.codeSpecified) {
            ToastHelpers.error(t("create-order-page.error-generic-change-code"));
          }
          ToastHelpers.error(t("common.error-generic"));
        }
      })
      .finally(() => {
        setCounter(resendNotificationTimeout * 1.5);
        setLoading(false);
      });
  }

  function createOrder(phone: string, email: string) {
    setLoading(true);
    let end = undefined;
    if (reservationBlock.meta?.[ResetTime]) {
      let date = DateHelpers.getNextDayWithTime(reservationBlock.meta[ResetTime], DateHelpers.UserTimeZone);
      end = DateHelpers.toApiDate(date);
    }

    let allowOffline = micrositeId === "osheaga2024" || micrositeId === "ilesoniq2024" || micrositeId === "lasso2024";

    micrositeService
      .createOrder(
        micrositeId,
        reservationBlockId,
        false,
        phone,
        email,
        undefined,
        undefined,
        undefined,
        code,
        undefined,
        end,
        allowOffline
      )
      .then((value) => {
        if (value) {
          setLinkSent(true);
          setCounter(resendNotificationTimeout);
          setOrderId(value);
        } else {
          ToastHelpers.error(t("create-order-page.error-no-availability"));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("create-order-page.error-conflict"));
        } else {
          if (reservationBlock.codeSpecified) {
            ToastHelpers.error(t("create-order-page.error-generic-change-code"));
          }
          ToastHelpers.error(t("common.error-generic"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
}
