import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import StringHelpers from "../services/core/string-helpers";

interface PagingProps {
  countTextResource: string;

  count: number;
  pageLength: number;
  pageIndex: number;

  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
}

export default function PagingComponent(props: PagingProps) {
  const { countTextResource, count, pageLength, pageIndex, canPreviousPage, canNextPage, previousPage, nextPage } = props;

  const { t } = useTranslation();

  return (
    <nav>
      <span>{t(StringHelpers.pluralize(count, countTextResource), { count: count })}</span>

      {count > 0 && (
        <ul className="pagination float-end">
          <li className={"page-item" + (!canPreviousPage ? " disabled" : "")}>
            <button className="page-link" aria-label="Previous" onClick={() => previousPage()}>
              <FontAwesomeIcon icon={["fas", "angle-left"]} />
            </button>
          </li>
          <li className="page-item disabled page-index">
            <button className="page-link" aria-label="Previous" onClick={() => previousPage()}>
              {pageIndex + 1} {t("common.of")} {pageLength}
            </button>
          </li>
          <li className={"page-item" + (!canNextPage ? " disabled" : "")}>
            <button className="page-link" aria-label="Next" onClick={() => nextPage()}>
              <FontAwesomeIcon icon={["fas", "angle-right"]} />
            </button>
          </li>
        </ul>
      )}
    </nav>
  );
}
