export default class StringHelpers {
  static pluralize(count: number, textSingular: string): string {
    return textSingular + (count > 1 ? "s" : "");
  }

  static getFullName(firstname: string | undefined, lastname: string | undefined): string {
    if (firstname && lastname) {
      return firstname + " " + lastname;
    }

    if (firstname) {
      return firstname;
    }

    if (lastname) {
      return lastname;
    }
    return "";
  }

  static isStringOnlyNumbers(stringToVerify: string): boolean {
    let regexp = new RegExp("^[0-9]*$"),
    test = regexp.test(stringToVerify);
    
    return test;
  } 

  static getEmailRegExp(): RegExp {
    // eslint-disable-next-line
    return /^(?:[a-zA-Z0-9.!#$%&amp;'*+\=?^_`{|}~-]+)(?:[\.a-zA-Z0-9!#$%&amp;'*+\=?^_`{|}~-]*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]+$/;
  }

  static formatNumber(value: number, digits: number): string {
    return value.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: digits,
      useGrouping: false,
    });
  }

  static formatPrice(amount: number, digits: number = 2): string {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      useGrouping: true,
    });
  }
}
