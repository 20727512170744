import toast from "react-hot-toast";

export default class ToastHelpers {
  static success(message: string) {
    toast.success(message);
  }

  static error(message: string) {
    toast.error(message);
  }
}
