import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { EditButtonComponent } from "../../components/button-component";
import LoadingComponent from "../../components/loading-component";
import languageService from "../../services/core/language-service";
import StringHelpers from "../../services/core/string-helpers";
import Microsite from "../../services/micro-site/microsite";
import { MicrositeHelpers } from "../../services/micro-site/microsite-helpers";
import DateHelpers from "../../services/core/date-helpers";

interface EventsTableProps {
  microsite: Microsite | undefined;
  isOrgAdmin: boolean;
  isLoading: boolean;
}

interface IParamTypes {
  organizationId: string;
  micrositeId: string;
}

function EventsTable(props: EventsTableProps) {
  const { microsite, isOrgAdmin, isLoading } = props;
  const { organizationId, micrositeId } = useParams<IParamTypes>();
  
  const { t } = useTranslation();
  const language: string = languageService.getLanguage();
  const history = useHistory();

  if (microsite?.reservationBlocks.length === 0) {
    return <div>{t("events-page.no-events")}</div>;
  }
  if (microsite?.reservationBlocks[0]) {
    microsite.reservationBlocks = microsite.reservationBlocks.sort(
      (rb1, rb2) => new Date(rb1.startOn).getTime() - new Date(rb2.startOn).getTime()
    );
  }
  return (
    <table className="table table-order">
      <thead>
        <tr>
          <th>{t("common.name")}</th>
          <th>{t("common.price")}</th>
          <th>{t("common.start-on")}</th>
          <th>{t("common.duration")}</th>
          <th></th>
          {isOrgAdmin && <th></th>}
        </tr>
      </thead>
      <tbody>
        {isLoading && (
          <tr>
            <td colSpan={5} className="text-center">
              <LoadingComponent loading={isLoading} />
            </td>
          </tr>
        )}
        {!isLoading &&
          microsite?.reservationBlocks?.map((reservationBlock) => {
            return (
              reservationBlock &&
              reservationBlock.name.get(language) &&
              reservationBlock.active === true && (
                <tr key={reservationBlock.id}>
                  <td>{reservationBlock.name.get(language)}</td>
                  <td>
                    {t("common.price-format", {
                      price: StringHelpers.formatPrice(reservationBlock.price + reservationBlock.price * reservationBlock.saleTaxRate),
                      currency: microsite.currency,
                    })}
                  </td>
                  <td>
                    {reservationBlock.startOn && DateHelpers.formatDateTime(new Date(reservationBlock.startOn), DateHelpers.UserTimeZone)}
                  </td>
                  <td>{reservationBlock.duration && DateHelpers.formatDuration(reservationBlock.duration)}</td>
                  <td className="text-center">
                    <button
                      className="btn btn-primary me-2"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        history.push(
                          `/organizations/${organizationId}/microsites/${micrositeId}/reservationBlocks/${reservationBlock.id}/reserve-locker`
                        );
                      }}
                      disabled={MicrositeHelpers.reservationBlockIsPassed(reservationBlock)}
                    >
                      {t("common.reserve-locker")}
                    </button>
                  </td>
                  {isOrgAdmin && (
                    <>
                      <td className="text-center">
                        <button
                          title={t("event-page.sales")}
                          className="btn btn-outline-primary me-2"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            history.push(
                              `/organizations/${organizationId}/microsites/${microsite.id}/reservationBlocks/${reservationBlock.id}/sales-report`
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={["fas", "file-invoice-dollar"]}></FontAwesomeIcon>
                        </button>
                        <EditButtonComponent
                          url={`/organizations/${organizationId}/microsites/${microsite.id}/reservationBlocks/${reservationBlock.id}`}
                        />
                      </td>
                    </>
                  )}
                </tr>
              )
            );
          })}
      </tbody>
    </table>
  );
}

export default EventsTable;
