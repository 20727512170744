import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./payment-form-square.scss";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingComponent from "../loading-component";
import { CardPaymentRequest } from "../../services/payment/payment-request";
import paymentService from "../../services/payment/payment-service";
import { ChargeState } from "./charge-state";
import { PaymentState } from "../../services/payment/payment-state";
import { CardPaymentResponse } from "../../services/payment/payment-response";

declare global {
  interface Window {
    Square: any; // This is to make sure the typescript doesn't cry.
  }
}

interface PaymentFormProps {
  cost: string;
  currency: string;
  orderToken: string;
  organizationId: string;
  locationId: string;
  paynow: boolean;
  minPrice?: string;
}

export default function PaymentFormSquare(props: PaymentFormProps) {
  const { cost, currency, locationId, orderToken, organizationId, paynow, minPrice } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [card, setCard] = useState<any>(null);
  const [state, setState] = useState<ChargeState>(ChargeState.EnteringData);

  if (!window.Square) throw new Error("PaymentLib failed to load properly.");

  useEffect(() => {
    if (state !== ChargeState.ChargeTimeout) {
      try {
        initializeCard();
      } catch (e) {
        console.error("Initializing Card failed", e);
      }
    }
  }, [locationId]);

  return (
    <div className="payment-form-container">
      {state === ChargeState.ChargeTimeout && (
        <div>
          <div className="text-danger">
            <FontAwesomeIcon className="me-2" icon={["fas", "exclamation-triangle"]} />
            {t("micro-site-reserve-locker.payment-timeout-msg")}
          </div>
          <div className="mt-2">
            <button className="btn btn-primary" onClick={() => window.location.reload()}>
              {t("micro-site-reserve-locker.try-again")}
            </button>
          </div>
        </div>
      )}

      {state !== ChargeState.ChargeTimeout && (
        <>
          <label className="form-label">{t("micro-site-reserve-locker.credit-card")}</label>

          <form id="payment-form">
            <div id="card-container"></div>
            <div id="payment-status-container"></div>
            <LoadingComponent loading={state === ChargeState.ChargeInProgress} />
            <button
              id="card-button"
              className="btn btn-primary pushable rounded-3 shadow-lg"
              type="button"
              disabled={state === ChargeState.ChargeInProgress}
              onClick={(ev: React.MouseEvent) => pay(ev, card, setState)}
            >
              <span className="front rounded-3">
                {paynow && t("payment.pay", { cost: cost })}
                {!paynow && (
                  <>
                    {!minPrice && t("payment.hold", { cost: cost })}
                    {minPrice && t("payment.pay", { cost: minPrice })}
                  </>
                )}
              </span>
            </button>
          </form>

          <div className="mt-3">
            <FontAwesomeIcon icon={["fas", "info-circle"]} /> {t("micro-site-reserve-locker.msg-taxes")}
          </div>
        </>
      )}
    </div>
  );

  async function initializeCard() {
    let payments;
    try {
      payments = window.Square.payments(process.env.REACT_APP_SQUARE_APP_ID, locationId);
    } catch {
      const statusContainer = document.getElementById("payment-status-container");
      if (statusContainer) {
        statusContainer.className = "missing-credentials";
        statusContainer.style.visibility = "visible";
      }
      return;
    }

    const _card = await payments.card();
    setCard(_card);

    const container = document.getElementById("card-container");
    if (container) container.innerHTML = "";
    await _card.attach("#card-container");

    console.log("initializeCard done.");
  }

  function clearPaymentResults() {
    const statusContainer = document.getElementById("payment-status-container");
    if (statusContainer) {
      statusContainer.style.visibility = "visible";
      statusContainer.classList.remove("is-success");
      statusContainer.classList.remove("is-failure");
      statusContainer.innerHTML = "";
    }
  }

  async function pay(event: React.MouseEvent, card: any, setState: Function) {
    event.preventDefault();
    clearPaymentResults();

    setState(ChargeState.ChargeInProgress);

    const token = await tokenize(card);
    if (!token) {
      console.error("Unable to retreive the token");
      displayPaymentResultsSquare("FAILURE");
      setState(ChargeState.ChargeError);
      return;
    }

    const cardPaymentRequest = new CardPaymentRequest();
    cardPaymentRequest.token = token;
    cardPaymentRequest.orderToken = orderToken;
    cardPaymentRequest.PayNow = paynow;
    await paymentService
      .pay(cardPaymentRequest, organizationId)
      .then((result: CardPaymentResponse) => {
        switch (result.state) {
          case PaymentState.Success:
            displayPaymentResultsSquare("SUCCESS");
            console.log("Payment charge Success");
            setState(ChargeState.Completed);
            history.push(`/my-reservations/${orderToken}`);
            break;
          case PaymentState.Error:
            setState(ChargeState.ChargeError);
            displayPaymentResultsSquare("FAILURE");
            break;
          case PaymentState.Timeout:
            setState(ChargeState.ChargeTimeout);
            displayPaymentResultsSquare("FAILURE");
            break;
        }
      })
      .catch(() => {
        setState(ChargeState.ChargeError);
        displayPaymentResultsSquare("FAILURE");
      });
  }

  async function tokenize(paymentMethod: any): Promise<string | null> {
    try {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      }

      return null;
    } catch {
      return null;
    }
  }

  function displayPaymentResultsSquare(status: string) {
    // 'SUCCESS' or 'FAILURE'
    const statusContainer = document.getElementById("payment-status-container");
    if (!statusContainer) return;

    if (status === "SUCCESS") {
      statusContainer.classList.remove("is-failure");
      statusContainer.classList.add("is-success");
      statusContainer.innerHTML += t("payment.is-success");
    } else {
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure");
      statusContainer.innerHTML += t("payment.is-failure");
    }
    statusContainer.style.visibility = "visible";
  }
}
