import { useTranslation } from "react-i18next";
import StringHelpers from "../../services/core/string-helpers";

type Props = {
  value: string;
  setValue: Function;
  msgError: string | undefined;
  onMsgErrorChanged: Function;
  required?: boolean | undefined;
};

export function EmailFormComponent(props: Props) {
  const { t } = useTranslation();
  const type = "tel";
  const error = t("input-component.error-email");

  return (
    <>
      <div className="mb-3">
        <label className="form-label">
          {t("member-profile.email")}
          {props.required && "*"}
        </label>
        <input
          className="form-control"
          type={type}
          value={props.value}
          placeholder={t("input-component.placeholder-email")}
          onChange={(ev) => validate(ev.target.value)}
          required={props.required}
        />
        {props.msgError && <label className="error-msg">{props.msgError}</label>}
      </div>
    </>
  );

  function validate(value: string) {
    props.setValue(value);

    if (isValid(value)) {
      props.onMsgErrorChanged(undefined);
    } else {
      props.onMsgErrorChanged(error);
    }
  }

  function isValid(value: string) {
    let valid: boolean = StringHelpers.getEmailRegExp().test(value);

    if (props.required && valid) return true;

    if (!props.required && (valid || value.length === 0)) return true;

    return false;
  }
}
