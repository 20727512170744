import { useEffect, useState } from "react";

type Props = {
  children: any;
  waitBeforeShow?: number;
};

export default function DelayCompoment({ children, waitBeforeShow = 500 }: Props) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);

    return () => clearInterval(timeoutId);
  }, [waitBeforeShow]);

  if (isShown) {
    return children;
  }
  return null;
}
