import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LoadingProps {
  loading: boolean;
}

export default function LoadingComponent(props: LoadingProps) {
  const { loading } = props;

  return (
    <>
      {loading && (
        <div className="loader">
          <div className="text-center">
            <FontAwesomeIcon icon="spinner" spin size="3x" />
          </div>
        </div>
      )}
    </>
  );
}
