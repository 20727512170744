import { useState } from "react";
import { Address } from "../services/core/address";

interface Props {
  address: Address;
  intialDetail: boolean;
}
AddressDetailComponent.defaultProps = {
  intialDetail: false,
};
export default function AddressDetailComponent(props: Props) {
  const { address, intialDetail } = props;
  const [detail, setDetail] = useState<boolean>(intialDetail);

  return (
    <>
      {address.streetNumber} {address.street}
      <br />
      {address.city}{" "}
      {detail ? (
        <>
          <br />
          <span>
            {address.state}, {address.country}, {address.postalCode}
          </span>
        </>
      ) : (
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            setDetail((curValue) => !curValue);
          }}
        >
          ...
        </button>
      )}
    </>
  );
}
