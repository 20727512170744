import { useEffect, useState } from "react";
import "./App.scss";
import { Route, useHistory, withRouter } from "react-router-dom";
import SignUpPage from "./pages/account/signup-page";
import OrdersPage from "./pages/orders/orders-page";
import DatabaseAdminPage from "./pages/admin/database-admin-page";
import CustomersPage from "./pages/customers/customers-page";
import OrderPage from "./pages/orders/order-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";
import languageService from "./services/core/language-service";
import authService from "./services/auth/auth-service";
import Member from "./services/member/member";
import { HeaderNavbar } from "./components/header";
import MemberRoleOrganization from "./services/member/member-role-organization";
import OrganizationsPage from "./pages/account/organizations-page";
import OrganizationPage from "./pages/account/organization-page";
import MemberPage from "./pages/account/member-page";
import CreateOrderPage from "./pages/orders/create-order-page";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import NoConnectionPage from "./pages/home/no-connection-page";
import WaitingComponent from "./components/waiting-component";
import DelayCompoment from "./components/delay-component";
import { Toaster } from "react-hot-toast";
import { CreateCustomerPage } from "./pages/customers/create-customer-page";
import { EditCustomerPage } from "./pages/customers/edit-customer-page";
import MicroSitePage from "./pages/micro-site/micro-site-page";
import ReserveLockerPage from "./pages/micro-site/reserve-locker";
import HubsPage from "./pages/hubs/hubs-page";
import HubPage from "./pages/hubs/hub-page";
import MyReservationsPage from "./pages/reservations/my-reservations-page";
import OrderExpiredPage from "./pages/micro-site/order-expired-page";
import memberService from "./services/member/member-service";
import DashboardPage from "./pages/dashboard/dashboard-page";
import MicrositesPage from "./pages/events/microsites-page";
import EventsPage from "./pages/events/events-page";
import EventPage from "./pages/events/event-page";
import ContributorReserveLockerPage from "./pages/micro-site/contributor-reserve-locker-page";
import RelocatePage from "./pages/orders/relocate-page";
import EventSettlementReportPage from "./pages/events/event-settlement-report";
import { HubActivitiesPage } from "./pages/hubs/hub-activities-page";
import GetLocketgoLogoSource, { AssetColors } from "./assets/assets-colors";
import ReserveLockerSignInPage from "./pages/micro-site/reserve-locker-signin";
import HomeNotRegisteredPage from "./pages/home/home-not-registered-page";
import MicrositeAdminPage from "./pages/admin/microsite-cms/microsite-admin";
import CreateOrganizationPage from "./pages/account/create-organization";

/* 
  AppInsights
 */
const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "cb42ef3d-5c2a-41fe-9f34-477a50329b27",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };

/* 
  The Application 
*/
function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isSquareLoaded, setSquareLoaded] = useState(false);
  const [member, setMember] = useState<Member | null>(null);
  const [activeOrganization, setActiveOrganization] = useState<MemberRoleOrganization | null>(null);
  const [, setLanguage] = useState<string>(languageService.getLanguage());
  const [delayExpired, setDelayExpired] = useState<boolean>(false);
  const [noConnection, setNoConnection] = useState<boolean>(false);
  const logo = GetLocketgoLogoSource(AssetColors.Black);

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const isMicrosite: boolean =
    browserHistory.location.pathname.includes("/sites/") ||
    browserHistory.location.pathname.includes("/my-reservations") ||
    browserHistory.location.pathname.includes("/my-orders");

  document.title = t("common.appTitle");

  useEffect(() => {
    console.log("App.useEffect start. [autologinRedirectUri=" + localStorage.getItem("autologinRedirectUri") + "]");

    const autoLogin = async () => {
      const loginResponse = await authService.autoLogin();
      setNoConnection(loginResponse.error);
      changeMember(loginResponse.member);

      setLoading(false);
      console.log("App.useEffect autologin done.");

      let autologinRedirectUri = localStorage.getItem("autologinRedirectUri");
      if (loginResponse.member && autologinRedirectUri) {
        console.log("autologinRedirectUri", autologinRedirectUri);
        localStorage.removeItem("autologinRedirectUri");
        history.push(autologinRedirectUri);
      }
    };
    autoLogin();

    authService.registerCallback(() => {
      console.log("App.registerCallback");
      autoLogin();
    });

    let interval = setTimeout(() => {
      setDelayExpired(true);
    }, 3000);

    if (!isSquareLoaded) {
      let sqPaymentScript = document.createElement("script");
      sqPaymentScript.src = process.env.REACT_APP_SQUARE_SCRIPT || "";
      sqPaymentScript.type = "text/javascript";
      sqPaymentScript.async = false;
      sqPaymentScript.onload = () => {
        console.log("PaymentLib loaded.");
        setSquareLoaded(true);
      };
      document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }

    console.log("App.useEffect end.");

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <DelayCompoment waitBeforeShow={750}>
        <WaitingComponent />
      </DelayCompoment>
    );
  }

  const orgContributor = memberService.isOrganizationContributor(member, activeOrganization?.entity?.id);
  const isHubContributor = memberService.isHubContributor(member, undefined);
  const isOrganizationContributor = memberService.isOrganizationContributor(member, undefined);
  const isConcierge = memberService.isConcierge(member);

  if (noConnection) {
    return <>{delayExpired && <NoConnectionPage changeLanguage={changeLanguage} />}</>;
  }

  if (noConnection) {
    return <>{delayExpired && <NoConnectionPage changeLanguage={changeLanguage} />}</>;
  }

  return (
    <>
      <Toaster position={"top-right"} />
      {isMicrosite && (
        <div className="container container-site mb-3 mb-lg-5">
          <div className="row">
            <div className="col-12 offset-lg-2 col-lg-8">
              <img src={logo} alt="" className="img-logo img-logo-locketgo" />
              <button className="nav-link float-end" onClick={changeLanguage}>
                <FontAwesomeIcon className="me-2" icon={["fas", "globe"]} />
                {t("language.display-long")}
              </button>
            </div>
          </div>
        </div>
      )}

      <UnauthenticatedTemplate>
        {!member && (
          <>
            <Route exact path="/">
              <SignUpPage changeMember={changeMember} changeLanguage={changeLanguage} />
            </Route>
            <Route exact path="/signup">
              <SignUpPage changeMember={changeMember} changeLanguage={changeLanguage} />
            </Route>

            <Route exact path="/my-reservations/:token">
              <MyReservationsPage />
            </Route>
          </>
        )}
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        {!isMicrosite && member && activeOrganization && (
          <header>
            <HeaderNavbar
              member={member}
              activeOrganization={activeOrganization}
              changeLanguage={changeLanguage}
              changeMember={changeMember}
            />
          </header>
        )}

        {!isMicrosite && member && isConcierge && !activeOrganization && (
          <header>
            <div className="container container-site mb-3 mb-lg-5">
              <div className="row">
                <div className="col-12">
                  <img src={logo} alt="" className="img-logo img-logo-locketgo" />
                  <button className="nav-link float-end" onClick={changeLanguage}>
                    <FontAwesomeIcon className="me-2" icon={["fas", "globe"]} />
                    {t("language.display-long")}
                  </button>
                </div>
              </div>
            </div>
          </header>
        )}

        {/* Root page */}
        {member && (isHubContributor || isOrganizationContributor) && (
          <Route exact path="/">
            <DashboardPage member={member} />
          </Route>
        )}

        {/* No Associations */}
        {member &&
          !isHubContributor &&
          !isOrganizationContributor &&
          (!isConcierge ? (
            <Route exact path="/">
              <HomeNotRegisteredPage member={member} changeLanguage={changeLanguage} changeMember={changeMember} />
            </Route>
          ) : (
            <Route exact path="/">
              <HubsPage member={member} />
            </Route>
          ))}

        {member && activeOrganization && (
          <>
            {orgContributor && (
              <>
                <Route exact path="/organizations/:organizationId">
                  <OrganizationPage member={member} changeMember={changeMember} />
                </Route>
                <Route exact path="/organizations/:organizationId/microsites">
                  <MicrositesPage member={member} />
                </Route>
                <Route exact path="/organizations/:organizationId/microsites/:micrositeId/reservationBlocks">
                  <EventsPage member={member} />
                </Route>
                <Route exact path="/organizations/:organizationId/microsites/:micrositeId/reservationBlocks/:eventId">
                  <EventPage member={member} />
                </Route>
                <Route exact path="/organizations/:organizationId/microsites/:micrositeId/reservationBlocks/:eventId/sales-report">
                  <EventSettlementReportPage member={member} />
                </Route>
                <Route
                  exact
                  path="/organizations/:organizationId/microsites/:micrositeId/reservationBlocks/:reservationBlockId/reserve-locker"
                >
                  <ContributorReserveLockerPage member={member} setNoConnection={setNoConnection} />
                </Route>

                {activeOrganization.entity && (
                  <>
                    <Route exact path="/orders">
                      <OrdersPage organization={activeOrganization.entity} member={member} />
                    </Route>
                    <Route exact path="/orders/:orderId">
                      <OrderPage organization={activeOrganization.entity} member={member} />
                    </Route>
                    <Route exact path="/orders/:orderId/items/:itemId/relocate">
                      <RelocatePage organizationId={activeOrganization.entity?.id} member={member} />
                    </Route>
                    <Route exact path="/create-order">
                      <CreateOrderPage organization={activeOrganization.entity} member={member}></CreateOrderPage>
                    </Route>
                    <Route exact path="/create-return">
                      <CreateOrderPage isReturn={true} organization={activeOrganization.entity} member={member}></CreateOrderPage>
                    </Route>

                    <Route exact path="/customers">
                      <CustomersPage organization={activeOrganization.entity} />
                    </Route>
                    {memberService.isLocketgoAdmin(member) && (
                      <>
                        <Route exact path="/database-admin">
                          <DatabaseAdminPage member={member} />
                        </Route>
                        <Route exact path="/database-admin/microsite/:organizationId/:micrositeId">
                          <MicrositeAdminPage chosenOrg={activeOrganization.entity} />
                        </Route>
                        <Route exact path="/create-organization">
                          <CreateOrganizationPage member={member} changeMember={changeMember} />
                        </Route>
                        <Route path="/customers/:customerId">
                          {<EditCustomerPage organization={activeOrganization.entity} user={member} />}
                        </Route>
                        <Route exact path="/create-customer">
                          <CreateCustomerPage organization={activeOrganization.entity} />
                        </Route>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <Route exact path="/organizations">
              <OrganizationsPage
                member={member}
                activeOrganization={activeOrganization}
                changeActiveOrganization={changeActiveOrganization}
              />
            </Route>
          </>
        )}

        {member && (
          <>
            {member.associatedHubs?.length > 0 && (
              <>
                <Route exact path="/hubs">
                  {<HubsPage member={member} />}
                </Route>

                <Route path="/hubs/:hubId/activities">
                  <HubActivitiesPage member={member} />
                </Route>

                <Route exact path="/hubs/:hubId/">
                  <HubPage member={member} />
                </Route>
              </>
            )}
            <Route exact path="/member-profile">
              {<MemberPage member={member} changeMember={changeMember} />}
            </Route>
            {/**
            <Route exact path="/create-organization">
              <CreateMerchantOrganizationPage member={member} changeMember={changeMember} />
              { {<CreateOrganizationPage member={member} changeMember={changeMember} />} }
            </Route>
            <Route exact path="/my-reservations">
              <MyReservationsPage />
            </Route>
            **/}
          </>
        )}
      </AuthenticatedTemplate>

      <Route exact path="/sites/:micrositeId">
        {<MicroSitePage member={member} setNoConnection={setNoConnection} />}
      </Route>
      <Route exact path="/sites/reservation-expired/:micrositeId">
        {<OrderExpiredPage />}
      </Route>
      <Route exact path="/sites/:micrositeId/signin-reserve-locker/:reservationBlockId">
        {<ReserveLockerSignInPage />}
      </Route>
      <Route exact path="/sites/:micrositeId/reserve-locker/:reservationBlockId/token/:token">
        {<ReserveLockerPage />}
      </Route>
      <Route exact path="/my-orders/:token">
        <MyReservationsPage />
      </Route>

      {/* <Route exact path="/admin-debug">
            <AdminPage />
          </Route> */}
    </>
  );

  function changeLanguage() {
    const newLanguage = languageService.changeLanguage();
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    document.title = t("common.appTitle");
  }

  function changeMember(newMember: Member | null) {
    setMember(newMember);

    if (newMember && newMember.associatedOrganizations.length > 0) {
      let organizationId = localStorage.getItem("organizationId");

      if (organizationId) {
        let temp = newMember.associatedOrganizations.find((org: MemberRoleOrganization) => org.entity?.id === organizationId);

        if (temp) {
          changeActiveOrganization(temp);
        } else {
          changeActiveOrganization(newMember.associatedOrganizations[0]);
        }
      } else {
        changeActiveOrganization(newMember.associatedOrganizations[0]);
      }
    } else {
      changeActiveOrganization(null);
    }

    return member;
  }

  function changeActiveOrganization(newActiveOrganization: MemberRoleOrganization | null) {
    setActiveOrganization(newActiveOrganization);
    newActiveOrganization?.entity?.id && localStorage.setItem("organizationId", newActiveOrganization?.entity?.id);
  }
}

export default withRouter(App);
