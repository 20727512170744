import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface OrderCustomerProps {
  customerInfoHtml: string;
  hasCustomerContactInformation: boolean;
  canSendNotification: boolean;
  isFirstTimeNotification: boolean;
  onSendNotification: React.MouseEventHandler<HTMLButtonElement>;
}

export default function OrderCustomer(props: OrderCustomerProps) {
  const { customerInfoHtml, hasCustomerContactInformation, canSendNotification, isFirstTimeNotification, onSendNotification } = props;
  const { t } = useTranslation();
  return (
    <div className="col-12 col-lg-4">
      <div className="customer card">
        <div className="card-body">
          <h5 className="card-title">{t("order-page.customer")}</h5>
          <div className="row">
            <div className="col-12">
              <p dangerouslySetInnerHTML={{ __html: customerInfoHtml }}></p>
            </div>
          </div>
          {hasCustomerContactInformation && (
            <div className="row">
              <div className="col-12">
                <button
                  className="btn btn-sm btn-primary float-end"
                  onClick={onSendNotification}
                  disabled={!canSendNotification}
                >
                  {isFirstTimeNotification ? t("order-page.notify") : t("order-page.notify-again")}
                  <FontAwesomeIcon className="ms-1" icon={["fas", "bell"]} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
