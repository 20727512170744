import { NotifyLevel } from "../core/notification-level";
import { Payment } from "../payment/payment";
import { Customer } from "./customer";
import { OrderActivity } from "./order-activity";
import { OrderItem } from "./order-item";

export enum OrderFlow {
  Deliver = 0,
  Return = 2,
  PersonalStorage = 3,
}

export enum OrderFlowStringValue {
  Deliver = "Deliver",
  Return = "Return",
  PersonalStorage = "PersonalStorage",
}

export class Order {
  id: string = "";
  customer: Customer = new Customer();
  payments: Array<Payment> | undefined = undefined;
  organizationId: string = "";
  orderDate: string = "";
  referenceNumber: string = "";
  hubId: string = "";
  state: string = "";
  flow: OrderFlow = OrderFlow.Deliver;
  deleted: number = 0;
  packagingOptionId: string = "";
  carrierId: string | undefined = undefined;
  carrierSubmissionReferenceNumber: string = "";

  items: Array<OrderItem> = [];
  activities: Array<OrderActivity> = [];

  notifyLevel: NotifyLevel = NotifyLevel.None;
}
