import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Hub } from "../../services/hub/hub";
import hubService from "../../services/hub/hub-service";
import { Order, OrderFlowStringValue } from "../../services/order/order";
import orderService from "../../services/order/order-service";
import "./order-page.scss";
import StringHelpers from "../../services/core/string-helpers";
import { OrderHelpers } from "../../services/order/order-helpers";
import { HubHelpers } from "../../services/hub/hub-helper";
import Member from "../../services/member/member";
import languageService from "../../services/core/language-service";
import compartmentSizeService from "../../services/hub/compartmentSize-service";
import "./orders-page.scss";
import { CompartmentSize } from "../../services/hub/compartmentSize";
import { PackagingOption } from "../../services/hub/packgingOption";
import packagingOptionService from "../../services/hub/packagingOption-service";
import { YesNoComponent } from "../../components/yesno-component";
import { ActionButtonComponent, DeleteButtonComponent } from "../../components/button-component";
import LoadingComponent from "../../components/loading-component";
import ToastHelpers from "../../services/core/toast-helpers";

import { CancelReservationMsgomponent } from "../../components/cancel-reservation-msg-component";
import { StateBadgeComponent } from "../../components/state-badge-component";
import Organization from "../../services/organization/organization";
import memberService from "../../services/member/member-service";
import paymentService from "../../services/payment/payment-service";
import { Payment } from "../../services/payment/payment";
import OrderHistory from "./order-details/order-history";
import OrderPaymentInformation from "./order-details/order-payment-information";
import OrderGeneral from "./order-details/order-general";
import OrderCustomer from "./order-details/order-customer";
import OrderReservation from "./order-details/order-reservation";
import OrderRequestOrganizationAddressModal from "./order-modals/order-request-organization-address-modal";
import DateHelpers from "../../services/core/date-helpers";

interface OrderPageProps {
  organization: Organization;
  member: Member;
}

interface IParamTypes {
  orderId: string;
}

export default function OrderPage(props: OrderPageProps) {
  const { organization, member } = props;
  const { orderId } = useParams<IParamTypes>();

  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<Order | null>(null);
  const [hub, setHub] = useState<Hub>(new Hub());
  const [compartmentSizes, setCompatmentSizes] = useState<Array<CompartmentSize>>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectSizeDisabled, setSelectSizeDisabled] = useState<boolean>(false);
  const [msgEditError, setMsgEditError] = useState<string | undefined>(undefined);
  const [packagingOption, setPackagingOption] = useState<PackagingOption | null>(null);
  const [isCancelOrder, setIsCancelOrder] = useState<boolean>(false);
  const [isCancelReservation, setIsCancelReservation] = useState<boolean>(false);
  const [itemIdInOpening, setItemIdInOpening] = useState<string | null>(null);
  const [isCarriedByCarrier, setIsCarriedByCarrier] = useState<boolean>(false);
  const [isRequestingCarrierPickup, setIsRequestingCarrierPickup] = useState<boolean>(false);
  const [isCancellingCarrierPickup, setIsCancellingCarrierPickup] = useState<boolean>(false);
  const [isCarrierPickupRequested, setIsCarrierPickupRequested] = useState<boolean>(false);
  const [canSendNotification, setCanSendNotification] = useState<boolean>(false);
  const [isSendingNotification, setIsSendingNotification] = useState<boolean>(false);
  const [isReturn, setIsReturn] = useState<boolean>(false);
  const [labelBlob, setLabelBlob] = useState<Blob | null>(null);
  const [isRequiresAddressMessage, setIsRequiresAddressMessage] = useState<boolean>(false);
  const [isDeleteTrackingNumber, setIsDeleteTrackingNumber] = useState<boolean>(false);
  const [isCompleteOrder, setIsCompleteOrder] = useState<boolean>(false);

  const [isPersonalStorage, setIsPersonalStorage] = useState<boolean>(false);
  const [isRequestingRefund, setIsRequestingRefund] = useState<boolean>(false);
  const [payment, setPayment] = useState<Payment | undefined>();

  const [itemHeight, setItemHeight] = useState<string | undefined>();
  const [itemWidth, setItemWidth] = useState<string | undefined>();
  const [itemDepth, setItemDepth] = useState<string | undefined>();
  const [itemWeight, setItemWeight] = useState<string | undefined>();
  const [orderItemId, setOrderItemId] = useState<string>("");
  const [trackingNumber, setTrackingNumber] = useState<string | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isOrgContributor, setIsOrgContributor] = useState<boolean>();

  const { t } = useTranslation();
  const language: string = languageService.getLanguage();

  useEffect(
    () => {
      if (loading && organization.id) {
        setIsAdmin(memberService.isOrganizationAdmin(member, organization.id));
        setIsOrgContributor(memberService.isOrganizationContributor(member, organization.id));
        packagingOptionService.getPackagingOptions().then((packagingOptions) => {
          orderService
            .getOrderById(organization.id, orderId)
            .then((order) => {
              setOrder(order);
              setPayment(order?.payments?.[0]);

              if (order) {
                setIsReturn(order.flow.toString() === "Return");
                setIsPersonalStorage(order.flow.toString() === "PersonalStorage");

                if (order.flow.toString() === "Return") {
                  setCanSendNotification(order.state === "New");
                } else if (order.flow.toString() === "PersonalStorage") {
                  setCanSendNotification(order.state !== "Cancelled" && order.state !== "Completed");
                } else {
                  setCanSendNotification(order.state === "ReadyForPickUp");
                }

                setIsCarriedByCarrier(order.carrierId ? true : false);

                if (order.carrierSubmissionReferenceNumber) setIsCarrierPickupRequested(true);

                if (order.hubId) {
                  hubService.getHubById(order.hubId).then((hub) => {
                    setHub(hub);

                    compartmentSizeService.getCompartmentSizes().then((compartmentSizes: Array<CompartmentSize>) => {
                      let compartmentSizeIdsOfHub = HubHelpers.getCompartmentSizeIds(hub);
                      let compartmentSizesOfHub = compartmentSizes?.filter((size) => compartmentSizeIdsOfHub.has(size.id));
                      setCompatmentSizes(compartmentSizesOfHub);
                    });

                    if (order.packagingOptionId) {
                      const option = packagingOptions.find((po) => po.id === order.packagingOptionId);

                      if (option) {
                        setPackagingOption(option);
                      }
                    }
                  });
                }
              }
            })
            .catch((error) => {
              ToastHelpers.error(t("order-page.error-refresh", { status: error?.response?.status }));
            })
            .finally(() => {
              setLoading(false);
            });
        });
      }
    },
    // eslint-disable-next-line
    [orderId, organization, itemIdInOpening]
  );

  useEffect(() => {
    const refreshInterval = setInterval(refresh, 5000);
    return () => clearInterval(refreshInterval);
  }, [loading]);

  const requestCarrierPickup = useCallback(
    async (order: Order, organization: Organization) => {
      try {
        if (OrderHelpers.canRequestCarrierPickup(order)) {
          if (requiresAddress(order, organization)) {
            setIsRequestingCarrierPickup(false);
            setIsRequiresAddressMessage(true);
            return;
          }

          setIsRequestingCarrierPickup(false);
          await orderService.requestCarrierPickup(order.organizationId.toString(), order.id);
          await refresh();
          setIsRequestingCarrierPickup(false);
        }
      } catch (error: any) {
        ToastHelpers.error(t("common.error-generic"));
      }
    },
    [refresh] // I think refresh needs to use callback too because otherwise, requestCarrierPickup will re-render every time the component renders
  );

  let listInfo = [];
  let customer = StringHelpers.getFullName(order?.customer?.firstname, order?.customer?.lastname);
  if (customer) {
    listInfo.push(customer);
  }

  let email = order?.customer?.email || "";
  if (email) {
    listInfo.push(email);
  }

  let phone = order?.customer?.phone || "";
  if (phone) {
    listInfo.push(phone);
  }

  let refId = order?.customer?.referenceId || "";
  if (refId) {
    listInfo.push(`${t("order-page.reference-number")}: <strong>${refId}</strong>`);
  }

  let customerInfoHtml = listInfo.join("<br/>");

  let carrierName = hub?.carriers?.find((c) => c.id === order?.carrierId)?.name;

  return (
    <div>
      {canShowOrderDetails() === true && (
        <div className="container page order-page">
          <div className="page-breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link className="" to="/orders">
                  {t("order-page.orderlink")}
                </Link>
              </li>
              <li className="breadcrumb-item active">{orderId}</li>
            </ul>
          </div>

          {!loading && !order && (
            <div className="text-center mt-4">
              <p>{t("order-page.no-order-message")}</p>
            </div>
          )}

          <div className="page-background-boxing shadow">
            <div className="header">
              <div className="d-flex flex-row">
                <h1 className="d-inline-block">{`${t("order-page.order")} #${orderId}`}</h1>
                {/* Complete order*/}
                {isAdmin && order?.flow?.toString() === "Deliver" && (
                  <button
                    type="button"
                    className="btn btn-danger d-inline-block ms-3 mt-3"
                    onClick={() => setIsCompleteOrder(true)}
                    disabled={itemIdInOpening ? true : false || !OrderHelpers.canCompleteOrder(order)}
                  >
                    {t("order-page.complete-order")}
                  </button>
                )}
              </div>
              <div className="order-state">
                {order && (
                  <StateBadgeComponent
                    state={order.state}
                    isPersonalStorage={order.flow.toString() === OrderFlowStringValue.PersonalStorage}
                  ></StateBadgeComponent>
                )}
              </div>
            </div>

            <LoadingComponent loading={loading} />

            {order && organization && orderId && (
              <>
                <div className="row">
                  <OrderCustomer
                    customerInfoHtml={customerInfoHtml}
                    hasCustomerContactInformation={Boolean(email || phone)}
                    canSendNotification={canSendNotification}
                    isFirstTimeNotification={isReturn || isPersonalStorage}
                    onSendNotification={() => setIsSendingNotification(true)}
                  />

                  <OrderGeneral
                    order={order}
                    hub={hub}
                    isOrgContributor={isOrgContributor}
                    timeZone={DateHelpers.UserTimeZone}
                    packagingOption={packagingOption}
                    carrierName={carrierName}
                  />

                  <OrderPaymentInformation
                    order={order}
                    payment={payment}
                    isPersonalStorage={isPersonalStorage}
                    timeZone={DateHelpers.UserTimeZone}
                  />
                </div>

                <OrderReservation
                  order={order}
                  orderId={orderId}
                  organization={organization}
                  hub={hub}
                  isAdmin={isAdmin}
                  isOrgContributor={isOrgContributor}
                  isCarriedByCarrier={isCarriedByCarrier}
                  isPersonalStorage={isPersonalStorage}
                  isReturn={isReturn}
                  itemIdInOpening={itemIdInOpening}
                  compartmentSizes={compartmentSizes}
                  onRefresh={refresh}
                  onTrackingNumberDelete={(orderItemId: string, trackingNumber: string) => {
                    setOrderItemId(orderItemId);
                    setTrackingNumber(trackingNumber);
                    setIsDeleteTrackingNumber(true);
                  }}
                  onCreateReservation={btnCreateReservation}
                  onOrderItemDelete={btnDeletOrderItem}
                  openDoor={openDoor}
                />

                <div className="mb-3">
                  {/* Request carrier pickup */}
                  {isCarriedByCarrier && (
                    <>
                      {!isCarrierPickupRequested && (
                        <ActionButtonComponent
                          title={t("order-page.request-carrier-pickup")}
                          onClick={() => setIsRequestingCarrierPickup(true)}
                          disabled={itemIdInOpening ? true : false || !OrderHelpers.canRequestCarrierPickup(order)}
                          className="btn btn-primary me-2"
                          icon={["fas", "paper-plane"]}
                        ></ActionButtonComponent>
                      )}

                      {isCarrierPickupRequested && (
                        <>
                          <ActionButtonComponent
                            title={t("order-page.cancel-carrier-pickup")}
                            onClick={() => setIsCancellingCarrierPickup(true)}
                            disabled={itemIdInOpening ? true : false || !OrderHelpers.canCancelCarrierPickup(order)}
                            className="btn btn-outline-primary me-2"
                            icon={["fas", "ban"]}
                          ></ActionButtonComponent>

                          <ActionButtonComponent
                            title={t("order-page.download-label")}
                            onClick={() => downloadLabelPdf(organization.id, orderId)}
                            disabled={itemIdInOpening ? true : false || !OrderHelpers.canCancelCarrierPickup(order)}
                            className="btn btn-outline-primary me-2"
                            icon={["fas", "tag"]}
                          ></ActionButtonComponent>
                        </>
                      )}
                    </>
                  )}

                  {/* delete order */}
                  {isPersonalStorage && payment && payment.amount > 0 ? (
                    <>
                      <ActionButtonComponent
                        title={t("order-page.request-refund")}
                        onClick={() => setIsRequestingRefund(true)}
                        disabled={!OrderHelpers.canRefundOrder(order)}
                        className="btn btn-outline-primary me-2"
                        icon={["fas", "credit-card"]}
                      ></ActionButtonComponent>
                    </>
                  ) : (
                    <>
                      <DeleteButtonComponent
                        title={t("order-page.delete-order")}
                        onClick={() => setIsCancelOrder(true)}
                        disabled={itemIdInOpening ? true : false || !OrderHelpers.canDeleteOrder(order)}
                      ></DeleteButtonComponent>
                    </>
                  )}
                </div>

                <OrderHistory order={order} member={member}></OrderHistory>
              </>
            )}
          </div>
        </div>
      )}

      {!isCarriedByCarrier && isEdit && (
        <div className="container page orders-page">
          <div className="col-12 col-xl-8 offset-md-1 offset-xl-2">
            <div className="card">
              <div className="card-header align-items-center">
                <div className="d-inline-block">
                  <h2>{t("order-page.edit-size-title")}</h2>
                </div>
                <button className="btn-close float-end" onClick={() => cancelEdit()}></button>
              </div>
              <div className="card-body">
                {compartmentSizes && organization.id && (
                  <table className="table table-hover row-clikable">
                    <tbody>
                      {compartmentSizes.map((compartmentSize: CompartmentSize) => {
                        return (
                          <tr
                            key={compartmentSize.id}
                            onClick={() => {
                              if (!selectSizeDisabled) createReservation(organization.id, orderId, compartmentSize.id);
                            }}
                          >
                            <td>
                              {compartmentSize.name.get(language)}: {compartmentSize.description.get(language)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}

                {msgEditError && <label className="form-label text-danger">{msgEditError}</label>}
              </div>
            </div>
          </div>
        </div>
      )}

      {isCarriedByCarrier && isEdit && (
        <div className="container page orders-page">
          <div className="col-12 col-sm-8 col-xl-6 offset-sm-2 offset-xl-3">
            <div className="card">
              <div className="card-header align-items-center">
                <div className="d-inline-block">
                  <h2>{t("order-page.add-item-title")}</h2>
                </div>
                <button className="btn-close float-end" onClick={() => cancelEdit()}></button>
              </div>
              <div className="card-body">
                {compartmentSizes && organization.id && (
                  <form onSubmit={(ev) => ev.preventDefault()}>
                    <label className="form-label">{t("order-page.item.enter-height")}</label>
                    <input
                      className="form-control"
                      value={itemHeight}
                      onChange={(ev) => setItemHeight(ev.target.value)}
                      required
                      autoFocus={true}
                    />
                    <label className="form-label mt-2">{t("order-page.item.enter-width")}</label>
                    <input className="form-control" value={itemWidth} onChange={(ev) => setItemWidth(ev.target.value)} required />
                    <label className="form-label mt-2">{t("order-page.item.enter-depth")}</label>
                    <input className="form-control" value={itemDepth} onChange={(ev) => setItemDepth(ev.target.value)} required />
                    <label className="form-label mt-2">{t("order-page.item.enter-weight")}</label>
                    <input className="form-control" value={itemWeight} onChange={(ev) => setItemWeight(ev.target.value)} required />
                    <button
                      className="btn btn-md btn-primary mt-3"
                      type="submit"
                      onClick={() => addItem(organization.id)}
                      disabled={selectSizeDisabled}
                    >
                      {t("order-page.item.add")}
                    </button>
                  </form>
                )}

                {msgEditError && <label className="form-label text-danger">{msgEditError}</label>}
              </div>
            </div>
          </div>
        </div>
      )}
      {isCancelOrder && organization && order && (
        <YesNoComponent
          headerMsg={t("order-page.msg-cancel-order")}
          onYesClick={() => cancelOrder(organization.id, order)}
          onNoClick={setIsCancelOrder}
        />
      )}

      {isCompleteOrder && organization && order && isAdmin && OrderHelpers.canCompleteOrder(order) && (
        <YesNoComponent
          headerMsg={t("order-page.msg-complete-order")}
          onYesClick={() => CompleteOrder(organization.id, order)}
          onNoClick={setIsCompleteOrder}
        />
      )}

      {isDeleteTrackingNumber && organization && order && orderItemId && trackingNumber && (
        <YesNoComponent
          headerMsg={t("order-page.msg-remove-tracking", { trackingNumber: trackingNumber })}
          onYesClick={() => removeTrackingNumber(organization.id, order, orderItemId, trackingNumber)}
          onNoClick={setIsDeleteTrackingNumber}
        />
      )}

      {isRequestingRefund && organization && order && (
        <YesNoComponent
          headerMsg={t("order-page.msg-refund-order")}
          onYesClick={() => refundOrder(organization.id, order)}
          onNoClick={setIsRequestingRefund}
        />
      )}

      {isRequestingCarrierPickup && order && (
        <YesNoComponent
          headerMsg={t("order-page.msg-requesting-carrier-pickup")}
          onYesClick={() => requestCarrierPickup(order, organization)}
          onNoClick={setIsRequestingCarrierPickup}
        />
      )}

      {isCancellingCarrierPickup && order && (
        <YesNoComponent
          headerMsg={t("order-page.msg-cancelling-carrier-pickup")}
          onYesClick={() => cancelCarrierPickup(order)}
          onNoClick={setIsCancellingCarrierPickup}
        />
      )}

      {isSendingNotification && organization && orderId && (
        <YesNoComponent
          headerMsg={isReturn ? t("order-page.msg-send-return-notification") : t("order-page.msg-send-notification")}
          onYesClick={() => sendNotification(organization.id, orderId)}
          onNoClick={setIsSendingNotification}
        />
      )}

      {isRequiresAddressMessage && organization && order && (
        <OrderRequestOrganizationAddressModal
          isAdmin={isAdmin}
          organization={organization}
          order={order}
          onRequiredAddressMessageChange={setIsRequiresAddressMessage}
          requestCarrierPickup={requestCarrierPickup}
        />
      )}

      {isCancelReservation && organization && orderId && order && (
        <CancelReservationMsgomponent
          headerMsg={t("order-page.cancel-reservation.msg-cancel-order-openDoor")}
          onYesClick={() => openDoorForCancellation(organization.id, orderId, orderItemId)}
          onNoClick={setIsCancelReservation}
        ></CancelReservationMsgomponent>
      )}
    </div>
  );

  function downloadLabelPdf(organizationId: string, orderId: string) {
    const download = (labelBlob: Blob) => {
      const url = window.URL.createObjectURL(labelBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Label-${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    };

    if (labelBlob) {
      download(labelBlob);
      return;
    }

    orderService
      .getAsset(organizationId, orderId, "1") //For now, there's always only one asset
      .then((res: Blob | null) => {
        if (res) {
          setLabelBlob(res);
          download(res);
        }
      })
      .catch((error: any) => {
        ToastHelpers.error(t("order-page.error-label-download"));
      });
  }

  function sendNotification(organizationId: string, orderId: string) {
    if (canSendNotification) {
      orderService.sendReadyForPickupNotification(organizationId, orderId).catch((error: any) => {
        //ToastHelpers.error(t("order-page.error-refresh", { status: error?.response?.status }));
      });
      setIsSendingNotification(false);
    }
  }

  async function createReservation(organizationId: string, orderId: string, sizeId: string) {
    setSelectSizeDisabled(true);
    try {
      const response = await orderService.createOrderItem(organizationId, orderId, sizeId);
      if (response) {
        setIsEdit(false);
        await refresh();
        setOrderItemId("");
        setMsgEditError(undefined);
      } else {
        setMsgEditError(t("order-page.error-edit-size"));
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        setMsgEditError(t("order-page.error-edit-size"));
      } else {
        ToastHelpers.error(t("common.error-generic"));
      }
    }
    setSelectSizeDisabled(false);
  }

  async function cancelEdit() {
    setMsgEditError(undefined);
    setIsEdit(false);
  }

  async function cancelOrder(organizationId: string, order: Order) {
    try {
      if (OrderHelpers.canDeleteOrder(order)) {
        setIsCancelOrder(false);
        await orderService.cancelOrder(organizationId, order.id);
        await refresh();
        setIsCancelOrder(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        ToastHelpers.error(t("order-page.error-cancel-order-item"));
      } else {
        ToastHelpers.error(t("common.error-generic"));
      }
    }
  }

  async function CompleteOrder(organizationId: string, order: Order) {
    try {
      if (isAdmin && OrderHelpers.canCompleteOrder(order)) {
        if (order.carrierSubmissionReferenceNumber && order.carrierId) {
          await orderService.cancelCarrierPickup(organizationId, order.id);
        }

        await orderService.completeBopilOrder(organizationId, order.id);
        await refresh();
      }
    } catch (error: any) {
      if (error?.response?.status === 400 && error?.response?.data === "CompletedDeleted") {
        ToastHelpers.error(t("order-page.error-complete-order"));
      } else {
        ToastHelpers.error(t("common.error-generic"));
      }
    }
    setIsCompleteOrder(false);
  }

  async function removeTrackingNumber(organizationId: string, order: Order, orderItemId: string, trackingNumber: string) {
    let orderItem = OrderHelpers.getOrderItem(order, orderItemId);
    let trackingNumbers: Array<any> = [];
    orderItem?.reservation.trackingNumbers.forEach((tracking) => {
      if (trackingNumber !== tracking) {
        trackingNumbers.push(tracking);
      }
    });
    await orderService
      .updateOrderItem(organizationId, orderId, orderItemId, trackingNumbers, true)
      .catch((error: any) => ToastHelpers.error(t("order-page.error-delete-tracking-number")));
    await refresh();
    setIsDeleteTrackingNumber(false);
  }

  async function refundOrder(organizationId: string, order: Order) {
    try {
      if (OrderHelpers.canRefundOrder(order)) {
        setIsRequestingRefund(false);

        if (order.payments) {
          await paymentService.refundPayment(order.payments[0].id);
          await refresh();
        }

        setIsRequestingRefund(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        ToastHelpers.error(t("order-page.error-cancel-order-item"));
      } else {
        ToastHelpers.error(t("common.error-generic"));
      }
    }
  }

  async function cancelCarrierPickup(order: Order) {
    try {
      if (OrderHelpers.canCancelCarrierPickup(order)) {
        setIsCancellingCarrierPickup(false);

        await orderService.cancelCarrierPickup(order.organizationId.toString(), order.id);
        await refresh();
        setIsCancellingCarrierPickup(false);
      }
    } catch (error: any) {
      ToastHelpers.error(t("common.error-generic"));
    }
  }

  async function addItem(organizationId: string) {
    let height = Number.parseFloat(itemHeight ? itemHeight.valueOf() : "");
    let width = Number.parseFloat(itemWidth ? itemWidth.valueOf() : "");
    let depth = Number.parseFloat(itemDepth ? itemDepth.valueOf() : "");
    let weight = Number.parseFloat(itemWeight ? itemWeight.valueOf() : "");

    if (!height || !width || !depth || !weight) {
      ToastHelpers.error(t("order-page.item.error-size"));
      return;
    }

    setSelectSizeDisabled(true);
    var sizeId = "";
    for (let i = 0; i < compartmentSizes.length; i++) {
      let cSize = compartmentSizes[i];

      if (
        (cSize.height >= height && ((cSize.width >= width && cSize.depth >= depth) || (cSize.depth >= width && cSize.width >= depth))) ||
        (cSize.height >= width && ((cSize.width >= height && cSize.depth >= depth) || (cSize.depth >= height && cSize.width >= depth))) ||
        (cSize.height >= depth && ((cSize.width >= width && cSize.depth >= height) || (cSize.depth >= width && cSize.width >= height)))
      ) {
        sizeId = cSize.id;
        break;
      }
    }

    if (!sizeId) {
      setMsgEditError(t("order-page.error-add-item"));
      setSelectSizeDisabled(false);
      return;
    }

    try {
      const response = await orderService.createOrderItem(organizationId, orderId, sizeId, height, width, depth, weight);
      if (response) {
        setIsEdit(false);
        await refresh();
        setOrderItemId("");
        setMsgEditError(undefined);
        setSelectSizeDisabled(false);
      } else {
        setMsgEditError(t("order-page.error-add-item"));
        setSelectSizeDisabled(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        setMsgEditError(t("order-page.error-add-item"));
      } else {
        ToastHelpers.error(t("common.error-generic"));
      }
    }
  }

  async function openDoorForCancellation(organizationId: string, orderId: string, orderItemId: string): Promise<void> {
    setItemIdInOpening(orderItemId);
    setIsCancelReservation(false);

    await orderService
      .openDoor(organizationId, orderId, orderItemId, "Recuperate", null)
      .then(async () => {
        await orderService.cancelOrderItem(organizationId, orderId, orderItemId);
        await refresh();
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("order-page.error-opendoor"));
        } else if (error?.response?.status === 424) {
          ToastHelpers.error(t("order-page.error-opendoor-424"));
        } else {
          ToastHelpers.error(t("common.error-generic"));
        }
      })
      .finally(async () => {
        await setItemIdInOpening(null);
      });
  }

  function canShowOrderDetails() {
    return (
      !isEdit &&
      !isCancelOrder &&
      !isCompleteOrder &&
      !isCancelReservation &&
      !isRequestingCarrierPickup &&
      !isCancellingCarrierPickup &&
      !isSendingNotification &&
      !isRequiresAddressMessage &&
      !isRequestingRefund &&
      !isDeleteTrackingNumber
    );
  }

  function btnCreateReservation() {
    if (HubHelpers.isHubOnline(hub.state)) {
      setSelectSizeDisabled(false);
      setIsEdit(true);
    } else {
      ToastHelpers.error(t("create-order-page.error-hub-offline"));
    }
  }

  function btnDeletOrderItem(organizationId: string, orderId: string, orderItemId: string, reservationState: string) {
    if (reservationState === "New") {
      deletOrderItem(organizationId, orderId, orderItemId);
    } else if (reservationState === "ReadyForPickUp") {
      setIsCancelReservation(true);
      setOrderItemId(orderItemId);
    }
  }

  async function deletOrderItem(organizationId: string, orderId: string, orderItemId: string): Promise<void> {
    try {
      await orderService.deleteOrderItem(organizationId, orderId, orderItemId);
      setOrderItemId("");
      setIsCancelReservation(false);
      await refresh();
    } catch (error: any) {
      if (error?.response?.status === 409) {
        ToastHelpers.error(t("order-page.error-delete-order-item"));
      } else {
        ToastHelpers.error(t("common.error-generic"));
      }
    }
  }

  async function openDoor(organizationId: string, orderId: string, orderItemId: string, state: string): Promise<void> {
    let reason: string;
    if (isReturn) {
      if (state === "ReadyForPickUp") {
        reason = "Pickup";
      } else {
        return;
      }
    } else {
      if (state === "New") {
        reason = "Dropoff";
      } else if (state === "PackagingReadyForPickUp") {
        reason = "PackagingPickup";
      } else {
        return;
      }
    }

    setItemIdInOpening(orderItemId);

    orderService
      .openDoor(organizationId, orderId, orderItemId, reason, null)
      .then(async () => {
        await refresh();
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("order-page.error-opendoor"));
        } else if (error?.response?.status === 424) {
          ToastHelpers.error(t("order-page.error-opendoor-424"));
        } else {
          ToastHelpers.error(t("common.error-generic"));
        }
      })
      .finally(() => {
        setItemIdInOpening(null);
      });
  }

  async function refresh() {
    if (!loading && organization.id) {
      try {
        const order = await orderService.getOrderById(organization.id, orderId);
        if (order) {
          setOrder(order);

          if (isReturn) {
            setCanSendNotification(order.state === "New");
          } else if (isPersonalStorage) {
            setCanSendNotification(order.state !== "Completed" && order.state !== "Cancelled");
          } else {
            setCanSendNotification(order.state === "ReadyForPickUp");
          }

          if (order?.carrierSubmissionReferenceNumber) {
            setIsCarrierPickupRequested(true);
          } else {
            setIsCarrierPickupRequested(false);
            setLabelBlob(null);
          }
        }
      } catch (error) {}
    }
  }
}

function requiresAddress(order: Order, organization: Organization): boolean {
  if (order.organizationId.toString() !== organization.id) {
    return false;
  }

  return (
    organization.city === "" ||
    organization.city === null ||
    organization.country === "" ||
    organization.country === null ||
    organization.postalCode === "" ||
    organization.postalCode === null ||
    organization.street === "" ||
    organization.street === null ||
    organization.streetNumber === "" ||
    organization.streetNumber === null ||
    organization.state === "" ||
    organization.state === null ||
    organization.country === "" ||
    organization.country === null
  );
}
