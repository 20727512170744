import { useEffect, useState } from "react";
import { RequestState } from "../core/request-state";
import ToastHelpers from "../core/toast-helpers";
import { MyHub } from "./hub";
import hubService from "./hub-service";

interface IProps {
  hubId: string;
  onErrorNotificationMessage: string;
}

function useMyHub(props: IProps) {
  const { hubId, onErrorNotificationMessage } = props;

  const [requestState, setRequestState] = useState<RequestState>(RequestState.Loading);
  const [error, setError] = useState<string>("");
  const [hub, setHub] = useState<MyHub | null>(null);

  useEffect(
    () => {
      refresh();
    },
    // eslint-disable-next-line
    []
  );

  function refresh() {
    hubService
      .getMyHubById(hubId)
      .then((hub) => {
        setHub(hub);
        setRequestState(RequestState.Success);
      })
      .catch((e) => {
        setRequestState(RequestState.Failure);
        setError(e);

        if (onErrorNotificationMessage) {
          ToastHelpers.error(onErrorNotificationMessage);
        }
      });
  }

  return { hub, requestState, error, refresh };
}

export default useMyHub;
