import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./yesno-component.scss";

interface ValidationProps {
  headerMsg: string;
  children?: React.ReactNode;
  onYesClick: Function;
  onNoClick: Function;
  isDangerousChoice?: boolean;
}

export function YesNoComponent(props: ValidationProps) {
  const { t } = useTranslation();

  const yesButtonClass = props.isDangerousChoice ? "btn btn-risky me-2" : "btn btn-outline-primary me-2";

  return (
    <div className="container page">
      <div className="col-12 col-xl-6 offset-xl-3">
        <div className="card">
          <div className="card-header align-items-center">
            <div className="d-inline-block">
              <h2>{props.headerMsg}</h2>{" "}
            </div>
          </div>
          {props.children && <div className="card-body">{props.children}</div>}
          <div className="card-footer">
            <div className="align-items-center">
              <button className={yesButtonClass} onClick={() => props.onYesClick()}>
                {props.isDangerousChoice && <FontAwesomeIcon className="icon" icon={["fas", "exclamation-triangle"]} />}
                {t("common.yes")}
              </button>

              <button className="btn btn-outline-primary me-2" onClick={() => props.onNoClick(false)}>
                {t("common.no")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
