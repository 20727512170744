import ServicesApiBaseService from "../services-api-base-service";
import Member from "./member";
import { MyReservations } from "./my-reservations";

class MemberService extends ServicesApiBaseService {
  async getMemberMe(): Promise<Member | undefined> {
    return await this.getObject<Member>(`${this.apiUrl}/members/me`);
  }

  async getMembers(organizationId: String): Promise<Array<Member>> {
    const members = await this.getObject<Array<Member>>(`${this.apiUrl}/admin/get-all-members?organizationId=${organizationId}`);
    return members || [];
  }

  async createMember(member: Member): Promise<Member | undefined> {
    return await this.postObject<Member, Member>(`${this.apiUrl}/members/me`, member);
  }

  async createContributor(member: Member): Promise<Member | undefined> {
    return await this.postObject<Member, Member>(`${this.apiUrl}/admin/create-member`, member);
  }

  async updateMemberMe(member: Member): Promise<void> {
    await this.putObject<Member>(`${this.apiUrl}/members/me`, member);
  }

  async addMemberRole(email: String, entityId: String, permission: String, entityType: String): Promise<any> {
    var res = await this.postObject<any, any>(`${this.apiUrl}/admin/add-member-role`, {
      status: "Active",
      email: email,
      entityId: entityId,
      permission: permission,
      entityType: entityType,
    });
    return res;
  }

  async updateMemberRole(email: String, entityId: String, permission: String, entityType: String, status: String): Promise<any> {
    var res = await this.postObject<any, any>(`${this.apiUrl}/admin/change-member-role`, {
      Active: status === "Active",
      Email: email,
      EntityId: entityId,
      Permission: permission,
      EntityType: entityType,
    });
    return res;
  }

  isLocketgoAdmin(member: Member | null): boolean {
    if (!member) {
      return false;
    }

    for (let i = 0; i < member.associatedHubs?.length; i++) {
      if (member.associatedHubs[i].roles.includes("LocketgoAdmin")) {
        return true;
      }
    }

    return false;
  }

  isLocketgoHubAdmin(member: Member | null): boolean {
    if (!member) {
      return false;
    }

    for (let i = 0; i < member.associatedHubs?.length; i++) {
      if (member.associatedHubs[i].roles.includes("LocketgoHubAdmin") || member.associatedHubs[i].roles.includes("LocketgoAdmin")) {
        return true;
      }
    }

    return false;
  }

  isOrganizationAdmin(member: Member | null, organizationId: string | undefined): boolean {
    if (this.isLocketgoAdmin(member)) {
      return true;
    }

    if (!member || !organizationId || organizationId === undefined) {
      return false;
    }

    const organisation = member.associatedOrganizations.find((org) => org.entity?.id === organizationId);

    if (organisation) {
      if (organisation.roles.includes("Admin") && organisation.entity?.id === organizationId) {
        return true;
      }
    }

    return false;
  }

  isOrganizationContributor(member: Member | null, organizationId?: string | undefined): boolean {
    if (this.isLocketgoAdmin(member)) {
      return true;
    }

    if (!member) {
      return false;
    }

    if (!organizationId || organizationId === undefined) {
      for (let i = 0; i < member.associatedOrganizations?.length; i++) {
        if (member.associatedOrganizations[i].roles.some((role) => role === "Contributor" || role === "Admin")) {
          return true;
        }
      }

      return false;
    }

    for (let i = 0; i < member.associatedOrganizations?.length; i++) {
      const associated = member.associatedOrganizations[i];

      if (associated.entity?.id !== organizationId) {
        continue;
      }

      if (associated.roles.includes("Admin") || associated.roles.includes("Contributor")) {
        return true;
      }
    }

    return false;
  }

  isConcierge(member: Member | null): boolean {
    if (!member) return false;

    for (let i = 0; i < member.associatedHubs?.length; i++) {
      if (member.associatedHubs[i].roles.includes("Concierge")) {
        return true;
      }
    }

    return false;
  }

  isHubContributor(member: Member | null, hubId?: string | undefined): boolean {
    if (this.isLocketgoAdmin(member) || this.isLocketgoHubAdmin(member)) {
      return true;
    }

    if (!member) {
      return false;
    }

    if (!hubId || hubId === undefined) {
      for (let i = 0; i < member.associatedHubs?.length; i++) {
        if (
          member.associatedHubs[i].roles.some(
            (role) => role === "Contributor" || role === "Admin" || role === "LocketgoAdmin" || role === "LocketgoHubAdmin"
          )
        ) {
          return true;
        }
      }

      return false;
    }

    for (let i = 0; i < member.associatedHubs?.length; i++) {
      if (member.associatedHubs[i].entity?.id !== hubId) {
        continue;
      }

      if (
        member.associatedHubs[i].roles.some(
          (role) => role === "Contributor" || role === "Admin" || role === "LocketgoAdmin" || role === "LocketgoHubAdmin"
        )
      ) {
        return true;
      }
    }

    return false;
  }

  async getMyReservations(token: string | null): Promise<MyReservations | undefined> {
    if (token) {
      return await this.getObject<MyReservations>(`${this.apiUrl}/members/me/my-reservations?token=${token}`);
    }

    return await this.getObject<MyReservations>(`${this.apiUrl}/members/me/my-reservations`);
  }
}

const memberService = new MemberService();
export default memberService;
