import { Order, OrderFlowStringValue } from "./order";
import { OrderActivity } from "./order-activity";
import { OrderItem } from "./order-item";
import { ReservationState } from "./ReservationState";

export class OrderHelpers {
  static getOrderLockers(order: Order): string {
    let lockers = "";
    order.items.forEach((orderItem) => {
      if (orderItem?.reservation) {
        let groupId = orderItem?.reservation?.groupId;
        let compartmentNumber = orderItem?.reservation?.compartmentNumber;

        if (groupId || compartmentNumber) {
          if (lockers.length > 0) {
            lockers += ", ";
          }
          lockers += /^\d+$/.test(compartmentNumber) ? compartmentNumber : groupId + compartmentNumber;
        }
      }
    });
    //console.log(lockers);
    return lockers;
  }

  static getOrderStateStyle(state: string, isPersonalStorage: boolean): string {
    if (isPersonalStorage) {
      return this.getPersonalStorageOrderStateBadgeStyle(state);
    } else {
      return this.getBopilOrderStateBadgeStyle(state);
    }
  }

  static getBopilOrderStateBadgeStyle(state: string): string {
    switch (state) {
      case "LoadingStarted":
      case "ReadyForPickUp":
      case "PackagingReadyForPickUp":
      case "PackagingPickUpStarted":
      case "PickUpStarted":
      case "PartiallyCompleted":
        return "badge badge-outline text-warning";
      case "Completed":
        return "badge badge-outline text-muted";
      case "NoReservation":
        return "badge badge-outline text-dark";
      case "Cancelled":
        return "badge badge-outline text-danger";
      default:
        return "badge badge-outline text-info";
    }
  }

  static getPersonalStorageOrderStateBadgeStyle(state: string): string {
    switch (state) {
      case "LoadingStarted":
      case "ReadyForPickUp":
        return "badge badge-outline text-warning";
      case "Completed":
        return "badge badge-outline text-muted";
      case "Cancelled":
        return "badge badge-outline text-danger";
      default:
        return "badge badge-outline text-success";
    }
  }

  static getActivityDescription(activity: OrderActivity, language: string): string {
    if (!activity.description) {
      return "";
    }
    const value = activity.description[language];
    if (!value) {
      return "";
    }
    return value;
  }

  static getOrderItem(order: Order, orderItemId: string): OrderItem | undefined {
    return order.items.find((item) => item.id === orderItemId);
  }

  static canEditReservation(state: string): boolean {
    return state === "New" || state === null;
  }

  static canRefundOrder(order: Order): boolean {
    if (
      !order ||
      !order.payments ||
      order.flow.toString() !== "PersonalStorage" ||
      order.deleted ||
      order.payments.every((p) => p.state === "Completed")
    ) {
      return false;
    }

    return true;
  }

  static isRefundedOrder(order: Order): boolean {
    if (!order || !order.deleted || !order.payments || !order.payments.length) {
      return false;
    }

    for (let i = 0; i < order.payments.length; i++) {
      if (order.payments[i].state !== "Refunded") {
        return false;
      }
    }

    return true;
  }

  static canDeleteOrder(order: Order): boolean {
    if (!order) {
      return false;
    }

    if (order.carrierSubmissionReferenceNumber || order?.deleted || (order.state !== "New" && order.state !== "NoReservation")) {
      return false;
    }

    let data = order.items.find((orderItem) => orderItem.reservation.state !== ReservationState.New);
    return data === null || data === undefined;
  }

  static canCompleteOrder(order: Order): boolean {
    if (!order) {
      return false;
    }

    return order.deleted != 1 && order.flow.toString() === "Deliver" && order.state !== "Completed" && order.items.length !== 0;
  }

  static inOpenedState(state: string): boolean {
    return state === "LoadingStarted" || state === "PickUpStarted" || state === "PackagingPickUpStarted";
  }

  static trackingNumbersEditable(order: Order | null): boolean {
    if (!order || order.carrierSubmissionReferenceNumber || order.deleted) {
      return false;
    }

    if (order.flow.toString() === OrderFlowStringValue.PersonalStorage) {
      return order.items.some(
        (orderItem: OrderItem) =>
          orderItem.reservation &&
          orderItem.reservation.state !== ReservationState.Completed &&
          orderItem.reservation.state !== ReservationState.Canceled
      );
    } else {
      if (order.state && order.state !== "NoReservation" && order.state !== "New") {
        return false;
      }
      return order.items.some((orderItem: OrderItem) => orderItem.reservation && orderItem.reservation.state === ReservationState.New);
    }
  }

  static editable(order: Order | null): boolean {
    if (!order) {
      return false;
    }

    if (
      order.carrierSubmissionReferenceNumber ||
      (order.state && order.state !== "NoReservation" && order.state !== "New") ||
      order.deleted
    ) {
      return false;
    }

    let data = order.items.find((orderItem: OrderItem) => orderItem.reservation && orderItem.reservation.state !== ReservationState.New);
    return data === null || data === undefined;
  }

  static canRequestCarrierPickup(order: Order | null): boolean {
    if (!order || order.carrierSubmissionReferenceNumber || order.state !== "New" || order.deleted) {
      return false;
    }

    return !this.hasNonNewOrderItem(order);
  }

  static canCancelCarrierPickup(order: Order | null): boolean {
    if (!order || !order.carrierSubmissionReferenceNumber || (order.state !== "New" && order.state !== "NoReservation")) {
      return false;
    }

    return !this.hasNonNewOrderItem(order);
  }

  static hasNonNewOrderItem(order: Order | null): boolean {
    let data = order?.items.find((orderItem: OrderItem) => orderItem.reservation && orderItem.reservation.state !== ReservationState.New);
    return data !== null && data !== undefined;
  }
}
