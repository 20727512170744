import React, { useState, useEffect } from "react";
import Microsite from "../services/micro-site/microsite";
import languageService from "../services/core/language-service";

interface Props {
  setTargetMicrosite: Function;
  micrositesList: Array<Microsite>;
  targetMicrosite: Microsite | undefined;
}

function MicrositeDropdown(props: Props) {
  const { setTargetMicrosite, micrositesList, targetMicrosite } = props;

  const language: string = languageService.getLanguage();
  const handleMicrositeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMicrosite = micrositesList.find((microsite) => microsite.name.get(language) === event.target.value);
    setTargetMicrosite(selectedMicrosite);
  };

  return (
    <div className="d-flex justify-content-center align-items-center bg-light">
      <select onChange={handleMicrositeChange} className="form-select" aria-label="Microsite selection">
        {micrositesList.map((microsite) => (
          <option key={microsite.id} value={microsite.name.get(language)} selected={targetMicrosite?.id === microsite.id}>
            {microsite.name.get(language)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default MicrositeDropdown;
