import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import "./i18n.ts"; // import i18n (needs to be bundled ;))
import "bootstrap";
import { MsalProvider } from "@azure/msal-react";
import msalInstance from "./services/auth/msal-instance";
import "./styles/index.scss";
/* 
  Font Awesome icons that could be used in the solution
  https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react
*/
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBars,
  faBox,
  faCheckCircle,
  faCircle,
  faClock,
  faCog,
  faEdit,
  faExclamationTriangle,
  faFileExport,
  faGlobe,
  faInfo,
  faInfoCircle,
  faLock,
  faLockOpen,
  faMapMarkerAlt,
  faMinusCircle,
  faPen,
  faPlus,
  faSearch,
  faSpinner,
  faSquare,
  faSync,
  faTrashAlt,
  faUser,
  faUserLock,
  faUsers,
  faPaperPlane,
  faBan,
  faBell,
  faCreditCard,
  faTag,
  faFileInvoiceDollar,
  faExternalLinkSquareAlt,
  faArrowLeft,
  faCube,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare as faSquareRegular } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import WaitingComponent from "./components/waiting-component";
import { BrowserRouter } from "react-router-dom";

library.add(
  faBox as IconDefinition,
  faUser as IconDefinition,
  faUsers as IconDefinition,
  faPlus as IconDefinition,
  faMapMarkerAlt as IconDefinition,
  faPen as IconDefinition,
  faTrashAlt as IconDefinition,
  faAngleDown as IconDefinition,
  faAngleUp as IconDefinition,
  faCircle as IconDefinition,
  faLock as IconDefinition,
  faLockOpen as IconDefinition,
  faCog as IconDefinition,
  faSearch as IconDefinition,
  faSpinner as IconDefinition,
  faAngleLeft as IconDefinition,
  faAngleRight as IconDefinition,
  faAngleDoubleLeft as IconDefinition,
  faAngleDoubleRight as IconDefinition,
  faCheckCircle as IconDefinition,
  faClock as IconDefinition,
  faMinusCircle as IconDefinition,
  faInfo as IconDefinition,
  faExclamationTriangle as IconDefinition,
  faBars as IconDefinition,
  faSync as IconDefinition,
  faSquare as IconDefinition,
  faSquareRegular as IconDefinition,
  faGlobe as IconDefinition,
  faInfoCircle as IconDefinition,
  faEdit as IconDefinition,
  faUserLock as IconDefinition,
  faPaperPlane as IconDefinition,
  faBan as IconDefinition,
  faBell as IconDefinition,
  faEdit as IconDefinition,
  faFileExport as IconDefinition,
  faCreditCard as IconDefinition,
  faTag as IconDefinition,
  faAngleRight as IconDefinition,
  faFileInvoiceDollar as IconDefinition,
  faExternalLinkSquareAlt as IconDefinition,
  faArrowLeft as IconDefinition,
  faCube as IconDefinition
);

moment.locale("fr", {
  months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"),
  monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
  monthsParseExact: true,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd'hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

function AppLoadingComponent() {
  return (
    <div className="container page">
      <div className="app">
        <div className="loader">
          <WaitingComponent />
        </div>
      </div>
    </div>
  );
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <Suspense fallback={<AppLoadingComponent />}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MsalProvider>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
