import { Line } from "react-chartjs-2";
import ChartProps from "./chart-props";

export default function ChartLine(props: ChartProps) {
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        tension: 0.2,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 2,
        backgroundColor: "rgba(54, 162, 235, 0)",
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        grid: { display: false, drawTicks: true },
      },
      y: {
        display: true,
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
}
