import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export function DoorOpeningGifComponent() {
  const { t } = useTranslation();

  return (
    <>
      <span>
        <img className="opening-image" src="/locketgo-glyph-spin.gif" alt="" /> {t("order-page.opening")}
      </span>
    </>
  );
}

export function DoorOpenedComponent() {
  const { t } = useTranslation();

  return (
    <>
      <FontAwesomeIcon icon={["fas", "lock-open"]} />
      <span className="ms-2">{t("order-page.opened")}</span>
    </>
  );
}

interface OpenDoorProps {
  onClick: Function;
  disabled: boolean;
  displayIcon: boolean;
}
OpenDoorComponent.defaultProps = {
  disabled: false,
  displayIcon: true,
};
export function OpenDoorComponent(props: OpenDoorProps) {
  const { t } = useTranslation();

  return (
    <>
      <button className="btn btn-primary text-nowrap" onClick={() => props.onClick()} disabled={props.disabled}>
        {props.displayIcon && <FontAwesomeIcon icon={["fas", "lock"]} />}
        <span className="ms-2">{t("order-page.btnOpen")}</span>
      </button>
    </>
  );
}

interface CreateReservationProps {
  onClick: Function;
  title: string;
  disabled: boolean;
}

export function CreateReservationComponent(props: CreateReservationProps) {
  return (
    <button className="btn-reserve-locker mt-3 mb-3 btn btn-primary" onClick={() => props.onClick()} disabled={props.disabled}>
      <FontAwesomeIcon className="me-2" icon={["fas", "plus"]} />
      {props.title}
    </button>
  );
}

interface NoReservationComponentProps {
  title: string;
}

export function NoReservationComponent(props: NoReservationComponentProps) {
  return (
    <>
      <tr key={"noreservation"}>
        <td colSpan={9} style={{ textAlign: "center" }}>
          {props.title}
        </td>
      </tr>
    </>
  );
}
