import { FormEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import memberService from "../../services/member/member-service";
import Organization from "../../services/organization/organization";
import Member from "../../services/member/member";
import { Hub } from "../../services/hub/hub";
import hubService from "../../services/hub/hub-service";
import OrganizationHubAssociation from "../../services/organization/organization-hub-association";
import { InputComponent } from "../../components/input";
import organizationService from "../../services/member/organization-service";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import WaitingComponent from "../../components/waiting-component";

type Props = {
  member: Member;
  changeMember: any;
};

export default function CreateOrganizationPage(props: Props) {
  const { member, changeMember } = props;
  const [hubs, setHubs] = useState<Array<Hub>>([]);
  const history = useHistory();
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);

  const [phone, setPhone] = useState<string>(member?.phone || "");
  const [phoneExtension, setPhoneExtension] = useState<number | undefined>(member?.phoneExtension);
  const [phoneError, setPhoneError] = useState<string>();
  const [phoneExtentionError, setPhoneExtensionError] = useState<string>();

  const [orgName, setOrgName] = useState<string>("");
  const [orgAddressStreetNumber, setOrgAddressStreetNumber] = useState<string>("");
  const [orgAddressStreet, setOrgAddressStreet] = useState<string>("");
  const [orgAddressCity, setOrgAddressCity] = useState<string>("");
  const [orgAddressPostalCode, setOrgAddressPostalCode] = useState<string>("");
  const [orgAddressState, setOrgAddressState] = useState<string>("");
  const [orgAddressCountry, setOrgAddressCountry] = useState<string>("");
  const [orgAssociatedHubs, setOrgAssociatedHubs] = useState<Array<OrganizationHubAssociation>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    hubService.getHubs().then((hubs) => {
      setHubs(hubs);
    });
  }, []);

  const optsNextMarchantOrg: any = {};
  if (!orgName || orgAssociatedHubs.length === 0) {
    optsNextMarchantOrg["disabled"] = "disabled";
  }

  const optsNextMarchantMember: any = {};
  if (!phone) {
    optsNextMarchantMember["disabled"] = "disabled";
  }

  if (loading) {
    return <WaitingComponent />;
  }

  return (
    <div className="container pt-3">
      <div className="row mt-5">
        <div className="col-12 offset-lg-2 col-lg-8">
          {step === 1 && (
            <div className="card">
              <div className="card-header align-items-center">
                <div className="d-inline-block">
                  <h2>Create Organization</h2>
                </div>
              </div>

              <div className="card-body">
                <p>{t("create-org.creation-message1")}</p>
                <p>{t("create-org.creation-message2")}</p>
                <button className="btn btn-outline-primary me-2" onClick={onPreviousStep}>
                  {t("common.previous")}
                </button>
                <button className="btn btn-primary" onClick={onNextStep}>
                  {t("create-org.start-creation")}
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="card">
              <div className="card-header align-items-center">
                <div className="d-inline-block">
                  <h2>{t("create-org.finalize-profile")}</h2>
                </div>
              </div>

              <div className="card-body">
                <p>Put the phone number of the person in charge of that Organization</p>
                <div className="mb-3 row">
                  <div className="col-8">
                    <PhoneFormComponent
                      value={phone}
                      setValue={setPhone}
                      onMsgErrorChanged={setPhoneError}
                      msgError={phoneError}
                      label={t("create-merchant-org.enter-phone")}
                      required={false}
                    />
                  </div>
                  <div className="col-4">
                    <InputComponent
                      labelText={t("member-profile.extension")}
                      regExp={/^[0-9]*$/}
                      type="extension"
                      value={phoneExtension ? phoneExtension.toString() : ""}
                      setValue={(value: number) => setPhoneExtension(value)}
                      msgError={phoneExtentionError}
                      onMsgErrorChanged={setPhoneExtensionError}
                      required={false}
                    />
                  </div>
                </div>

                <button className="btn btn-outline-primary me-2" onClick={onPreviousStep}>
                  {t("common.previous")}
                </button>
                <button className="btn btn-primary" {...optsNextMarchantMember} onClick={onNextStep}>
                  {t("common.next")}
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="card">
              <div className="card-header align-items-center">
                <div className="d-inline-block">
                  <h2>{t("create-org.organization-information")}</h2>
                </div>
              </div>

              <div className="card-body">
                <div className="mb-3">
                  <label htmlFor="orgname" className="form-label">
                    {t("create-org.orgName")}*
                  </label>
                  <input
                    className="form-control"
                    value={orgName}
                    onChange={(event: FormEvent<HTMLInputElement>) => setOrgName(event.currentTarget.value)}
                  />
                </div>

                <p className="fw-bold">{t("create-org.address")}</p>
                <div className="mb-3">
                  <label className="form-label">{t("create-org.streetNumber")}</label>
                  <input
                    className="form-control"
                    value={orgAddressStreetNumber}
                    onChange={(event: FormEvent<HTMLInputElement>) => setOrgAddressStreetNumber(event.currentTarget.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">{t("create-org.street")}</label>
                  <input
                    className="form-control"
                    value={orgAddressStreet}
                    onChange={(event: FormEvent<HTMLInputElement>) => setOrgAddressStreet(event.currentTarget.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">{t("create-org.city")}</label>
                  <input
                    className="form-control"
                    value={orgAddressCity}
                    onChange={(event: FormEvent<HTMLInputElement>) => setOrgAddressCity(event.currentTarget.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">{t("create-org.postal-code")}</label>
                  <input
                    className="form-control"
                    value={orgAddressPostalCode}
                    onChange={(event: FormEvent<HTMLInputElement>) => setOrgAddressPostalCode(event.currentTarget.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">{t("create-org.state")}</label>
                  <input
                    className="form-control"
                    value={orgAddressState}
                    onChange={(event: FormEvent<HTMLInputElement>) => setOrgAddressState(event.currentTarget.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">{t("create-org.country")}</label>
                  <input
                    className="form-control"
                    value={orgAddressCountry}
                    onChange={(event: FormEvent<HTMLInputElement>) => setOrgAddressCountry(event.currentTarget.value)}
                  />
                </div>

                <p className="fw-bold">{t("create-org.locations")}</p>
                {hubs.map((hub) => {
                  let address = "";
                  if (hub.address) {
                    address = `${hub.address.streetNumber} ${hub.address.street}, ${hub.address.city}, ${hub.address.state} ${hub.address.country}, ${hub.address.postalCode}`;
                  }
                  return (
                    <div className="mb-3 form-check" key={hub.id}>
                      <input type="checkbox" className="form-check-input" onChange={() => onHubSelectionChange(hub.id)} />
                      <label className="form-check-label" key={hub.id}>
                        <strong>{hub.name}</strong> {address}
                      </label>
                    </div>
                  );
                })}

                <div className="mt-4">
                  <button className="btn btn-outline-primary me-2" onClick={onPreviousStep}>
                    {t("common.previous")}
                  </button>
                  <button className="btn btn-primary" {...optsNextMarchantOrg} onClick={onNextStep}>
                    {t("create-org.create-org")}
                  </button>
                </div>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="card">
              <div className="card-header align-items-center">
                <div className="d-inline-block">
                  <h2>
                    {t("create-org.organization-created-title", {
                      name: orgName,
                    })}
                  </h2>
                </div>
              </div>

              <div className="card-body">
                <p className="fw-bold">{t("create-org.organization-created-message")}</p>

                <div className="mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    {t("create-org.return-menu")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function onPreviousStep(event: MouseEvent) {
    event.preventDefault();
    setStep(step - 1);
  }

  async function onNextStep(event: MouseEvent) {
    event.preventDefault();

    if (step === 3) {
      setLoading(true);
      const organization = new Organization();
      organization.name = orgName;
      organization.streetNumber = orgAddressStreetNumber;
      organization.street = orgAddressStreet;
      organization.city = orgAddressCity;
      organization.postalCode = orgAddressPostalCode;
      organization.state = orgAddressState;
      organization.country = orgAddressCountry;
      organization.associatedHubs = orgAssociatedHubs;

      await organizationService.createOrganization(organization);
      changeMember(await memberService.getMemberMe());
      setLoading(false);
    }

    setStep(step + 1);
  }

  async function onHubSelectionChange(hubId: string) {
    let newLocations = [...orgAssociatedHubs];
    const index = orgAssociatedHubs.findIndex((associatedHub) => associatedHub.hubId === hubId);
    if (index >= 0) {
      newLocations.splice(index, 1);
    } else {
      newLocations.push({
        hubId: hubId,
        state: "Approved",
      });
    }
    setOrgAssociatedHubs(newLocations);
  }
}
