import ReactPlaceholder from "react-placeholder";

interface Props {
  loading: boolean;
  children: JSX.Element;
  type?: any;
  rows?: number;
}

PlacerholderComponent.defaultProps = {
  type: "text",
  rows: 5,
};
function PlacerholderComponent(props: Props) {
  const { loading, children, rows, type } = props;

  return (
    <ReactPlaceholder type={type} rows={rows} ready={!loading}>
      {children}
    </ReactPlaceholder>
  );
}

export default PlacerholderComponent;
