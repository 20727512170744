export enum CompartmentStates {
  Unknown = "Unknown",
  Closed = "Closed",
  Opened = "Opened",
}

export class Compartment {
  number: string = "";
  state: CompartmentStates = CompartmentStates.Unknown;
  isObjectDetected: boolean = false;
  sizeId: string = "";
  packagingOptionsIds: string[] = [];
}
