import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CardPaymentRequest } from "../../services/payment/payment-request";
import { CardPaymentResponse } from "../../services/payment/payment-response";
import paymentService from "../../services/payment/payment-service";
import { PaymentState } from "../../services/payment/payment-state";
import LoadingComponent from "../loading-component";
import { ChargeState } from "./charge-state";
import "./payment-form-stripe.scss";

type Props = {
  cost: string;
  currency: string;
  orderToken: string;
  organizationId: string;
  paynow: boolean;
  minPrice?: string;
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function PaymentFormStripe(props: Props) {
  const { cost, currency, orderToken, organizationId, paynow, minPrice } = props;

  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const history = useHistory();

  const [message, setMessage] = useState<any>(null);
  const [state, setState] = useState<ChargeState>(ChargeState.EnteringData);

  return (
    <div>
      {state === ChargeState.ChargeTimeout && (
        <div>
          <div className="text-danger">
            <FontAwesomeIcon className="me-2" icon={["fas", "exclamation-triangle"]} />
            {t("micro-site-reserve-locker.payment-timeout-msg")}
          </div>
          <div className="mt-2">
            <button className="btn btn-primary" onClick={() => window.location.reload()}>
              {t("micro-site-reserve-locker.try-again")}
            </button>
          </div>
        </div>
      )}

      {state !== ChargeState.ChargeTimeout && (
        <form id="payment-form" onSubmit={pay}>
          <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardChange} />

          <br />
          <button
            disabled={!stripe || !elements || state === ChargeState.ChargeInProgress}
            id="submit"
            className="btn btn-primary pushable rounded-3 shadow-lg"
          >
            <span id="button-text" className="front rounded-3">
              {paynow && t("payment.pay", { cost: cost })}
              {!paynow && (
                <>
                  {!minPrice && t("payment.hold", { cost: cost })}
                  {minPrice && t("payment.pay", { cost: minPrice })}
                </>
              )}
            </span>
          </button>

          <LoadingComponent loading={state === ChargeState.ChargeInProgress} />

          {message && <div id="payment-message">{message}</div>}
          <div className="mt-3">
            <FontAwesomeIcon icon={["fas", "info-circle"]} /> {t("micro-site-reserve-locker.msg-taxes")}
          </div>
        </form>
      )}
    </div>
  );

  async function pay(e: any) {
    e.preventDefault();

    // Stripe.js has not yet loaded. Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      return;
    }

    setState(ChargeState.ChargeInProgress);

    const result = await stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement) as StripeCardElement,
      })
      .catch((e: any) => console.log("createPaymentMethod error", e));
    if (!result) {
      console.error("Unable to create payment method");
      setMessage(t("common.error-generic"));
      setState(ChargeState.ChargeError);
      return;
    }
    if (result.error) {
      console.error("Unable to create payment method, error:", result.error);
      setMessage(result.error.message);
      setState(ChargeState.ChargeError);
      return;
    }

    const cardPaymentRequest = new CardPaymentRequest();
    cardPaymentRequest.token = result.paymentMethod.id;
    cardPaymentRequest.orderToken = orderToken;
    cardPaymentRequest.PayNow = paynow;
    await paymentService
      .pay(cardPaymentRequest, organizationId)
      .then((result: CardPaymentResponse) => {
        switch (result.state) {
          case PaymentState.Success:
            setMessage("");
            console.log("Payment charge Success");
            setState(ChargeState.Completed);
            history.push(`/my-reservations/${orderToken}`);
            break;
          case PaymentState.Error:
            setState(ChargeState.ChargeError);
            setMessage(result.error ?? t("common.error-generic"));
            break;
          case PaymentState.Timeout:
            setState(ChargeState.ChargeTimeout);
            setMessage("");
            break;
        }
      })
      .catch(() => {
        setState(ChargeState.ChargeError);
        setMessage(t("common.error-generic"));
      });
  }

  function handleCardChange(event: any) {
    const errorMessage = event.error ? event.error.message : null;
    setMessage(errorMessage);
  }
}
