import { Carrier } from "../carrier/carrier";
import { Address } from "../core/address";
import { Reservation } from "../order/reservation";
import { FlowConfigs } from "./flow-configs";
import { Group } from "./group";
import { HubActivity } from "./hub-activity";
import { ReservationDeny } from "./reservation-deny";

export enum HubStates {
  Unknown = 0,
  Online = 1,
  Offline = 2,
}

export class Hub {
  id: string = "";
  name: string = "";
  zoneName: string = "";
  description: string = "";
  flowConfigs: FlowConfigs = new FlowConfigs();
  private: boolean = false;
  address: Address = new Address();
  model: string = "";
  state: string = "";
  groups: Array<Group> = [];
  lat: number = 0;
  lng: number = 0;
  carriers: Array<Carrier> = [];
  version: string = "";
  active: boolean = true;
}

export class Kiosk {
  position: string = "";
  sizeId: string = "";
}

export class MyHub {
  id: string = "";
  name: string = "";
  zoneName: string = "";
  description: string = "";
  flowConfigs: FlowConfigs = new FlowConfigs();
  version: string = "";
  computerMetrics: any;
  lastSynchronization: string = "";
  private: boolean = false;
  active: boolean = true;
  address: Address = new Address();
  model: string = "";
  state: string = "";
  groups: Array<Group> = [];
  lat: number = 0;
  lng: number = 0;

  carriers: Array<Carrier> = [];
  reservationDenies: Array<ReservationDeny> = [];
  reservations: Array<Reservation> = [];
  kiosk: Kiosk | null = null;

  activities: Array<HubActivity> = [];
}
