import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../components/loading-component";
import ToastHelpers from "../../services/core/toast-helpers";
import customersService from "../../services/member/customers-service";
import membersService from "../../services/member/member-service";
import Member from "../../services/member/member";
import Organization from "../../services/organization/organization";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { YesNoComponent } from "../../components/yesno-component";

type Props = {
  organization: Organization | undefined;
  user: Member | undefined;
};

interface IParamTypes {
  customerId: string;
}

export function EditCustomerPage(props: Props) {
  const { organization } = props;
  const { user } = props;
  const { customerId } = useParams<IParamTypes>();

  const [loading, setLoading] = useState<boolean>(true);

  const [customer, setCustomer] = useState<Member>();
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstname, setFistname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [isDeletedCustomer, setIsDeletedCustomer] = useState<boolean>(false);

  const [phoneError, setPhoneError] = useState<string>();

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (organization && customerId) {
      customersService
        .getCustomer(organization?.id, customerId)
        .then((response: Member | undefined) => {
          setCustomer(response);

          if (response) {
            setEmail(response?.email);
            setPhone(response?.phone);
            setFistname(response?.firstname);
            setLastname(response?.lastname);
            setUnit(response?.unit);
          }
        })
        .catch(() => {
          ToastHelpers.error(t("customers-page.error-refresh"));
        });
    }
    setLoading(false);
  }, [customerId, organization]);

  return (
    <div className="container page orders-page">
      {isDeletedCustomer ? (
        <YesNoComponent
          headerMsg={t("edit-customer-page.msg-delete-customer")}
          onYesClick={() => deleteCustomer()}
          onNoClick={setIsDeletedCustomer}
        />
      ) : (
        <div className="col col-lg-6 offset-lg-3">
          <h1>{t("edit-customer-page.title")}</h1>

          <LoadingComponent loading={loading} />

          {!loading && (
            <div className="customer card">
              <div className="card-header align-items-center">
                <div className="d-inline-block">
                  <h2>{t("customer.customer-information")}</h2>
                </div>
                <button className="btn-close float-end" onClick={() => history.push("/customers")}></button>
              </div>

              <div className="card-body">
                <form onSubmit={(ev) => ev.preventDefault()}>
                  {/* FIRST NAME */}
                  <div className="mb-3">
                    <label className="form-label">{t("customer.enter-firstname")}</label>
                    <input
                      className="form-control"
                      value={firstname}
                      onChange={(ev) => setFistname(ev.target.value)}
                      required
                      autoFocus={true}
                    />
                  </div>

                  {/* LAST NAME */}
                  <div className="mb-3">
                    <label className="form-label">{t("customer.enter-lastname")}</label>
                    <input className="form-control" value={lastname} onChange={(ev) => setLastname(ev.target.value)} required />
                  </div>

                  {/* PHONE */}
                  <div className="mb-3">
                    <PhoneFormComponent
                      value={phone}
                      setValue={setPhone}
                      onMsgErrorChanged={setPhoneError}
                      msgError={phoneError}
                      label={t("customer.enter-phone")}
                    />
                  </div>

                  {/* UNIT */}
                  <div className="mb-3">
                    <label className="form-label">{t("customer.enter-unit")}</label>
                    <input className="form-control" value={unit} onChange={(ev) => setUnit(ev.target.value)} />
                  </div>

                  {canEdit() && (
                    <>
                      <button className="btn btn-lg btn-primary" type="submit" onClick={() => editCustomer()}>
                        {t("edit-customer-page.btn-save")}
                      </button>
                      <button className={`btn btn-lg btn-outline-primary float-end`} onClick={() => setIsDeletedCustomer(true)}>
                        <FontAwesomeIcon icon={["fas", "trash-alt"]} /> {t("common.delete")}
                      </button>
                    </>
                  )}

                  {!canEdit() && <label>{t("edit-customer-page.not-authorized")}</label>}
                  <button className="btn btn-lg btn-primary-outline" type="submit" onClick={() => history.push("/customers")}>
                    {t("edit-customer-page.btn-cancel")}
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  function canEdit(): boolean {
    if (user && membersService.isLocketgoAdmin(user)) return true;

    if (customer?.type === "User") return false;

    return true;
  }

  async function editCustomer() {
    if (organization?.id && firstname && lastname && email && !phoneError) {
      let newCustomer = { ...customer, firstname, lastname, phone, email, unit };

      await customersService
        .updateCustomer(organization?.id, newCustomer)
        .then(() => {
          return history.push(`/customers`);
        })
        .catch(() => {
          ToastHelpers.error(t("create-order-page.error-conflict"));
        });
    }
  }

  async function deleteCustomer() {
    if (organization?.id && customerId) {
      setIsDeletedCustomer(false);
      await customersService
        .deleteCustomer(organization?.id, customerId)
        .then(() => {
          return history.push(`/customers`);
        })
        .catch(() => {
          ToastHelpers.error(t("edit-customer-page.error-delete-customer"));
        });
    }
  }
}
