import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import { InputComponent } from "../../components/input";
import LoadingComponent from "../../components/loading-component";
import StringHelpers from "../../services/core/string-helpers";
import ToastHelpers from "../../services/core/toast-helpers";
import customersService from "../../services/member/customers-service";
import Organization from "../../services/organization/organization";

type Props = {
  organization: Organization | undefined;
};

export function CreateCustomerPage(props: Props) {
  const { organization } = props;

  const [loading, setLoading] = useState<boolean>(true);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstname, setFistname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [unit, setUnit] = useState<string>("");

  const [emailError, setEmailError] = useState<string>();
  const [phoneError, setPhoneError] = useState<string>();

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setLoading(false);
  });

  return (
    <div className="container page orders-page">
      <div className="col col-lg-6 offset-lg-3">
        <h1>{t("customer.create-customer-title")}</h1>

        <LoadingComponent loading={loading} />

        {!loading && (
          <div className="customer card">
            <div className="card-header align-items-center">
              <div className="d-inline-block">
                <h2>{t("customer.customer-information")}</h2>
              </div>
              <button className="btn-close float-end" onClick={() => history.push("/customers")}></button>
            </div>

            <div className="card-body">
              <form onSubmit={(ev) => ev.preventDefault()}>
                {/* FIRST NAME */}
                <div className="mb-3">
                  <label className="form-label">{t("customer.enter-firstname")}</label>
                  <input
                    className="form-control"
                    value={firstname}
                    onChange={(ev) => setFistname(ev.target.value)}
                    required
                    autoFocus={true}
                  />
                </div>

                {/* LAST NAME */}
                <div className="mb-3">
                  <label className="form-label">{t("customer.enter-lastname")}</label>
                  <input className="form-control" value={lastname} onChange={(ev) => setLastname(ev.target.value)} required />
                </div>

                {/* PHONE */}
                <div className="mb-3">
                  <PhoneFormComponent
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    onMsgErrorChanged={setPhoneError}
                    msgError={phoneError}
                    label={t("common.phone")}
                  />
                </div>

                {/* EMAIL */}
                <div className="mb-3">
                  <InputComponent
                    value={email}
                    setValue={setEmail}
                    regExp={StringHelpers.getEmailRegExp()}
                    msgError={emailError}
                    onMsgErrorChanged={setEmailError}
                    type={"email"}
                    labelText={t("customer.enter-email")}
                    required={true}
                  />
                </div>

                {/* UNIT */}
                <div className="mb-3">
                  <label className="form-label">{t("customer.enter-unit")}</label>
                  <input className="form-control" value={unit} onChange={(ev) => setUnit(ev.target.value)} />
                </div>

                <button className="btn btn-lg btn-primary" type="submit" onClick={() => createCustomer()}>
                  {t("customer.create")}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  async function createCustomer() {
    if (organization?.id && firstname && lastname && email && !phoneError && !emailError) {
      const lowerCaseEmail = email.toLowerCase();
      await customersService
        .createCustomer(organization.id, firstname, lastname, phoneNumber, lowerCaseEmail, unit)
        .then(() => {
          return history.push(`/customers`);
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            setEmailError(t("create-order-page.error-email-exist"));
          } else {
            ToastHelpers.error(t("create-order-page.error-conflict"));
          }
        });
    }
  }
}
