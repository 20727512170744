import "./my-reservations-page.scss";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import languageService from "../../services/core/language-service";
import StringHelpers from "../../services/core/string-helpers";
import { Reservation } from "../../services/order/reservation";
import { Order, OrderFlowStringValue } from "../../services/order/order";
import memberService from "../../services/member/member-service";
import ToastHelpers from "../../services/core/toast-helpers";
import orderService from "../../services/order/order-service";
import { MyReservations } from "../../services/member/my-reservations";
import { YesNoComponent } from "../../components/yesno-component";
import LoadingComponent from "../../components/loading-component";
import { HubHelpers } from "../../services/hub/hub-helper";
import compartmentSizeService from "../../services/hub/compartmentSize-service";
import { CompartmentSize } from "../../services/hub/compartmentSize";
import { useParams } from "react-router-dom";
import LockerSingleReservationMessageComponent from "../../components/locker-single-reservation-message-component";
import { ReservationState } from "../../services/order/ReservationState";
import { StateBadgeComponent } from "../../components/state-badge-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimerComponent from "../../components/timerComponent";
import LockerMultipleReservationsMessageComponent from "../../components/locker-multiple-reservations-message-component";
import paymentService from "../../services/payment/payment-service";
import { Pricing } from "../../services/payment/pricing";
import { Payment } from "../../services/payment/payment";
import DateHelpers from "../../services/core/date-helpers";

interface IParamTypes {
  token?: string;
}

export default function MyReservationsPage() {
  const { t } = useTranslation();
  const { token } = useParams<IParamTypes>();
  const language: string = languageService.getLanguage();
  const [loading, setIsLoading] = useState<boolean>(true);
  const [myReservations, setMyReservations] = useState<MyReservations>();
  const [reservationInOpening, setReservationInOpening] = useState<{ order: Order; reservation: Reservation } | null>(null);
  const [compartmentSizes, setCompartmentSizes] = useState<Array<CompartmentSize>>([]);
  const [firstOrder, setFirstOrder] = useState<Order>();
  const [firstReservation, setFirstReservation] = useState<Reservation | undefined>(undefined);
  const [isFirstReservationStarted, setIsFirstReservationStarted] = useState<boolean>(false);
  const [reservationTimeLeft, setReservationTimeLeft] = useState<number | undefined>(undefined);
  const [isAskingReservationEnd, setIsAskingReservationEnd] = useState<boolean>(false);
  const [endPricing, setEndPricing] = useState<Pricing | undefined>(undefined);
  const [payments, setPayments] = useState<Payment[]>([]);

  useEffect(() => {
    async function setValues() {
      try {
        setEndPricing(await paymentService.getEndNowPricing(token || ""));
        const myReservations = await memberService.getMyReservations(token || "");
        setPayments(await paymentService.getPayment(token || ""));
        if (myReservations) {
          setMyReservations(myReservations);
          let firstOrderTmp = myReservations.orders?.[0];

          if (firstOrderTmp) {
            await setMyReservationState();
            const reservation = firstOrderTmp.items[0]?.reservation;
            setFirstReservation(reservation);
            const isStarted = dateNow >= new Date(reservation.start);
            setIsFirstReservationStarted(isStarted);
            const end = new Date(reservation.end);
            const milisecondsLeft = end.getTime() - dateNow.getTime();
            setReservationTimeLeft(milisecondsLeft / 1000);
          }
          setFirstOrder(myReservations.orders?.[0]);
        }
        const compartmentSizes = await compartmentSizeService.getCompartmentSizes(token);
        setCompartmentSizes(compartmentSizes);
      } catch (error: any) {
        ToastHelpers.error(t("my-reservations-page.error-refresh", { status: error?.response?.status }));
      } finally {
        setIsLoading(false);
      }
    }

    setValues();
  }, [t]);

  const isSingleReservation = myReservations?.orders.length === 1 && firstOrder?.items.length === 1;
  const dateNow = new Date();

  //This is a checker if the door is opened or closed
  useEffect(() => {
    setInterval(async () => {
      await setMyReservationState();
      setEndPricing(await paymentService.getEndNowPricing(token || ""));
    }, 10000);
  }, []);

  const firstReservationBlock = myReservations?.myReservations?.[0]?.reservationBlock;
  const firstCurrency = myReservations?.myReservations?.[0]?.currency;
  const firstHub = myReservations?.hubs.find((h) => h.id === firstOrder?.hubId);

  const payed = payments?.some((p) => p.state === "Completed");

  const isCompleted =
    firstReservation && (firstReservation?.state === ReservationState.Completed || dateNow > new Date(firstReservation?.end));

  const isCompartmentOnline =
    firstHub && firstReservation && HubHelpers.isCompartmentOnline(firstHub, firstReservation.groupId, firstReservation.compartmentNumber);

  const isSwych = firstReservationBlock?.micrositeId === "swych";

  const helpLink = "https://locketgo.com/faq.html";

  const timeZone = DateHelpers.UserTimeZone;

  return (
    <div className="container page my-reservations-page">
      <div className="col col-lg-8 offset-lg-2">
        <LoadingComponent loading={loading} />

        {reservationInOpening && (
          <YesNoComponent
            headerMsg={t("my-reservations-page.msg-opendoor")}
            onYesClick={async () =>
              await onYesClickOpenDoor(reservationInOpening.order, reservationInOpening.reservation, isSingleReservation)
            }
            onNoClick={() => setReservationInOpening(null)}
          />
        )}

        {isAskingReservationEnd && (
          <YesNoComponent
            isDangerousChoice={true}
            headerMsg={t("my-reservations-page.msg-end-reservation")}
            onYesClick={async () => await endReservation()}
            onNoClick={() => setIsAskingReservationEnd(false)}
          >
            <p>{t("my-reservations-page.msg-end-reservation-info")}</p>
            {!isSwych && (
              <p>
                {t("my-reservations-page.msg-end-reservation-new-amount", {
                  amount: endPricing ? StringHelpers.formatPrice(endPricing.price + endPricing.saleTaxTotal) : "0.00",
                  currency: firstCurrency,
                })}
              </p>
            )}
          </YesNoComponent>
        )}

        {!loading &&
          !reservationInOpening &&
          !isAskingReservationEnd &&
          (isSingleReservation ? (
            <div className="page-background-boxing shadow text-center pt-5 pb-5">
              {/*This section is for personal storage and bopil single reservation*/}
              {firstReservationBlock && (
                <h2 className="mb-4">{language === "en" ? firstReservationBlock.name.en : firstReservationBlock.name.fr}</h2>
              )}
              {(!myReservations || !firstOrder || !firstOrder.items.length) && (
                <div className="row">
                  <div className="col">
                    <div className="customer card">
                      <div className="card-body text-center">{t("my-reservations-page.no-reservation")}</div>
                    </div>
                  </div>
                </div>
              )}
              {firstOrder && (
                <div>
                  {firstHub && firstHub.zoneName && (
                    <>
                      {firstReservation && (
                        <>
                          {isFirstReservationStarted && (
                            <>
                              <span className="fs-5 congrats">{t("my-reservations-page.congrats-msg")}</span>
                              <div className="d-flex justify-content-center fs-2 mb-3 mt-2">
                                <StateBadgeComponent
                                  state={firstReservation.state}
                                  isPersonalStorage={firstOrder.flow.toString() === OrderFlowStringValue.PersonalStorage}
                                ></StateBadgeComponent>
                              </div>
                            </>
                          )}
                          {firstHub.address && firstOrder.flow.toString() === "Deliver" && (
                            <span className="mt-3 pt-0 pb-1 location">
                              <FontAwesomeIcon className="me-2 mb-1" icon={["fas", "map-marker-alt"]} />
                              {HubHelpers.getAddress(firstHub)}
                            </span>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {firstReservation && (
                    <>
                      {firstHub && isCompartmentOnline ? (
                        <LockerSingleReservationMessageComponent
                          firstOrder={firstOrder}
                          firstReservation={firstReservation}
                          firstHub={firstHub}
                          askOpenDoor={askOpenDoor}
                          disabled={/*OrderHelpers.inOpenedState(firstReservation.state) ||*/ reservationInOpening != null}
                          dateNow={dateNow}
                        />
                      ) : (
                        <>
                          <h4 className="text-center fw-bold mt-0 pt-0">
                            {t("my-reservations-page.hub-or-group-offline")}
                            <br />
                            <a className="h4 text-center mt-0 pt-0" href={helpLink}>
                              {helpLink}
                            </a>
                          </h4>
                          <br />
                        </>
                      )}
                      {isFirstReservationStarted ? (
                        reservationTimeLeft && (
                          <>
                            {firstReservation.state !== ReservationState.Completed && (
                              <div className="fs-5 end-reservation">
                                <TimerComponent
                                  timeInSeconds={reservationTimeLeft}
                                  message="my-reservations-page.end-of-reservation"
                                  onTimerEnds={() => null}
                                />
                              </div>
                            )}
                            {(!payed || isSwych) && (
                              <div className="mt-3 row justify-content-center mb-3">
                                <button
                                  className="btn btn-md col-11 btn-danger rounded-3 shadow-lg pushable"
                                  onClick={() => setIsAskingReservationEnd(true)}
                                  disabled={isCompleted || !isCompartmentOnline}
                                >
                                  <span className="front rounded-3">
                                    <span>{t("my-reservations-page.end-reservation-early").toUpperCase()}</span>
                                  </span>
                                </button>
                              </div>
                            )}
                          </>
                        )
                      ) : (
                        <div className="fs-5 text-center mb-3 end-reservation text-strong-black">
                          <Trans
                            i18nKey={"my-reservations-page.end-of-reservation-before-start"}
                            values={{
                              dateTimeFormat:
                                DateHelpers.toDateString(new Date(firstReservation.end), timeZone) +
                                " " +
                                DateHelpers.toHourString(new Date(firstReservation.end), timeZone),
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ) : (
            <>
              <h1>{t("my-reservations-page.title")}</h1>
              {(!myReservations || !(myReservations?.orders?.length > 0) || !myReservations?.orders?.some((o) => o.items.length > 0)) && (
                <div className="row">
                  <div className="col">
                    <div className="customer card">
                      <div className="card-body text-center">{t("my-reservations-page.no-reservation")}</div>
                    </div>
                  </div>
                </div>
              )}
              {myReservations && myReservations.myReservations.length > 0
                ? myReservations?.myReservations.map((myReservation) => {
                    //This section is for personal storage multiple reservations, This part will never be used, because at this time a PS order only have one reservation.
                    const reservationBlock = myReservation.reservationBlock;

                    const curReservations: Array<Reservation> = [];
                    myReservations.orders.forEach((order) => {
                      order.items.forEach((item) => {
                        if (item.reservation.reservationBlockId === reservationBlock.id) {
                          curReservations.push(item.reservation);
                        }
                      });
                    });

                    const name = language === "en" ? reservationBlock.name.en : reservationBlock.name.fr;

                    return (
                      <div className="row" key={myReservation.reservationBlock.id}>
                        <div className="col">
                          <div className="customer card">
                            <div className="card-body">
                              {myReservation.micrositeLogoUrl && (
                                <img src={myReservation.micrositeLogoUrl} alt="" className="img-logo float-end" />
                              )}

                              <h2 className="card-title pb-0 mb-0">
                                {reservationBlock.logoUrl && <img src={reservationBlock.logoUrl} alt="" className="app-logo" />} {name}
                              </h2>

                              {reservationBlock.startOn && (
                                <div className="large mb-2">{DateHelpers.formatDateTime(new Date(reservationBlock.startOn), timeZone)}</div>
                              )}

                              <table className="table table-sm table-reservations">
                                <thead>
                                  <tr>
                                    <th>{t("my-reservations-page-table-header.locker-id")}</th>
                                    <th>{t("my-reservations-page-table-header.end-time")}</th>
                                    <th>{t("my-reservations-page-table-header.size")}</th>
                                    <th>{t("my-reservations-page-table-header.reservation-state")}</th>
                                    <th>{t("my-reservations-page-table-header.locker-door")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {curReservations.map((reservation) => {
                                    const order = myReservations.orders.find((o) =>
                                      o.items.find((i) => i.reservation.id === reservation.id)
                                    );
                                    const hub = myReservations.hubs.find((h) => h.id === order?.hubId);
                                    let hubName = hub?.name;
                                    if (hubName?.startsWith("Event ")) {
                                      hubName = "Section " + hubName.substr(6, hubName.length - 6) + " - ";
                                    } else {
                                      hubName = "";
                                    }

                                    let compartmentSizeName = name;
                                    if (hub) {
                                      let sizeId = HubHelpers.findGroup(hub, reservation.groupId)?.compartments.find(
                                        (c) => c.number === reservation.compartmentNumber
                                      )?.sizeId;

                                      var compartmentSize = compartmentSizes.find((cs) => cs.id === sizeId);
                                      compartmentSizeName = compartmentSize?.name.get(language);
                                    }

                                    const startOn = new Date(reservation.start);
                                    const until = new Date(reservation.end);
                                    const reservationIsActive = dateNow >= startOn && dateNow <= until;

                                    return (
                                      <LockerMultipleReservationsMessageComponent
                                        order={order}
                                        reservation={reservation}
                                        hub={hub}
                                        askOpenDoor={askOpenDoor}
                                        helpLink={helpLink}
                                        compartmentSizeName={compartmentSizeName}
                                        reservationIsActive={reservationIsActive}
                                        reservationInOpening={reservationInOpening}
                                        hubName={hubName}
                                      />
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : myReservations?.orders.map((order) => {
                    //This section is for bopil multiple reservations
                    const hub = myReservations.hubs.find((h) => h.id === order?.hubId);
                    return (
                      !!order.items.length && (
                        <div className="row" key={order.id}>
                          <div className="col">
                            <div className="customer card">
                              <div className="card-body">
                                <h1 className="mb-1 pb-0 pt-2 ms-4">{hub?.name}</h1>
                                {hub?.address && (
                                  <span className="location ms-4">
                                    <FontAwesomeIcon className="me-2 mb-1" icon={["fas", "map-marker-alt"]} />
                                    {HubHelpers.getAddress(hub)}
                                  </span>
                                )}

                                <table className="table table-sm table-reservations mt-4">
                                  <thead>
                                    <tr>
                                      <th>{t("my-reservations-page-table-header.locker-id")}</th>
                                      <th>{t("my-reservations-page-table-header.end-time")}</th>
                                      <th>{t("my-reservations-page-table-header.reservation-state")}</th>
                                      <th>{t("my-reservations-page-table-header.locker-door")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {order.items.map((orderItem) => {
                                      const reservation = orderItem.reservation;
                                      if (!reservation) {
                                        return null;
                                      }

                                      let reservationIsActive = false;
                                      if (order.flow.toString() === "Return") {
                                        reservationIsActive = reservation.state === ReservationState.New;
                                      } else {
                                        reservationIsActive = reservation.state === ReservationState.ReadyForPickUp;
                                      }

                                      return (
                                        <LockerMultipleReservationsMessageComponent
                                          order={order}
                                          reservation={reservation}
                                          hub={hub}
                                          askOpenDoor={askOpenDoor}
                                          helpLink={helpLink}
                                          reservationIsActive={reservationIsActive}
                                          reservationInOpening={reservationInOpening}
                                        />
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
            </>
          ))}
      </div>
    </div>
  );

  async function endReservation(): Promise<void> {
    if (token) {
      setIsLoading(true);
      setIsAskingReservationEnd(false);

      if (isSwych) {
        if (firstOrder) {
          await orderService
            .endOrder(firstOrder?.organizationId, firstOrder?.id, token)
            .then(async () => {
              await setMyReservationState();
            })
            .finally(() => setIsLoading(false));
        }
      } else {
        await paymentService
          .endReservationEarly(token)
          .then(async () => {
            await setMyReservationState();
          })
          .finally(() => setIsLoading(false));
      }
    }
  }

  async function onYesClickOpenDoor(order: Order, reservation: Reservation, isSingleReservation: boolean): Promise<void> {
    if (order && reservation) {
      setIsLoading(true);
      await openDoor(order, reservation)
        .then(async () => {
          if (isSingleReservation) {
            await setMyReservationState();
          } else {
            const myReservations = await memberService.getMyReservations(token || "");
            if (myReservations) {
              setMyReservations(myReservations);
            }
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      ToastHelpers.error(t("my-reservations-page.error-opendoor-424"));
    }
  }

  async function setMyReservationState(): Promise<void> {
    const myReservations = await memberService.getMyReservations(token || "");
    if (myReservations) {
      const firstReservationTmp = myReservations.orders[0]?.items[0]?.reservation;
      setFirstReservation(firstReservationTmp);
    }
  }

  async function askOpenDoor(order: Order, reservation: Reservation): Promise<void> {
    setReservationInOpening({ order, reservation });
  }

  async function openDoor(order: Order, reservation: Reservation): Promise<void> {
    setReservationInOpening(null);
    let reason = null;
    switch (order.flow.toString()) {
      case "PersonalStorage":
        reason = "PersonalStorage";
        break;
      case "Deliver":
        reason = "Pickup";
        break;
      case "Return":
        reason = "Dropoff";
        break;
    }

    const orderItem = order.items.find((i) => i.reservation.id === reservation.id);
    let tokenTmp = token || null;
    if (orderItem && reason) {
      await orderService.openDoor(order.organizationId.toString(), order.id, orderItem.id, reason, tokenTmp).catch((error) => {
        switch (error?.response?.status) {
          case 409:
            ToastHelpers.error(t("my-reservations-page.error-opendoor"));
            break;
          case 424:
            ToastHelpers.error(t("my-reservations-page.error-opendoor-424"));
            break;
          case 429:
            ToastHelpers.error(t("my-reservations-page.error-opendoor-429"));
            break;
          default:
            ToastHelpers.error(t("common.error-generic"));
            break;
        }
      });
    }
  }
}
