interface EmailProps {
    address: string;
    subject?: string;
    body?: string;
  }
  
  const EmailLink: React.FC<EmailProps> = ({ address, subject, body }) => {
  
    const mailtoLink = `mailto:${address}?subject=${encodeURIComponent(
      subject || ''
    )}&body=${encodeURIComponent(body || '')}`;
  
    return (
      <a href={mailtoLink}>
        {address}
      </a>
    );
  };

  export default EmailLink;