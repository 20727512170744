import ServicesApiBaseService from "../services-api-base-service";
import { Hub, MyHub } from "./hub";
import { HubActivity } from "./hub-activity";

export class HubService extends ServicesApiBaseService {
  async getHubById(id: string): Promise<Hub> {
    const hub = await this.getObject<Hub>(`${this.apiUrl}/hubs/${id}?details=true`);
    return hub || new Hub();
  }

  async getHubsByIds(ids: Array<string>): Promise<Array<Hub>> {
    const hubs = await this.postObject<Array<string>, Array<Hub>>(`${this.apiUrl}/hubs/with-ids?details=true`, ids);
    hubs?.sort((a, b) => a.name.localeCompare(b.name));
    return hubs || [];
  }

  async getHubs(): Promise<Array<Hub>> {
    const hubs = await this.getObject<Array<Hub>>(`${this.apiUrl}/hubs?details=true`);
    hubs?.sort((a, b) => a.name.localeCompare(b.name));
    return hubs || [];
  }

  async getMyHubs(): Promise<Array<Hub>> {
    const hubs = await this.getObject<Array<Hub>>(`${this.apiUrl}/myhubs`);
    hubs?.sort((a, b) => a.name.localeCompare(b.name));
    return hubs || [];
  }

  async getMyHubById(id: string): Promise<MyHub> {
    const hub = await this.getObject<MyHub>(`${this.apiUrl}/myhubs/${id}`);
    return hub || new MyHub();
  }

  async activateLockerReservation(hubId: string, groupId: string, compartmentNumber: string): Promise<boolean> {
    try {
      await this.postObject(`${this.apiUrl}/hubs/${hubId}/groups/${groupId}/compartments/${compartmentNumber}/actions/activate`, {});
      return true;
    } catch (e) {
      return false;
    }
  }

  async deactivateLockerReservation(hubId: string, groupId: string, compartmentNumber: string): Promise<boolean> {
    try {
      await this.postObject(`${this.apiUrl}/hubs/${hubId}/groups/${groupId}/compartments/${compartmentNumber}/actions/deactivate`, {});
      return true;
    } catch (e) {
      return false;
    }
  }

  async activateAllLockers(hubId: string): Promise<boolean> {
    try {
      await this.postObject(`${this.apiUrl}/hubs/${hubId}/actions/activatealldoors`, {});
      return true;
    } catch (e) {
      return false;
    }
  }

  async deactivateAllLockers(hubId: string): Promise<boolean> {
    try {
      await this.postObject(`${this.apiUrl}/hubs/${hubId}/actions/deactivatealldoors`, {});
      return true;
    } catch (e) {
      return false;
    }
  }

  async openDoor(hubId: string, groupId: string, compartmentNumber: string, side: string): Promise<boolean> {
    try {
      await this.postObject(
        `${this.apiUrl}/hubs/${hubId}/groups/${groupId}/compartments/${compartmentNumber}/actions/opendoor?side=${side}`,
        {}
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  async openAllDoors(hubIid: string): Promise<void> {
    try {
      await this.postObject<any, any>(`${this.apiUrl}/hubs/${hubIid}/actions/openalldoors?requestedfrom=portal`, {});
    } catch (e) {}
  }

  async toggleDoor(hubIid: string, groupId: string, compartmentNumber: string): Promise<boolean> {
    try {
      await this.postObject(`${this.apiUrl}/hubs/${hubIid}/groups/${groupId}/compartments/${compartmentNumber}/actions/toggledoor`, {});
      return true;
    } catch (e) {
      return false;
    }
  }

  async getHubActivities(hubId: string, activitiesMultiplier: number): Promise<Array<HubActivity>> {
    try {
      let activities = await this.getObject<Array<HubActivity>>(
        `${this.apiUrl}/hubs/${hubId}/activities?activitiesMultiplier=${activitiesMultiplier}`
      );
      return activities || [];
    } catch (e) {
      return [];
    }
  }
}

const hubService = new HubService();
export default hubService;
