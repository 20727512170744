import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../components/loading-component";
import { YesNoComponent } from "../../components/yesno-component";
import ToastHelpers from "../../services/core/toast-helpers";
import Member from "../../services/member/member";
import memberService from "../../services/member/member-service";
import micrositeService from "../../services/micro-site/microsite-service";
import useCompartmentSizes from "../../services/hub/useCompartmentSizes";
import { CompartmentSize } from "../../services/hub/compartmentSize";
import languageService from "../../services/core/language-service";
import "./event-page.scss";
import { CodeType, MinimalPrice, PricePerHour, PricePerMinute, ResetTime } from "../../assets/meta-table-keys";
import DateHelpers from "../../services/core/date-helpers";
import organizationService from "../../services/member/organization-service";
import Microsite from "../../services/micro-site/microsite";
import MicrositeDropdown from "../../components/micrositeDropDown";
import { NumberDropDown } from "../../components/NumberDropdown";

enum EventType {
  Duration = "duration",
  StartEnd = "startend",
  ResetTime = "resetTime",
}

interface Props {
  member: Member | undefined;
}

interface IParamTypes {
  organizationId: string;
  micrositeId: string;
  eventId: string;
}

export default function EventPage(props: Props) {
  const { t } = useTranslation();
  const { member } = props;
  const { organizationId, micrositeId, eventId } = useParams<IParamTypes>();
  const language: string = languageService.getLanguage();

  const { compartmentSizes } = useCompartmentSizes({ onErrorNotificationMessage: t("event-page.event-page-error-refresh") });
  const [micrositeCompartmentSizes, setMicrositeCompartmentSizes] = useState<CompartmentSize[] | undefined>(undefined);
  const [currency, setCurrency] = useState<string>("CAD");
  const [currEventId, setCurrEventId] = useState<string>(eventId);

  const [loading, setLoading] = useState<boolean>(true);
  const [isDeleteReservationBlock, setIsDeleteReservationBlock] = useState<boolean>(false);
  const [eventAlreadyStarted, setEventAlreadyStarted] = useState<boolean>(false);

  const [nameFrError, setNameFrError] = useState<boolean>(false);
  const [nameEnError, setNameEnError] = useState<boolean>(false);
  const [priceError, setPriceError] = useState<boolean>(false);
  const [saleTaxRateError, setSaleTaxError] = useState<boolean>(false);
  const [availableStartError, setAvailableStartError] = useState<string | undefined>(undefined);
  const [availableEndError, setAvailableEndError] = useState<string | undefined>(undefined);
  const [endOnError, setEndOnError] = useState<string | undefined>(undefined);
  const [startOnError, setStartOnError] = useState<string | undefined>(undefined);

  const [nameFr, setNameFr] = useState<string | undefined>(undefined);
  const [nameEn, setNameEn] = useState<string | undefined>(undefined);
  const [descriptionFr, setDescriptionFr] = useState<string | undefined>(undefined);
  const [descriptionEn, setDescriptionEn] = useState<string | undefined>(undefined);
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
  const [price, setPrice] = useState<number>(0);
  const [pricePerHour, setPricePerHour] = useState<number>(0);
  const [pricePerMinute, setPricePerMinute] = useState<number>(0);
  const [minimalPrice, setMinimalPrice] = useState<number>(0);
  const [saleTaxRate, setSaleTaxRate] = useState<number>(0);
  const [startOn, setStartOn] = useState<string | undefined>(undefined);
  const [availableStart, setAvailableStart] = useState<string | undefined>(undefined);
  const [availableEnd, setAvailableEnd] = useState<string | undefined>(undefined);
  const [endOn, setEndOn] = useState<string | undefined>(undefined);
  const [sizeId, setSizeId] = useState<string | undefined>(undefined);
  const [codeType, setCodeType] = useState<string | undefined>("none");
  const [resetTime, setResetTime] = useState<string | undefined>(undefined);

  const [eventType, setEventType] = useState<EventType>(EventType.Duration);

  const [durationError, setDurationError] = useState<boolean>(false);
  const [durationDays, setDurationDays] = useState<number>(0);
  const [durationHours, setDurationHours] = useState<number>(0);
  const [durationMinutes, setDurationMinutes] = useState<number>(0);

  const [isPricedPerHour, setIsPricedPerHour] = useState<boolean>(false);
  const [isPricedPerMinute, setIsPricedPerMinute] = useState<boolean>(false);
  const [isResetTimeInput, setIsResetTimeInput] = useState<boolean>(true);
  const [isFixedPeriod, setIsFixedPeriod] = useState<boolean>(false);
  const [micrositesList, setMicrosites] = useState<Array<Microsite>>();
  const [targetMicrosite, setTargetMicrosite] = useState<Microsite>();

  const dateTimeNow = () => moment(new Date()).format("YYYY-MM-DDTHH:mm");

  const history = useHistory();

  useEffect(
    () => {
      if (compartmentSizes.length > 0) {
        let organization = member && member.associatedOrganizations.find((organization) => organization.entity?.id === organizationId);
        if (member) {
          if (organization && memberService.isOrganizationContributor(member, organizationId)) {
            micrositeService
              .getMicrosite(micrositeId, "All")
              .then((microsite) => {
                if (microsite) {
                  setCurrency(microsite.currency);
                  setTargetMicrosite(microsite);
                  micrositeService
                    .getMicrositeSizesIds(microsite)
                    .then((sizeIds) => {
                      let tmpCompartmentSizes = compartmentSizes.filter((size) => sizeIds.has(size.id));
                      setMicrositeCompartmentSizes(tmpCompartmentSizes);
                    })
                    .catch(() => {
                      ToastHelpers.error(t("event-page.event-page-error-refresh"));
                    })
                    .finally(() => {
                      setLoading(false);
                    });

                  var reservationBlock = microsite.reservationBlocks.find((rb) => rb.id === currEventId);

                  if (reservationBlock && currEventId !== "new") {
                    setNameFr(reservationBlock.name.get("fr"));
                    setNameEn(reservationBlock.name.get("en"));
                    setDescriptionFr(reservationBlock.description.get("fr"));
                    setDescriptionEn(reservationBlock.description.get("en"));
                    setLogoUrl(reservationBlock.logoUrl);
                    setPrice(reservationBlock.price);
                    setSaleTaxRate(reservationBlock.saleTaxRate * 100);
                    setSizeId(reservationBlock.sizeId);

                    let Hours_Minutes = [""];
                    if (reservationBlock.duration.toString().includes(".")) {
                      let days = reservationBlock.duration.toString().split(".");
                      setDurationDays(parseInt(days[0]));
                      Hours_Minutes = days[1].split(":");
                    } else Hours_Minutes = reservationBlock.duration.toString().split(":");

                    setDurationHours(parseInt(Hours_Minutes[0]));
                    setDurationMinutes(parseInt(Hours_Minutes[1]));

                    if (reservationBlock.meta) {
                      if (reservationBlock.meta[CodeType]) setCodeType(reservationBlock.meta[CodeType]);
                      if (reservationBlock.meta[MinimalPrice]) setMinimalPrice(parseFloat(reservationBlock.meta[MinimalPrice]));
                      if (reservationBlock.meta[ResetTime]) {
                        setResetTime(reservationBlock.meta[ResetTime]);
                        setEventType(EventType.ResetTime);
                      }
                      if (reservationBlock.meta[PricePerHour]) {
                        setPricePerHour(parseFloat(reservationBlock.meta[PricePerHour]));
                        // setIsPricedPerHour(true);
                      }
                    }

                    if (reservationBlock.startOn && reservationBlock.availableEnd) {
                      setStartOn(moment(new Date(reservationBlock.startOn)).format("YYYY-MM-DDTHH:mm"));
                      setEndOn(moment(new Date(reservationBlock.availableEnd)).format("YYYY-MM-DDTHH:mm"));
                      setEventType(EventType.StartEnd);
                      // setIsResetTimeInput(false);
                      // setIsDurationInput(false);
                    }

                    //Get the date and put it in the selected options
                    if (reservationBlock.startOn && reservationBlock.availableEnd && reservationBlock.availableStart) {
                      setAvailableStart(moment(new Date(reservationBlock.availableStart)).format("YYYY-MM-DDTHH:mm"));

                      if (dateTimeNow() > moment(new Date(reservationBlock.startOn)).format("YYYY-MM-DDTHH:mm"))
                        setEventAlreadyStarted(true);
                    } else {
                      if (reservationBlock.availableStart && reservationBlock.availableEnd) {
                        setIsFixedPeriod(true);

                        if (dateTimeNow() > moment(new Date(reservationBlock.availableStart)).format("YYYY-MM-DDTHH:mm"))
                          setEventAlreadyStarted(true);

                        setAvailableStart(moment(new Date(reservationBlock.availableStart)).format("YYYY-MM-DDTHH:mm"));
                        setAvailableEnd(moment(new Date(reservationBlock.availableEnd)).format("YYYY-MM-DDTHH:mm"));
                      } //else if (!reservationBlock.startOn) {
                      //   setAvailableStart(undefined);
                      //   setAvailableEnd(undefined);
                      // }
                    }
                  }
                }
              })
              .catch(() => {
                ToastHelpers.error(t("event-page.event-page-error-refresh"));
                setLoading(false);
              });
          }
        }
      }
    },
    // eslint-disable-next-line
    [compartmentSizes]
  );

  useEffect(() => {
    if (member) {
      let organization = member.associatedOrganizations.find((organization) => organization.entity?.id == organizationId);
      if (member && organization && memberService.isOrganizationContributor(member, organizationId)) {
        organizationService
          .getAllMicrositesOfOrganization(organizationId)
          .then((microsites) => {
            setMicrosites(microsites);
          })
          .catch(() => {
            ToastHelpers.error(t("micro-site-page.microsites-error"));
          });
      }
    }
  }, []);

  if (isDeleteReservationBlock) {
    return (
      <div className="container page orders-page">
        <YesNoComponent
          headerMsg={t("event-page.event-page-msg-delete-reservationBlock")}
          onYesClick={() => deleteReservationBlock()}
          onNoClick={setIsDeleteReservationBlock}
        />
      </div>
    );
  }

  return (
    <div className="container page orders-page">
      <div className="col">
        <LoadingComponent loading={loading} />

        {!loading && (
          <form onSubmit={(ev) => ev.preventDefault()}>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="card">
                  <div className="card-header align-items-center">
                    <div className="d-inline-block">
                      <h2>{t("event-page.event-page-information")}</h2>
                    </div>
                    <button
                      className="btn-close float-end"
                      onClick={() => history.push(`/organizations/${organizationId}/microsites/${micrositeId}/reservationBlocks`)}
                    ></button>
                  </div>

                  <div className="card-body">
                    {/* Name Fr */}
                    <div className="mb-3">
                      <label className="form-label">{t("event-page.event-page-create-nameFr")}</label>
                      <input
                        className="form-control"
                        value={nameFr}
                        onChange={(ev) => onNameChange(ev.target.value, setNameFr, setNameFrError)}
                        required
                        autoFocus={true}
                        disabled={eventAlreadyStarted}
                      />
                      {nameFrError && (
                        <label className="error-msg" id="nameFr-Error-Msg">
                          {t("event-page.event-page-error-nameFr")}
                        </label>
                      )}
                    </div>

                    {/* Name En */}
                    <div className="mb-3">
                      <label className="form-label">{t("event-page.event-page-create-nameEn")}</label>
                      <input
                        className="form-control"
                        value={nameEn}
                        onChange={(ev) => onNameChange(ev.target.value, setNameEn, setNameEnError)}
                        required
                        disabled={eventAlreadyStarted}
                      />
                      {nameEnError && (
                        <label className="error-msg" id="nameEn-Error-Msg">
                          {t("event-page.event-page-error-nameEn")}
                        </label>
                      )}
                    </div>

                    {/* Description Fr */}
                    <div className="mb-3">
                      <label className="form-label">{t("event-page.event-page-create-descriptionFr")}</label>
                      <input
                        className="form-control"
                        value={descriptionFr}
                        onChange={(ev) => setDescriptionFr(ev.target.value)}
                        disabled={eventAlreadyStarted}
                      />
                    </div>

                    {/* Description En */}
                    <div className="mb-3">
                      <label className="form-label">{t("event-page.event-page-create-descriptionEn")}</label>
                      <input
                        className="form-control"
                        value={descriptionEn}
                        onChange={(ev) => setDescriptionEn(ev.target.value)}
                        disabled={eventAlreadyStarted}
                      />
                    </div>

                    {/* Logo Url */}
                    <div className="mb-3">
                      <label className="form-label">{t("event-page.event-page-create-logoUrl")}</label>
                      <input
                        className="form-control"
                        value={logoUrl}
                        onChange={(ev) => setLogoUrl(ev.target.value)}
                        disabled={eventAlreadyStarted}
                      />
                    </div>

                    {/* Sale Tax */}
                    <div className="mb-3">
                      <label className="form-label">{t("event-page.event-page-create-saleTaxRate")}</label>

                      <div className="input-group mb-3">
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          value={saleTaxRate}
                          onChange={(ev) => onSaleTaxChange(ev.target.value)}
                          required
                          disabled={eventAlreadyStarted}
                        />

                        <span className="input-group-append input-group-text">%</span>
                      </div>
                      {saleTaxRateError && (
                        <label className="error-msg" id="saleTaxRate-Error-Msg">
                          {t("event-page.event-page-error-saleTaxRate")}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="card">
                  <div className="card-header align-items-center">
                    <div className="d-inline-block">
                      <h2>{t("event-page.event-page-rules")}</h2>
                    </div>
                    <button
                      className="btn-close float-end"
                      onClick={() => history.push(`/organizations/${organizationId}/microsites/${micrositeId}/reservationBlocks`)}
                    ></button>
                  </div>

                  <div className="card-body">
                    {/* Is Priced per hour */}
                    <div className="mb-3">
                      <input
                        type="checkbox"
                        id="isPricedPerHour"
                        onChange={() => onChangeIsPricedPerHour(!isPricedPerHour)}
                        checked={isPricedPerHour}
                        disabled={eventAlreadyStarted}
                      />
                      <label htmlFor="isPricedPerHour" className="ms-2">
                        {t("event-page.event-is-priced-per-hour")}
                      </label>
                    </div>

                    {/* Is Priced per minute */}
                    <div className="mb-3">
                      <input
                        type="checkbox"
                        id="isPricedPerMinute"
                        onChange={() => onChangeIsPricedPerMinute(!isPricedPerMinute)}
                        checked={isPricedPerMinute}
                        disabled={eventAlreadyStarted}
                      />
                      <label htmlFor="isPricedPerMinute" className="ms-2">
                        {t("event-page.event-is-priced-per-minute")}
                      </label>
                    </div>

                    {/* Code Type */}
                    <div className="mb-3" id="code-type">
                      <label htmlFor="codeTypeSelect" className="form-label">
                        {t("event-page.event-page-create-code-type")}
                      </label>
                      <select
                        id={"codeTypeSelect"}
                        className="form-select"
                        value={codeType}
                        onChange={(ev) => setCodeType(ev.target.value)}
                      >
                        <option key="none" id="none" value="none">
                          {t("code-type.none")}
                        </option>
                        <option key="6-digit" id="6-digit" value="6-digit">
                          {t("code-type.6-digit")}
                        </option>
                        <option key="1-to-4" id="1-to-4" value="1-to-4">
                          {t("code-type.only-1-to-4")}
                        </option>
                      </select>
                    </div>

                    {/* Price */}
                    {isPricedPerHour || isPricedPerMinute ? (
                      <>
                        {/* Max */}
                        <div className="mb-3">
                          <label className="form-label">{t("event-page.event-page-create-price-max")}</label>
                          <div className="input-group">
                            <input
                              type="number"
                              min="0.00"
                              step="0.01"
                              className="form-control"
                              value={price}
                              onChange={(ev) => onPriceChange(parseFloat(ev.target.value))}
                              disabled={eventAlreadyStarted}
                              required
                            />

                            <span className="input-group-append input-group-text">$ {currency}</span>
                            {priceError && (
                              <label className="error-msg" id="price-Error-Msg">
                                {t("event-page.event-page-error-price")}
                              </label>
                            )}
                          </div>
                        </div>

                        {/* Min */}
                        <div className="mb-3">
                          <label className="form-label">{t("event-page.event-page-create-price-min")}</label>
                          <div className="input-group">
                            <input
                              type="number"
                              min="0.00"
                              step="0.01"
                              className="form-control"
                              value={minimalPrice}
                              onChange={(ev) => onPriceChange(parseFloat(ev.target.value), setMinimalPrice)}
                              disabled={eventAlreadyStarted}
                            />

                            <span className="input-group-append input-group-text">$ {currency}</span>
                            {priceError && (
                              <label className="error-msg" id="price-Error-Msg">
                                {t("event-page.event-page-error-price")}
                              </label>
                            )}
                          </div>
                        </div>

                        {isPricedPerHour && (
                          <>
                            {/* Price per hour */}
                            <div className="mb-3">
                              <label className="form-label">{t("event-page.event-page-create-price-per-hour")}</label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  min="0.00"
                                  step="0.01"
                                  className="form-control"
                                  value={pricePerHour}
                                  onChange={(ev) => onPriceChange(parseFloat(ev.target.value), setPricePerHour)}
                                  disabled={eventAlreadyStarted}
                                  required
                                />

                                <span className="input-group-append input-group-text">$ {currency}/h</span>
                                {priceError && (
                                  <label className="error-msg" id="price-Error-Msg">
                                    {t("event-page.event-page-error-price")}
                                  </label>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {isPricedPerMinute && (
                          <>
                            {/* Price per minute */}
                            <div className="mb-3">
                              <label className="form-label">{t("event-page.event-page-create-price-per-minute")}</label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  min="0.00"
                                  step="0.01"
                                  className="form-control"
                                  value={pricePerMinute}
                                  onChange={(ev) => onPriceChange(parseFloat(ev.target.value), setPricePerMinute)}
                                  disabled={eventAlreadyStarted}
                                  required
                                />

                                <span className="input-group-append input-group-text">$ {currency}/min</span>
                                {priceError && (
                                  <label className="error-msg" id="price-Error-Msg">
                                    {t("event-page.event-page-error-price")}
                                  </label>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="mb-3">
                        <label className="form-label">{t("event-page.event-page-create-price")}</label>
                        <div className="input-group">
                          <input
                            type="number"
                            min="0.00"
                            step="0.01"
                            className="form-control"
                            value={price}
                            onChange={(ev) => onPriceChange(parseFloat(ev.target.value))}
                            required
                            disabled={eventAlreadyStarted}
                          />

                          <span className="input-group-append input-group-text">$ {currency}</span>
                          {priceError && (
                            <label className="error-msg" id="price-Error-Msg">
                              {t("event-page.event-page-error-price")}
                            </label>
                          )}
                        </div>
                      </div>
                    )}

                    {/* SizeId */}
                    <div className="mb-3" id="size">
                      <label className="form-label">{t("event-page.event-page-locker-size")}</label>
                      <select
                        id={"sizeInfoSelect"}
                        className="form-select"
                        value={sizeId}
                        disabled={eventAlreadyStarted}
                        onChange={(ev) => setSizeId(ev.target.value)}
                      >
                        <option key="Select_a_size" id="Select_a_size" value="Select a size" hidden>
                          {t("event-page.event-page-no-size-selected")}
                        </option>
                        {micrositeCompartmentSizes &&
                          micrositeCompartmentSizes.map((compartmentSize: CompartmentSize) => {
                            return (
                              <option key={"sizeOpt_" + compartmentSize.id} id={"sizeOpt_" + compartmentSize.id} value={compartmentSize.id}>
                                <span>
                                  {compartmentSize?.name.get(language)} - {compartmentSize?.description.get(language)}
                                </span>
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    {isPricedPerHour || isPricedPerMinute ? (
                      <>
                        <label htmlFor="resetTime">{t("event-page.event-page-create-resetting-time")}</label>
                        <input
                          type="time"
                          id="resetTime"
                          name="resetTime"
                          className="form-control"
                          value={resetTime}
                          onChange={(ev) => setResetTime(ev.target.value)}
                          required
                        />
                      </>
                    ) : (
                      <>
                        <div className="mb-3">
                          <div>
                            <input
                              type="radio"
                              id="byDuration"
                              name="reserve"
                              value="duration"
                              checked={eventType === EventType.Duration}
                              disabled={eventAlreadyStarted}
                              onChange={() => onEventTypeChange(EventType.Duration)}
                            />
                            <label htmlFor="byDuration" className="ms-2 me-3" onClick={() => onEventTypeChange(EventType.Duration)}>
                              {t("event-page.event-page-create-reserveByDuration")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="byResetTime"
                              name="reserve"
                              value="resetTime"
                              checked={eventType === EventType.ResetTime}
                              disabled={eventAlreadyStarted}
                              onChange={() => onEventTypeChange(EventType.ResetTime)}
                            />
                            <label htmlFor="byResetTime" className="ms-2 me-3" onClick={() => onEventTypeChange(EventType.ResetTime)}>
                              {t("event-page.event-page-create-reserveByResetTime")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="byStartEnd"
                              name="reserve"
                              value="startend"
                              checked={eventType === EventType.StartEnd}
                              disabled={eventAlreadyStarted}
                              onChange={() => onEventTypeChange(EventType.StartEnd)}
                            />
                            <label htmlFor="byStartEnd" className="ms-2" onClick={() => onEventTypeChange(EventType.StartEnd)}>
                              {t("event-page.event-page-create-reserveByStart-End")}
                            </label>
                          </div>
                        </div>

                        {eventType === EventType.Duration && (
                          <>
                            {/*duration*/}
                            <div className={`ms-4 mb-3 ${eventAlreadyStarted && "readOnly"}`} id="duration">
                              <div>
                                <span className="d-inline-block">
                                  <label className="me-2">{t("event-page.event-page-create-durationDays")}</label>
                                  <NumberDropDown
                                    id="durationDays"
                                    min={0}
                                    max={366}
                                    current={durationDays}
                                    disabled={eventAlreadyStarted}
                                    onchange={() => onDurationChange("durationDays")}
                                  />
                                </span>
                                <span className="d-inline-block">
                                  <label className="ms-2 me-2">{t("event-page.event-page-create-durationHours")}</label>
                                  <NumberDropDown
                                    id="durationHours"
                                    min={0}
                                    max={24}
                                    current={durationHours}
                                    disabled={eventAlreadyStarted}
                                    onchange={() => onDurationChange("durationHours")}
                                  />
                                </span>
                                <span className="d-inline-block">
                                  <label className="ms-2 me-2">{t("event-page.event-page-create-durationMinutes")}</label>
                                  <NumberDropDown
                                    id="durationMinutes"
                                    min={0}
                                    max={60}
                                    current={durationMinutes}
                                    disabled={eventAlreadyStarted}
                                    onchange={() => onDurationChange("durationMinutes")}
                                  />
                                </span>
                                {durationError && <label className="error-msg">{t("event-page.event-page-error-duration")}</label>}
                              </div>
                            </div>
                            <div className="ms-4 mb-3">
                              <input
                                type="checkbox"
                                id="withAvailableStartEnd"
                                onChange={() => onFixedPeriod(!isFixedPeriod)}
                                checked={isFixedPeriod}
                                disabled={eventAlreadyStarted}
                              />
                              <label htmlFor="withAvailableStartEnd" className="ms-2">
                                {t("event-page.event-page-create-checkbox")}
                              </label>
                            </div>
                            {isFixedPeriod && (
                              <div className="ms-4">
                                {/* Available Start */}
                                <div className="mb-3">
                                  <label className="form-label">{t("event-page.event-page-create-availableStart")}</label>
                                  <input
                                    id="availableStartInput"
                                    type="datetime-local"
                                    className="form-control"
                                    value={availableStart}
                                    onChange={(ev) => onAvailableStartChange(ev.target.value)}
                                    disabled={eventAlreadyStarted}
                                  />
                                  {availableStartError && <label className="error-msg">{t(availableStartError)}</label>}
                                </div>

                                {/* Available End */}
                                <div className="mb-3">
                                  <label className="form-label">{t("event-page.event-page-create-availableEnd")}</label>
                                  <input
                                    id="availableEndInput"
                                    type="datetime-local"
                                    className="form-control"
                                    value={availableEnd}
                                    onChange={(ev) => onAvailableEndChange(ev.target.value)}
                                    disabled={eventAlreadyStarted}
                                  />
                                  {availableEndError && <label className="error-msg">{t(availableEndError)}</label>}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {eventType === EventType.ResetTime && (
                          <>
                            <div className="ms-4 mb-3">
                              {/*Reset Time*/}
                              <label htmlFor="resetTime">{t("event-page.event-page-create-resetting-time")}</label>
                              <input
                                type="time"
                                id="resetTime"
                                name="resetTime"
                                className="form-control"
                                value={resetTime}
                                onChange={(ev) => setResetTime(ev.target.value)}
                                required
                              />
                            </div>
                            <div className="ms-4 mb-3">
                              <input
                                type="checkbox"
                                id="withAvailableStartEnd"
                                onChange={() => onFixedPeriod(!isFixedPeriod)}
                                checked={isFixedPeriod}
                                disabled={eventAlreadyStarted}
                              />
                              <label htmlFor="withAvailableStartEnd" className="ms-2">
                                {t("event-page.event-page-create-checkbox")}
                              </label>
                            </div>
                            {isFixedPeriod && (
                              <div className="ms-4">
                                {/* Available Start */}
                                <div className="mb-3">
                                  <label className="form-label">{t("event-page.event-page-create-availableStart")}</label>
                                  <input
                                    id="availableStartInput"
                                    type="datetime-local"
                                    className="form-control"
                                    value={availableStart}
                                    onChange={(ev) => onAvailableStartChange(ev.target.value)}
                                    disabled={eventAlreadyStarted}
                                  />
                                  {availableStartError && <label className="error-msg">{t(availableStartError)}</label>}
                                </div>

                                {/* Available End */}
                                <div className="mb-3">
                                  <label className="form-label">{t("event-page.event-page-create-availableEnd")}</label>
                                  <input
                                    id="availableEndInput"
                                    type="datetime-local"
                                    className="form-control"
                                    value={availableEnd}
                                    onChange={(ev) => onAvailableEndChange(ev.target.value)}
                                    disabled={eventAlreadyStarted}
                                  />

                                  {availableEndError && <label className="error-msg">{t(availableEndError)}</label>}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {eventType === EventType.StartEnd && (
                          <>
                            <div id="datesOnly">
                              {/* Start On */}
                              <div className="mb-3">
                                <label className="form-label">{t("event-page.event-page-create-startOn")}</label>
                                <input
                                  id="startOnInput"
                                  type="datetime-local"
                                  className="form-control"
                                  value={startOn}
                                  onChange={(ev) => onStartOnChange(ev.target.value)}
                                  disabled={eventAlreadyStarted}
                                />
                                {startOnError && <label className="error-msg">{t(startOnError)}</label>}
                              </div>

                              {/* End On */}
                              <div className="mb-3">
                                <label className="form-label">{t("event-page.event-page-create-endOn")}</label>
                                <input
                                  id="EndOnInput"
                                  type="datetime-local"
                                  className="form-control"
                                  value={endOn}
                                  onChange={(ev) => onEndOnChange(ev.target.value)}
                                  disabled={eventAlreadyStarted}
                                />
                                {endOnError && <label className="error-msg">{t(endOnError)}</label>}
                              </div>

                              {/* Available Start */}
                              <div className="mb-3">
                                <label className="form-label">{t("event-page.event-page-create-availableStart")}</label>
                                <input
                                  id="availableStartInput"
                                  type="datetime-local"
                                  className="form-control"
                                  value={availableStart}
                                  onChange={(ev) => onAvailableStartChange(ev.target.value)}
                                  disabled={eventAlreadyStarted}
                                />
                                {availableStartError && <label className="error-msg">{t(availableStartError)}</label>}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    className="btn btn-md btn-primary"
                    type="submit"
                    onClick={() => createOrUpdateReservationBlock()}
                    disabled={!isValidData()}
                  >
                    {currEventId === "cloned-event" || currEventId === "new" ? t("create-org.create-org") : t("common.save")}
                  </button>

                  <button
                    className="btn btn-md btn-outline-primary ms-2"
                    type="submit"
                    onClick={() => history.push(`/organizations/${organizationId}/microsites/${micrositeId}/reservationBlocks`)}
                  >
                    {t("common.cancel")}
                  </button>

                  {currEventId !== "new" && currEventId !== "cloned-event" && (
                    <div className="float-end" style={{ display: "flex", flexDirection: "row" }}>
                      <MicrositeDropdown
                        setTargetMicrosite={setTargetMicrosite}
                        micrositesList={micrositesList ?? []}
                        targetMicrosite={targetMicrosite}
                      />
                      <button
                        className={`btn btn-md btn-outline-primary float-end`}
                        style={{ marginRight: "20px", marginLeft: 10 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrEventId("cloned-event");
                          var prefix = "CLONE - ";
                          setNameFr(prefix + nameFr);
                          setNameEn(prefix + nameEn);
                        }}
                        disabled={false}
                      >
                        {t("event-page.event-clone")}
                      </button>
                      <button
                        className={`btn btn-danger float-end`}
                        style={{ marginLeft: 30 }}
                        onClick={() => setIsDeleteReservationBlock(true)}
                        disabled={false}
                      >
                        <FontAwesomeIcon icon={["fas", "trash-alt"]} /> {t("common.delete")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );

  function onDurationChange(id: string) {
    var select: HTMLSelectElement = document.getElementById(id) as HTMLSelectElement;

    let tmp = parseInt(select.options[select.selectedIndex].value);

    if (id === "durationDays") {
      setDurationDays(tmp);
    } else if (id === "durationHours") {
      setDurationHours(tmp);
    } else if (id === "durationMinutes") {
      setDurationMinutes(tmp);
    }

    if (tmp === 0 || !isDurationValid(tmp)) setDurationError(true);
    else setDurationError(false);
  }

  function onEventTypeChange(input: EventType) {
    if (currEventId === "new") {
      if (input !== EventType.StartEnd) {
        setStartOn(undefined);
        setEndOn(undefined);
        setAvailableStartError(undefined);
        setEndOnError(undefined);
      } else {
        setAvailableEnd(undefined);
      }
      setAvailableStart(undefined);
    }

    if (input === EventType.Duration) {
      setDurationDays(0);
      setDurationHours(0);
      setDurationMinutes(0);
    } else if (input === EventType.ResetTime) {
      setResetTime(undefined);
    } else {
      setStartOn(undefined);
    }

    setIsFixedPeriod(false);
    setEventType(input);
  }

  function isValidData(): boolean {
    if (hasError()) return false;

    if (eventAlreadyStarted || !nameFr || !nameEn || isNaN(price) || price < 0 || isNaN(saleTaxRate) || sizeId === undefined) return false;

    if (isPricedPerHour || isPricedPerMinute) {
      if (!resetTime) return false;
    } else {
      switch (eventType) {
        case EventType.Duration:
          if (!isDurationValid()) return false;
          break;
        case EventType.ResetTime:
          if (!resetTime) return false;
          break;
        case EventType.StartEnd:
          if (!startOn || !endOn) return false;
          break;
      }

      if (isFixedPeriod && (!availableStart || !availableEnd)) return false;

      if (availableStart && availableEnd) return !(availableStart > availableEnd || dateTimeNow() > availableStart);

      if (startOn && endOn) return !(startOn > endOn || dateTimeNow() > startOn);
    }
    return true;
  }

  function onNameChange(value: string, setValue: Function, setError: Function) {
    setValue(value);
    if (!value || value.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
  }

  function onAvailableStartChange(value: string) {
    setAvailableStart(value);
    if (availableEnd && value) {
      if (dateTimeNow() > value) {
        setAvailableStartError("event-page.event-page-error-availableStart-now");
      } else if (value > availableEnd) {
        setAvailableStartError("event-page.event-page-error-availableStart-availableEnd");
      } else {
        setAvailableStartError(undefined);
      }
    } else {
      setAvailableStartError(undefined);
    }
  }

  function onAvailableEndChange(value: string) {
    setAvailableEnd(value);
    if (availableStart && value) {
      if (dateTimeNow() > value) {
        setAvailableEndError("event-page.event-page-error-availableEnd-now");
      } else if (availableStart > value) {
        setAvailableEndError("event-page.event-page-error-availableStart-availableEnd");
      } else {
        setAvailableEndError(undefined);
      }
    } else {
      setAvailableEndError(undefined);
    }
  }

  function onStartOnChange(value: string) {
    setStartOn(value);
    if (value && endOn) {
      if (dateTimeNow() > value) {
        setStartOnError("event-page.event-page-error-startOn-now");
      } else if (value > endOn) {
        setStartOnError("event-page.event-page-error-startOn-endOn");
      } else {
        setStartOnError(undefined);
      }
    } else {
      setStartOnError(undefined);
    }
  }

  function onEndOnChange(value: string) {
    setEndOn(value);
    setAvailableEnd(value);
    if (startOn && value) {
      if (dateTimeNow() > value) {
        setEndOnError("event-page.event-page-error-endOn-now");
      } else if (startOn > value) {
        setEndOnError("event-page.event-page-error-startOn-endOn");
      } else {
        setEndOnError(undefined);
      }
    } else {
      setEndOnError(undefined);
    }
  }

  function onChangeIsPricedPerHour(value: boolean) {
    if (value) {
      setIsPricedPerMinute(false);
      onEventTypeChange(EventType.ResetTime);
    }

    setIsPricedPerHour(value);
  }

  function onChangeIsPricedPerMinute(value: boolean) {
    if (value) {
      setIsPricedPerHour(false);
      onEventTypeChange(EventType.ResetTime);
    }

    setIsPricedPerMinute(value);
  }

  function onPriceChange(value: number, set: (v: number) => void = setPrice) {
    set(value);
    if (value < 0) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  }

  function onSaleTaxChange(value: string) {
    const parsedValue = parseFloat(value);
    setSaleTaxRate(parsedValue);
    if (parsedValue !== undefined) {
      setSaleTaxError(!isValidSaleTax(value));
    }
  }

  function onFixedPeriod(input: boolean) {
    if (isFixedPeriod) {
      setAvailableStartError(undefined);
      setAvailableEndError(undefined);
      if (currEventId === "new") {
        setAvailableStart(undefined);
        setAvailableEnd(undefined);
      }
    }
    setIsFixedPeriod(input);
    setAvailableStart(undefined);
    setAvailableEnd(undefined);
  }

  function createOrUpdateReservationBlock() {
    let duration = 86400;
    let availableEndValue = availableEnd ?? "";
    const availableStartValue = availableStart ?? "";
    const startOnValue = startOn ?? "";
    const lockerSize = sizeId?.toLowerCase();
    const isCodeSpecified = codeType !== "6-digit" && codeType !== "1-to-4" ? false : true;

    if (sizeId === undefined) return;

    if (endOn && startOn) {
      let durationInMilisec = Date.parse(endOn) - Date.parse(startOn);
      duration = Math.round(durationInMilisec / 1000);
      availableEndValue = endOn;
    }

    if (durationDays > 0 || durationHours > 0 || durationMinutes > 0) {
      duration = durationDays * 86400 + durationHours * 3600 + durationMinutes * 60;
    }

    if (duration > 0) {
      let name = { fr: nameFr, en: nameEn };
      let description = { fr: descriptionFr, en: descriptionEn };
      let availableEnd = availableEndValue !== "" ? DateHelpers.toApiDate(new Date(availableEndValue)) : undefined;
      let availableStart = availableStartValue !== "" ? DateHelpers.toApiDate(new Date(availableStartValue)) : undefined;
      let startOn = startOnValue !== "" ? DateHelpers.toApiDate(new Date(startOnValue)) : undefined;
      let meta: { [key: string]: string | undefined } = {};

      meta[CodeType] = codeType;
      meta[ResetTime] = resetTime;

      if (isPricedPerHour || isPricedPerMinute) {
        if (isPricedPerHour) {
          meta[PricePerHour] = pricePerHour.toString();
        } else {
          meta[PricePerMinute] = pricePerMinute.toString();
        }
        meta[MinimalPrice] = minimalPrice.toString();
      }

      let data = {
        Name: name,
        Description: description,
        LogoUrl: logoUrl,
        Price: price,
        SaleTaxRate: saleTaxRate / 100,
        StartOn: startOn,
        Duration: duration,
        AvailableStart: availableStart,
        AvailableEnd: availableEnd,
        SizeId: lockerSize,
        CodeSpecified: isCodeSpecified,
        Meta: meta,
      };

      if (currEventId === "new" || currEventId === "cloned-event") createEvent(data);
      else updateEvent(data);
    }
  }

  function updateEvent(data: any) {
    data["Id"] = currEventId;

    micrositeService
      .updateReservationBlock(organizationId, micrositeId, data)
      .then(() => {
        history.push(`/organizations/${organizationId}/microsites/${micrositeId}/reservationBlocks`);
      })
      .catch((error: any) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("event-page.event-page-error-update-reservationBlock"));
        } else {
          ToastHelpers.error(t("common.error-generic"));
        }
      });
  }

  function createEvent(data: any) {
    data["MicrositeId"] = targetMicrosite?.id;
    data.Active = true;

    micrositeService
      .createReservationBlock(organizationId, data)
      .then(() => {
        if (targetMicrosite?.id) {
          return history.push(`/organizations/${organizationId}/microsites/${targetMicrosite?.id}/reservationBlocks`);
        }
      })
      .catch((error: any) => {
        if (error?.response?.status === 409) {
          ToastHelpers.error(t("event-page.event-page-error-create-reservationBlock"));
        } else {
          ToastHelpers.error(t("common.error-generic"));
        }
      });
  }

  function deleteReservationBlock() {
    if (organizationId && currEventId !== "new") {
      setIsDeleteReservationBlock(false);
      micrositeService
        .deleteReservationBlock(organizationId, micrositeId, currEventId)
        .then(() => {
          return history.push(`/organizations/${organizationId}/microsites/${micrositeId}/reservationBlocks`);
        })
        .catch((error: any) => {
          if (error?.response?.status === 409) {
            ToastHelpers.error(t("event-page.event-page-error-delete-reservationBlock"));
          } else {
            ToastHelpers.error(t("common.error-generic"));
          }
        });
    }
  }

  function hasError() {
    return (
      nameFrError ||
      nameEnError ||
      priceError ||
      durationError ||
      availableEndError ||
      availableStartError ||
      endOnError ||
      saleTaxRateError
    );
  }

  function isDurationValid(tmp: number = 0) {
    return (
      tmp !== 0 ||
      (durationDays && durationDays >= 0) ||
      (durationHours && durationHours >= 0) ||
      (durationMinutes && durationMinutes >= 0) ||
      (isFixedPeriod && (!availableStart || !availableEnd))
    );
  }
}

function isValidSaleTax(value: string): boolean {
  const regex = new RegExp(/^[0-9]{1,2}$|^[0-9]{1,2}\.[0-9]{1,3}$/);
  return regex.test(value);
}
