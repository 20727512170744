import { useEffect, useState } from "react";
import { RequestState } from "../core/request-state";
import ToastHelpers from "../core/toast-helpers";
import { CompartmentSize } from "./compartmentSize";
import compartmentSizeService from "./compartmentSize-service";

interface IProps {
  onErrorNotificationMessage: string;
}

export default function useCompartmentSizes(props: IProps) {
  const { onErrorNotificationMessage } = props;

  const [requestState, setRequestState] = useState<RequestState>(RequestState.Loading);
  const [error, setError] = useState<string>("");
  const [compartmentSizes, setCompatmentSizes] = useState<Array<CompartmentSize>>([]);

  useEffect(
    () => {
      refresh();
    },
    // eslint-disable-next-line
    []
  );

  function refresh() {
    compartmentSizeService
      .getCompartmentSizes()
      .then((compartmentSizes: Array<CompartmentSize>) => {
        setCompatmentSizes(compartmentSizes);
        setRequestState(RequestState.Success);
      })
      .catch((e) => {
        setRequestState(RequestState.Failure);
        setError(e);

        if (onErrorNotificationMessage) {
          ToastHelpers.error(onErrorNotificationMessage);
        }
      });
  }

  return { compartmentSizes, requestState, error, refresh };
}
