import { useTranslation } from "react-i18next";
import StringHelpers from "../../../services/core/string-helpers";
import { Order } from "../../../services/order/order";
import { OrderHelpers } from "../../../services/order/order-helpers";
import { Payment } from "../../../services/payment/payment";
import DateHelpers from "../../../services/core/date-helpers";

interface OrderPaymentInformationProps {
  order: Order;
  payment: Payment | undefined;
  isPersonalStorage: boolean;
  timeZone: string;
}

export default function OrderPaymentInformation(props: OrderPaymentInformationProps) {
  const { order, payment, isPersonalStorage, timeZone } = props;
  const { t } = useTranslation();
  return (
    <div className="col-12 col-lg-4">
      {payment && (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title d-flex justify-content-between">
              <span>{t("order-page.reservation-payment-informations")}</span>
            </h5>
            <p className="card-text">
              {isPersonalStorage && (
                <>
                  {t("order-page.paid-date")}{" "}
                  <strong>
                    {payment?.creationDate
                      ? DateHelpers.formatDateTime(new Date(payment?.creationDate), timeZone)
                      : t("order-page.packaging-option-none")}
                  </strong>
                  <br />
                  {t("order-page.paid-amount")}{" "}
                  <strong>
                    {t("order-page.price", {
                      cost: payment ? StringHelpers.formatPrice(payment.amount + payment.saleTaxTotal) : StringHelpers.formatPrice(0),
                      currency: payment?.currency,
                    })}

                    {OrderHelpers.isRefundedOrder(order) && <span className={"ms-2 badge bg-danger"}>{t("order-page.refunded")}</span>}
                  </strong>
                  {OrderHelpers.canRefundOrder(order) && (
                    <>
                      <br />
                      {t("order-page.card-number")}{" "}
                      <strong>
                        {payment?.cardLastFour && payment?.cardBrand
                          ? `${payment?.cardBrand} •••• •••• •••• ${payment?.cardLastFour}`
                          : t("order-page.packaging-option-none")}
                      </strong>
                      <br />
                      {t("order-page.card-expiration")}{" "}
                      <strong>
                        {payment?.cardExpirationMonth && payment?.cardExpirationYear
                          ? ` ${String(payment?.cardExpirationMonth).padStart(2, "0")}/${String(payment?.cardExpirationYear % 100).padStart(
                              2,
                              "0"
                            )}`
                          : t("order-page.packaging-option-none")}
                      </strong>
                    </>
                  )}
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
