import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MyHub } from "../../../services/hub/hub";
import hubService from "../../../services/hub/hub-service";

interface IPropHubOpenDoor {
  hub: MyHub | null;
  onClose: any;
  refresh: any;
}

enum OpeningAllDoorsState {
  Question = 0,
  Opening,
  Finished,
}

export default function HubOpenAllDoor(props: IPropHubOpenDoor) {
  const { hub, onClose, refresh } = props;
  const { t } = useTranslation();

  const [state, setState] = useState<OpeningAllDoorsState>(OpeningAllDoorsState.Question);

  return (
    <div className="panel-popup panel-centered">
      <div className="card">
        <div className="card-header align-items-center">
          <div className="d-inline-block">
            <h2>{t("hub-page.open-all-door-title")}</h2>
          </div>
          <button className="btn-close float-end" onClick={onClose}></button>
        </div>

        <div className="card-body">
          {state === OpeningAllDoorsState.Question && (
            <>
              {t("hub-page.open-all-door-message")}
              <div className="mt-3">
                <button className="btn btn-primary" onClick={openDoors}>
                  {t("common.yes")}
                </button>
                <button className="ms-2 btn btn-primary" onClick={onClose}>
                  {t("common.no")}
                </button>
              </div>
            </>
          )}

          {state === OpeningAllDoorsState.Opening && (
            <div>
              <div>{t("hub-page.opening")}</div>
            </div>
          )}

          {state === OpeningAllDoorsState.Finished && <div>{t("hub-page.opening-done")}</div>}
        </div>
      </div>
    </div>
  );

  async function openDoors(): Promise<void> {
    if (!hub) {
      return;
    }

    try {
      setState(OpeningAllDoorsState.Opening);

      await hubService.openAllDoors(hub.id);
    } finally {
      setState(OpeningAllDoorsState.Finished);
      await refresh();
    }

    const delayReset = () =>
      new Promise(() =>
        setTimeout(() => {
          onClose();
        }, 5000)
      );
    await delayReset();
    await refresh();
  }
}
