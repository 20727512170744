import { useCallback, useState } from "react";
import { useAsyncDebounce } from "react-table";
import { useTranslation } from "react-i18next";

export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const { t } = useTranslation();

  const onChange = useCallback(useAsyncDebounce((value) => {
    setGlobalFilter(value.trim());
  }, 200), [setGlobalFilter]);

  return (
    <input
      className="global-filter form-control"
      placeholder={t("common.search")}
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
}
