import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProp {
  opened: boolean;
  disabled: boolean;
}

LockComponent.defaultProps = {
  opened: false,
  disabled: false,
};
export default function LockComponent(props: IProp) {
  const { opened, disabled } = props;

  return (
    <FontAwesomeIcon
      className={"lock" + (opened ? " opened" : "") + (disabled ? " disabled" : "")}
      icon={["fas", opened ? "lock-open" : "lock"]}
    />
  );
}
