import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Compartment, CompartmentStates } from "../../services/hub/compartment";
import { CompartmentSize } from "../../services/hub/compartmentSize";
import LockComponent from "../lock-component";
import { MyHub } from "../../services/hub/hub";
import { Group } from "../../services/hub/group";

interface LockerProps {
  hub: MyHub;
  group: Group;
  compartment: Compartment;
  compartmentSizes: CompartmentSize[];
  onCompartmentSelected: (group: Group, compartment: Compartment) => void;
}

function Locker(props: LockerProps) {
  const { hub, group, compartment, compartmentSizes, onCompartmentSelected } = props;

  const reservation = hub.reservations.find(
    (r) =>
      r.groupId === group.id &&
      r.compartmentNumber === compartment.number &&
      Date.parse(r.start) < Date.now() &&
      Date.parse(r.end) > Date.now()
  );
  const isOpened = compartment.state === CompartmentStates.Opened;
  const isDeactivated = hub?.reservationDenies?.some(
    (r) => r.compartmentNumber === compartment.number && r.groupId === group.id && r.hubId === hub.id
  );
  const compartmentName = group.id + compartment.number;
  var compartmentSize = compartmentSizes.find((cs) => cs.id === compartment.sizeId);
  const compartmentHeight = (compartmentSize ? compartmentSize.height * 7.3 : 50) + "px";

  let kioskSize: CompartmentSize | undefined = undefined;
  if (hub.kiosk && hub.kiosk.sizeId && hub.kiosk.position === compartmentName) {
    kioskSize = compartmentSizes.find((cs) => cs.id === hub.kiosk?.sizeId);
  }
  const kioskHeight = (kioskSize ? kioskSize.height * 7.3 : 50) + "px";

  const showObjectDetected = hub.flowConfigs.isRiderLocker || hub.id === "swych-locker" || hub.flowConfigs.hasObjectDetection;

  return (
    <div key={compartmentName}>
      {/* Kiosk */}
      {kioskSize && (
        <div key={"kiosk" + compartmentName} style={{ height: kioskHeight }} className={"panel-kiosk"}>
          <div className="kiosk"></div>
          {/* <span className="item-number float-start">{compartment.number}</span> */}
        </div>
      )}

      {/* Compartment */}
      <div
        style={{ height: compartmentHeight }}
        className={
          "panel-compartment clickable" +
          (reservation ? " reservation" : "") +
          (isOpened ? " opened" : " closed") +
          (isDeactivated ? " disabled" : "")
        }
        onClick={() => {
          onCompartmentSelected(group, compartment);
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <span className="item-number float-start">{compartment.number}</span>

            <span className={"float-end item-lock" + (isOpened ? " opened" : " closed")}>
              {reservation?.paymentId && <FontAwesomeIcon className="me-2" icon={["fas", "credit-card"]} />}

              {showObjectDetected && (
                <FontAwesomeIcon className="me-2" style={{ opacity: !compartment.isObjectDetected ? 0.2 : 1 }} icon={["fas", "cube"]} />
              )}

              <LockComponent opened={isOpened} disabled={hub.state !== "Online" || group.state !== "Online"} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Locker;
