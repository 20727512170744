import i18next from "i18next";
import moment from "moment-timezone";
import languageService from "./language-service";
import StringHelpers from "./string-helpers";

export default class DateHelpers {
  static readonly DefaultTimeZone: string = "America/Toronto";

  static readonly UserTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  static readonly NB_OF_SECONDS_IN_A_DAY = 86400;
  static readonly NB_OF_SECONDS_IN_AN_HOUR = 3600;
  static readonly NB_OF_SECONDS_IN_A_MINUTE = 60;

  static formatDateEightChar(date: Date, timeZone: string): string {
    if (!date) {
      return "";
    }

    var dec = moment(date);

    return dec.tz(timeZone).format("YYYYMMDD");
  }

  static toDateString(date: Date, timeZone: string = DateHelpers.DefaultTimeZone) {
    return moment(date).tz(timeZone).format("YYYY-MM-DD");
  }

  static toHourString(date: Date, timeZone: string = DateHelpers.DefaultTimeZone) {
    return moment(date).tz(timeZone).format("hh:mm A");
  }

  static timeSince(date: Date): string {
    return moment(date, undefined, languageService.language).fromNow();
  }

  static formatNumberToTimeLeftString(nbOfSeconds: number): string {
    const days = Math.trunc(nbOfSeconds / this.NB_OF_SECONDS_IN_A_DAY);
    const hours = Math.trunc((nbOfSeconds % this.NB_OF_SECONDS_IN_A_DAY) / this.NB_OF_SECONDS_IN_AN_HOUR);
    const minutes = Math.trunc((nbOfSeconds % this.NB_OF_SECONDS_IN_AN_HOUR) / this.NB_OF_SECONDS_IN_A_MINUTE);
    const seconds = Math.trunc(nbOfSeconds % this.NB_OF_SECONDS_IN_A_MINUTE);

    let dateTime = "";

    if (days > 0) {
      dateTime = `${Math.max(days, 0)}${i18next.t("common.day")}${days > 1 ? "s" : ""} `;
    }
    if (hours > 0) {
      dateTime += `${Math.max(hours, 0)}${i18next.t("common.hour-symbol")} `;
    }
    dateTime += `${Math.max(minutes, 0).toString().padStart(2, "0")}${i18next.t("common.min")} `;
    dateTime += `${Math.max(seconds, 0).toString().padStart(2, "0")}${i18next.t("common.sec")}`;

    return dateTime;
  }

  static formatDateTime(date: Date, timeZone: string): string | null {
    if (!date) {
      return "";
    }

    var dec = moment(date);
    const formattedDate = dec.tz(timeZone).format("YYYY/MM/DD HH:mm");

    return formattedDate;
  }

  static formatDuration(seconds: number): string {
    if (!seconds) {
      return "";
    }

    const duration = moment.duration(seconds, "seconds");
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    let val = "";

    if (days > 0) {
      val += `${days} ${i18next.t("common.day")}`;
      val = `${StringHelpers.pluralize(days, val)} `;
    }

    if (hours > 0) {
      val += `${hours} ${i18next.t("common.hour")}`;
      val = `${StringHelpers.pluralize(hours, val)} `;
    }

    if (minutes > 0) {
      val += `${minutes}${i18next.t("common.min")}`;
    }

    return val.trim();
  }

  static getMonth(date: Date): string {
    return date.toLocaleDateString(languageService.getLanguage(), { month: "long" });
  }

  static getDay(date: Date): string {
    return date.toLocaleDateString(languageService.getLanguage(), { day: "numeric" });
  }

  static getNextDayWithTime(time: string, timeZone: string): Date {
    const now = moment().tz(timeZone);
    return moment(time, "HH:mm", "en").isBefore(now)
      ? moment(time, "HH:mm", "en").add(1, "day").toDate()
      : moment(time, "HH:mm", "en").toDate();
  }

  static formatTime(time: string, timeZone: string): string {
    const hourCycle = new Intl.Locale(navigator.language, {
      region: "US",
    }).hourCycle;
    console.log(hourCycle);
    if (hourCycle === "h24") {
      return time;
    } else {
      return moment(time, "HH:mm", "en").format("hh:mm A");
    }
  }

  static toApiDate(date: Date): string {
    return moment.utc(date).format("YYYY-MM-DDTHH:mm Z");
  }
}
