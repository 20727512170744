import StringHelpers from "../core/string-helpers";
import MemberRoleHub from "./member-role-hub";
import MemberRoleOrganization from "./member-role-organization";

export default class Member {
  id: string = "";
  type: string = "User";
  referenceId: string = "";
  firstname: string = "";
  lastname: string = "";

  streetNumber: string = "";
  street: string = "";
  city: string = "";
  state: string = "";
  country: string = "";
  postalCode: string = "";
  unit: string = "";

  phone: string = "";
  phoneExtension: number | undefined;
  email: string = "";

  externalId: string = "";
  active: boolean = true;

  features: Array<string> = new Array<string>();

  associatedOrganizations: Array<MemberRoleOrganization> = [];
  associatedHubs: Array<MemberRoleHub> = [];
}
