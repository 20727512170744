import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./customers-page.scss";
import ToastHelpers from "../../services/core/toast-helpers";
import customersService from "../../services/member/customers-service";
import Member from "../../services/member/member";
import Organization from "../../services/organization/organization";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../../components/loading-component";
import PagingComponent from "../../components/paging-component";
import { Customer } from "../../services/order/customer";
import { AddButtonComponent, EditButtonComponent, SyncButtonComponent } from "../../components/button-component";
import languageService from "../../services/core/language-service";
import { GlobalFilter } from "../../components/globalfilter-component";

interface IProps {
  organization: Organization | undefined;
}

export default function CustomersPage(props: IProps) {
  const { organization } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [customers, setCustomers] = useState<Array<Member>>([]);

  const [filteredCustomers, setFilteredCustomers] = useState<Array<Customer>>([]);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (organization) {
      setPageSize(10);

      customersService
        .getCustomers(organization.id)
        .then((customers) => {
          setCustomers(customers);
          refresh();
        })
        .catch(() => {
          ToastHelpers.error(t("customers-page.error-refresh"));
        });
    }
  }, []);

  const filterCustomers = (list: Array<Member>) => {
    if (!customers) {
      setFilteredCustomers([]);
      return;
    }

    setFilteredCustomers(list);
  };

  //Table configuration
  const columnsTemp = [
    {
      Header: t("customers-page-table-header.FirstName"),
      accessor: "firstname",
    },
    {
      Header: t("customers-page-table-header.LastName"),
      accessor: "lastname",
    },
    {
      Header: t("customers-page-table-header.Email"),
      accessor: "email",
    },
    {
      Header: t("customers-page-table-header.Phone"),
      accessor: "phone",
    },
    {
      Header: t("customers-page-table-header.Unit"),
      accessor: "unit",
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line
      columns: useMemo(() => columnsTemp, [languageService.language]),
      // eslint-disable-next-line
      data: useMemo(() => updateTableCustomersData(filteredCustomers), [filteredCustomers]),
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(state.globalFilter);
  }, [filteredCustomers]);

  return (
    <div className="container page customers-page">
      <h1>{t("customers-page.title")}</h1>

      <div className="toolbar">
        <AddButtonComponent
          title={t("customers-page.btn-create-customer")}
          onClick={() => history.push(`/create-customer`)}
        ></AddButtonComponent>

        <SyncButtonComponent className="ms-2" onClick={() => refresh()}></SyncButtonComponent>

        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </div>

      <div>
        <div className="row mt-3">
          <div className="col">
            <div className="customer card">
              <div className="card-body">
                <table {...getTableProps()} className="table  table-sm table-hover row-clikable">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return <th {...column.getHeaderProps()}>{column.render("Header")}</th>;
                        })}

                        {/* Empty column header for actions */}
                        <td></td>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {loading && (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <LoadingComponent loading={loading} />
                        </td>
                      </tr>
                    )}

                    {!loading &&
                      page.map((row, i) => {
                        prepareRow(row);

                        let customer = row.original as any;
                        let url = `/customers/${customer.id}`;

                        return (
                          <tr {...row.getRowProps()} onClick={() => history.push(url)}>
                            <td {...row.cells[0].getCellProps()}>{customer.firstname}</td>
                            <td {...row.cells[1].getCellProps()}>{customer.lastname}</td>
                            <td {...row.cells[2].getCellProps()}>{customer.email}</td>
                            <td {...row.cells[3].getCellProps()}>{customer.phone}</td>
                            <td {...row.cells[4].getCellProps()}>{customer.unit}</td>
                            <td>
                              <EditButtonComponent url={url}></EditButtonComponent>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <PagingComponent
          countTextResource={"customers-page.item-count"}
          count={rows.length}
          pageLength={pageOptions.length}
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );

  function refresh() {
    const orgId = organization?.id;
    if (orgId) {
      setLoading(true);

      customersService
        .getCustomers(orgId)
        .then((data) => {
          setCustomers(data);
          filterCustomers(data);
        })
        .catch(() => {
          ToastHelpers.error(t("orders-page.error-refresh"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
}

function updateTableCustomersData(filteredCustomers: Customer[]): Array<any> {
  const tableData: Array<any> = [];
  if (filteredCustomers) {
    filteredCustomers.forEach((customer: Customer) => {
      if (customer.id && customer.lastname && customer.firstname && customer.email) {
        tableData.push({
          id: customer.id,
          firstname: customer.firstname.toString() ?? "",
          lastname: customer.lastname.toString() ?? "",
          email: customer.email.toString() ?? "",
          phone: customer.phone?.toString() ?? "",
          unit: customer.unit?.toString() ?? "",
        });
      }
    });
  }
  return tableData;
}
