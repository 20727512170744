import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function HubLegend() {
  const { t } = useTranslation();
  return (
    <div className="mt-3">
      <span>
        <FontAwesomeIcon className="text-black bg-white border-1" icon={["far", "square"]} /> {t("common.closed")}
      </span>
      <span className="ms-3">
        <FontAwesomeIcon className="text-danger" icon={["fas", "square"]} /> {t("common.opened")}
      </span>
      <span className="ms-3">
        <FontAwesomeIcon className="text-success" icon={["fas", "square"]} /> {t("hub-page.state-with-reservation")}
      </span>
      <span className="ms-3">
        <FontAwesomeIcon className="text-muted" icon={["fas", "square"]} /> {t("hub-page.state-unavailable")}
      </span>
      <span className="ms-3">
        <FontAwesomeIcon className="text-danger bg-white border-1" icon={["far", "square"]} />{" "}
        {t("hub-page.reservationDenies-label-deactivated")}
      </span>
    </div>
  );
}
