import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import "./timerComponent.scss";
import DateHelpers from "../services/core/date-helpers";

type Props = {
  timeInSeconds: number;
  message: string;
  onTimerEnds: VoidFunction;
  className?: string;
};

export default function TimerComponent(props: Props) {
  const { timeInSeconds, message, onTimerEnds, className } = props;
  const [counter, setCounter] = useState(timeInSeconds);

  useEffect(() => {
    if (counter <= 0) {
      onTimerEnds();
    }

    const timer = (counter > 0 && setInterval(() => setCounter(counter - 1), 1000)) || undefined;

    return timer && (() => clearInterval(timer));
  }, [counter]);

  const dateTime = DateHelpers.formatNumberToTimeLeftString(counter);

  return (
    <div className={className ?? "countdown-red"}>
      {message && <Trans i18nKey={message} values={{ dateTimeFormat: dateTime }} />}
      {!message && <strong>{dateTime}</strong>}
    </div>
  );
}
