import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { EmailFormComponent } from "../../components/customer-information-components/email-component";
import { FirstNameComponent } from "../../components/customer-information-components/firstname-component";
import { LastNameComponent } from "../../components/customer-information-components/lastname-components";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import LoadingComponent from "../../components/loading-component";
import useHubs from "../../components/useEffect/useHubs";
import languageService from "../../services/core/language-service";
import ToastHelpers from "../../services/core/toast-helpers";
import Member from "../../services/member/member";
import Microsite from "../../services/micro-site/microsite";
import micrositeService from "../../services/micro-site/microsite-service";
import ReservationBlock from "../../services/micro-site/reservationBlock";
import "./reserve-locker.scss";
import { InputComponent } from "../../components/input";
import DateHelpers from "../../services/core/date-helpers";
import { ResetTime } from "../../assets/meta-table-keys";

type Props = {
  member: Member;
  setNoConnection: any;
};

interface IParamTypes {
  micrositeId: string;
  reservationBlockId: string;
}

export default function ContributorReserveLockerPage(props: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const language: string = languageService.getLanguage();

  const { micrositeId, reservationBlockId } = useParams<IParamTypes>();
  const { member, setNoConnection } = props;
  const { hubs, isHubsLoading } = useHubs();
  const [reservationBlock, setReservationBlock] = useState<ReservationBlock | null>();
  const [organisationId, setOrganisationId] = useState<String>("");

  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [referenceNumber, setReferenceNumber] = useState<string>("");
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [firstnameError, setFirstnameError] = useState<string | undefined>(undefined);
  const [lastnameError, setLastnameError] = useState<string | undefined>(undefined);
  const [phoneError, setPhoneError] = useState<string>();

  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<string | undefined>(undefined);

  const [microsite, setMicrosite] = useState<Microsite>();

  const [loading, setLoading] = useState<boolean>(true);

  const isLoading = useMemo(() => {
    return !isHubsLoading && !loading ? false : true;
  }, [loading, isHubsLoading]);

  //const showSms = microsite?.countryCode.toLocaleLowerCase() === "ca";
  const showSms = true;

  useEffect(() => {
    //Gather the microsite and reservation block requested
    micrositeService.getMicrosite(micrositeId).then((microsite: Microsite | undefined) => {
      if (microsite) {
        setMicrosite(microsite);
        setOrganisationId(microsite.organizationId);

        const reservationBlock = microsite.reservationBlocks.find((rb) => rb.id === reservationBlockId);
        if (reservationBlock) {
          setReservationBlock(reservationBlock);
          setLoading(false);
        }
      }
    });
  }, [micrositeId, reservationBlockId, member]);

  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  }

  if (!reservationBlock) {
    return (
      <div className="container page reerve-locker-page">
        <div className="col col-lg-6 offset-lg-3">
          <div className="card">{!reservationBlock && <div>{t("micro-site-reserve-locker.no-reservation-block")}</div>}</div>
        </div>
      </div>
    );
  }

  const until = reservationBlock ? moment(reservationBlock.startOn).add(reservationBlock.duration, "seconds").toDate() : new Date();

  return (
    <div className="container page reserve-locker-page">
      <div className="card">
        <div className="card-header align-items-center">
          <div className="d-inline-block">
            {reservationBlock?.logoUrl && <img src={reservationBlock?.logoUrl} alt="" className="img-logo" />}
            <h2 className="mt-2"> {reservationBlock?.name.get(language)}</h2>
          </div>
          <button
            className="btn-close float-end"
            onClick={() => {
              history.push(`/organizations/${organisationId}/microsites/${micrositeId}/reservationBlocks`);
            }}
          ></button>
        </div>

        <div className="card-body">
          <p className="mb-4">
            {hubs?.every((hub) => hub.state !== "Online" || !microsite?.hubIds.includes(hub.id)) && (
              <>
                <span className="text-danger">
                  <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="me-2" />
                  {t("contributor-reserve-locker-page.no-hub-online")}
                </span>
                <br />
              </>
            )}
            {reservationBlock.description.get(language)}
            <br />
            {reservationBlock.startOn && (
              <Trans
                i18nKey="micro-site-page.locker-available"
                values={{
                  start: DateHelpers.formatDateTime(new Date(reservationBlock.startOn), DateHelpers.UserTimeZone),
                  end: DateHelpers.formatDateTime(until, DateHelpers.UserTimeZone),
                }}
              />
            )}
            {!reservationBlock.startOn && (
              <Trans
                i18nKey="micro-site-page.locker-available-now"
                values={{ duration: DateHelpers.formatDuration(reservationBlock.duration) }}
              />
            )}
          </p>

          <>
            <p>{t("contributor-reserve-locker-page.msg-fill-info")}</p>

            <div className="row">
              <div className="col-12 col-lg-8">
                <form onSubmit={(ev) => ev.preventDefault()}>
                  <div className="mb-4">
                    <EmailFormComponent
                      value={email}
                      setValue={setEmail}
                      msgError={emailError}
                      onMsgErrorChanged={setEmailError}
                    ></EmailFormComponent>

                    <FirstNameComponent
                      value={firstname}
                      setValue={setFirstname}
                      msgError={firstnameError}
                      onMsgErrorChanged={setFirstnameError}
                    ></FirstNameComponent>
                    <LastNameComponent
                      value={lastname}
                      setValue={setLastname}
                      msgError={lastnameError}
                      onMsgErrorChanged={setLastnameError}
                    ></LastNameComponent>
                    {showSms && (
                      <PhoneFormComponent
                        value={phone}
                        setValue={setPhone}
                        onMsgErrorChanged={setPhoneError}
                        msgError={phoneError}
                        label={t("common.phone")}
                      />
                    )}

                    <div className="mb-3">
                      <label className="form-label">{t("contributor-reserve-locker-page.reference-number")}</label>
                      <input
                        className="form-control"
                        type="text"
                        value={referenceNumber}
                        onChange={(ev) => setReferenceNumber(ev.target.value)}
                      />
                    </div>
                    {reservationBlock.codeSpecified && (
                      <InputComponent
                        labelText={t("input-component.code-label")}
                        type="code-sl5c"
                        value={code}
                        setValue={setCode}
                        msgError={codeError}
                        onMsgErrorChanged={setCodeError}
                        required={true}
                        regExp={/^[1-4]{6}$/}
                      />
                    )}
                  </div>
                  <button onClick={() => CreateOrder()} className="btn btn-primary" disabled={!canCreateOrder()}>
                    {t("common.reserve-locker")}
                  </button>
                </form>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );

  function canCreateOrder(): boolean {
    return !!(
      !emailError &&
      !phoneError &&
      !lastnameError &&
      !firstnameError &&
      (email !== "" || phone !== "" || referenceNumber !== "") &&
      !codeError &&
      (code !== "" || !reservationBlock?.codeSpecified)
    );
  }

  function CreateOrder() {
    if (reservationBlock) {
      setLoading(true);

      let end = undefined;
      if (reservationBlock.meta?.[ResetTime]) {
        let date = DateHelpers.getNextDayWithTime(reservationBlock.meta[ResetTime], DateHelpers.UserTimeZone);
        end = DateHelpers.toApiDate(date);
      }

      micrositeService
        .createOrder(micrositeId, reservationBlock.id, true, phone, email, firstname, lastname, referenceNumber, code, language, end, true)
        .then((orderId) => {
          if (orderId) {
            history.push(`/orders/${orderId}`);
          } else {
            ToastHelpers.error(t("create-order-page.error-conflict"));
          }
        })
        .catch(() => {
          setNoConnection(true);
          ToastHelpers.error(t("create-order-page.error-conflict"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
}
