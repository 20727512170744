import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CardPaymentIntentResponse } from "../../services/payment/payment-intent-response";
import { CardPaymentRequest } from "../../services/payment/payment-request";
import { CardPaymentResponse } from "../../services/payment/payment-response";
import paymentService from "../../services/payment/payment-service";
import { PaymentState } from "../../services/payment/payment-state";
import PaymentFormSquare from "./payment-form-square";
import PaymentFormStripe from "./payment-form-stripe";

type Props = {
  paymentIntent: CardPaymentIntentResponse;
  organizationId: string;
  orderToken: string;
  cost: string;
  currency: string;
  hasPayment: boolean;
  paynow: boolean;
  minPrice?: string;
};

export default function PaymentForm(props: Props) {
  const { paymentIntent, organizationId, orderToken, currency, cost, hasPayment, paynow, minPrice } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [message, setMessage] = useState<any>(null);

  useEffect(() => {
    if (process.env.REACT_APP_STRIPE_APP_PUBLIC_KEY && paymentIntent.providerType === "Stripe") {
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_APP_PUBLIC_KEY));
    }
  }, []);

  if (hasPayment) {
    const appearance = {
      theme: "stripe",
    };
    const stripeOptions: any = {
      appearance,
    };

    return (
      <>
        {paymentIntent.providerType === "Stripe" && stripePromise && (
          <Elements options={stripeOptions} stripe={stripePromise}>
            <PaymentFormStripe
              organizationId={organizationId}
              orderToken={orderToken}
              cost={cost}
              currency={currency}
              paynow={paynow}
              minPrice={minPrice}
            />
          </Elements>
        )}

        {paymentIntent.providerType === "Square" && (
          <PaymentFormSquare
            organizationId={organizationId}
            orderToken={orderToken}
            cost={cost}
            currency={currency}
            locationId={paymentIntent.location}
            paynow={paynow}
            minPrice={minPrice}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <button className="btn btn-primary pushable rounded-3 shadow-lg" onClick={payFreeReservation}>
          <span className="front rounded-3">{t("payment.reserve")}</span>
        </button>

        {message && <div id="payment-message">{message}</div>}
      </>
    );
  }

  async function payFreeReservation() {
    const cardPaymentRequest = new CardPaymentRequest();
    cardPaymentRequest.token = orderToken;
    cardPaymentRequest.orderToken = orderToken;
    await paymentService
      .pay(cardPaymentRequest, organizationId)
      .then((result: CardPaymentResponse) => {
        switch (result.state) {
          case PaymentState.Success:
            setMessage("");
            console.log("Payment charge Success");
            history.push(`/my-reservations/${orderToken}`);
            break;
          case PaymentState.Error:
            setMessage(result.error ?? t("common.error-generic"));
            break;
          case PaymentState.Timeout:
            setMessage(t("common.error-generic"));
            break;
        }
      })
      .catch(() => {
        setMessage(t("common.error-generic"));
      });
  }
}
