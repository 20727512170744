import moment from "moment";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../components/loading-component";
import languageService from "../../services/core/language-service";
import StringHelpers from "../../services/core/string-helpers";
import Member from "../../services/member/member";
import Microsite from "../../services/micro-site/microsite";
import { MicrositeHelpers } from "../../services/micro-site/microsite-helpers";
import micrositeService from "../../services/micro-site/microsite-service";
import ReservationBlock from "../../services/micro-site/reservationBlock";
import "./micro-site-page.scss";
import { MinimalPrice, PricePerHour } from "../../assets/meta-table-keys";
import EventCard from "./event-card";

type Props = {
  member: Member | null;
  setNoConnection: any;
};

interface IParamTypes {
  micrositeId: string;
}

export default function MicroSitePage(props: Props) {
  const { member, setNoConnection } = props;

  const { t } = useTranslation();
  const language: string = languageService.getLanguage();
  const history = useHistory();
  const { micrositeId } = useParams<IParamTypes>();

  const [loading, setLoading] = useState<boolean>(true);
  const [microsite, setMicrosite] = useState<Microsite>();
  const [reservationBlocks, setReservationBlocks] = useState<ReservationBlock[]>([]);

  useEffect(() => {
    micrositeService
      .getMicrosite(micrositeId)
      .then((microsite: Microsite | undefined) => {
        if (microsite) {
          setMicrosite(microsite);
          if (microsite.reservationBlocks[0]) {
            setReservationBlocks(
              microsite.reservationBlocks.sort((rb1, rb2) => new Date(rb1.startOn).getTime() - new Date(rb2.startOn).getTime())
            );
          }
        }
      })
      .catch(() => setNoConnection(true))
      .finally(() => {
        setLoading(false);
      });
  }, [member, setNoConnection, micrositeId]);

  if (loading) {
    return <LoadingComponent loading={loading} />;
  }

  if (!microsite) {
    return (
      <div className="container page micro-site-page">
        {!microsite && (
          <div className="text-center mt-4">
            <p>{t("micro-site-page.no-reservationBlock")}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="container page micro-site-page ">
      <div className="row">
        <div className="col-12 offset-lg-2 col-lg-8 page-background-boxing shadow">
          {/* No reservation */}
          {reservationBlocks.length === 0 && (
            <div className="row">
              <div className="col-12 offset-lg-2 col-lg-8">
                <div className="card-body">
                  <h1>{microsite.name.get(language)}</h1>
                  <h5>{t("micro-site-page.no-reservationBlock")}</h5>
                </div>
              </div>
            </div>
          )}

          {/* Display the reservations */}
          {reservationBlocks.map((reservationBlock) => {
            return (
              <EventCard
                reserve={reserve}
                reservationBlock={reservationBlock}
                language={language}
                microsite={microsite}
                canShowCreateReservation={canShowCreateReservation}
              />
            );
          })}
        </div>
      </div>
    </div>
  );

  function canShowCreateReservation(reservationBlock: ReservationBlock): boolean {
    return MicrositeHelpers.reservationBlockIsAvailable(reservationBlock);
  }

  async function reserve(reservationBlock: ReservationBlock) {
    const reserveUrl = `/sites/${micrositeId}/signin-reserve-locker/${reservationBlock?.id}`;
    history.push(reserveUrl);
  }
}
