import React, { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

type Item = {
  id: number;
  name: string;
  objId: string;
};

interface AutocompleteSearchProps {
  items: Array<Item>;
  setter: Function | undefined;
}

export default function AutocompleteSearch(props: AutocompleteSearchProps) {
  const [currSelection, setCurrSelection] = useState<String>("");

  const handleOnSelect = (item: any) => {
    setCurrSelection(item.objId);
  };

  const propagateCurrSelection = () => {
    if (props.setter) {
      props.setter(currSelection);
    }
  };

  return (
    <div>
      <ReactSearchAutocomplete<Item> items={props.items} onSelect={handleOnSelect} />
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: "5px",
          justifyContent: "right",
        }}
      >
        <button
          className="btn btn-primary"
          style={{ width: "300px" }}
          onClick={() => {
            propagateCurrSelection();
          }}
        >
          Select
        </button>
      </div>
    </div>
  );
}
