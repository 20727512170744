import languageService from "../core/language-service";
import { HubHelpers } from "../hub/hub-helper";
import hubService from "../hub/hub-service";
import { Order } from "../order/order";
import ServicesApiBaseService from "../services-api-base-service";
import Microsite from "./microsite";
import ReservationBlock from "./reservationBlock";

interface ICreateReservationBlock {
  MicrositeId: string;
  Name: { [key: string]: string };
  Description?: { [key: string]: string | undefined };
  LogoUrl?: string;
  Price: number;
  StartOn?: string;
  Duration: number;
  AvailableStart?: string;
  AvailableEnd?: string;
  CodeSpecified: boolean;
  Meta?: { [key: string]: string | undefined };
  Active: boolean;
}

interface IUpdateReservationBlock {
  Id: string;
  Name: { [key: string]: string };
  Description?: { [key: string]: string | undefined };
  LogoUrl?: string;
  Price: number;
  StartOn?: string;
  Duration: number;
  AvailableStart?: string;
  AvailableEnd?: string;
  CodeSpecified: boolean;
  Meta?: { [key: string]: string | undefined };
}

class MicrositeService extends ServicesApiBaseService {
  async getMicrosite(micrositeId: string, filter: string = "Active"): Promise<Microsite | undefined> {
    const microsite = await this.getObject<Microsite>(`${this.apiUrl}/microsites/${micrositeId}?filter=${filter}`);
    if (microsite) {
      if (microsite.name) {
        microsite.name = new Map(Object.entries(microsite?.name));
      }
      if (microsite.reservationBlocks) {
        microsite.reservationBlocks.map((reservationBlock) => {
          if (reservationBlock.name) {
            reservationBlock.name = new Map(Object.entries(reservationBlock.name));
          }

          if (reservationBlock.description) {
            reservationBlock.description = new Map(Object.entries(reservationBlock.description));
          }
          return reservationBlock;
        });
      }
    }

    return microsite || undefined;
  }

  async getTimeUntilExpired(orderId: String | undefined): Promise<any> {
    const result = await this.getObject<any>(`${this.apiUrl}/microsites/timeUntilExpired/${orderId}`);
    return result || 0;
  }

  async getReservationBlockById(micrositeId: string, reservationBlockId: string): Promise<ReservationBlock | undefined> {
    const reservationBlock = await this.getObject<any>(`${this.apiUrl}/microsites/${micrositeId}/reservationBlocks/${reservationBlockId}`);
    if (reservationBlock) {
      if (reservationBlock.name) {
        reservationBlock.name = new Map(Object.entries(reservationBlock.name));
      }

      if (reservationBlock.description) {
        reservationBlock.description = new Map(Object.entries(reservationBlock.description));
      }
    }
    return reservationBlock;
  }

  async createReservationBlock(organizationId: string, data: ICreateReservationBlock): Promise<number | undefined> {
    return await this.postObject<any, number>(
      `${this.apiUrl}/microsites/${data.MicrositeId}/organization/${organizationId}/reservationBlocks`,
      data
    );
  }

  async updateReservationBlock(organizationId: string, micrositeId: string, data: IUpdateReservationBlock): Promise<void> {
    return await this.putObject<any>(
      `${this.apiUrl}/microsites/${micrositeId}/organization/${organizationId}/reservationBlocks/${data.Id}`,
      data
    );
  }

  async deleteReservationBlock(organizationId: string, micrositeId: string, reservationBlockId: string): Promise<void> {
    return await this.deleteObject<any>(
      `${this.apiUrl}/microsites/${micrositeId}/organization/${organizationId}/reservationBlocks/${reservationBlockId}`
    );
  }

  async createOrder(
    micrositeId: string,
    reservationBlockId: string,
    createPayment: boolean,
    phoneNumber?: string | undefined,
    email?: string | undefined,
    firstname?: string | undefined,
    lastname?: string | undefined,
    referenceId?: string | undefined,
    code?: string | undefined,
    language?: string | undefined,
    end: string | undefined = undefined,
    allowOffline: boolean = true
  ): Promise<string> {
    const result = await this.postObject<any, string>(
      `${this.apiUrl}/microsites/${micrositeId}/reservationBlocks/${reservationBlockId}/orders`,
      {
        phone: phoneNumber,
        email: email,
        firstname: firstname,
        lastname: lastname,
        referenceId: referenceId,
        createPayment: createPayment,
        code: code,
        language: language || languageService.getLanguage(),
        end: end,
        allowOffline: allowOffline,
      }
    );
    return result || "";
  }

  async resendNotification(
    micrositeId: string,
    reservationBlockId: string,
    orderId: string,
    phoneNumber?: string | undefined,
    email?: string | undefined,
    referenceId?: string | undefined
  ): Promise<string> {
    const result = await this.postObject<any, string>(
      `${this.apiUrl}/microsites/${micrositeId}/reservationBlocks/${reservationBlockId}/resendNotification`,
      {
        phone: phoneNumber,
        email: email,
        referenceId: referenceId,
        orderId: orderId,
      }
    );
    return result || "";
  }

  async getMicrositeSizesIds(microsite: Microsite): Promise<Set<String>> {
    let sizeIds = new Set<String>();
    const hubs = await hubService.getHubs();

    microsite.hubIds.forEach((hubId) => {
      let hub = hubs.find((h) => h.id === hubId);
      if (hub) {
        let compartmentSizes = HubHelpers.getCompartmentSizeIds(hub);
        compartmentSizes.forEach((sizeId) => sizeIds.add(sizeId));
      }
    });

    return sizeIds;
  }

  async getEventOrders(micrositeId: string, eventId: string): Promise<Array<Order>> {
    let result = await this.getObject<Array<Order>>(`${this.apiUrl}/microsites/${micrositeId}/reservationBlocks/${eventId}/orders`);

    return result || [];
  }
}

const micrositeService = new MicrositeService();
export default micrositeService;
