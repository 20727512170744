import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { AssetColors } from "../../assets/assets-colors";
import { HeaderIcon } from "../../components/header";

interface NoConnectionPageProps {
  changeLanguage: any;
}

export default function NoConnectionPage(props: NoConnectionPageProps) {
  const { changeLanguage } = props;
  const { t } = useTranslation();

  function reload() {
    window.location.reload();
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
              <nav className="navbar reset">
                <ul className="navbar-nav align-items-center ms-auto">
                  <li className="nav-item">
                    <button className="nav-link" onClick={changeLanguage}>
                      <FontAwesomeIcon className="me-2" icon={["fas", "globe"]} />
                      {t("language.display-long")}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div className="container page sign-up-page pt-5">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <div className="card card-full">
              <div className="row">
                <div className="col col-xl-8">
                  <div className="logo">
                    <HeaderIcon logo={AssetColors.Blue} />
                  </div>

                  <div className="card-left">
                    <div className="card-body">
                      <h5>{t("no-connection-page.oups")}</h5>
                      <h6>{t("no-connection-page.title")}</h6>
                      <h6>{t("no-connection-page.subtitle")}</h6>

                      <button className="btn btn-lg btn-primary" onClick={async () => await reload()}>
                        {t("no-connection-page.retry")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-right d-none d-xl-inline-block col-4 gx-0">
                  <img className="img-fluid" src="/images/sign-up.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
