import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { SyncButtonComponent } from "../../components/button-component";
import HubLocationComponent from "../../components/hub-location-component";
import LoadingComponent from "../../components/loading-component";
import useHubs from "../../components/useEffect/useHubs";
import languageService from "../../services/core/language-service";
import ToastHelpers from "../../services/core/toast-helpers";
import Member from "../../services/member/member";
import MemberExtensions from "../../services/member/member-extensions";
import MemberRoleOrganization from "../../services/member/member-role-organization";
import memberService from "../../services/member/member-service";
import Microsite from "../../services/micro-site/microsite";
import { MicrositeHelpers } from "../../services/micro-site/microsite-helpers";
import micrositeService from "../../services/micro-site/microsite-service";
import EventsTable from "./events-table";
import MicrositeZones from "./microsite-zones";

interface Props {
  member: Member | undefined;
}

interface IParamTypes {
  organizationId: string;
  micrositeId: string;
}

interface ZoneProps {
  microsite: Microsite | undefined;
  isLoading: boolean;
}

export default function EventsPage(props: Props) {
  const { t } = useTranslation();
  const { member } = props;
  const { organizationId, micrositeId } = useParams<IParamTypes>();
  const language: string = languageService.getLanguage();

  const [microsite, setMicrosite] = useState<Microsite | undefined>();
  const [currentOrganization, setCurrentOrganization] = useState<MemberRoleOrganization | undefined>(undefined);
  const { hubs, isHubsLoading } = useHubs();
  const [loading, setLoading] = useState<boolean>(true);

  const isLoading = useMemo(() => {
    return !isHubsLoading && !loading ? false : true;
  }, [loading, isHubsLoading]);

  const [isOrgAdmin, setIsOrgAdmin] = useState<boolean>(false);
  const [isOrgContributor, setIsOrgContributor] = useState<boolean>(false);

  useEffect(
    () => {
      refreshEvents();
    },
    // eslint-disable-next-line
    [currentOrganization]
  );

  useEffect(
    () => {
      if (member) {
        setIsOrgContributor(memberService.isOrganizationContributor(member, organizationId));
        setIsOrgAdmin(memberService.isOrganizationAdmin(member, organizationId));
      }
    },
    // eslint-disable-next-line
    [member]
  );

  return (
    <>
      {member && currentOrganization && isOrgContributor && (
        <div className="container pt-3 page">
          <div className="row">
            <div className="page-breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to={`/organizations`}>{t("common.organizations")}</Link>
                  {` / `}
                  <Link to={`/organizations/${organizationId}`}>{currentOrganization?.entity?.name}</Link>
                  {` / `}
                  <Link to={`/organizations/${organizationId}/microsites`}>{t("microsites-page.name")}</Link>
                  {` / ${microsite?.name.get(language)}`}
                </li>
              </ul>
            </div>

            <div className="header">
              <h1>{microsite?.name.get(language).toUpperCase()}</h1>
            </div>

            <div className="card col-6 col-lg-4">
              <div className="card-body">
                <h5 className="card-title d-flex justify-content-between">
                  <span>{t("common.hubs")}</span>
                </h5>
                <LoadingComponent loading={isHubsLoading} />
                {hubs && !isHubsLoading && (
                  <p className="card-text">
                    {hubs
                      .filter((hub) => microsite?.hubIds.includes(hub.id))
                      .map((hub) => {
                        return (
                          <>
                            <HubLocationComponent hub={hub} />
                            <br />
                          </>
                        );
                      })}
                  </p>
                )}
              </div>
            </div>

            <MicrositeZones microsite={microsite} isLoading={isLoading} />

            <div className="card">
              <div className="card-header align-items-center">
                <h2>{t("events-page.events")}</h2>
              </div>

              <div className="card-body">
                <div className="col-12">
                  {isOrgAdmin && (
                    <Link className="btn btn-primary mb-4" to="reservationBlocks/new">
                      {t("events-page.create-event")}
                    </Link>
                  )}

                  <a
                    title={t("orders-page.microsite-reservation-button")}
                    className="btn btn-outline-primary mb-4 ms-2"
                    href={`/sites/${micrositeId}`}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={["fas", "external-link-square-alt"]} />
                    <span> {t("orders-page.microsite-reservation-button")}</span>
                  </a>

                  <SyncButtonComponent className="mb-4 ms-2" onClick={() => refreshEvents()} />
                  <EventsTable microsite={microsite} isOrgAdmin={isOrgAdmin} isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  function refreshEvents() {
    if (member) {
      let organization = MemberExtensions.getMemberRoleOrganization(member, organizationId);
      if (organization && memberService.isOrganizationContributor(member, organizationId)) {
        setCurrentOrganization(organization);
        micrositeService
          .getMicrosite(micrositeId, "All")
          .then((microsite) => {
            if (microsite) {
              microsite.reservationBlocks = microsite.reservationBlocks.sort((rb1, rb2) => {
                return MicrositeHelpers.compareReservationBlocks(rb1, rb2) * -1;
              });
              setMicrosite(microsite);
            } else ToastHelpers.error(t("events-page.events-page-error-refresh"));
          })
          .catch((error: any) => {
            if (error?.response?.status === 409) {
              ToastHelpers.error(t("events-page.events-page-error-refresh"));
            } else {
              ToastHelpers.error(t("common.error-generic"));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }
}
