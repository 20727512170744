import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

interface IParamTypes {
  micrositeId: string;
}

export default function OrderExpiredPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const { micrositeId } = useParams<IParamTypes>();

  return (
    <div className="container page orders-page">
      <div className="col col-lg-6 offset-lg-3">
        <div className="customer card">
          <div className="card-header align-items-center">
            <div className="d-inline-block">
              <h2>{t("micro-site-reserve-locker.order-expired")}</h2>
            </div>
            <button className="btn-close float-end" onClick={() => history.push(`/sites/${micrositeId}`)}></button>
          </div>

          <div className="card-body">
            <div className="align-items-center">
              <button className="btn btn-outline-primary me-2" onClick={() => history.push(`/sites/${micrositeId}`)}>
                {t("micro-site-reserve-locker.new-reservation")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
