export class FlowConfigs {
  organizationId: string = "";
  personalStorageEnabled: boolean = false;
  parcelEnabled: boolean = false;
  customerSelectionEnabled: boolean = false;
  rfidEnabled: boolean = false;
  orderLookupEnabled: boolean = false;
  isRiderLocker: boolean = false;
  hasObjectDetection: boolean = false;
}
