import ReservationBlock from "./reservationBlock";

export class MicrositeHelpers {
  static reservationBlockIsAvailable(rb: ReservationBlock) {
    let dateNow = new Date(Date.now());
    let availableStart = new Date(rb.availableStart);
    return availableStart <= dateNow;
  }

  static reservationBlockIsPassed(rb: ReservationBlock) {
    if (!rb.availableEnd) {
      return false;
    }

    let dateNow = new Date(Date.now());
    let availableEnd = new Date(rb.availableEnd);
    return availableEnd <= dateNow;
  }

  static compareReservationBlocks(rb1: ReservationBlock, rb2: ReservationBlock): number {
    if (this.reservationBlockIsPassed(rb1) && !this.reservationBlockIsPassed(rb2)) {
      return -1;
    }
    if (this.reservationBlockIsPassed(rb2) && !this.reservationBlockIsPassed(rb1)) {
      return 1;
    }

    if (this.reservationBlockIsAvailable(rb1) && !this.reservationBlockIsAvailable(rb2)) {
      return -1;
    }
    if (this.reservationBlockIsAvailable(rb2) && !this.reservationBlockIsAvailable(rb1)) {
      return 1;
    }

    if (rb1.availableEnd && rb2.availableEnd && rb1.availableEnd > rb2.availableEnd && rb1.duration > rb2.duration) {
      return 1;
    } else if (rb1.availableEnd && rb2.availableEnd && rb1.availableEnd === rb2.availableEnd && rb1.duration < rb2.duration) {
      return -1;
    }

    if (
      (rb1.availableStart && rb2.availableStart && rb1.availableStart < rb2.availableStart) ||
      (rb1.startOn && rb2.startOn && rb1.startOn < rb2.startOn) ||
      (!rb1.startOn && !rb1.availableStart && (rb2.startOn || rb2.availableStart))
    ) {
      return -1;
    }

    return 0;
  }
}
