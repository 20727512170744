import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import languageService from "../services/core/language-service";
import StringHelpers from "../services/core/string-helpers";
import "./dateRangeComponent.scss";
import DateHelpers from "../services/core/date-helpers";

interface DateRangeComponentProps {
  id: string;
  default: DateRangeOptions;
  disabled?: boolean;
  onDateChange: (start: string, end: string) => void;
  start: string;
  end: string;
}

export enum DateRangeOptions {
  Today = "analytic.today",
  Week = "analytic.7days",
  Month = "analytic.30days",
  Custom = "analytic.customize",
}

export default function DateRangeComponent(props: DateRangeComponentProps) {
  const { t } = useTranslation();

  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<string | undefined>();

  const [startDate, setStartDate] = useState<string>(props.start);
  const [endDate, setEndDate] = useState<string>(props.end);

  const onStartDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStartDate(event.target.value);
    },
    [setStartDate]
  );

  const onEndDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEndDate(event.target.value);
    },
    [setEndDate]
  );

  const selectionOptions = useMemo(() => {
    return ["analytic.today", "analytic.7days", "analytic.30days", "analytic.customize"];
  }, [t]);

  useEffect(() => {
    setCurrentOption(selectionOptions.find((option) => option === props.default.toString()));
    setIsCustom(false);
  }, [languageService.language]);

  const onItemClick = async (option: string) => {
    setCurrentOption(option);
    await setDataBySelectedIndex(option);
  };

  return (
    <div id="date-range-component">
      <div className="collapse-date-range collapse">
        <a
          className="close-collapse-toggler collapsed"
          data-bs-toggle="collapse"
          data-bs-target=".collapse-date-range"
          aria-controls="collapse-date-range"
          aria-expanded="false"
        ></a>
      </div>

      <button
        className="btn btn-outline-primary arrow"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target=".collapse-date-range"
        aria-controls="collapse-date-range"
        aria-expanded="false"
      >
        <span>
          {props.start && props.end && (
            <>
              {props.start.substring(0, 4) === props.end.substring(0, 4) ? (
                <>
                  {props.start.substring(5, props.start.length)} {t("common.to")} {props.end.substring(5, props.end.length)}
                </>
              ) : (
                <>
                  {props.start} {t("common.to")} {props.end}
                </>
              )}
            </>
          )}
        </span>
      </button>

      <div className="position-relative mt-2">
        <div style={{ width: "430px" }} className={`collapse-date-range position-absolute collapse top-0`}>
          <div className="card card-body">
            <div className="mb-3 btn-group" role="group">
              {selectionOptions.map((option) => {
                let active = currentOption && option === currentOption ? " active" : "";
                const isCustom = option === DateRangeOptions.Custom;
                return (
                  <button
                    key={option}
                    type="button"
                    data-bs-toggle={!isCustom ? "collapse" : undefined}
                    data-bs-target={!isCustom ? ".collapse-date-range" : undefined}
                    aria-controls={!isCustom ? "collapse-date-range" : undefined}
                    aria-expanded={!isCustom ? "false" : undefined}
                    className={`btn btn-outline-primary ${active}`}
                    onClick={() => {
                      onItemClick(option);
                    }}
                  >
                    {t(option)}
                  </button>
                );
              })}
            </div>
            <div>
              <div className="input-group custom">
                <div>
                  <input
                    disabled={!isCustom}
                    className="form-control"
                    type="date"
                    max={setMinMax(endDate, 0)}
                    value={startDate}
                    onChange={onStartDateChange}
                  />
                </div>

                <div className="textBetween mt-2">{t("common.to")}</div>

                <div>
                  <input
                    disabled={!isCustom}
                    className="form-control"
                    type="date"
                    min={setMinMax(startDate, 2)}
                    value={endDate}
                    onChange={onEndDateChange}
                  />
                </div>
              </div>

              <div className="mt-2 mb-2">
                <button
                  className="btn btn-primary collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target=".collapse-date-range"
                  aria-controls="collapse-date-range"
                  aria-expanded="false"
                  disabled={!isCustom || !areDateValid(startDate, endDate)}
                  onClick={async () => {
                    await props.onDateChange(startDate, endDate);
                  }}
                >
                  {t("analytic.apply")}
                </button>
                <button
                  className="btn btn-outline-secondary collapsed  ms-2"
                  data-bs-toggle="collapse"
                  data-bs-target=".collapse-date-range"
                  aria-controls="collapse-date-range"
                  aria-expanded="false"
                >
                  {t("common.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  async function setDataBySelectedIndex(option: string) {
    const selectedCustom = option === DateRangeOptions.Custom;

    setIsCustom(selectedCustom);
    if (!selectedCustom) {
      await setStartEndOnClick(
        Object.values<DateRangeOptions>(DateRangeOptions).find((dateOption) => dateOption === option),
        props.onDateChange
      );
    }
  }
}

async function setStartEndOnClick(startDateStr: DateRangeOptions | undefined, onDateChange: (start: string, end: string) => void) {
  const now = new Date();
  let startDate = new Date(now);
  let endDate = new Date(now);
  endDate.setDate(endDate.getDate() + 1);

  if (startDateStr === DateRangeOptions.Week) {
    startDate.setDate(startDate.getDate() - 6);
  } else if (startDateStr === DateRangeOptions.Month) {
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setDate(startDate.getDate() + 1);
  }

  const start = DateHelpers.toDateString(startDate);
  const end = DateHelpers.toDateString(endDate);

  if (end && start) {
    onDateChange(start, end);
  }
}

function setMinMax(value: string | undefined, daysAdded: number) {
  if (value) {
    if (daysAdded > 0) {
      let minEndDate = new Date(value);
      minEndDate.setDate(minEndDate.getDate() + daysAdded);

      return DateHelpers.toDateString(minEndDate);
    } else return DateHelpers.toDateString(new Date(value));
  }
}

function areDateValid(startDate: string, endDate: string) {
  return startDate < endDate && new Date(startDate).getFullYear() >= 2020;
}
