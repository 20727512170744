import { useTranslation } from "react-i18next";
import HubLocationComponent from "../../../components/hub-location-component";
import languageService from "../../../services/core/language-service";
import StringHelpers from "../../../services/core/string-helpers";
import { Hub } from "../../../services/hub/hub";
import { PackagingOption } from "../../../services/hub/packgingOption";
import { Order } from "../../../services/order/order";
import DateHelpers from "../../../services/core/date-helpers";

interface OrderGeneralProps {
  order: Order;
  hub: Hub;
  isOrgContributor: boolean | undefined;
  timeZone: string;
  packagingOption: PackagingOption | null;
  carrierName: string | undefined;
}

export default function OrderGeneral(props: OrderGeneralProps) {
  const { order, hub, isOrgContributor, timeZone, packagingOption, carrierName } = props;
  const { t } = useTranslation();
  return (
    <div className="col-12 col-lg-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title d-flex justify-content-between">
            <span>{t("order-page.general")}</span>
          </h5>
          <p className="card-text">
            <span>
              {t("order-page.location")}
              {": "}
              <strong>
                <HubLocationComponent hub={hub} />
              </strong>
              <br />
            </span>
            {isOrgContributor && (
              <span>
                {t("order-page.order-flow")}{" "}
                <strong>{order.flow && t("order-page.order-flow-" + order.flow.toString().toLowerCase())}</strong>
              </span>
            )}
            <br />
            {t("order-page.creation-date")} <strong>{DateHelpers.formatDateTime(new Date(order?.orderDate), timeZone)}</strong>
            {order?.flow.toString() === "PersonalStorage" && (
              <>
                <br />
                <span>
                  {t("order-page.reservation-Block-Name")} <strong>{order?.items[0]?.reservation?.reservationBlockName}</strong>
                </span>
              </>
            )}
            {packagingOption && (
              <>
                <br />
                {t("order-page.packaging-option")}:{" "}
                <strong>{packagingOption && packagingOption.description.get(languageService.language)}</strong>
              </>
            )}
            {carrierName && (
              <>
                <br />
                {t("order-page.carrier")}: <strong>{carrierName}</strong>
              </>
            )}
            {order.referenceNumber && (
              <>
                <br />
                <span className="overflow-ellipsis">
                  {t("order-page.reference-number")}: <strong>{order.referenceNumber}</strong>
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
