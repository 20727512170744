import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./member-page.scss";
import Member from "../../services/member/member";
import authService from "../../services/auth/auth-service";
import { InputComponent } from "../../components/input";
import { useState } from "react";
import memberService from "../../services/member/member-service";
import { ActionButtonComponent } from "../../components/button-component";
import ToastHelpers from "../../services/core/toast-helpers";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import { FirstNameComponent } from "../../components/customer-information-components/firstname-component";
import { LastNameComponent } from "../../components/customer-information-components/lastname-components";

type Props = {
  member: Member;
  changeMember: any;
};

export default function MemberPage(props: Props) {
  const { member, changeMember } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [firstname, setFirstname] = useState<string>(member.firstname);
  const [lastname, setLastname] = useState<string>(member.lastname);
  const [email, setEmail] = useState<string>(member.email);
  const [phone, setPhone] = useState<string>(member.phone);
  const [phoneExtension, setPhoneExtension] = useState<number | undefined>(member.phoneExtension);
  const [phoneError, setPhoneError] = useState<string | undefined>(undefined);
  const [firstnameError, setFirstnameError] = useState<string | undefined>(undefined);
  const [lastnameError, setLastnameError] = useState<string | undefined>(undefined);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  // const hasBopil = member.features.includes("bopil");
  // const hasPersonalStorage = member.features.includes("personalstorage");

  return (
    <div className="container pt-5 page member-page">
      <div className="row">
        <div className="col-12 offset-lg-2 col-lg-8">
          <div className="card">
            <div className="card-header align-items-center">
              <h2>{t("member-profile.title")}</h2>
            </div>

            <div className="card-body">
              <div className="profile-buttons">
                {!isEdit && (
                  <>
                    <button className="btn btn-primary" data-bs-target="#changePasswordModal" data-bs-toggle="modal">
                      {t("member-profile.change-password")}
                    </button>
                    <div className="modal" tabIndex={-1} id="changePasswordModal">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">{t("member-profile.change-password")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <p>{t("member-profile.change-password-text")}</p>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
                              {t("common.close")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button className="btn btn-purple ms-2" onClick={async () => await logout()}>
                      {t("account.logout")}
                    </button>

                    <ActionButtonComponent onClick={() => setIsEdit(true)} className="btn btn-outline-primary ms-2" icon={["fas", "pen"]} />
                  </>
                )}
              </div>

              <div className="row g-3">
                <div className="col-md-6">
                  <FirstNameComponent
                    value={firstname}
                    setValue={setFirstname}
                    msgError={firstnameError}
                    onMsgErrorChanged={setFirstnameError}
                    disabled={!isEdit}
                  />
                </div>
                <div className="col-md-6">
                  <LastNameComponent
                    value={lastname}
                    setValue={setLastname}
                    msgError={lastnameError}
                    onMsgErrorChanged={setLastnameError}
                    disabled={!isEdit}
                  />
                </div>
                <div className="col-md-6">
                  <InputComponent
                    labelText={t("member-profile.email")}
                    value={email}
                    setValue={(value: string) => setEmail(value)}
                    disabled={true}
                  />
                </div>
                <div className="col-md-4">
                  {/* <InputComponent
                    labelText={t("common.phone")}
                    value={phone}
                    setValue={(value: string) => setPhone(value)}
                    disabled={!isEdit}
                  /> */}
                  <PhoneFormComponent
                    value={phone}
                    setValue={setPhone}
                    onMsgErrorChanged={setPhoneError}
                    msgError={phoneError}
                    label={t("common.phone")}
                    disabled={!isEdit}
                  />
                </div>
                <div className="col-md-2">
                  <InputComponent
                    labelText={t("member-profile.extension")}
                    value={phoneExtension ? phoneExtension.toString() : ""}
                    setValue={(value: number) => setPhoneExtension(value)}
                    disabled={!isEdit}
                  />
                </div>

                <div>
                  {isEdit && (
                    <>
                      <ActionButtonComponent
                        title={t("common.save")}
                        onClick={() => save()}
                        className="btn btn-primary"
                        disabled={saveDisabled()}
                      />
                      <ActionButtonComponent
                        title={t("common.cancel")}
                        onClick={() => cancelEdit()}
                        className="ms-2 btn btn-outline-primary"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 offset-lg-2 col-lg-8">
          <div className="card">
            <div className="card-header align-items-center">
              <h2>{t("member-profile.title-features")}</h2>
            </div>

            <div className="card-body">
              <form className="row g-3">
                <div className="col-12">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={hasBopil} disabled />
                    <label className="form-check-label">{t("member-profile.feature-bopil")}</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={hasPersonalStorage} disabled />
                    <label className="form-check-label">{t("member-profile.feature-personal-storage")}</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );

  async function logout(): Promise<void> {
    history.push("/");
    changeMember(null);
    await authService.logout();
  }

  function saveDisabled(): boolean {
    return !firstname || !lastname || !email || !phone || firstname.length > 50 || lastname.length > 50 || !isValidPhone(phone);
  }
  function isValidPhone(value: string): boolean {
    const RegExPhone1 = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
    const RegExPhone2 = /^\d{10}$/;
    let valid: boolean = RegExPhone1.test(value) || RegExPhone2.test(value);

    if (valid) return true;

    return false;
  }
  function save() {
    if (firstname && lastname && email && phone) {
      let newMember = { ...member };

      newMember.firstname = firstname;
      newMember.lastname = lastname;
      newMember.email = email;
      newMember.phone = phone;
      newMember.phoneExtension = phoneExtension;

      memberService
        .updateMemberMe(newMember)
        .then(() => {
          setIsEdit(false);
          refreshMe();
        })
        .catch((error: any) => {
          ToastHelpers.error(t("common.error-refresh"));
        });
    }
  }

  function refreshMe() {
    memberService.getMemberMe().then((me) => {
      changeMember(me);
      if (me) {
        setFirstname(me.firstname);
        setLastname(me.lastname);
        setPhone(me.phone);
        setPhoneExtension(me.phoneExtension);
        setEmail(me.email);
      }
    });
  }

  function cancelEdit() {
    setIsEdit(false);

    refreshMe();
  }
}
