import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../dashboard/dashboard-page.scss";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../components/loading-component";
import useHubs from "../../components/useEffect/useHubs";
import useMicrosite from "../../components/useEffect/useMicrosite";
import languageService from "../../services/core/language-service";
import StringHelpers from "../../services/core/string-helpers";
import ToastHelpers from "../../services/core/toast-helpers";
import Member from "../../services/member/member";
import MemberExtensions from "../../services/member/member-extensions";
import MemberRoleOrganization from "../../services/member/member-role-organization";
import micrositeService from "../../services/micro-site/microsite-service";
import ReservationBlock from "../../services/micro-site/reservationBlock";
import { Order } from "../../services/order/order";
import { Hub } from "../../services/hub/hub";
import { HubHelpers } from "../../services/hub/hub-helper";
import { ExportButton } from "../../components/button-component";
import DateHelpers from "../../services/core/date-helpers";

class EventSalesReport {
  nbLockers: number = 0;
  nbLockersSold: number = 0;
  grossRevenue: number = 0;
  fees: number = 0;
  orders: Array<Order> = [];
}

interface IParamTypes {
  organizationId: string;
  micrositeId: string;
  eventId: string;
}

interface Props {
  member: Member;
}

export default function EventSettlementReportPage(props: Props) {
  const language: string = languageService.getLanguage();
  const history = useHistory();
  const { t } = useTranslation();

  const { organizationId, micrositeId, eventId } = useParams<IParamTypes>();
  const { member } = props;

  const [loading, setLoading] = useState<boolean>(true);

  const { microsite, isMicrositeLoading } = useMicrosite(micrositeId);
  const { hubs, isHubsLoading } = useHubs();
  const [salesReport, setSalesReport] = useState<EventSalesReport>(new EventSalesReport());
  const [currentOrganization, setCurrentOrganization] = useState<MemberRoleOrganization | undefined>(undefined);
  const [lockerSold, setLockerSold] = useState<string | number>();
  const [netRevenue, setNetRevenue] = useState<number>(0);
  const [feesPerOrder, setFeesPerOrder] = useState<number>(0);

  const event: ReservationBlock | undefined = useMemo(() => {
    if (microsite) {
      return microsite.reservationBlocks.find((rb) => rb.id === eventId);
    }
    return undefined;
  }, [microsite, eventId]);

  useEffect(() => {
    setNetRevenue(salesReport.grossRevenue - salesReport.fees);
    setFeesPerOrder(salesReport.fees / salesReport.orders.length);
  }, [salesReport]);

  useEffect(() => {
    let organization = MemberExtensions.getMemberRoleOrganization(member, organizationId);

    if (organization) {
      setCurrentOrganization(organization);
    }

    if (!isHubsLoading && !isMicrositeLoading) {
      micrositeService
        .getEventOrders(micrositeId, eventId)
        .then((orders) => {
          if (orders && orders.length && hubs && hubs.length && microsite) {
            let micrositeHubs = hubs.filter((h) => microsite.hubIds.includes(h.id));
            setSalesReport(generateSalesFromOrders(orders, micrositeHubs));
          }
        })
        .catch(() => {
          ToastHelpers.error(t("event-page.event-page-error-refresh"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [micrositeId, eventId, microsite, hubs, t, member, organizationId, isHubsLoading, isMicrositeLoading]);

  const data: Array<any> = useMemo(() => generateSalesReportData(salesReport, hubs, event, feesPerOrder), [salesReport, feesPerOrder]);

  useEffect(() => {
    const isLimited = !!event?.startOn;
    const lockerSold = isLimited
      ? t("analytic.hub-usage-text", { used: salesReport.nbLockersSold, total: salesReport.nbLockers })
      : salesReport.nbLockersSold;
    setLockerSold(lockerSold);
  }, [salesReport]);

  if (loading) {
    return (
      <div className="container page orders-page">
        <LoadingComponent loading={loading} />
      </div>
    );
  }

  return (
    <div className="container page orders-page">
      <div className="offset-lg-2">
        <div className="page-breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to={`/organizations`}>{t("common.organizations")}</Link>
              {` / `}
              <Link to={`/organizations/${organizationId}`}>{currentOrganization?.entity?.name}</Link>
              {` / `}
              <Link to={`/organizations/${organizationId}/microsites`}>{t("microsites-page.name")}</Link>
              {` / `}
              <Link to={`/organizations/${organizationId}/microsites/${micrositeId}/reservationBlocks`}>
                {microsite?.name.get(language)}
              </Link>
              {` / ${event?.name.get(language)}`}
            </li>
          </ul>
        </div>

        <div className="page-background-boxing shadow col-lg-8">
          <h1 className="text-primary">{t("event-settlement-report.metrics", { event: event?.name.get(language) }).toUpperCase()}</h1>

          <div className="card">
            <div className="card-header align-items-center">
              <div className="d-inline-block">
                <h2 className="mt-2">
                  {t("event-settlement-report.summary")}
                  <span className="ms-2">
                    <ExportButton data={data} fileName={`event-${event?.name.get(language)}-report`} />
                  </span>
                </h2>
              </div>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-lg-6 col-md-12">
                  {t("event-settlement-report.nb-locker-sold")} {lockerSold}
                </div>
              </div>

              <div className="row">
                <div className="col-6">{t("event-settlement-report.gross-revenue")}</div>
                <div className="col-6 text-end">
                  {t("common.price-format", {
                    price: StringHelpers.formatPrice(salesReport.grossRevenue),
                    currency: microsite?.currency,
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t("event-settlement-report.fees")}</div>
                <div className="col-6 border-bottom text-end">
                  -
                  {t("common.price-format", {
                    price: StringHelpers.formatPrice(salesReport.fees),
                    currency: microsite?.currency,
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-6">{t("event-settlement-report.net-revenue")} </div>
                <div className="col-6 text-end">
                  {t("common.price-format", { price: StringHelpers.formatPrice(netRevenue), currency: microsite?.currency })}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header align-items-center">
              <div className="d-inline-block">
                <h2 className="mt-2">Reservations</h2>
              </div>
            </div>

            <div className="card-body">
              <table className="table table-sm table-hover row-clikable">
                <thead>
                  <tr>
                    <th>{t("common.location")}</th>
                    <th>{t("common.locker")}</th>
                    <th>{t("common.date")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {salesReport?.orders.map((order: Order) => {
                    let location = hubs?.find((hub) => hub.id === order.hubId)?.name;
                    let compartmentNumber = order.items[0].reservation.compartmentNumber;
                    let group = order.items[0].reservation.groupId;
                    let date = DateHelpers.formatDateTime(new Date(order.orderDate), DateHelpers.UserTimeZone);
                    const redirectToOrderPage = () => history.push(`/orders/${order.id}`);

                    return (
                      <tr onClick={() => redirectToOrderPage()}>
                        <td>{location}</td>
                        <td>{/^\d+$/.test(compartmentNumber) ? compartmentNumber : group + compartmentNumber}</td>
                        <td>{date}</td>
                        <td>
                          <FontAwesomeIcon icon={["fas", "angle-right"]} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function generateSalesFromOrders(orders: Array<Order>, hubs: Array<Hub>): EventSalesReport {
  let sr = new EventSalesReport();
  sr.nbLockers = hubs.reduce((total, h) => total + HubHelpers.GetCompartmentCount(h), 0);
  sr.orders = orders;

  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (order.payments && order.payments.length) {
      const lockers = order.items.filter((value, index, array) => array.indexOf(value) === index).length;
      sr.nbLockersSold += lockers;

      order.payments.forEach((payment) => {
        sr.fees += payment.fees;
        sr.grossRevenue += payment.amount + payment.saleTaxTotal;
      });
    }
  }

  return sr;
}

function generateSalesReportData(
  salesReport: EventSalesReport,
  hubs: Hub[] | undefined,
  event: ReservationBlock | undefined,
  feesPerOrder: number
) {
  return salesReport.orders.map((order) => {
    const reservation = order.items[0].reservation;

    let feesOrder: number = 0;
    let paymentOrder: number = 0;
    order?.payments?.forEach((payment) => {
      feesOrder += payment.fees;
      paymentOrder += payment.amount + payment.saleTaxTotal;
    });

    return {
      order_date: order.orderDate.toString(),
      locker_price: event?.price.toString(),
      locker: reservation.groupId.toString() + reservation.compartmentNumber.toString(),
      hub: hubs?.find((x) => x.id === order.hubId)?.name.toString(),
      price: paymentOrder.toString(),
      sale_tax_rate: event?.saleTaxRate.toString(),
      Fees: feesOrder.toString() ?? feesPerOrder.toString(),
    };
  });
}
