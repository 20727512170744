import { AccountInfo, AuthenticationResult, EventType, InteractionType } from "@azure/msal-browser";
import { acquireTokenRequest, loginRequest } from "./authConfig";
import msalInstance from "./msal-instance";

class MsalService {
  public registerCallback(callback: any) {
    msalInstance.addEventCallback((message) => {
      if (message.eventType === EventType.LOGIN_SUCCESS || message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (message.interactionType === InteractionType.Redirect) {
          callback();
        }
      }
    });
  }

  public async getToken(): Promise<AuthenticationResult | null> {
    const account = this.getAccount();
    if (!account) {
      return null;
    }

    const request = {
      ...acquireTokenRequest,
      account: account,
    };
    try {
      return await msalInstance.acquireTokenSilent(request);
    } catch (error) {
      console.error(`Error while acquiring token: ${error}`);
      msalInstance.acquireTokenRedirect(request);
      return null;
    }
  }

  public getAccount(): AccountInfo | null {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      return accounts[0];
    }
    return null;
  }

  public async login(loginType: string) {
    if (loginType === "popup") {
      await msalInstance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } else if (loginType === "redirect") {
      await msalInstance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  }

  public async logout(logoutType: string): Promise<void> {
    if (logoutType === "popup") {
      await msalInstance.logoutPopup({
        postLogoutRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      await msalInstance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }
}

const msalService = new MsalService();
export default msalService;
