export class Address {
  streetNumber: string = "";
  street: string = "";
  city: string = "";
  postalCode: string = "";
  state: string = "";
  country: string = "";
  lat: string = "";
  lng: string = "";
}
