import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../components/loading-component";
import languageService from "../../services/core/language-service";
import ToastHelpers from "../../services/core/toast-helpers";
import { CompartmentSize } from "../../services/hub/compartmentSize";
import compartmentSizeService from "../../services/hub/compartmentSize-service";
import { HubHelpers } from "../../services/hub/hub-helper";
import hubService from "../../services/hub/hub-service";
import Member from "../../services/member/member";
import memberService from "../../services/member/member-service";
import organizationService from "../../services/member/organization-service";
import { Order } from "../../services/order/order";
import orderService from "../../services/order/order-service";
import { Reservation } from "../../services/order/reservation";
import { ReservationState } from "../../services/order/ReservationState";

interface relocatePage {
  organizationId: string | undefined;
  member: Member;
}

interface IParamTypes {
  orderId: string;
  itemId: string;
}

export default function RelocatePage(props: relocatePage) {
  const { organizationId, member } = props;
  const { orderId, itemId } = useParams<IParamTypes>();

  const [compartmentSizes, setCompartmentSizes] = useState<CompartmentSize[]>();
  const [currentCompartmentSize, setCurrentCompartmentSize] = useState<CompartmentSize>();
  const [compartmentState, setCompartmentState] = useState<string>();
  const [reservation, setReservation] = useState<Reservation | undefined>();
  const [sizeId, setSizeId] = useState<string>();
  const [availableSizeIds, setAvailableSizeIds] = useState<Array<string>>();

  const [loading, setLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [isOrgContributor, setIsOrgContributor] = useState<boolean>(false);

  const language: string = languageService.getLanguage();

  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    orderService.getOrderById(organizationId, orderId).then(async (order: Order | null) => {
      const orderItem = order?.items.find((orderItem) => orderItem.id === itemId);
      const reservation = orderItem?.reservation;
      if (!reservation || !order?.hubId) {
        return;
      }

      const hub = await hubService.getHubById(order.hubId);
      const currentCompartment = HubHelpers.findCompartment(hub, reservation);
      const sizeId = currentCompartment?.sizeId;

      setCompartmentState(reservation.state);
      setReservation(reservation);

      await orderService
        .getAvailableCompartmentSizeIds(organizationId, hub.id, order.flow, reservation.start, reservation.end)
        .then(async (compartmentSizeIds) => {
          if (sizeId && compartmentSizeIds.includes(sizeId)) {
            setSizeId(sizeId);
          }

          setAvailableSizeIds(compartmentSizeIds);

          const compartmentSizes = await compartmentSizeService.getCompartmentSizes();
          const organization = await organizationService.getOrganizationById(organizationId);

          const compartmentSizeIdsOfHubsCandidates = organization?.associatedHubs?.map(async (hub) => {
            return await hubService.getHubById(hub.hubId).then((hub) => {
              return HubHelpers.getCompartmentSizeIds(hub);
            });
          });

          const compartmentSizesOfHubs = compartmentSizes?.filter((size) =>
            compartmentSizeIdsOfHubsCandidates?.some((c) => c.then((c) => c.has(size.id)))
          );

          setCompartmentSizes(compartmentSizesOfHubs);
          setCurrentCompartmentSize(compartmentSizes.find((cs) => cs.id === sizeId));
        })
        .finally(() => setLoading(false));
    });
  }, [organizationId]);

  useEffect(() => setIsOrgContributor(memberService.isOrganizationContributor(member, organizationId)), [member]);

  return (
    <div className="container pt-3 page">
      <div className="row">
        <div className="page-breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link to={`/orders`}>{t("order-page.orderlink")}</Link>
              {` / `}
              <Link to={`/orders/${orderId}`}>{orderId}</Link>
              {` / `}
              {t("order-page.relocate")}
            </li>
          </ul>
        </div>

        <LoadingComponent loading={loading || isUpdating} />

        {!loading && isOrgContributor && (
          <>
            <div className="page-background-boxing shadow">
              <div className="header">
                <h1>
                  <div>
                    {t("order-page.relocate")} #{orderId}{" "}
                    <button
                      className="btn-close h5 float-end mb-0"
                      onClick={() => history.push(`/orders/${orderId}`)}
                      disabled={loading || isUpdating}
                    ></button>
                  </div>
                </h1>
              </div>

              <div className="card">
                <div className="card-header align-items-center">
                  <h2>{t("relocate-page.locker-reservation")}</h2>
                </div>

                <div className="card-body">
                  <div className="col-12">
                    <table className="table table-hover row-clikable table-order">
                      <thead>
                        <tr>
                          <th>{t("order-page-table-header.locker")}</th>
                          <th>{t("order-page-table-header.size")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {reservation?.groupId}
                            {reservation?.compartmentNumber}
                          </td>
                          <td>
                            {currentCompartmentSize?.name.get(language)} {currentCompartmentSize?.description.get(language)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header align-items-center">
                  <h2>{t("relocate-page.size-title")}</h2>
                </div>

                <div className="card-body">
                  <div className="col-12">
                    {availableSizeIds && availableSizeIds?.length > 0 ? (
                      <>
                        {compartmentSizes?.map((compartmentSize) => {
                          return (
                            <div className="clickable">
                              <input
                                type="radio"
                                id={compartmentSize.id}
                                name={compartmentSize.name.get(language)}
                                value={compartmentSize.id}
                                checked={compartmentSize.id === sizeId}
                                onChange={() => setSizeId(compartmentSize.id)}
                                disabled={
                                  !availableSizeIds?.includes(compartmentSize.id) || compartmentState === ReservationState.Completed
                                }
                              />
                              <label htmlFor={compartmentSize.id} className="ms-2 me-3">
                                {compartmentSize.name.get(language)} {compartmentSize.description.get(language)}
                              </label>
                              <hr></hr>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <span>{t("relocate-page.no-lockers-available")}</span>
                        <br />
                      </>
                    )}

                    <br />
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={async () => await relocateLocker()}
                      disabled={saveDisabled()}
                    >
                      {t("relocate-page.save-button")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  function saveDisabled(): boolean {
    return !sizeId || isUpdating || compartmentState === ReservationState.Completed || !availableSizeIds?.includes(sizeId);
  }

  async function relocateLocker() {
    if (organizationId && sizeId && compartmentState !== ReservationState.Completed && reservation) {
      setIsUpdating(true);

      try {
        await orderService.relocateReservation(organizationId, orderId, itemId, sizeId, reservation.start, reservation.end);

        if (compartmentState !== ReservationState.New) {
          await orderService.sendReadyForPickupNotification(organizationId, orderId).catch(() => {
            ToastHelpers.error(t("relocate-page.error-while-sending-notification"));
          });
        }
      } catch (e) {
        ToastHelpers.error(t("common.error-generic"));
      } finally {
        history.push(`/orders/${orderId}`);
        setIsUpdating(false);
      }
    }
  }
}
