import { useTranslation } from "react-i18next";
import { Order } from "../../../services/order/order";
import languageService from "../../../services/core/language-service";
import StringHelpers from "../../../services/core/string-helpers";
import Member from "../../../services/member/member";
import { OrderHelpers } from "../../../services/order/order-helpers";
import DateHelpers from "../../../services/core/date-helpers";

interface OrderHistoryProps {
  order: Order;
  member: Member;
}

export default function OrderHistory({ order, member }: OrderHistoryProps) {
  const language: string = languageService.getLanguage();
  const { t } = useTranslation();

  return (
    <>
      <div className="row panel-history-mobile">
        <div className="col-12">
          <div className="history card">
            <div className="card-body">
              <h5 className="card-title">{t("order-page.history")}</h5>

              {order.activities.map((activity) => {
                return (
                  <div className="history-item" key={activity.id}>
                    <hr />
                    <div>
                      <span className="creation-date">
                        {DateHelpers.formatDateTime(new Date(activity.createdOn), DateHelpers.UserTimeZone)}
                      </span>
                      <span className="author">{activity.createBy}</span>
                    </div>
                    <div className="text">
                      <span>{OrderHelpers.getActivityDescription(activity, language)}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="row panel-history-desktop">
        <div className="col-12">
          <div className="customer card">
            <div className="card-body">
              <h5 className="card-title">{t("order-page.history")}</h5>

              <table className="table table-history">
                <thead>
                  <tr>
                    <td>{t("order-page-table-header.date")}</td>
                    <td>{t("order-page-table-header.operation")}</td>
                    <td>{t("order-page-table-header.author")}</td>
                  </tr>
                </thead>
                <tbody>
                  {order.activities.map((activity) => {
                    return (
                      <tr key={activity.id}>
                        <td>{DateHelpers.formatDateTime(new Date(activity.createdOn), DateHelpers.UserTimeZone)}</td>
                        <td>{OrderHelpers.getActivityDescription(activity, language)}</td>
                        <td>{activity.createBy}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
