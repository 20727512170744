import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ToastHelpers from "../../services/core/toast-helpers";
import Microsite from "../../services/micro-site/microsite";
import micrositeService from "../../services/micro-site/microsite-service";

export default function useMicrosite(micrositeId: string) {
  const { t } = useTranslation();

  const [microsite, setMicrosite] = useState<Microsite>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    function get() {
      micrositeService
        .getMicrosite(micrositeId, "All")
        .then((microsite) => {
          if (microsite) {
            setMicrosite(microsite);
          }
        })
        .catch((error: any) => {
          ToastHelpers.error(t("common.error-generic"));
        })
        .finally(() => setLoading(false));
    }

    get();
  }, [micrositeId, t]);

  return { microsite, isMicrositeLoading: loading };
}
