import { useEffect, useState } from "react";
import { RequestState } from "../core/request-state";
import ToastHelpers from "../core/toast-helpers";
import { Hub } from "./hub";
import hubService from "./hub-service";

interface IProps {
  onErrorNotificationMessage: string;
}

function useMyHubs(props: IProps) {
  const { onErrorNotificationMessage } = props;

  const [requestState, setRequestState] = useState<RequestState>(RequestState.Loading);
  const [error, setError] = useState<string>("");
  const [hubs, setHubs] = useState<Array<Hub>>([]);

  useEffect(
    () => {
      refresh();
    },
    // eslint-disable-next-line
    []
  );

  function refresh() {
    setRequestState(RequestState.Loading);

    hubService
      .getMyHubs()
      .then((hubs) => {
        setHubs(hubs);
        setRequestState(RequestState.Success);
      })
      .catch((e) => {
        setRequestState(RequestState.Failure);
        setError(e);

        if (onErrorNotificationMessage) {
          ToastHelpers.error(onErrorNotificationMessage);
        }
      });
  }

  return { hubs, requestState, error, refresh };
}

export default useMyHubs;
