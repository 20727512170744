import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ToastHelpers from "../../services/core/toast-helpers";
import { Hub } from "../../services/hub/hub";
import hubService from "../../services/hub/hub-service";

export default function useHubs() {
  const { t } = useTranslation();

  const [hubs, setHubs] = useState<Array<Hub>>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    function get() {
      hubService
        .getHubs()
        .then((hubs) => {
          if (hubs) {
            setHubs(hubs);
          }
        })
        .catch(() => {
          ToastHelpers.error(t("orders-page.error-refresh"));
        })
        .finally(() => setLoading(false));
    }

    get();
  }, [t]);

  return { hubs, isHubsLoading: loading };
}
