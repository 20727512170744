import "./hub-page.scss";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { SyncButtonComponent } from "../../components/button-component";
import { Compartment } from "../../services/hub/compartment";
import { Group } from "../../services/hub/group";
import useMyHub from "../../services/hub/useMyHub";
import Member from "../../services/member/member";
import HubCompartmentDetail from "./hub-compartment-detail";
import HubLegend from "./hub-details/hub-legend";
import HubOpenAllDoor from "./hub-details/hub-open-all-door";
import HubStateInformation from "./hub-details/hub-state-information";
import DropdownSide from "../../components/hub/rider-lockers/dropdown-side-component";
import hubService from "../../services/hub/hub-service";
import Locker from "../../components/hub/locker-component";
import { YesNoComponent } from "../../components/yesno-component";
import useCompartmentSizes from "../../services/hub/useCompartmentSizes";

interface IParamTypes {
  hubId: string;
}

type HubPageProps = {
  member: Member;
};

export default function HubPage(props: HubPageProps) {
  const { member } = props;
  const { hubId } = useParams<IParamTypes>();
  const { t } = useTranslation();

  const { hub, refresh } = useMyHub({ hubId: hubId, onErrorNotificationMessage: t("hub-page.error-refresh") });
  const { compartmentSizes } = useCompartmentSizes({ onErrorNotificationMessage: t("hub-page.error-refresh") });

  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  const [selectedCompartmentNumber, setSelectedCompartmentNumber] = useState<string>("");
  const [selectedSide, setSelectedSide] = useState<string>("front");
  const [showOpenAllDoors, setShowOpenAllDoors] = useState<boolean>(false);
  const [isActivateAllDoors, setIsActivateAllDoors] = useState<boolean>(false);
  const [isDeactivateAllDoors, setIsDeactivateAllDoors] = useState<boolean>(false);

  const selectedReservation = hub?.reservations.find(
    (r) =>
      r.groupId === selectedGroupId &&
      r.compartmentNumber === selectedCompartmentNumber &&
      Date.parse(r.start) < Date.now() &&
      Date.parse(r.end) > Date.now()
  );

  useEffect(() => {
    const timeoutId = setInterval(async () => {
      await refresh();
    }, 5000);
    return () => clearInterval(timeoutId);
  }, [refresh]);

  const onCompartmentSelected = useCallback((group: Group, compartment: Compartment) => {
    setSelectedGroupId(group.id);
    setSelectedCompartmentNumber(compartment.number);
  }, []);

  if (isActivateAllDoors) {
    return (
      <YesNoComponent
        headerMsg={t("hub-page.msg-activate-all-doors")}
        onYesClick={() => activateAllDoors(hubId)}
        onNoClick={setIsActivateAllDoors}
      />
    );
  }

  if (isDeactivateAllDoors) {
    return (
      <YesNoComponent
        headerMsg={t("hub-page.msg-deactivate-all-doors")}
        onYesClick={() => deactivateAllDoors(hubId)}
        onNoClick={setIsDeactivateAllDoors}
      />
    );
  }

  return (
    <div>
      <div className="container page hub-page">
        <div className="page-breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active">
              <Link className="" to="/hubs">
                {t("hub-page.hublink")}
              </Link>
            </li>
            <li className="breadcrumb-item active">{hub ? hub.name : hubId}</li>
          </ul>
        </div>

        <HubStateInformation member={member} />

        {/* Groups/Compartments */}
        {hub && hub.groups && hub.groups.length > 0 && (
          <div className="card">
            <div className="card-body">
              <h5 className="card-title toolbar">
                {t("hub-page.compartments")}
                {hub && (
                  <>
                    <SyncButtonComponent className="ms-2" onClick={refresh}></SyncButtonComponent>

                    <button className="ms-2 btn btn-outline-primary" onClick={() => setShowOpenAllDoors(true)}>
                      {t("hub-page.open-all-doors")}
                    </button>

                    <button className="ms-2 btn btn-outline-primary" onClick={() => setIsActivateAllDoors(true)}>
                      {t("hub-page.activate-all-doors")}
                    </button>

                    <button className="ms-2 btn btn-outline-primary" onClick={() => setIsDeactivateAllDoors(true)}>
                      {t("hub-page.deactivate-all-doors")}
                    </button>

                    {hub.flowConfigs.isRiderLocker && (
                      <DropdownSide
                        onChange={(side: string) => {
                          setSelectedSide(side);
                        }}
                      />
                    )}
                  </>
                )}
              </h5>

              <HubLegend />

              <div className="row g-1">
                {hub?.groups?.map((group: Group) => {
                  return (
                    <div key={group.id} className={"panel-group col-4 col-md-3 col-lg-2" + (group.state !== "Online" ? " disabled" : "")}>
                      <div className="row">
                        <div className="col-12">
                          <div className="group-title">{group.id} </div>
                        </div>
                      </div>

                      <div className="compartments">
                        {group.compartments.map((compartment: Compartment) => {
                          return (
                            <Locker
                              hub={hub}
                              group={group}
                              compartment={compartment}
                              onCompartmentSelected={onCompartmentSelected}
                              compartmentSizes={compartmentSizes}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      {selectedGroupId !== "" && selectedCompartmentNumber !== "" && (
        <HubCompartmentDetail
          hub={hub}
          orderId={selectedReservation?.orderId}
          paymentId={selectedReservation?.paymentId}
          groupId={selectedGroupId}
          compartmentNumber={selectedCompartmentNumber}
          side={selectedSide}
          member={member}
          onClose={clearCompartmentSelected}
          refresh={refresh}
        />
      )}

      {showOpenAllDoors && <HubOpenAllDoor hub={hub} onClose={() => setShowOpenAllDoors(false)} refresh={refresh} />}
    </div>
  );

  function clearCompartmentSelected() {
    setSelectedGroupId("");
    setSelectedCompartmentNumber("");
  }

  async function activateAllDoors(hubId: string) {
    setIsActivateAllDoors(false);
    await hubService.activateAllLockers(hubId);
  }

  async function deactivateAllDoors(hubId: string) {
    setIsDeactivateAllDoors(false);
    await hubService.deactivateAllLockers(hubId);
  }
}
