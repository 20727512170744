import ServicesApiBaseService from "../services-api-base-service";
import { Dashboard } from "./dashboard";

class AnalyticService extends ServicesApiBaseService {
  async getDashboard(start:string, end:string): Promise<Dashboard | undefined> {
    const dashboard = await this.getObject<Dashboard>(`${this.apiUrl}/analytics?start=${start}&end=${end}`);
    return dashboard;
  }
}

const analyticService = new AnalyticService();
export default analyticService;
