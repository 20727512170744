import ServicesApiBaseService from "../services-api-base-service";
import Microsite from "../micro-site/microsite";

class AdminService extends ServicesApiBaseService {
  async deleteMicrosite(micrositeId: string) {
    const res = await this.deleteObject(`${this.apiUrl}/admin/deleteMicrosite/${micrositeId}`);
    return res;
  }
  async updateMicrosite(
    micrositeId: string,
    nameFr: string | undefined,
    nameEn: string | undefined,
    micrositeIdState: string | undefined,
    logoUrl: string | undefined,
    countryCode: string,
    currency: string,
    organizationId: string,
    hubIds: Array<String>
  ): Promise<any> {
    const res = await this.postObject<any, any>(`${this.apiUrl}/admin/updateMicrosite`, {
      id: micrositeId, // Can be changed through the cascade
      name: {
        fr: nameFr,
        en: nameEn,
      },
      micrositeId: micrositeIdState,
      logoUrl: logoUrl,
      countryCode: countryCode,
      currency: currency,
      organizationId: organizationId,
      hubIds: hubIds.join(","),
    });
    return res;
  }

  async createMicrosite(
    nameFr: string | undefined,
    nameEn: string | undefined,
    micrositeIdState: string | undefined,
    logoUrl: string | undefined,
    countryCode: string,
    currency: string,
    organizationId: string,
    hubIds: Array<String>
  ): Promise<any> {
    const res = await this.postObject<any, any>(`${this.apiUrl}/admin/createMicrosite`, {
      id: "",
      name: {
        fr: nameFr,
        en: nameEn,
      },
      micrositeId: micrositeIdState,
      logoUrl: logoUrl,
      countryCode: countryCode,
      currency: currency,
      organizationId: organizationId,
      hubIds: hubIds.join(","),
    });
    return res;
  }
}

const adminService = new AdminService();
export default adminService;
