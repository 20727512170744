import { useTranslation } from "react-i18next";
import { OrderHelpers } from "../services/order/order-helpers";

type Props = {
  state: string;
  isPersonalStorage: boolean;
};

export function StateBadgeComponent(props: Props) {
  const { t } = useTranslation();
  const { state, isPersonalStorage } = props;

  return (
    <span className={OrderHelpers.getOrderStateStyle(state, isPersonalStorage)}>
      {t((isPersonalStorage ? "ps-" : "") + "order-state." + state)}
    </span>
  );
}
