import { useTranslation } from "react-i18next";
import { ExportButton } from "../../components/button-component";
import { Kpi } from "../../services/analytic/kpi";
import StringHelpers from "../../services/core/string-helpers";

type Props = {
  model: Kpi;
  isLocketgoAdmin: boolean;
  exportFileName: string;
  title: string;
};

export default function OrderStatsTable(props: Props) {
  const { model, isLocketgoAdmin } = props;
  const { t } = useTranslation();

  let data: Array<any> = [];

  var resSum: number = 0;
  var confirmedSum: number = 0;
  var totalCadSum: number = 0;
  var totalUSSum: number = 0;

  props.model.values.forEach((elem) => {
    resSum += elem.count;
    confirmedSum += elem.countPaid;
    totalCadSum += elem.currency == "CAD" ? elem.amountGross : 0;
    totalUSSum += elem.currency == "USD" ? elem.amountGross : 0;

    data.push({
      Name: elem.name,
      Reservations: elem.count,
      Confirmed: elem.countPaid,
      "Total Amount of CAD": elem.currency == "CAD" ? elem.amountGross : 0,
      "Total Amount of USD": elem.currency == "USD" ? elem.amountGross : 0,
    });
  });

  data.push({
    Name: "Total",
    Reservations: resSum,
    Confirmed: confirmedSum,
    "Total Amount of CAD": totalCadSum,
    "Total Amount of USD": totalUSSum,
  });

  props.model.values.sort((a, b) => {
    let dif = b.count - a.count;
    if (dif !== 0) return dif;
    return b.countPaid - a.countPaid;
  });

  return (
    <div className="col-12">
      <table className="table table-sm mt-4">
        <thead>
          <tr>
            <th>
              {props.title}
              <span className="ms-2">
                <ExportButton data={data} fileName={props.exportFileName} />
              </span>
            </th>
            <th className="text-end">{t("analytic.reserved")}</th>
            {isLocketgoAdmin && <th className="text-end">{t("analytic.confirmed")}</th>}
            <th className="text-end">{t("analytic.total-amount")}</th>
          </tr>
        </thead>
        <tbody>
          {model.values.map((kpi) => {
            return (
              <tr key={kpi.name}>
                <td className="pt-2 pb-2">{kpi.name}</td>
                <td className="text-end pt-2 pb-2">{kpi.count}</td>
                {isLocketgoAdmin && <td className="text-end pt-2 pb-2">{kpi.countPaid}</td>}
                <td className="text-end pt-2 pb-2">
                  {!kpi.currency
                    ? "N/A"
                    : t("common.price-format", { price: StringHelpers.formatPrice(kpi.amountGross), currency: kpi.currency })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
