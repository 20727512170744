import ServicesApiBaseService from "../services-api-base-service";
import { CompartmentSize } from "./compartmentSize";

class CompartmentSizeService extends ServicesApiBaseService {
  async getCompartmentSizes(token?: string | undefined): Promise<Array<CompartmentSize>> {
    const compartmentSizes = await this.getObject<Array<CompartmentSize>>(
      `${this.apiUrl}/compartmentSizes${!token ? "" : `?token=${token}`}`
    );
    if (compartmentSizes) {
      let cs = compartmentSizes.map((compartmentSize) => {
        if (compartmentSize?.description) {
          compartmentSize.description = new Map(Object.entries(compartmentSize?.description));
        }
        if (compartmentSize.name) {
          compartmentSize.name = new Map(Object.entries(compartmentSize?.name));
        }
        return compartmentSize;
      });
      return cs;
    }

    return compartmentSizes || [];
  }
}

const compartmentSizeService = new CompartmentSizeService();
export default compartmentSizeService;
