import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import memberService from "../../services/member/member-service";
import { InputComponent } from "../../components/input";
import Member from "../../services/member/member";
import Organization from "../../services/organization/organization";
import ToastHelpers from "../../services/core/toast-helpers";
import MemberRoleOrganization from "../../services/member/member-role-organization";
import organizationService from "../../services/member/organization-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import HubPermissionTable from "./hub-permission-table";
import { request } from "http";
import LoadingComponent from "../../components/loading-component";

interface IProps {
  editedMember: Member | undefined;
  setEditingMember: Function;
  chosenOrgId: String;
  isNewUser: boolean;
  setMemberEdited: Function;
}

export default function EditMember(props: IProps) {
  const { editedMember, setEditingMember, chosenOrgId, isNewUser, setMemberEdited } = props;

  const [email, setEmail] = useState<string | undefined>(editedMember?.email);
  const [firstName, setFirstName] = useState<string | undefined>(editedMember?.firstname);
  const [lastName, setLastName] = useState<string | undefined>(editedMember?.lastname);

  const [permission, setPermission] = useState<String>(
    editedMember?.associatedOrganizations[0] ? editedMember.associatedOrganizations[0].roles[0] : ""
  );
  const [organization, setOrganization] = useState<String>(chosenOrgId);
  const [entityType, setEntityType] = useState<String>("Organization");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [newStatus, setNewStatus] = useState<string>("Active");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [exlanationHidden, setExlanationHidden] = useState<boolean>(true);

  const handleSelect = (setFunc: any, id: any) => {
    setFunc(id);
  };

  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  }

  return (
    <div className="col-12">
      <button
        className="btn btn-outline-primary"
        onClick={() => {
          setEditingMember(false);
        }}
      >
        <FontAwesomeIcon icon={["fas", "arrow-left"]} />
      </button>
      <br></br>
      {exlanationHidden ? (
        <button
          className="btn btn-outline-primary mt-4"
          onClick={() => {
            setExlanationHidden(false);
          }}
        >
          Explain Permissions...
        </button>
      ) : (
        <div>
          <p>Roles explanation (In most cases, just put Admin): </p>
          <li>All have the permissions of lower roles. Eg: Admin is also a contributor that is also a concierge</li>
          <li>
            LocketgoAdmin (dangerous) {">"} Admin {">"} Contributor {">"} Concierge {">"} None{" "}
          </li>
          <li>LocketgoAdmins are Admins of all orgs and hubs. Only give this permission to trusted Locketgo employees</li>
          <li>Admins can block doors from being reserved. They can do report exports and modify events</li>
          <li>Contributors can see information related to the reservation such as client phone number or bracelet </li>
          <li>Contributors can create customers on the portal for the parcel features </li>
          <li>
            Concierge can open doors as they wish. Concierges cannot see the organization and it's role can only be applied to the hubs{" "}
          </li>
        </div>
      )}

      <div className="row g-3 mt-3">
        {isNewUser && (
          <div className="col-md-6">
            <InputComponent
              labelText={"Firstname"}
              value={firstName ?? ""}
              setValue={(value: string) => setFirstName(value)}
              disabled={false}
            />
            <InputComponent
              labelText={"Lastname"}
              value={lastName ?? ""}
              setValue={(value: string) => setLastName(value)}
              disabled={false}
            />
          </div>
        )}

        <div className="col-md-6">
          <InputComponent
            labelText={isNewUser ? "Email to create*" : "Email"}
            value={email ?? ""}
            setValue={(value: string) => setEmail(value)}
            disabled={!isNewUser}
          />
        </div>
        <div className="col-md-6">
          <div style={{ display: "flex" }}>
            <div style={{ width: "70%" }}>
              <InputComponent
                labelText={"OrganizationId"}
                value={organization?.toString()}
                setValue={(value: string) => setOrganization(value)}
                disabled={false}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <InputComponent
            labelText={"Role over organization"}
            value={permission?.toString()}
            setValue={(value: string) => setPermission(value)}
            disabled={true}
          />
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Permission options
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleSelect(setPermission, "Admin")}>Admin</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect(setPermission, "LocketgoAdmin")}>LocketgoAdmin</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect(setPermission, "Contributor")}>Contributor</Dropdown.Item>
              <Dropdown.Item onClick={() => handleSelect(setPermission, "")}>Nothing</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {isNewUser ? (
          <div className="mb-3">
            <PhoneFormComponent
              value={phoneNumber}
              setValue={setPhoneNumber}
              onMsgErrorChanged={() => {}}
              msgError={""}
              label={"Phone Number"}
            />
          </div>
        ) : (
          <></>
        )}
        {!isNewUser ? (
          <div className="col-md-6">
            <div style={{ display: "flex" }}>
              <div style={{ width: "70%" }}>
                <InputComponent labelText={"Status"} value={newStatus} setValue={(value: string) => setNewStatus(value)} disabled={true} />
              </div>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Status options
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSelect(setNewStatus, "Active")}>Active</Dropdown.Item>
                <Dropdown.Item onClick={() => handleSelect(setNewStatus, "Disabled")}>Disabled</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <></>
        )}
      </div>

      <br></br>
      <div style={{ marginBottom: "100px" }} className="mt-10">
        <button
          className="btn btn-primary"
          onClick={() => {
            setLoading(true);
            changeMemberPermissions();
          }}
        >
          {isNewUser ? "Create Member" : "Update Member"}
        </button>
      </div>

      <h2>Hub permissions for this Member</h2>

      {!isNewUser && <HubPermissionTable member={editedMember} organizationId={organization.toString()} setMember={setMemberEdited} />}
    </div>
  );
  async function changeMemberPermissions() {
    var statusText = document.getElementById("queryStatus");
    var res;

    // Creation of updated Member
    var requestMember: Member = editedMember == undefined ? new Member() : editedMember;

    requestMember.email = email ?? requestMember.email;

    if (isNewUser) {
      var ourOrg: any = await organizationService.getOrganizationById(chosenOrgId?.toString());
      requestMember.associatedOrganizations = [ourOrg];
      requestMember.id = "";
      requestMember.phone = phoneNumber ?? "1231231234";
      requestMember.firstname = firstName ?? "";
      requestMember.lastname = lastName ?? "";
      requestMember.postalCode = "";
      requestMember.unit = "";
      requestMember.active = true;
    }

    if (entityType == "Organization") {
      requestMember.associatedOrganizations?.forEach((org) => {
        if (org.entity?.id == chosenOrgId) {
          var arr: Array<string> = org.roles ?? [];

          (permission + ",").split(",").forEach((role: String) => {
            arr.push(role?.toString());
          });

          org.roles = arr;
        }
      });
    }

    requestMember.type = "0";

    // Push user to DB
    if (isNewUser) {
      if (email) {
        await memberService
          .createContributor(requestMember)
          .then((res) => {})
          .catch((err) => {
            ToastHelpers.error("Error creating member");
          });
        await memberService
          .addMemberRole(email, organization, permission, entityType)
          .then((res) => {
            if (statusText) {
              setLoading(false);
            }
            setEditingMember(false);
          })
          .catch((err) => {
            ToastHelpers.error("Error changing member role");
          });
      } else {
        ToastHelpers.error("Invalid Email");
      }
    } else {
      if (email) {
        await memberService
          .updateMemberRole(email, organization, permission, entityType, newStatus)
          .then((res) => {
            setLoading(false);
            setEditingMember(false);
          })
          .catch((err) => {
            ToastHelpers.error("Error changing member role");
          });
      } else {
        ToastHelpers.error("Invalid Email");
      }
    }
  }
}
