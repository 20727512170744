import ServicesApiBaseService from "../services-api-base-service";
import { CardPaymentRequest } from "./payment-request";
import { v4 as uuidv4 } from "uuid";
import { CardPaymentIntentResponse } from "./payment-intent-response";
import orderService from "../order/order-service";
import { PaymentState } from "./payment-state";
import { CardPaymentResponse } from "./payment-response";
import { Pricing } from "./pricing";
import { Payment } from "./payment";

class PaymentService extends ServicesApiBaseService {
  async createPaymentIntent(orderId: string): Promise<CardPaymentIntentResponse> {
    return await this.postObject<any, any>(`${this.apiUrl}/payments/create-payment-intent/${orderId}`, null);
  }

  async pay(cardPaymentRequest: CardPaymentRequest, organizationId: string): Promise<CardPaymentResponse> {
    if (!cardPaymentRequest.idempotencyKey) cardPaymentRequest.idempotencyKey = uuidv4();

    let timeoutId = setTimeout(() => {
      orderService
        .getOrderById(organizationId, cardPaymentRequest.orderToken, cardPaymentRequest.orderToken)
        .then((order) => {
          if (order && order.payments && order.payments.length > 0) {
            console.log("Payment charge Success (Timer)");
            return { state: PaymentState.Success };
          }

          console.log("Payment charge Timeout");
          return { state: PaymentState.Timeout };
        })
        .catch(() => {
          console.log("Payment charge Timeout error catched");
          return { state: PaymentState.Timeout };
        });
    }, 25000);

    try {
      const error = await this.postObject<CardPaymentRequest, string>(`${this.apiUrl}/payments`, cardPaymentRequest);
      if (error) {
        return { state: PaymentState.Error, error: error };
      } else {
        return { state: PaymentState.Success, error: undefined };
      }
    } catch (e: any) {
      console.log(typeof e);
      console.error(e.message);
      return { state: PaymentState.Error, error: "Declined payment" };
    } finally {
      clearTimeout(timeoutId);
    }
  }

  async getEndNowPricing(orderToken: string): Promise<Pricing | undefined> {
    let pricing = await this.getObject<Pricing>(`${this.apiUrl}/payments/end-now-pricing?ordertoken=${orderToken}`);
    return pricing;
  }

  async getPayment(orderToken: string): Promise<Payment[]> {
    let payment = await this.getObject<Payment[]>(`${this.apiUrl}/payments?ordertoken=${orderToken}`);
    return payment || [];
  }

  async endReservationEarly(orderToken: string): Promise<void> {
    let idempotencyKey = uuidv4();
    await this.postObject<any, any>(`${this.apiUrl}/payments/pay-for-end-of-reservation`, {
      orderToken: orderToken,
      idempotencyKey: idempotencyKey,
    });
  }

  async refundPayment(paymentId: string): Promise<void> {
    let idempotencyKey = uuidv4();
    await this.postObject<any, any>(`${this.apiUrl}/payments/${paymentId}/actions/refund?idempotencyKey=${idempotencyKey}`, null);
  }
}

const paymentService = new PaymentService();
export default paymentService;
