import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import StringHelpers from "../../services/core/string-helpers";
import { Hub } from "../../services/hub/hub";
import orderService from "../../services/order/order-service";
import hubService from "../../services/hub/hub-service";
import "./create-order-page.scss";
import Member from "../../services/member/member";
import { InputComponent } from "../../components/input";
import { HubHelpers } from "../../services/hub/hub-helper";
import packagingOptionService from "../../services/hub/packagingOption-service";
import { PackagingOption } from "../../services/hub/packgingOption";
import languageService from "../../services/core/language-service";
import Organization from "../../services/organization/organization";
import LoadingComponent from "../../components/loading-component";
import ToastHelpers from "../../services/core/toast-helpers";
import HubLocationComponent from "../../components/hub-location-component";
import customersService from "../../services/member/customers-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PhoneFormComponent } from "../../components/customer-information-components/phone-component";
import { Carrier } from "../../services/carrier/carrier";
import { OrderFlow } from "../../services/order/order";
import { InfoCirleButton } from "../../components/button-component";

interface CreateOrderPageProps {
  isReturn?: boolean | undefined;
  organization: Organization | undefined;
  member: Member;
}

export default function CreateOrderPage(props: CreateOrderPageProps) {
  const { organization, isReturn } = props;

  const [loading, setLoading] = useState(true);

  const searchResultCount: number = 5;
  const [hubs, setHubs] = useState<Array<Hub> | null>(null);
  const [carriers, setCarriers] = useState<Array<Carrier>>([]);
  const [packagingOptions, setPackagingOptions] = useState<Array<PackagingOption> | null>(null);
  const [packagingOptionsAvailable, setPackagingOptionsAvailable] = useState<Array<PackagingOption> | null>(null);
  const [customers, setCustomers] = useState<Array<Member>>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<Array<Member>>([]);
  const [customerSearchText, setCustomerSearchText] = useState<string>("");
  const [customerSelectedEmail, setCustomerSelectedEmail] = useState<string>("");
  const [referenceId, setReferenceId] = useState<string>("");

  const [step, setStep] = useState(1);

  const [selectExistingCustomer, setSelectExistingCustomer] = useState(true);

  const [hubId, setHubId] = useState<string>();

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstname, setFistname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");

  const [emailError, setEmailError] = useState<string>();
  const [phoneError, setPhoneError] = useState<string>();

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [packagingOptionId, setPackagingOptionId] = useState<string>("nopackaging");
  const [carrierId, setCarrierId] = useState<string>("nocarrier");

  const nocarrier = carrierId !== "nocarrier";

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(
    () => {
      if (organization) {
        packagingOptionService
          .getPackagingOptions()
          .then((packagingOptions) => {
            setPackagingOptions(packagingOptions);

            const hubAssociations = organization.associatedHubs.filter((o) => o.state !== "Rejected");

            hubService
              .getHubs()
              .then((hubs) => {
                const hubsFiltered = hubs.filter((h) => hubAssociations?.find((ha) => ha.hubId === h.id) && h.active);
                setHubs(hubsFiltered);

                if (hubsFiltered.length === 1) {
                  onHubSelected(hubs, packagingOptions, hubsFiltered[0].id);
                }

                customersService
                  .getCustomers(organization?.id)
                  .then((customers) => {
                    customers = customers.filter((customer) => customer.firstname && customer.lastname && customer.email);
                    setCustomers(customers);
                    setSelectExistingCustomer(customers.length > 0);

                    onCustomerSearchTextChanged("", customers);

                    setLoading(false);
                  })
                  .catch(() => {
                    ToastHelpers.error(t("customers-page.error-refresh"));
                  });
              })
              .catch(() => {
                ToastHelpers.error(t("common.error-refresh"));
              });
          })
          .catch(() => {
            ToastHelpers.error(t("common.error-refresh"));
          });
      }
    },
    // eslint-disable-next-line
    [organization]
  );

  if (hubs?.length === 0) {
    return (
      <div className="container page create-order-page">
        <div className="col col-lg-6 offset-lg-3">
          <p>{t("create-order-page.no-hub")}</p>
          <button className="btn btn-primary" onClick={() => history.push(`/orders`)}>
            {t("common.return")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container page create-order-page">
      <div className="col col-lg-6 offset-lg-3">
        <h1>{isReturn ? t("create-order-page.return.title") : t("create-order-page.title")}</h1>

        <LoadingComponent loading={loading} />

        {!loading && step === 1 && (
          <div className="customer card">
            <div className="card-header align-items-center">
              <div className="d-inline-block">
                <h2>{t("create-order-page.chose-hub")}</h2>
              </div>
              <button className="btn-close float-end" onClick={() => history.push("orders")}></button>
            </div>
            <div className="card-body">
              {hubs && (
                <table className="table table-hover row-clikable">
                  <tbody>
                    {hubs.map((hub: Hub) => {
                      return (
                        <tr
                          key={hub.id}
                          onClick={() => {
                            onHubSelected(hubs, packagingOptions, hub.id);
                          }}
                        >
                          <td style={{ textAlign: "left" }}>
                            <HubLocationComponent hub={hub} />
                          </td>
                          <td>
                            {!HubHelpers.isHubOnline(hub.state) && (
                              <span style={{ color: "red" }}>{" " + t("create-order-page.select-hub-not-available")}</span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}

        {!loading && step === 2 && (
          <div className="customer card">
            <div className="card-header align-items-center">
              <div className="d-inline-block">
                <h2>{t("customer.customer-information")}</h2>
              </div>
              <button className="btn-close float-end" onClick={() => history.push("orders")}></button>
            </div>

            <div className="card-body">
              <form onSubmit={(ev) => ev.preventDefault()}>
                <div className="mb-4">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="selectexistingcustomer"
                    id="selectexistingcustomer"
                    key="selectexistingcustomer"
                    checked={selectExistingCustomer}
                    disabled={customers.length === 0}
                    onChange={onSelectCustomerSelected}
                  />
                  <label className="form-check-label" htmlFor="selectexistingcustomer">
                    {t("create-order-page.existing-customer")}
                  </label>

                  {customers.length > 0 && selectExistingCustomer && (
                    <>
                      <div className="ms-4 mb-2">
                        <input
                          className="form-control mb-2"
                          value={customerSearchText}
                          placeholder={t("create-order-page.searchbox")}
                          onChange={(ev) => onCustomerSearchTextChanged(ev.target.value, customers)}
                          autoFocus={true}
                        />
                        <div className="fixed-head-table">
                          <table className="table table-sm table-hover row-clikable">
                            <thead>
                              <tr>
                                <th></th>
                                <th>{t("create-order-page.name")}</th>
                                <th>{t("create-order-page.email")}</th>
                                <th>{t("common.phone")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredCustomers.length > 0 &&
                                filteredCustomers.map((c) => (
                                  <tr key={c.email} onClick={() => setCustomerSelectedEmail(c.email)}>
                                    <td>
                                      {customerSelectedEmail === c.email && (
                                        <FontAwesomeIcon icon="check-circle" className="text-success"></FontAwesomeIcon>
                                      )}
                                    </td>
                                    <td>
                                      {c.firstname} {c.lastname?.length > 0 && " " + c.lastname}
                                    </td>
                                    <td>{c.email}</td>
                                    <td>{c.phone}</td>
                                  </tr>
                                ))}
                              {filteredCustomers.length === 0 && <div>{t("create-order-page.no-results")}</div>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="newcustomer"
                      id="newcustomer"
                      key="newcustomer"
                      checked={!selectExistingCustomer}
                      onChange={onSelectCustomerSelected}
                    />
                    <label className="form-check-label" htmlFor="newcustomer">
                      {t("create-order-page.new-customer")}
                    </label>

                    {(customers.length === 0 || !selectExistingCustomer) && (
                      <div className="ms-4">
                        {/* FIRST NAME */}
                        <div className="mb-3">
                          <label className="form-label">{t("customer.enter-firstname")}</label>
                          <input
                            className="form-control"
                            value={firstname}
                            onChange={(ev) => setFistname(ev.target.value)}
                            required
                            autoFocus={true}
                          />
                        </div>

                        {/* LAST NAME */}
                        <div className="mb-3">
                          <label className="form-label">{t("customer.enter-lastname")}</label>
                          <input className="form-control" value={lastname} onChange={(ev) => setLastname(ev.target.value)} required />
                        </div>

                        {/* PHONE */}
                        <div className="mb-3">
                          <PhoneFormComponent
                            value={phoneNumber}
                            setValue={setPhoneNumber}
                            onMsgErrorChanged={setPhoneError}
                            msgError={phoneError}
                            label={t("common.phone")}
                          />
                        </div>

                        {/* EMAIL */}
                        <div className="mb-3">
                          <InputComponent
                            value={email}
                            setValue={setEmail}
                            regExp={StringHelpers.getEmailRegExp()}
                            msgError={emailError}
                            onMsgErrorChanged={setEmailError}
                            type={"email"}
                            labelText={t("customer.enter-email")}
                            required={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label">{t("create-order-page.reference-number")}</label>
                  <input
                    className="form-control"
                    value={referenceId}
                    onChange={(ev) => setReferenceId(ev.target.value.substring(0, 128))}
                    maxLength={128}
                  />
                </div>

                {/* Packaging Options */}
                {!isReturn && hubs && packagingOptionsAvailable && packagingOptionsAvailable.length > 0 && (
                  <div className="mb-4">
                    <div className="mb-2">
                      {t("create-order-page.chose-packaging-option")}
                      <InfoCirleButton
                        id={"packagingInfo"}
                        title={t("create-order-page.chose-packaging-option")}
                        textInfo={t("create-order-page.packaging-option-info")}
                      />
                    </div>
                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="nopackaging"
                        id="nopackaging"
                        key="nopackaging"
                        checked={packagingOptionId === "nopackaging"}
                        onChange={onPackagingOptionSelected}
                        disabled={nocarrier}
                      />
                      <label className="btn btn-outline-primary" htmlFor="nopackaging">
                        {t("create-order-page.no-packaging")}
                      </label>

                      {packagingOptionsAvailable?.map((packagingOption: PackagingOption) => {
                        return (
                          <React.Fragment key={packagingOption.id}>
                            <input
                              type="radio"
                              className="btn-check"
                              name={packagingOption.id}
                              id={packagingOption.id}
                              checked={packagingOptionId === packagingOption.id}
                              onChange={onPackagingOptionSelected}
                              disabled={nocarrier}
                            />
                            <label className="btn btn-outline-primary" htmlFor={packagingOption.id}>
                              {packagingOption.description.get(languageService.language)}
                            </label>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* Carrier Options */}
                {!isReturn && hubs && carriers.length > 0 && (
                  <div className="mb-5">
                    <div className="mb-2">
                      {t("create-order-page.chose-carrier-option")}
                      <InfoCirleButton
                        id={"carrierInfo"}
                        title={t("create-order-page.chose-carrier-option")}
                        textInfo={t("create-order-page.carrier-info")}
                      />
                    </div>
                    <div className="btn-group" role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="nocarrier"
                        id="nocarrier"
                        key="nocarrier"
                        checked={carrierId === "nocarrier"}
                        onChange={onCarrierSelected}
                      />
                      <label className="btn btn-outline-primary" htmlFor="nocarrier">
                        {t("create-order-page.default-carrier-option")}
                      </label>

                      {carriers.map((carrier: Carrier) => {
                        return (
                          <React.Fragment key={carrier.id}>
                            <input
                              type="radio"
                              className="btn-check"
                              name={carrier.id}
                              id={carrier.id}
                              checked={carrierId === carrier.id}
                              onChange={onCarrierSelected}
                            />
                            <label className="btn btn-outline-primary" htmlFor={carrier.id}>
                              {carrier.name}
                            </label>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                )}

                <button className="btn btn-lg btn-primary" type="submit" onClick={() => createOrder()} disabled={isCreating}>
                  {isReturn ? t("create-order-page.return.create") : t("create-order-page.create")}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  function onPackagingOptionSelected(event: any) {
    event.persist();
    const { id } = event.target;
    setPackagingOptionId(id);

    if (carrierId !== "nocarrier" && id !== "nopackaging") setPackagingOptionId("nopackaging");
  }

  function onCarrierSelected(event: any) {
    event.persist();
    const { id } = event.target;
    setCarrierId(id);

    if (id !== "nocarrier") setPackagingOptionId("nopackaging");
  }

  function onSelectCustomerSelected(event: any) {
    event.persist();
    const { id } = event.target;
    setSelectExistingCustomer(id === "selectexistingcustomer");
  }

  function onCustomerSearchTextChanged(text: string, customers: Array<Member>) {
    setCustomerSearchText(text);

    if (text === "") {
      //console.log("listFiltered : No text to filter");
      setFilteredCustomers(customers);
    } else {
      const listFiltered = customers.filter((c) => {
        return searchMatch(c.firstname, text) || searchMatch(c.lastname, text) || searchMatch(c.email, text) || searchMatch(c.phone, text);
      });

      if (listFiltered.length > searchResultCount) {
        listFiltered.slice(0, searchResultCount);
      }

      // console.log("listFiltered", listFiltered);
      setFilteredCustomers(listFiltered);
    }
  }

  function searchMatch(text: string, search: string): boolean {
    return text.startsWith(search);
  }

  function onClick(number: number) {
    number < 4 && setStep(number);
  }

  function onHubSelected(hubs: Array<Hub> | null, packagingOptions: Array<PackagingOption> | null, hubId: string) {
    setHubId(hubId);

    const hub = hubs?.find((h) => h.id === hubId);

    if (HubHelpers.isHubOnline(hub?.state)) {
      if (hub) {
        setCarriers(hub.carriers);

        const ids = Array.from(HubHelpers.getPackagingOptiponIds(hub));

        let options = new Array<PackagingOption>();
        ids.forEach((id) => {
          const option = packagingOptions?.find((option) => option.id === id);
          if (option) {
            options.push(option);
          }
        });
        setPackagingOptionsAvailable(options);
      }

      onClick(step + 1);
    } else {
      ToastHelpers.error(t("create-order-page.error-hub-offline"));
    }
  }

  function createOrder() {
    if (!hubId || !organization) return;

    if (selectExistingCustomer && customerSelectedEmail?.length === 0) {
      ToastHelpers.error(t("create-order-page.error-select-customer"));
      return;
    }

    if (packagingOptionId !== "nopackaging" && carrierId !== "nocarrier") return;

    if (!selectExistingCustomer && (!email || !firstname || !lastname || phoneError || emailError)) {
      setIsCreating(false);
      return;
    }

    setIsCreating(true);

    let packagingId = packagingOptionId;
    if (packagingOptionId === "nopackaging") {
      packagingId = "";
    }

    let newCarrierId: string | undefined = carrierId;
    if (newCarrierId === "nocarrier") {
      newCarrierId = undefined;
    }

    let tmpFirstname = firstname;
    let tmpLastname = lastname;
    let tmpEmail = email;
    let tmpPhoneNumber = phoneNumber;
    if (selectExistingCustomer && customerSelectedEmail?.length > 0) {
      const customerSelected = customers.find((c) => c.email === customerSelectedEmail);
      if (customerSelected) {
        tmpFirstname = customerSelected?.firstname;
        tmpLastname = customerSelected?.lastname;
        tmpEmail = customerSelected?.email;
        tmpPhoneNumber = customerSelected?.phone;
      }
    }

    if (tmpFirstname && tmpLastname && tmpEmail) {
      const flow = isReturn ? OrderFlow.Return : OrderFlow.Deliver;
      orderService
        .createOrder(
          hubId,
          organization.id,
          tmpPhoneNumber,
          tmpEmail,
          tmpFirstname,
          tmpLastname,
          languageService.getLanguage(),
          packagingId,
          newCarrierId,
          flow,
          referenceId
        )
        .then((response) => {
          return history.push(`/orders/${response}`);
        })
        .catch((error) => {
          setIsCreating(false);
          if (error?.response?.status === 409) {
            setEmailError(t("create-order-page.error-email-exist"));
          } else {
            ToastHelpers.error(t("create-order-page.error-conflict"));
          }
        });
    } else {
      ToastHelpers.error(t("create-order-page.customer-information-error"));
    }
  }
}
