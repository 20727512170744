import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AddressDetailComponent from "../../../components/address-detail-component";
import HubLocationComponent from "../../../components/hub-location-component";
import LoadingComponent from "../../../components/loading-component";
import { RequestState } from "../../../services/core/request-state";
import StringHelpers from "../../../services/core/string-helpers";
import useMyHub from "../../../services/hub/useMyHub";
import Member from "../../../services/member/member";
import memberService from "../../../services/member/member-service";
import DateHelpers from "../../../services/core/date-helpers";

interface HubStateInformationProps {
  member: Member;
}

interface IParamTypes {
  hubId: string;
}

export default function HubStateInformation(props: HubStateInformationProps) {
  const { member } = props;

  const { hubId } = useParams<IParamTypes>();
  const { t } = useTranslation();
  const history = useHistory();
  const { hub, requestState } = useMyHub({ hubId: hubId, onErrorNotificationMessage: t("hub-page.error-refresh") });

  const [isLocketgoHubAdmin, setIsLocketgoHubAdmin] = useState<boolean>(false);
  const [isHubAdmin, setIsHubAdmin] = useState<boolean>(false);

  const loading = requestState === RequestState.Loading;

  useEffect(() => {
    setIsLocketgoHubAdmin(memberService.isLocketgoHubAdmin(member));
    setIsHubAdmin(memberService.isHubContributor(member, hub?.id));
  }, [member]);

  return (
    <div className="row hub-infos">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {loading && <LoadingComponent loading={loading} />}
            {!loading && (
              <>
                <div className="row">
                  <div className="col-6">
                    {hub ? <HubLocationComponent hub={hub} /> : <p>{t("hub-page.no-hub-message")}</p>}
                    {hub?.private && <FontAwesomeIcon className="ms-2" icon={["fas", "user-lock"]} />}
                    {hub && isHubAdmin && (
                      <>
                        {hub.lastSynchronization && (
                          <>
                            <br />
                            <span>
                              {t("hub-page.last-synchronization")}{" "}
                              {DateHelpers.formatDateTime(new Date(hub.lastSynchronization), DateHelpers.UserTimeZone)}
                            </span>
                          </>
                        )}
                        {hub.version && (
                          <>
                            <br />
                            <span>
                              Version:{" "}
                              {DateHelpers.formatDateTime(new Date(hub.version.substring(0, 19) + "+00:00"), DateHelpers.UserTimeZone)}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {hub?.address && (
                    <div className="col-6">{hub ? <AddressDetailComponent intialDetail={true} address={hub.address} /> : <></>}</div>
                  )}
                  {hub && hub.computerMetrics && isLocketgoHubAdmin && (
                    <>
                      <div className="col-6">
                        <hr></hr>
                        <h5>Computer metrics</h5>
                        <span>
                          {t("hub-page.computerMetrics-cpuTemp")} {roundValue(hub.computerMetrics.cpuTemperature, 1)} C°
                        </span>
                        <br />
                        <span>
                          {t("hub-page.computerMetrics-cpuUsed")} {roundValue(hub.computerMetrics.cpuPercentage, 1)}%
                        </span>
                        <br />
                        <span>
                          {t("hub-page.computerMetrics-memUsed")} {Math.round(hub.computerMetrics.memUsed)} /{" "}
                          {Math.round(hub.computerMetrics.memTotal)} MB ({roundValue(hub.computerMetrics.memUsedPercentage, 1)}%)
                        </span>
                        <br />
                        <span>
                          {t("hub-page.computerMetrics-diskUsed")} {Math.round(hub.computerMetrics.diskUsed)} /{" "}
                          {Math.round(hub.computerMetrics.diskTotal)} MB ({roundValue(hub.computerMetrics.diskUsedPercentage, 1)}%)
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-sm btn-primary float-end"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        history.push(`/hubs/${hubId}/activities`);
                      }}
                    >
                      {t("hub-page.history")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function roundValue(value: string, numberOfDigits: number): string {
  return Number.parseFloat(value).toFixed(numberOfDigits);
}
