import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SyncButtonComponent, DeleteButtonComponent } from "../../../components/button-component";
import { NotifyLevelComponent } from "../../../components/notify-level-icon-component";
import {
  NoReservationComponent,
  DoorOpeningGifComponent,
  OpenDoorComponent,
  DoorOpenedComponent,
} from "../../../components/order-page-components";
import { StateBadgeComponent } from "../../../components/state-badge-component";
import languageService from "../../../services/core/language-service";
import StringHelpers from "../../../services/core/string-helpers";
import ToastHelpers from "../../../services/core/toast-helpers";
import { CompartmentSize } from "../../../services/hub/compartmentSize";
import { Hub } from "../../../services/hub/hub";
import { HubHelpers } from "../../../services/hub/hub-helper";
import { Order } from "../../../services/order/order";
import { OrderHelpers } from "../../../services/order/order-helpers";
import { Reservation } from "../../../services/order/reservation";
import Organization from "../../../services/organization/organization";
import orderService from "../../../services/order/order-service";
import { ReservationState } from "../../../services/order/ReservationState";
import { useMemo } from "react";
import DateHelpers from "../../../services/core/date-helpers";

interface OrderReservationProps {
  order: Order;
  orderId: string;
  organization: Organization;
  hub: Hub;
  isAdmin: boolean;
  isOrgContributor: boolean | undefined;
  isCarriedByCarrier: boolean;
  isPersonalStorage: boolean;
  isReturn: boolean;
  itemIdInOpening: string | null;
  compartmentSizes: Array<CompartmentSize>;
  onCreateReservation(): void;
  onRefresh(): Promise<void>;
  onTrackingNumberDelete(orderItemId: string, trackingNumber: string): void;
  onOrderItemDelete(organizationId: string, orderId: string, orderItemId: string, reservationState: string): void;
  openDoor(organizationId: string, orderId: string, orderItemId: string, state: string): Promise<void>;
}

function OrderReservation(props: OrderReservationProps) {
  const {
    order,
    orderId,
    organization,
    hub,
    isAdmin,
    isOrgContributor,
    isCarriedByCarrier,
    isPersonalStorage,
    isReturn,
    itemIdInOpening,
    compartmentSizes,
    onRefresh,
    onTrackingNumberDelete,
    onCreateReservation,
    onOrderItemDelete,
    openDoor,
  } = props;

  const { t } = useTranslation();
  const language: string = languageService.getLanguage();
  const history = useHistory();
  const dateTimeNow = () => moment(new Date()).format("YYYY-MM-DDTHH:mm");

  const isAnyReservationOpened = useMemo(() => {
    return order.items?.some((oi) => OrderHelpers.inOpenedState(oi?.reservation?.state));
  }, [order]);

  const reservationStart = order.items[0]?.reservation?.start;
  const reservationEnd = order.items[0]?.reservation?.end;
  const isCarrierOrder = order.carrierId && order.items.length >= 1;

  let sliceReservationDatesNumber: number = 0;

  if (reservationStart && reservationEnd) {
    if (reservationStart.substring(0, 10) === reservationEnd.substring(0, 10)) {
      sliceReservationDatesNumber = 10;
    } else if (reservationStart.substring(0, 5) === reservationEnd.substring(0, 5)) {
      sliceReservationDatesNumber = 5;
    }
  }

  return (
    <div className="row">
      <div className="col">
        <div className="customer card">
          <div className="card-body">
            <h5 className="card-title">
              {t("order-page.locker-reservation")}
              {isPersonalStorage && (
                <SyncButtonComponent className={!isCarrierOrder ? "ms-2" : ""} onClick={onRefresh}></SyncButtonComponent>
              )}
            </h5>
            <div>
              {compartmentSizes[0] && !isPersonalStorage && (
                <>
                  {!isCarrierOrder && (
                    <button
                      className="btn-reserve-locker mt-3 mb-3 btn btn-primary"
                      onClick={() => onCreateReservation()}
                      disabled={!canCreateReservation(hub, itemIdInOpening, order)}
                    >
                      <FontAwesomeIcon className="me-2" icon={["fas", "plus"]} />
                      {t("order-page.btn-add-locker-reservation")}
                    </button>
                  )}
                  <SyncButtonComponent className={!isCarrierOrder ? "ms-2" : ""} onClick={onRefresh}></SyncButtonComponent>
                </>
              )}
            </div>

            <table className="table table-sm table-order">
              <thead>
                <tr>
                  <th></th>
                  <th>{t("order-page-table-header.locker")}</th>
                  <th>{t("order-page-table-header.size")}</th>
                  <th>{t("order-page-table-header.orderItemState")}</th>
                  <th>{t("order-page-table-header.doorState")}</th>
                  {isPersonalStorage && <th>{t("order-page-table-header.codes")}</th>}
                  {!isPersonalStorage && (
                    <>
                      <th>{t("common.tracking-numbers")}</th>
                      <th>{t("order-page-table-header.dropoffCode")}</th>
                    </>
                  )}

                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {order.items?.length === 0 && <NoReservationComponent title={t("order-page.no-reservation")}></NoReservationComponent>}

                {order.items?.map((orderItem) => {
                  let reservation = orderItem?.reservation;
                  const codeToDisplay = isReturn ? orderItem.reservation.pickupCode : orderItem.reservation.dropoffCode;

                  if (reservation) {
                    let sizeId = HubHelpers.findGroup(hub, reservation.groupId)?.compartments.find(
                      (c) => c.number === reservation.compartmentNumber
                    )?.sizeId;

                    const compartmentSize = compartmentSizes.find((cs) => cs.id === sizeId);

                    const notifyLevel = reservation.notifyLevel;
                    let notifyText = "";
                    if (notifyLevel === "Medium" || notifyLevel === "High") {
                      const dropOffTime = new Date(reservation.dropOffTime);
                      notifyText = t("order-page.notifyText", { since: DateHelpers.timeSince(dropOffTime) });
                    }

                    const hasOpening = itemIdInOpening !== null;
                    const inOpening = itemIdInOpening == orderItem.id;
                    const isTrackingNumberDisabled =
                      !OrderHelpers.trackingNumbersEditable(order) || hasOpening || isAnyReservationOpened || order.carrierId !== null;
                    let openAvailable = true;
                    if (isReturn) {
                      openAvailable = reservation.state === ReservationState.ReadyForPickUp;
                    } else if (isPersonalStorage) {
                      openAvailable = false;
                    } else {
                      openAvailable =
                        reservation.state === ReservationState.New || reservation.state === ReservationState.PackagingReadyForPickUp;
                    }

                    openAvailable = openAvailable && dateTimeNow() >= moment(new Date(reservation.start)).format("YYYY-MM-DDTHH:mm");

                    const opened = OrderHelpers.inOpenedState(reservation.state);

                    const lockernumb = /^\d+$/.test(reservation.compartmentNumber)
                      ? reservation.compartmentNumber
                      : reservation.groupId + reservation.compartmentNumber;

                    return (
                      <tr key={orderItem.id}>
                        <td title={notifyText}>
                          <NotifyLevelComponent notifyLevel={order.notifyLevel}></NotifyLevelComponent>
                        </td>
                        <td>{lockernumb}</td>
                        <td>
                          {isCarriedByCarrier
                            ? t("order-page.item.size", {
                                height: orderItem.height,
                                width: orderItem.width,
                                depth: orderItem.depth,
                              })
                            : compartmentSize?.name.get(language)}
                        </td>
                        <td>
                          <StateBadgeComponent state={reservation.state} isPersonalStorage={isPersonalStorage}></StateBadgeComponent>
                        </td>
                        <td>
                          {inOpening && !isAnyReservationOpened && <DoorOpeningGifComponent />}
                          {!inOpening && !opened && (
                            <OpenDoorComponent
                              onClick={async () => await openDoor(organization.id, orderId, orderItem.id, reservation.state)}
                              disabled={!openAvailable || hasOpening || isAnyReservationOpened || !HubHelpers.isHubOnline(hub.state)}
                            ></OpenDoorComponent>
                          )}
                          {!inOpening && opened && <DoorOpenedComponent />}
                        </td>
                        <td style={{ maxWidth: "750px" }}>
                          {orderItem.reservation.trackingNumbers.map((track) => (
                            <span className="badge rounded-pill bg-dark text-light p-2 me-1">
                              {track}
                              <button
                                className="btn-close btn-close-white float-end ms-1 p-0"
                                onClick={() => onTrackingNumberDelete(orderItem.id, track)}
                                disabled={isTrackingNumberDisabled}
                              ></button>
                            </span>
                          ))}

                          <span
                            id={`tracking-number-adding-${orderItem.id}`}
                            className="badge rounded-pill bg-dark text-light p-2 me-1"
                            hidden
                          >
                            <form
                              onSubmit={(ev) => {
                                ev.preventDefault();
                                let input = ev.currentTarget.getElementsByTagName("input")[0] as HTMLInputElement;
                                addTrackingNumber(organization.id, order, orderItem.id, input.value);
                                showAddOrderItemTrackingNumberInput(orderItem.id, false);
                              }}
                            >
                              <input
                                id="trackingNumberInput"
                                onBlur={() => showAddOrderItemTrackingNumberInput(orderItem.id, false)}
                                onKeyDown={(input: React.KeyboardEvent<any>) =>
                                  onTabKeyDownToAddTrackingNumber(input, order, organization.id, orderItem.id)
                                }
                                type="text"
                              ></input>
                            </form>
                          </span>

                          <button
                            type="button"
                            onClick={() => showAddOrderItemTrackingNumberInput(orderItem.id, true)}
                            id={`tracking-number-add-btn-${orderItem.id}`}
                            className="badge rounded-pill btn btn-primary p-2 ps-3 pe-3"
                            disabled={isTrackingNumberDisabled}
                          >
                            <FontAwesomeIcon icon={["fas", "plus"]} />
                          </button>
                        </td>

                        {!isPersonalStorage && <td>{codeToDisplay}</td>}
                        <td>
                          <span className="justify-content-around d-flex">
                            {isOrgContributor && (
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  history.push(`${orderId}/items/${orderItem.id}/relocate`);
                                }}
                                disabled={
                                  hasOpening ||
                                  isAnyReservationOpened ||
                                  reservation.state === ReservationState.Completed ||
                                  order.deleted === 1
                                }
                              >
                                {t("order-page.relocate")}
                              </button>
                            )}
                            {orderItem.id && reservation.state && !isPersonalStorage && (
                              <DeleteButtonComponent
                                title={undefined}
                                onClick={() => onOrderItemDelete(organization.id, orderId, orderItem.id, reservation.state)}
                                disabled={!canCancelReservation(hub, order, hasOpening, isAnyReservationOpened, reservation, isReturn)}
                              ></DeleteButtonComponent>
                            )}
                          </span>
                        </td>
                        <td>
                          <button className="icon-info-circle primary" data-bs-target="#sizeInfo" data-bs-toggle="modal">
                            <FontAwesomeIcon icon={["fas", "info-circle"]} />
                          </button>
                          <div className="modal" tabIndex={-1} id="sizeInfo">
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">{t("order-page.reservation-details")}</h5>
                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                  <div style={{ textAlign: "left" }}>
                                    <h6>{t("order-page.size-details") + ":"}</h6>
                                    {compartmentSizes.map((compartmentSize: CompartmentSize) => {
                                      return (
                                        <div>
                                          {compartmentSize?.name.get(language)}: {compartmentSize?.description.get(language)}
                                        </div>
                                      );
                                    })}
                                    {order?.flow.toString() === "PersonalStorage" && isAdmin && reservationStart && reservationEnd && (
                                      <div>
                                        <hr></hr>
                                        {t("order-page.reservationDate")}
                                        {": "}
                                        {DateHelpers.formatDateTime(new Date(reservationStart), DateHelpers.DefaultTimeZone)?.slice(
                                          sliceReservationDatesNumber
                                        )}{" "}
                                        {t("common.to")}{" "}
                                        {DateHelpers.formatDateTime(new Date(reservationEnd), DateHelpers.DefaultTimeZone)?.slice(
                                          sliceReservationDatesNumber
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
                                    {t("common.close")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  return "";
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  function onTabKeyDownToAddTrackingNumber(input: React.KeyboardEvent<any>, order: Order, organizationId: string, orderItemId: string) {
    if (input.key === "Tab") {
      const trackingNumberInput: HTMLInputElement = document.getElementById("trackingNumberInput") as HTMLInputElement;
      addTrackingNumber(organizationId, order, orderItemId, trackingNumberInput?.value);
      showAddOrderItemTrackingNumberInput(orderItemId, false);
    }
  }

  async function addTrackingNumber(organizationId: string, currentOrder: Order, orderItemId: string, trackingNumber: string) {
    if (!trackingNumber) {
      return;
    }

    if (organization.id == "granby" && isPersonalStorage && !trackingNumber.match(/^[1-4]{6}$/)) {
      ToastHelpers.error(t("input-component.error-code-sl5c"));
      return;
    }

    const order: Order = { ...currentOrder };
    let trackingNumbers = new Array<string>();

    if (order) {
      for (let i = 0; i < order.items.length; i++) {
        if (order.items[i].id === orderItemId) {
          order.items[i].reservation.trackingNumbers.push(trackingNumber);
          trackingNumbers = order.items[i].reservation.trackingNumbers;
        }
      }

      await orderService.updateOrderItem(organizationId, orderId, orderItemId, trackingNumbers).catch((error: any) => {
        if (error?.response?.data === "Tracking number already existing") {
          if (isPersonalStorage) {
            ToastHelpers.error(t("order-page.code-already-existing"));
          } else {
            ToastHelpers.error(t("order-page.tracking-number-already-existing"));
          }
        } else {
          ToastHelpers.error(t("order-page.error-create-tracking-number"));
        }
      });

      await onRefresh();
    }
  }
}

function canCreateReservation(hub: Hub, itemIdInOpening: String | null, order: Order) {
  if (!HubHelpers.isOneGroupsOnline(hub)) {
    return false;
  }

  if (itemIdInOpening) {
    return false;
  }

  return OrderHelpers.editable(order);
}

function canCancelReservation(
  hub: Hub,
  order: Order,
  hasOpening: boolean,
  isAnyReservationOpened: boolean,
  reservation: Reservation,
  isReturn: boolean
) {
  if (!hub) return false;
  if (order?.deleted) return false;
  if (hasOpening || isAnyReservationOpened) return false;

  if (reservation.state === ReservationState.New) return true;

  if (!isReturn && reservation.state === ReservationState.ReadyForPickUp) {
    return HubHelpers.isGroupOnline(hub, reservation.groupId);
  }

  return false;
}

function showAddOrderItemTrackingNumberInput(orderItemId: string, show: boolean) {
  let inputContainer = document.getElementById(`tracking-number-adding-${orderItemId}`);
  let btn = document.getElementById(`tracking-number-add-btn-${orderItemId}`);

  if (inputContainer) {
    inputContainer.hidden = !show;
    let input = inputContainer.getElementsByTagName("form")[0].getElementsByTagName("input")[0];
    if (show) {
      input.focus();
    } else {
      input.value = "";
    }
  }
  if (btn) {
    btn.hidden = show;
  }
}

export default OrderReservation;
