import languageService from "../core/language-service";
import ServicesApiBaseService from "../services-api-base-service";
import { Order, OrderFlow } from "./order";

export enum OrderState {
  Active = "Active",
  Cancelled = "Cancelled",
  Completed = "Completed",
}

class OrderService extends ServicesApiBaseService {
  // GET
  async getOrders(organizationId: string, state: string, start?: string, end?: string): Promise<Array<Order>> {
    const orders = await this.getObject<Array<Order>>(
      `${this.apiUrl}/organizations/${organizationId}/orders?state=${state}${start ? `&start=${start}` : ""}${end ? `&end=${end}` : ""}`
    );
    return orders || [];
  }

  async getOrderById(organizationId: string, id: string, token: string | null = null): Promise<Order | null> {
    let tokenTmp = token !== null ? `?token=${token}` : "";
    const order = await this.getObject<Order>(`${this.apiUrl}/organizations/${organizationId}/orders/${id + tokenTmp}`);
    return order || null;
  }

  async getAvailableCompartmentSizeIds(
    organizationId: string,
    hubId: string,
    orderFlow: OrderFlow,
    start?: string,
    end?: string
  ): Promise<Array<string>> {
    const sizeIds = await this.postObject<any, Array<string>>(
      `${this.apiUrl}/organizations/${organizationId}/orders/actions/availablesizes`,
      { hubId: hubId, orderFlow: orderFlow, start: start, end: end }
    );
    return sizeIds || [];
  }

  async getAsset(organizationId: string, orderId: string, assetId: string): Promise<Blob | null> {
    let label = await this.getFile(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/assets/${assetId}`);
    return label || null;
  }

  // POST
  async createOrder(
    hubId: string,
    organizationId: string,
    phoneNumber: string,
    email: string,
    firstname: string | undefined,
    lastname: string | undefined,
    language: string | undefined,
    packagingOptionId: string | undefined,
    carrierId: string | undefined,
    flow: OrderFlow,
    referenceNumber: string | undefined
  ): Promise<number | undefined> {
    return await this.postObject<any, number>(`${this.apiUrl}/organizations/${organizationId}/orders`, {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phoneNumber,
      language: language || languageService.getLanguage(),
      hubId: hubId,
      packagingOptionId: packagingOptionId,
      flow: flow,
      carrierId: carrierId,
      referenceNumber: referenceNumber,
    });
  }

  async createOrderItem(
    organizationId: string,
    orderId: string,
    sizeId: string,
    height?: number,
    width?: number,
    depth?: number,
    weight?: number
  ): Promise<number | undefined> {
    return await this.postObject<any, number>(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/items?reserve=true`, {
      description: "",
      height: height,
      width: width,
      depth: depth,
      weight: weight,
      sizeId: sizeId,
    });
  }

  async relocateReservation(
    organizationId: string,
    orderId: string,
    orderItemId: string,
    sizeId?: string,
    reservationStart?: string,
    reservationEnd?: string
  ): Promise<number | undefined> {
    return await this.postObject<any, number>(
      `${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/items/${orderItemId}/relocate`,
      { sizeId: sizeId, reservationStart: reservationStart, reservationEnd: reservationEnd }
    );
  }

  async openDoor(organizationId: string, orderId: string, orderItemId: string, reason: string, token: string | null): Promise<void> {
    let url = `${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/items/${orderItemId}/actions/opendoor`;
    if (token) {
      url += `?token=${token}`;
    }

    await this.postObject(url, {
      requestingFrom: "portal",
      reason: reason,
    });
  }

  async sendReadyForPickupNotification(organizationId: string, orderId: string) {
    await this.postObject(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/notify`, null);
  }

  async requestCarrierPickup(organizationId: string, orderId: string): Promise<Order | null> {
    let order = await this.postObject<any, Order>(
      `${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/actions/requestcarrierpickup`,
      null
    );
    return order || null;
  }

  async cancelCarrierPickup(organizationId: string, orderId: string): Promise<Order | null> {
    let order = await this.postObject<any, Order>(
      `${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/actions/cancelcarrierpickup`,
      null
    );
    return order || null;
  }

  async completeBopilOrder(organizationId: string, orderId: string): Promise<any> {
    return await this.postObject(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/complete`, null);
  }

  async endOrder(organizationId: string, orderId: string, token: string) {
    return await this.postObject(
      `${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/end${token ? `?token=${token}` : ""}`,
      null
    );
  }

  // PUT
  async updateOrderItem(
    organizationId: string,
    orderId: string,
    orderItemId: string,
    trackingNumbers: Array<string>,
    deleteTrackingNumber?: boolean
  ): Promise<any> {
    let url = `${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/items/${orderItemId}`;
    return await this.putObject(url, { trackingNumbers, deleteTrackingNumber });
  }

  // DELETE

  async cancelOrder(organizationId: string, orderId: string): Promise<void> {
    await this.deleteObject(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}`);
  }

  async deleteOrderItem(organizationId: string, orderId: string, orderItemId: string) {
    return await this.deleteObject(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/items/${orderItemId}`);
  }

  async cancelOrderItem(organizationId: string, orderId: string, orderItemId: string): Promise<void> {
    await this.deleteObject(`${this.apiUrl}/organizations/${organizationId}/orders/${orderId}/items/${orderItemId}`);
  }
}

const orderService = new OrderService();
export default orderService;
