import languageService from "../core/language-service";
import ServicesApiBaseService from "../services-api-base-service";
import Member from "./member";

class CustomersService extends ServicesApiBaseService {
  async getCustomer(organizationId: string, customerId: string): Promise<any | undefined> {
    const customer = await this.getObject<any>(`${this.apiUrl}/organizations/${organizationId}/customers/${customerId}`);
    // console.log(customer);
    return customer || undefined;
  }

  async getCustomers(organizationId: string): Promise<Array<Member>> {
    const members = await this.getObject<Array<Member>>(`${this.apiUrl}/organizations/${organizationId}/customers`);
    return members || [];
  }

  async createCustomer(
    organizationId: string,
    firstname: string,
    lastname: string,
    phoneNumber: string,
    email: string,
    unit: string,
    language?: string | undefined
  ): Promise<string | undefined> {
    return await this.postObject<any, string>(`${this.apiUrl}/organizations/${organizationId}/customers`, {
      firstname: firstname,
      lastname: lastname,
      phone: phoneNumber,
      email: email,
      language: language || languageService.getLanguage(),
      unit: unit,
    });
  }

  async updateCustomer(organizationId: string, customer: any): Promise<void> {
    return await this.putObject<any>(`${this.apiUrl}/organizations/${organizationId}/customers/${customer.id}`, customer);
  }

  async deleteCustomer(organizationId: string, customerId: string): Promise<void> {
    return await this.deleteObject<any>(`${this.apiUrl}/organizations/${organizationId}/customers/${customerId}`);
  }
}

const customersService = new CustomersService();
export default customersService;
