class LanguageService {
  language: string = "en";

  constructor() {
    this.language = localStorage.getItem("language") || (window.navigator.language.includes("fr") ? "fr" : "en");
  }

  getLanguage(): string {
    return this.language;
  }

  changeLanguage(): string {
    if (this.language === "fr") {
      this.language = "en";
    } else {
      this.language = "fr";
    }
    localStorage.setItem("language", this.language);

    // console.log(`languageService.changeLanguage --> ${this.language}`);
    return this.language;
  }
}

const languageService = new LanguageService();
export default languageService;
