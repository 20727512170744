import React, { useEffect, useState } from "react";
import OrganizationHubAssociation from "../../../services/organization/organization-hub-association";
import "../hub-permission-table.scss";

interface Iprops {
  hubs: Array<OrganizationHubAssociation>;
  currentHubs: Array<string> | undefined;
  setHubsIds: Function;
}

export default function HubInclusionTable(props: Iprops) {
  const { hubs, setHubsIds, currentHubs } = props;
  const [selectedOptions, setSelectedOptions] = useState<{ [hubId: string]: boolean }>({});

  useEffect(() => {
    var initialSelections: any = {};

    currentHubs?.forEach((hub) => {
      initialSelections[hub] = true;
    });

    setSelectedOptions(initialSelections);
  }, []);

  useEffect(() => {
    var newHubs: Array<string> = [];
    hubs.forEach((hub) => {
      if (selectedOptions[hub.hubId]) newHubs.push(hub.hubId);
    });

    setHubsIds(newHubs);
  }, [selectedOptions, setHubsIds]);

  const handleOptionChange = (hubId: string, option: boolean) => {
    setSelectedOptions((prev) => {
      const updatedOptions = { ...prev, [hubId]: option };
      return updatedOptions;
    });
  };

  return (
    <div className="mt-5 mb-5">
      {hubs.map((hub) => (
        <div key={hub.hubId}>
          <span>
            <b>{hub.hubId}</b>
          </span>
          <div className="permission-radio-group">
            <label>
              <input
                type="radio"
                name={`hub-${hub.hubId}`}
                value="include"
                checked={selectedOptions[hub.hubId]}
                onChange={() => handleOptionChange(hub.hubId, true)}
              />
              Include
            </label>
            <label>
              <input
                type="radio"
                name={`hub-${hub.hubId}`}
                value="exclude"
                checked={!selectedOptions[hub.hubId]}
                onChange={() => handleOptionChange(hub.hubId, false)}
              />
              Exclude
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}
